const currencyFormats = {
    USD: "en-US",
    INR: "en-IN",
};

const currencyFormatter = (
    amount: number | undefined,
    format: string | undefined,
    type?: string | undefined,
) => {
    if (amount === undefined) return null;
    if (format === undefined) return null;
    let minMax = 2;
    return new Intl.NumberFormat((currencyFormats as any)[format], {
        style: "currency",
        currency: format,
        minimumFractionDigits: minMax,
        maximumFractionDigits: minMax,
    }).format(amount);
};

export default currencyFormatter;
