import React, { useState } from "react";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import SwapHorizIcon from "@material-ui/icons/SwapHoriz";
import SettingsIcon from "@material-ui/icons/Settings";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import MailIcon from "@material-ui/icons/Mail";
import { createTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/core/styles";
import { ReactElement } from "react";
import NavigationList from "../../layout/MainLayout/Components/NavigationList";
import { Icon } from "@material-ui/core";
import FormDialog from "../Dialog/Dialog";
// import Combo from "../../Components/SwitchContext/SwitchContext";
import { ExitToApp } from "@material-ui/icons";
import { useHistory } from "react-router";
import { connect } from "react-redux";
import Breadcrumbs from "Common/Breadcrumbs/Breadcrumbs";
import Openworks from "Common/NavBar/Openworks.svg";

const drawerWidth = 240;

const applicationSettings = [
    // {
    //     name: "Change context",
    //     icon: SwapHorizIcon,
    // },
    // {
    //     name: "Setup Preferences",
    //     icon: SettingsIcon,
    // },
    {
        name: "Logout",
        icon: ExitToApp,
    },
];

const theme = createTheme({
    palette: {
        primary: {
            main: "#FFFFFF",
        },
        secondary: {
            main: "#0073e6",
        },
    },
});

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    hide: {
        display: "none",
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: "nowrap",
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: "hidden",
        width: theme.spacing(7) + 1,
        [theme.breakpoints.up("sm")]: {
            width: theme.spacing(9) + 1,
        },
    },
    toolbar: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
}));

interface MiniDrawer {
    Child: ReactElement;
    breadcrumb: { value: string; href: string }[];
}

function MiniDrawer(props: MiniDrawer) {
    const classes = useStyles();
    const { Child, breadcrumb } = props;
    const history = useHistory();
    const [open, setOpen] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    return (
        <div className={classes.root}>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <AppBar
                    position="fixed"
                    className={clsx(classes.appBar, {
                        [classes.appBarShift]: open,
                    })}
                >
                    <Toolbar>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={handleDrawerOpen}
                            edge="start"
                            className={clsx(classes.menuButton, {
                                [classes.hide]: open,
                            })}
                        >
                            <MenuIcon />
                        </IconButton>
                        <img
                            src={Openworks}
                            style={{ maxWidth: "60px", maxHeight: "60px" }}
                            alt="Logo"
                        />
                    </Toolbar>
                </AppBar>
                <Drawer
                    variant="permanent"
                    className={clsx(classes.drawer, {
                        [classes.drawerOpen]: open,
                        [classes.drawerClose]: !open,
                    })}
                    classes={{
                        paper: clsx({
                            [classes.drawerOpen]: open,
                            [classes.drawerClose]: !open,
                        }),
                    }}
                    style={{
                        backgroundColor: "#33415C",
                        color: "white !important",
                    }}
                >
                    <div className={classes.toolbar}>
                        <IconButton onClick={handleDrawerClose}>
                            {theme.direction === "rtl" ? (
                                <ChevronRightIcon />
                            ) : (
                                <ChevronLeftIcon />
                            )}
                        </IconButton>
                    </div>
                    <Divider />
                    <NavigationList />
                    <Divider />
                    <List>
                        {applicationSettings.map((element, index) => (
                            <ListItem
                                button
                                key={index}
                                onClick={
                                    element.name === "Change context"
                                        ? () => {
                                              setOpenDialog(!openDialog);
                                          }
                                        : element.name === "Logout"
                                        ? () => {
                                              history.push("/logout");
                                          }
                                        : () => {}
                                }
                            >
                                <ListItemIcon>
                                    <element.icon />
                                </ListItemIcon>
                                <ListItemText primary={element.name} />
                            </ListItem>
                        ))}
                    </List>
                </Drawer>
                <main className={classes.content}>
                    <div className={classes.toolbar} />
                    <FormDialog
                        Children={<></>}
                        title="Switch Context"
                        open={[openDialog, setOpenDialog]}
                    />
                    <Breadcrumbs />
                    {Child}
                </main>
            </ThemeProvider>
        </div>
    );
}

const mapStateToProps = (state: any) => {
    return {
        supplierCode: state.hiddenSupplier?.supplierCodeOut,
        currentContext: state.context?.userDefaults,
        userName: state.context?.name,
        breadcrumb: state.context?.breadcrumb,
    };
};

export default connect(mapStateToProps)(MiniDrawer);
