import React from 'react';
import Body from './Invoices/Body';

const Invoices = () => {
  return (
      <Body/>
  );
};

export default Invoices;
