const servicedayQuerySV = `
query Query {
  ServicedaysSV {
    ContractId
    Monday
    Tuesday
    Wednesday
    Thrusday
    Friday
    Saturday
    Sunday
    }
  }
  `;

export default servicedayQuerySV;
