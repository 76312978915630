const BuildingQuery = `
mutation(
    $entity: String
    $ou: Int
    $service: String
    $User: String
    $languageId: Int
    $customerCode: String
    $customerName: String
    $merrorid: String
) {
    BuildingQuery(
        entity: $entity
        ou: $ou
        service: $service
        User: $User
        languageId: $languageId
        customerCode: $customerCode
        customerName: $customerName
        merrorid: $merrorid
    ) {
        Building{
            area
            number
            city
        }
        AccNo
        CustLevel
        Address{
            name
            city
            state
            pincode
        }
        VendorInfo{
            name
            company
        }
        TotalAmt
        LastQOS
    }
}`;

export default BuildingQuery;
