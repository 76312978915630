import { useLocation } from "react-router";
import { Backdrop, CircularProgress, Grid, Paper } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import MUIButton from "Common/Button/Button";
import ButtonGroups from "Common/ButtonGroup/ButtonGroup";
import useGetPosts from "ReactQuery/reactQuery";
import MuiDatatables from "Common/Mui-DataTables/MuiDatatables";
// import invoiceDetailsListSchema from "./Schema/InvoiceDetailsListSchema";
// import vendorDetailsListSchema from "./Schema/VendorDetailsListSchema";
import COGSSchema from "./Schema/COGSSchema";
import Icons from "Common/Icons/Icons";
import {
    COGSQuery,
    PaymentsQuery,
    InvoicePaymentsQuery,
    InvoiceTabQuery,
    ContractInfoQuery,
    customerdetailQuery,
    detailinvoiceQuery,
    invoiceCardHeaderQuery,
} from "ReactQuery/Query/index";
import InvoicePaymentsSchema from "./Schema/InvoicePaymentsSchema";
import CustomerContactInfo from "./CustomerContactInfo";
import ContractInfoSchema from "./Schema/ContractInfoSchema";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import Alert from "Common/Alerts/Alerts";
import { makeStyles, Theme } from "@material-ui/core/styles";
import currencyFormatter from "Common/Utils/currencyFormatter";

interface InvoiceLocation {
    pathName?: string;
    hash?: "";
    key?: string;
    pathname?: string;
    search?: string;
    state?: any;
}

interface Customercards {
    CustomerInfo: {
        accountname: string;
        accountnumber: string;
        customerstatus: string;
        primarycontact: string;
        region: string;
    };
    StreetBilling: {
        billingstreet: string;
        billingcity: string;
        billingstate: string;
        email: string;
    };
}
interface Invoicecdetards {
    contractstatus: string;
    Type: string;
    billingamount: string;
    contract: string;
    revision: string;
    revisionstartend: string;
    servicecode: string;
    servicedescription: string;
    salesperson: string;
}

interface InvoiceDetailsListProps {
    desc: string;
    value: string;
}

interface contactcards {
    VendorID: string;
    ContractID: string;
    Building: string;
    Amount: number;
    DocDate: {
        date: string;
        pendingdate: string;
        PastDueDays: string;
    };
    ExtendedAmount: number;
    StatusReason: string;
}

interface BodyProps {
    currentContext: any;
    userName: any;
    selectedCustomer: any;
    currency: any;
}

const useStyles = makeStyles((theme: Theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
}));
const InvoicesDetails = (props: BodyProps) => {
    const classes = useStyles();
    const invoiceData = useLocation<any>();
    const invoiceNo: any = invoiceData?.state?.InvoiceSelected;
    console.log("invoiceNo", invoiceNo);
    const { currentContext, userName, currency, selectedCustomer } = props;

    const navlist = [
        {
            desc: "Invoice",
            value: "Invoice",
        },

        {
            desc: "Customer Details",
            value: "Customer Details",
        },

        {
            desc: "Contract Info",
            value: "Contract Info",
        },

        {
            desc: "Contract Details",
            value: "Contract Details",
        },
    ];

    const controlList = [
        {
            desc: "Customer & Contract Info",
            value: "Customer & Contract Info",
        },
        // {
        //     desc: "COGS",
        //     value: "COGS",
        // },
        {
            desc: "Payables",
            value: "Payables",
        },
        {
            desc: "Invoice Payments",
            value: "Invoice Payments",
        },
    ];

    const [ContactInfoComp, setContactInfoComp] = useState<
        InvoiceDetailsListProps[]
    >(navlist);
    const [selectedContactInfo, setSelectedContactInfo] = useState(
        navlist[0]?.desc,
    );
    const [contractInfoSchema, setContractInfoSchema] = useState(
        ContractInfoSchema,
    );
    const [PaymentsList, setPaymentsList] = useState<any>([]);
    const [contractInfo, setContractInfo] = useState();
    const [InvoicecontactData, setInvoicecontactData] = useState<any>([]);
    const [contractNumber, setContractNumber] = useState<any>("");
    const [InvoicePayments, setInvoicePayments] = useState<any>([]);
    const [detailinvoiceinfo, setdetailinvoiceinfo] = useState<any>([]);
    const [InvoiceTab, setInvoiceTab] = useState<any>([]);

    const [popoverBlack, setPopoverBlack] = useState(false);
    const [alert, setAlert] = useState(false);
    const [errr, seterr] = useState({
        code: 0,
        msg: "",
    });
    const [activeList, setActiveList] = useState(controlList[0].desc);

    const handleDocList = (listType: string) => {
        console.log("listType", listType);
        setActiveList(listType);
    };

    const handleErrorAlert = (code: number, message: string) => {
        Alert({
            statusCode: code,
            message: message,
            willClose: () => {
                setAlert(false);
                seterr({ code: 0, msg: "" });
            },
        });
    };

    const {
        data: InvoiceTabData,
        error: InvoiceTabDataError,
        isLoading: InvoiceTabDataLoading,
    } = useGetPosts({
        name: `InvoiceTabQuery_get_data_${invoiceNo}_${contractNumber}`,
        query: InvoiceTabQuery,
        variables: {
            entity: "OW_Cust_portal_inv_Contr_Cust",
            ou: currentContext?.ouId,
            service: "",
            User: userName,
            languageId: currentContext?.langId,
            invoiceno: invoiceNo,
            customerName: selectedCustomer?.companyName,
            customerCode: selectedCustomer?.companyCode,
            siteId: "",
            buildName: "",
            contractno: contractNumber,
            merrorid: "",
        },
        skip: !(
            activeList === "Customer & Contract Info" &&
            selectedContactInfo === "Invoice" &&
            contractNumber?.length
        ),
    });

    useEffect(() => {
        console.log("InvoiceTabQuery", InvoiceTabData?.GetInvoiceTab);
        if (InvoiceTabData?.GetInvoiceTab) {
            setInvoiceTab(InvoiceTabData?.GetInvoiceTab);
        }
    }, [InvoiceTabData]);
    useEffect(() => {
        if (InvoiceTabDataError && !InvoiceTabDataLoading) {
            const data = JSON.parse(JSON.stringify(InvoiceTabDataError));
            const errmsg = data?.response?.errors[0].message;
            seterr({
                code: 400,
                msg: errmsg,
            });
            setAlert(true);
        }
    }, [InvoiceTabDataError, InvoiceTabDataLoading]);

    const {
        data: InvoicePaymentsData,
        error: InvoicePaymentsDataError,
        isLoading: InvoicePaymentsDataLoading,
    } = useGetPosts({
        name: `InvoicePayments_get_data_${invoiceNo}_${contractNumber}`,
        query: InvoicePaymentsQuery,
        variables: {
            entity: "OW_Cust_portal_Inv_Cust_Cntr_Paymt",
            ou: currentContext?.ouId,
            service: "",
            User: userName,
            languageId: currentContext?.langId,
            invoiceno: invoiceNo,
            customerCode: selectedCustomer?.companyCode,
            customerName: selectedCustomer?.companyName,
            // siteId: "S101",
            // buildName: "",
            // contractNo: "CC285558",
            siteId: "",
            buildName: "",
            contractNo: contractNumber,
            merrorid: "",
        },
        skip: !(activeList === "Invoice Payments"),
    });

    useEffect(() => {
        console.log(
            "InvoicePaymentsQuery",
            InvoicePaymentsData?.GetInvoicePayments,
        );
        if (InvoicePaymentsData?.GetInvoicePayments) {
            setInvoicePayments(InvoicePaymentsData?.GetInvoicePayments);
        }
    }, [InvoicePaymentsData]);

    useEffect(() => {
        if (InvoicePaymentsDataError && !InvoicePaymentsDataLoading) {
            const data = JSON.parse(JSON.stringify(InvoicePaymentsDataError));
            const errmsg = data?.response?.errors[0].message;
            seterr({
                code: 400,
                msg: errmsg,
            });
            setAlert(true);
        }
    }, [InvoicePaymentsDataError, InvoicePaymentsDataLoading]);

    const {
        data: invoiceContactData,
        error: invoiceContactDataError,
        isLoading: invoiceContactDataLoading,
    } = useGetPosts({
        name: `invoiceContactQuery_${invoiceNo}`,
        query: invoiceCardHeaderQuery,
        variables: {
            entity: "OW_Cust_portal_InvHdr",
            ou: currentContext?.ouId,
            service: "",
            User: userName,
            languageId: currentContext?.langId,
            invoiceno: invoiceNo,
            customerCode: selectedCustomer?.companyCode,
            customerName: selectedCustomer?.companyName,
            siteId: "",
            buildName: "",
            contractNo: "",
            merrorid: "",
        },
    });

    useEffect(() => {
        console.log("InvoiceDetailHeader", invoiceContactData?.invoiceContact);
        if (invoiceContactData?.invoiceContact) {
            setInvoicecontactData(invoiceContactData?.invoiceContact);
            setContractNumber(
                invoiceContactData?.invoiceContact[0]?.ContractID,
            );
        }
    }, [invoiceContactData]);
    useEffect(() => {
        if (invoiceContactDataError && !invoiceContactDataLoading) {
            const data = JSON.parse(JSON.stringify(invoiceContactDataError));
            const errmsg = data?.response?.errors[0].message;
            seterr({
                code: 400,
                msg: errmsg,
            });
            setAlert(true);
        }
    }, [invoiceContactDataError, invoiceContactDataLoading]);

    const {
        data: contractInfoData,
        error: contractInfoDataError,
        isLoading: contractInfoDataLoading,
    } = useGetPosts({
        name: `contractInfoQuery_get_data_${invoiceNo}_${contractNumber}`,
        query: ContractInfoQuery,
        variables: {
            entity: "OW_Cust_portal_inv_Contr_Cust_info",
            ou: currentContext?.ouId,
            service: "",
            User: userName,
            languageId: currentContext?.langId,
            invoiceno: invoiceNo,
            customerCode: selectedCustomer?.companyCode,
            customerName: selectedCustomer?.companyName,
            siteId: "",
            buildName: "",
            contractNo: contractNumber,
            merrorid: "",
        },
        skip: !(
            activeList === "Customer & Contract Info" &&
            selectedContactInfo === "Contract Info" &&
            contractNumber?.length
        ),
    });

    useEffect(() => {
        console.log("contractInfoData", contractInfoData?.GetContractInfo);
        if (contractInfoData?.GetContractInfo) {
            setContractInfo(contractInfoData?.GetContractInfo);
        }
    }, [contractInfoData]);

    useEffect(() => {
        if (contractInfoDataError && !contractInfoDataLoading) {
            const data = JSON.parse(JSON.stringify(contractInfoDataError));
            const errmsg = data?.response?.errors[0].message;
            seterr({
                code: 400,
                msg: errmsg,
            });
            setAlert(true);
        }
    }, [contractInfoDataError, contractInfoDataLoading]);

    const {
        data: invoicedetailData,
        error: invoicedetailDataError,
        isLoading: invoicedetailDataLoading,
    } = useGetPosts({
        name: `detailinvoiceQuery_${invoiceNo}_${contractNumber}`,
        query: detailinvoiceQuery,
        variables: {
            entity: "OW_Cust_portal_inv_Contr_Cust_dtls",
            ou: currentContext?.ouId,
            service: "",
            User: userName,
            languageId: currentContext?.langId,
            invoiceno: invoiceNo,
            customerCode: selectedCustomer?.companyCode,
            customerName: selectedCustomer?.companyName,
            siteId: "",
            buildName: "",
            contractNo: contractNumber,
            merrorid: "",
        },
        skip: !(
            activeList === "Customer & Contract Info" &&
            selectedContactInfo === "Contract Details" &&
            contractNumber?.length
        ),
    });

    useEffect(() => {
        console.log("detailinvoiceQuery", invoicedetailData?.detailsInvoice);
        if (invoicedetailData?.detailsInvoice) {
            setdetailinvoiceinfo(invoicedetailData?.detailsInvoice);
        }
    }, [invoicedetailData]);

    useEffect(() => {
        if (invoicedetailDataError && !invoicedetailDataLoading) {
            const data = JSON.parse(JSON.stringify(invoicedetailDataError));
            const errmsg = data?.response?.errors[0].message;
            seterr({
                code: 400,
                msg: errmsg,
            });
            setAlert(true);
        }
    }, [invoicedetailDataError, invoicedetailDataLoading]);

    const [customerdetailsinfo, setcustomerdetailsinfo] = useState<
        Customercards[]
    >([]);

    const {
        data: CustomerdetailData,
        error: CustomerdetailDataError,
        isLoading: CustomerdetailDataLoading,
    } = useGetPosts({
        name: `customerdetailQuery_${invoiceNo}_${contractNumber}`,
        query: customerdetailQuery,
        variables: {
            entity: "OW_Cust_portal_Invs_Invoice",
            ou: currentContext?.ouId,
            service: "",
            User: userName,
            languageId: currentContext?.langId,
            invoiceno: invoiceNo,
            customerCode: selectedCustomer?.companyCode,
            customerName: selectedCustomer?.companyName,
            siteId: "",
            buildName: "",
            contractno: contractNumber,
            merrorid: "",
        },
        skip: !(
            activeList === "Customer & Contract Info" &&
            selectedContactInfo === "Customer Details" &&
            contractNumber?.length
        ),
    });

    useEffect(() => {
        console.log("customerdetailQuery", CustomerdetailData?.Customerdetails);
        if (CustomerdetailData?.Customerdetails) {
            setcustomerdetailsinfo(CustomerdetailData?.Customerdetails);
        }
    }, [CustomerdetailData]);

    useEffect(() => {
        if (CustomerdetailDataError && !CustomerdetailDataLoading) {
            const data = JSON.parse(JSON.stringify(CustomerdetailDataError));
            const errmsg = data?.response?.errors[0].message;
            seterr({
                code: 400,
                msg: errmsg,
            });
            setAlert(true);
        }
    }, [CustomerdetailDataError, CustomerdetailDataLoading]);

    const {
        data: PaymentsListData,
        error: PaymentsListDataError,
        isLoading: PaymentsListDataLoading,
    } = useGetPosts({
        name: `PaymentsListQuery_get_data_${invoiceNo}_${contractNumber}`,
        query: PaymentsQuery,
        variables: {
            entity: "OW_Cust_portal_inv_Contr_Cust_Payables",
            ou: currentContext?.ouId,
            service: "",
            User: userName,
            languageId: currentContext?.langId,
            invoiceNo: invoiceNo,
            customerCode: selectedCustomer?.companyCode,
            customerName: selectedCustomer?.companyName,
            siteId: "",
            buildName: "",
            contractNo: contractNumber,
            merrorid: "",
        },
        skip: !(activeList === "Payables" && contractNumber?.length),
    });

    useEffect(() => {
        console.log("PaymentsQuery", PaymentsListData?.GetPayments);
        if (PaymentsListData?.GetPayments) {
            setPaymentsList(PaymentsListData?.GetPayments);
        }
    }, [PaymentsListData]);

    useEffect(() => {
        if (PaymentsListDataError && !PaymentsListDataLoading) {
            const data = JSON.parse(JSON.stringify(PaymentsListDataError));
            const errmsg = data?.response?.errors[0].message;
            seterr({
                code: 400,
                msg: errmsg,
            });
            setAlert(true);
        }
    }, [PaymentsListDataError, PaymentsListDataLoading]);

    const [COGSList, setCOGSList] = useState([]);
    const [toggle, setToggle] = useState(false);

    const handleBuildingClick = () => {
        // redirect to build page
        setToggle((prevState) => !prevState);
    };

    // const { data: COGSListData, error: COGSListDataError } = useGetPosts({
    //     name: `COGSListQuery_get_data`,
    //     query: COGSQuery,
    //     variables: {},
    //     skip: !(activeList === "COGS"),
    // });

    // useEffect(() => {
    //     if (COGSListData?.GetCogs) {
    //         setCOGSList(COGSListData?.GetCogs);
    //     }
    // }, [COGSListData]);

    const handleSelect = (data: any) => {
        setSelectedContactInfo(data.desc);
        console.log("data", data);
    };

    if (toggle) {
        return (
            <Redirect
                to={{
                    pathname: "/app/BuildDetail",
                }}
            />
        );
    }

    const InvoiceTabSchema = [
        {
            name: "InvoiceNo",
            Label: "InvoiceNo",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Invoice Number</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return (
                        <b style={{ color: "#0073E6" }} className="font-bolder">
                            {value}
                        </b>
                    );
                },
            },
        },
        {
            name: "AccountName",
            Label: "AccountName",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Account Name</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return <b className="grey">{value}</b>;
                },
            },
        },
        {
            name: "AccountNo",
            Label: "AccountNo",
            type: "string",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Account No.</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return <b style={{ color: "#5C677D" }}>{value}</b>;
                },
            },
        },
        {
            name: "TotalAmount",
            Label: "TotalAmount",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Total Amount</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return (
                        <b
                            className="green font-bolder"
                            style={{
                                display: "flex",
                                justifyContent: "flex-end",
                            }}
                        >
                            {currencyFormatter(
                                (value as any) && value,
                                currency,
                            )}
                        </b>
                    );
                },
            },
        },
        {
            name: "TotalAndTax",
            Label: "TotalAndTax",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Amount + Tax</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: {
                        line1: number;
                        line2: number;
                    },
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return (
                        <b
                            className="grey"
                            style={{
                                display: "flex",
                                justifyContent: "flex-end",
                            }}
                        >
                            {`${currencyFormatter(
                                (value as any) && value.line1,
                                currency,
                            )} + ${currencyFormatter(
                                (value as any) && value.line2,
                                currency,
                            )}`}
                        </b>
                    );
                },
            },
        },
        {
            name: "BuildingDetails",
            Label: "BuildingDetails",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Building Details</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: {
                        line1: string;
                        line2: string;
                    },
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return (
                        <Grid container>
                            <Grid item xs={12}>
                                <b
                                    style={{ color: "#0073E6" }}
                                    className=" font-bolder "
                                >
                                    {value.line1}
                                </b>
                            </Grid>
                            <Grid item xs={12}>
                                <b className="grey ">{value.line2}</b>
                            </Grid>
                        </Grid>
                    );
                },
            },
        },
        {
            name: "Comments",
            Label: "Comments",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}> Comments</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return <b style={{ color: "#5C677D" }}>{value}</b>;
                },
            },
        },
        {
            name: "MonthYear",
            Label: "MonthYear",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}> Month & Year</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return <b style={{ color: "#5C677D" }}>{value}</b>;
                },
            },
        },
    ];

    const PaymentsSchema = [
        {
            name: "VendorName",
            Label: "VendorName",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Vendor Name</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return (
                        <b style={{ color: "#0073E6" }} className="font-bolder">
                            {value}
                        </b>
                    );
                },
            },
        },
        {
            name: "VendorID",
            Label: "VendorID",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Vendor ID</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return <b className="grey">{value}</b>;
                },
            },
        },
        {
            name: "ContractDetails",
            Label: "ContractDetails",

            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Contract Details</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: {
                        line1: string;
                        line2: string;
                        line3: string;
                    },
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return (
                        <Grid container>
                            <Grid item xs={12}>
                                <b className="grey2 font-bolder">
                                    {value.line1}
                                </b>
                            </Grid>
                            <Grid item xs={12}>
                                <b className="grey ">
                                    {value?.line2 ? `${value.line2} | ` : ""}
                                    {value.line3}
                                </b>
                            </Grid>
                        </Grid>
                    );
                },
            },
        },
        {
            name: "Building",
            Label: "Building",
            type: "string",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Building</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: {
                        line1: string;
                        line2: string;
                        line3: string;
                    },
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return (
                        <b
                            style={{
                                color: "#0073E6",
                                cursor: "pointer",
                            }}
                            // onClick={(value) => handleBuildingClick()}
                            className=" font-bolder "
                        >
                            {value}
                        </b>
                    );
                },
            },
        },
        {
            name: "BuildingID",
            Label: "BuildingID",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Building ID</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return <b className="grey">{value}</b>;
                },
            },
        },
        {
            name: "City",
            Label: "City",
            type: "string",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>City</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return <b className="grey ">{value}</b>;
                },
            },
        },
        {
            name: "PayStatus",
            Label: "PayStatus",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Pay Status</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return value === "Paid" ? (
                        <>
                            <b className="Completed_dot"></b>
                            <b style={{ color: "#00AE50", padding: "3%" }}>
                                {value}
                            </b>
                        </>
                    ) : value === "Not Paid" ? (
                        <>
                            <b className="Failed_dot"></b>
                            <b style={{ color: "#E01E37", padding: "3%" }}>
                                {value}
                            </b>
                        </>
                    ) : (
                        <>
                            <b className="Pending_dot"></b>
                            <b style={{ color: "#dd9200", padding: "3%" }}>
                                {value}
                            </b>
                        </>
                    );
                },
            },
        },
        {
            name: "TransactionDetails",
            Label: "TransactionDetails",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Transaction Details</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return <b style={{ color: "#5C677D" }}>{value}</b>;
                },
            },
        },

        {
            name: "VendorAmount",
            Label: "VendorAmount",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Vendor Amount</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return (
                        <b
                            style={{
                                color: "#00AE50",
                                display: "flex",
                                justifyContent: "flex-end",
                            }}
                        >
                            {currencyFormatter(value, currency)}
                        </b>
                    );
                },
            },
        },
        {
            name: "Desc",
            Label: "Desc",
            type: "string",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Desc.</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return <b style={{ color: "#5C677D" }}>{value}</b>;
                },
            },
        },

        {
            name: "AdjustmentReason",
            Label: "AdjustmentReason",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Adjustment Reason</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return <b style={{ color: "#5C677D" }}>{value}</b>;
                },
            },
        },
        {
            name: "Date",
            Label: "Date",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Date</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return <b style={{ color: "#5C677D" }}>{value}</b>;
                },
            },
        },
        {
            name: "Approved",
            Label: "Approved",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Approved</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return value === "Paid" ? (
                        <>
                            <b className="Completed_dot"></b>
                        </>
                    ) : value === "Not Paid" ? (
                        <>
                            <b className="Failed_dot"></b>
                        </>
                    ) : (
                        <>
                            <b className="Pending_dot"></b>
                        </>
                    );
                },
            },
        },
    ];

    return (
        <>
            {alert ? handleErrorAlert(errr.code, errr.msg) : <></>}
            <Backdrop
                className={classes.backdrop}
                open={
                    invoiceContactDataLoading ||
                    contractInfoDataLoading ||
                    invoicedetailDataLoading ||
                    CustomerdetailDataLoading ||
                    InvoiceTabDataLoading ||
                    InvoicePaymentsDataLoading ||
                    PaymentsListDataLoading
                }
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Backdrop
                className={classes.backdrop}
                open={popoverBlack}
            ></Backdrop>

            <Grid container spacing={2} style={{ textAlign: "left" }}>
                <Grid
                    container
                    spacing={2}
                    style={{ paddingTop: "2%", textAlign: "left" }}
                >
                    <Grid item sm={9} xs={12}>
                        <Paper elevation={3}>
                            <Grid container style={{ padding: "1.5%" }}>
                                <Grid
                                    item
                                    xs={5}
                                    style={{
                                        margin: "0%",
                                        padding: "1.5% 4% 1.5% 1.5%",
                                        borderRight: "1px dashed #C9CDD6",
                                    }}
                                >
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <Grid
                                                container
                                                justifyContent="space-between"
                                                // style={{paddingLeft:"2%"}}
                                            >
                                                <Grid
                                                    item
                                                    className="grey2 font-boldest"
                                                    xs={12}
                                                >
                                                    <Grid
                                                        container
                                                        alignItems="center"
                                                        spacing={2}
                                                    >
                                                        <Grid
                                                            item
                                                            xs={1}
                                                            style={{
                                                                paddingRight:
                                                                    "2%",
                                                            }}
                                                        >
                                                            <Icons Icon="contractDetail" />
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xs={4}
                                                            style={{
                                                                padding: "0 2%",
                                                            }}
                                                        >
                                                            {!invoiceContactDataLoading
                                                                ? InvoicecontactData[0]
                                                                      ?.VendorID
                                                                : ""}
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xs={4}
                                                            style={{
                                                                padding:
                                                                    "0 0 0 1%",
                                                            }}
                                                        >
                                                            {!invoiceContactDataLoading &&
                                                            InvoicecontactData[0]
                                                                ?.ContractID
                                                                ? `|  #${InvoicecontactData[0]?.ContractID}`
                                                                : ""}
                                                        </Grid>
                                                    </Grid>
                                                    {/* {contactName?.contractSelected[0]} */}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            // style={{ padding: "2%" }}
                                        >
                                            <Grid
                                                container
                                                alignItems="center"
                                                spacing={2}
                                            >
                                                <Grid
                                                    item
                                                    xs={1}
                                                    style={{
                                                        paddingRight: "2%",
                                                    }}
                                                >
                                                    <Icons Icon="buildingContract" />
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={9}
                                                    className="grey2 "
                                                    style={{
                                                        paddingLeft: "2%",
                                                    }}
                                                >
                                                    {!invoiceContactDataLoading
                                                        ? InvoicecontactData[0]
                                                              ?.Building
                                                        : ""}
                                                </Grid>
                                            </Grid>

                                            {/* {contactName?.contractSelected[4]} */}
                                        </Grid>
                                        <Grid
                                            container
                                            alignItems="center"
                                            justifyContent="space-between"
                                            spacing={1}
                                        >
                                            <Grid
                                                item
                                                xs={5}
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    border: `${
                                                        InvoicecontactData[0]?.StatusReason.toLowerCase() ===
                                                        "paid"
                                                            ? "1px solid #00DA64"
                                                            : InvoicecontactData[0]?.StatusReason.toLowerCase() ===
                                                              "not paid"
                                                            ? "1px solid #e01e37"
                                                            : "1px solid #DD9200"
                                                    }`,
                                                    borderRadius: "3px",
                                                    background: `${
                                                        InvoicecontactData[0]?.StatusReason.toLowerCase() ===
                                                        "paid"
                                                            ? "#EDFDF2"
                                                            : "#FCF5E9"
                                                    }`,
                                                    margin: "4px 0 0 4px",
                                                }}
                                            >
                                                {InvoicecontactData[0]
                                                    ?.StatusReason
                                                    ? InvoicecontactData[0]
                                                          ?.StatusReason
                                                    : ""}
                                            </Grid>

                                            <Grid
                                                item
                                                xs={3}
                                                className="font-bolder font-bigger"
                                                style={{
                                                    paddingLeft: "2%",
                                                    color: "#00AE50",
                                                    textAlign: "right",
                                                }}
                                            >
                                                {!invoiceContactDataLoading
                                                    ? currencyFormatter(
                                                          InvoicecontactData[0]?.Amount.toFixed(
                                                              2,
                                                          ),
                                                          currency,
                                                      )
                                                    : ""}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid
                                    item
                                    xs={7}
                                    style={{ padding: "1.5% 1.5% 1.5% 2%" }}
                                >
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={4}>
                                                    <Grid container>
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            className="grey"
                                                        >
                                                            Doc Date (Invoice
                                                            Date)
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            className="grey2 font-boldest"
                                                        >
                                                            {!invoiceContactDataLoading
                                                                ? InvoicecontactData[0]
                                                                      ?.DocDate
                                                                      ?.date
                                                                : ""}
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <Grid container>
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            className="grey"
                                                        >
                                                            Extended Amount
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            className="grey2 font-boldest"
                                                        >
                                                            {!invoiceContactDataLoading &&
                                                            InvoicecontactData[0]
                                                                ?.ExtendedAmount
                                                                ? currencyFormatter(
                                                                      InvoicecontactData[0]?.ExtendedAmount.toFixed(
                                                                          2,
                                                                      ),
                                                                      currency,
                                                                  )
                                                                : ""}
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <Grid container>
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            className="grey"
                                                        >
                                                            Status Reason
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            className="grey2 font-boldest"
                                                        >
                                                            {!invoiceContactDataLoading
                                                                ? InvoicecontactData[0]
                                                                      ?.StatusReason
                                                                : ""}
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={4}>
                                                    <Grid container>
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            className="grey"
                                                        >
                                                            Past due Days
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            className="grey2 font-boldest"
                                                        >
                                                            {!invoiceContactDataLoading
                                                                ? InvoicecontactData[0]
                                                                      ?.DocDate
                                                                      ?.PastDueDays
                                                                : ""}
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>

                <Grid container style={{ paddingTop: "2%" }}>
                    {/* <Grid item > */}
                    <ButtonGroups
                        controlList={controlList}
                        handleDocList={handleDocList}
                        gridSpacing={2}
                        externalActive={activeList}
                    />
                    {/* </Grid> */}
                </Grid>

                {activeList === "COGS" && (
                    <Grid container spacing={2} style={{ paddingTop: "2%" }}>
                        <Grid item xs={12}>
                            <MuiDatatables
                                header={COGSSchema}
                                title={""}
                                // rowData={checklistDetailsList}
                                detail={[COGSList, setCOGSList]}
                                // handleAddSideEffect={handleAddSideEffect}
                                // handleDeleteSideEffect={handleDeleteSideEffect}
                                // handleSelectedSideEffect={handleSelectedSideEffect}
                                // initRow={true}
                            />
                        </Grid>
                    </Grid>
                )}
                {activeList === "Payables" && (
                    <Grid container spacing={2} style={{ paddingTop: "2%" }}>
                        <Grid item xs={12}>
                            <MuiDatatables
                                header={PaymentsSchema}
                                title={""}
                                // rowData={checklistDetailsList}
                                detail={[PaymentsList, setPaymentsList]}
                                // handleAddSideEffect={handleAddSideEffect}
                                // handleDeleteSideEffect={handleDeleteSideEffect}
                                // handleSelectedSideEffect={handleSelectedSideEffect}
                                // initRow={true}
                            />
                        </Grid>
                    </Grid>
                )}
                {activeList === "Invoice Payments" && (
                    <Grid container spacing={2} style={{ paddingTop: "2%" }}>
                        <Grid item xs={12}>
                            <MuiDatatables
                                header={InvoicePaymentsSchema}
                                title={""}
                                // rowData={checklistDetailsList}
                                detail={[InvoicePayments, setInvoicePayments]}
                                // handleAddSideEffect={handleAddSideEffect}
                                // handleDeleteSideEffect={handleDeleteSideEffect}
                                // handleSelectedSideEffect={handleSelectedSideEffect}
                                // initRow={true}InvoiceTab
                            />
                        </Grid>
                    </Grid>
                )}

                {activeList === "Customer & Contract Info" && (
                    <Grid item xs={12} style={{ marginRight: "2%" }}>
                        <Grid
                            container
                            spacing={2}
                            style={{ paddingTop: "2%" }}
                        >
                            <Grid item xs={2} style={{ marginTop: "6px" }}>
                                <CustomerContactInfo
                                    data={ContactInfoComp}
                                    handleSelect={handleSelect}
                                />
                            </Grid>

                            {selectedContactInfo === "Invoice" && (
                                <Grid item xs={10}>
                                    {/* <Grid item xs={12} > */}
                                    <MuiDatatables
                                        header={InvoiceTabSchema}
                                        title={""}
                                        // rowData={checklistDetailsList}
                                        detail={[InvoiceTab, setInvoiceTab]}
                                        // handleAddSideEffect={handleAddSideEffect}
                                        // handleDeleteSideEffect={handleDeleteSideEffect}
                                        // handleSelectedSideEffect={handleSelectedSideEffect}
                                        // initRow={true}InvoiceTab
                                    />
                                </Grid>
                            )}

                            {selectedContactInfo === "Contract Info" && (
                                <Grid item xs={10}>
                                    {/* <Grid item xs={12} > */}
                                    <MuiDatatables
                                        header={contractInfoSchema}
                                        title={""}
                                        // rowData={checklistDetailsList}
                                        detail={[contractInfo, setContractInfo]}
                                        // handleAddSideEffect={handleAddSideEffect}
                                        // handleDeleteSideEffect={handleDeleteSideEffect}
                                        // handleSelectedSideEffect={handleSelectedSideEffect}
                                        // initRow={true}InvoiceTab
                                    />
                                </Grid>
                            )}

                            {selectedContactInfo === "Contract Details" && (
                                <Grid item xs={8}>
                                    <Grid container>
                                        <Grid item xs={6}>
                                            <Paper>
                                                <Grid
                                                    container
                                                    spacing={2}
                                                    style={{ padding: "4%" }}
                                                >
                                                    <Grid item xs={12}>
                                                        <Grid container>
                                                            <Grid
                                                                item
                                                                xs={6}
                                                                className="grey font-bold"
                                                            >
                                                                Contract Status
                                                            </Grid>

                                                            <Grid
                                                                item
                                                                xs={6}
                                                                className="grey2 font-boldest"
                                                            >
                                                                {!invoicedetailDataLoading
                                                                    ? detailinvoiceinfo[0]
                                                                          ?.contractstatus
                                                                    : ""}
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        {" "}
                                                        <Grid container>
                                                            <Grid
                                                                item
                                                                xs={6}
                                                                className="grey font-bold"
                                                            >
                                                                Type{" "}
                                                            </Grid>

                                                            <Grid
                                                                item
                                                                xs={6}
                                                                className="grey2 font-boldest"
                                                            >
                                                                {!invoicedetailDataLoading
                                                                    ? detailinvoiceinfo[0]
                                                                          ?.Type
                                                                    : ""}
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid item xs={12}>
                                                        {" "}
                                                        <Grid container>
                                                            <Grid
                                                                item
                                                                xs={6}
                                                                className="grey font-bold"
                                                            >
                                                                Billing Amount{" "}
                                                            </Grid>

                                                            <Grid
                                                                item
                                                                xs={6}
                                                                className="grey2 font-boldest"
                                                            >
                                                                {!invoicedetailDataLoading
                                                                    ? currencyFormatter(
                                                                          detailinvoiceinfo[0]?.billingamount.toFixed(
                                                                              2,
                                                                          ),
                                                                          currency,
                                                                      )
                                                                    : ""}
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid item xs={12}>
                                                        {" "}
                                                        <Grid container>
                                                            <Grid
                                                                item
                                                                xs={6}
                                                                className="grey font-bold"
                                                            >
                                                                Revision{" "}
                                                            </Grid>

                                                            <Grid
                                                                item
                                                                xs={6}
                                                                className="grey2 font-boldest"
                                                            >
                                                                {!invoicedetailDataLoading
                                                                    ? detailinvoiceinfo[0]
                                                                          ?.revision
                                                                    : ""}
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid item xs={12}>
                                                        {" "}
                                                        <Grid container>
                                                            <Grid
                                                                item
                                                                xs={6}
                                                                className="grey font-bold"
                                                            >
                                                                Revision Start -
                                                                End
                                                            </Grid>

                                                            <Grid
                                                                item
                                                                xs={6}
                                                                className="grey2 font-boldest"
                                                            >
                                                                {!invoicedetailDataLoading
                                                                    ? detailinvoiceinfo[0]
                                                                          ?.revisionstartend
                                                                    : ""}
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid item xs={12}>
                                                        {" "}
                                                        <Grid container>
                                                            <Grid
                                                                item
                                                                xs={6}
                                                                className="grey font-bold"
                                                            >
                                                                Service Code
                                                            </Grid>

                                                            <Grid
                                                                item
                                                                xs={6}
                                                                className="grey2 font-boldest"
                                                            >
                                                                {!invoicedetailDataLoading
                                                                    ? detailinvoiceinfo[0]
                                                                          ?.servicecode
                                                                    : ""}
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid item xs={12}>
                                                        {" "}
                                                        <Grid container>
                                                            <Grid
                                                                item
                                                                xs={6}
                                                                className="grey font-bold"
                                                            >
                                                                Service
                                                                Description
                                                            </Grid>

                                                            <Grid
                                                                item
                                                                xs={6}
                                                                className="grey2 font-boldest"
                                                            >
                                                                {!invoicedetailDataLoading
                                                                    ? detailinvoiceinfo[0]
                                                                          ?.servicedescription
                                                                    : ""}
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid item xs={12}>
                                                        {" "}
                                                        <Grid container>
                                                            <Grid
                                                                item
                                                                xs={6}
                                                                className="grey font-bold"
                                                            >
                                                                Sales Person
                                                            </Grid>

                                                            <Grid
                                                                item
                                                                xs={6}
                                                                className="grey2 font-boldest"
                                                            >
                                                                {!invoicedetailDataLoading
                                                                    ? detailinvoiceinfo[0]
                                                                          ?.salesperson
                                                                    : ""}
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Paper>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )}

                            {selectedContactInfo === "Customer Details" && (
                                <Grid item xs={8}>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <Paper>
                                                <Grid
                                                    container
                                                    spacing={2}
                                                    style={{ padding: "2%" }}
                                                >
                                                    <Grid
                                                        item
                                                        xs={6}
                                                        style={{
                                                            borderRight:
                                                                "1px dashed #C9CDD6",
                                                            padding:
                                                                "1% 6% 1% 1%",
                                                        }}
                                                    >
                                                        <Grid
                                                            container
                                                            spacing={2}
                                                        >
                                                            <Grid item xs={12}>
                                                                <Grid container>
                                                                    <Grid
                                                                        item
                                                                        xs={6}
                                                                        className="grey font-bold"
                                                                    >
                                                                        Account
                                                                        Name
                                                                    </Grid>

                                                                    <Grid
                                                                        item
                                                                        xs={6}
                                                                        className="grey2 font-boldest"
                                                                        style={{
                                                                            textAlign:
                                                                                "right",
                                                                        }}
                                                                    >
                                                                        {!CustomerdetailDataLoading
                                                                            ? customerdetailsinfo[0]
                                                                                  ?.CustomerInfo
                                                                                  ?.accountname
                                                                            : ""}
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>

                                                            <Grid item xs={12}>
                                                                {" "}
                                                                <Grid container>
                                                                    <Grid
                                                                        item
                                                                        xs={6}
                                                                        className="grey font-bold"
                                                                    >
                                                                        Account
                                                                        Number
                                                                    </Grid>

                                                                    <Grid
                                                                        item
                                                                        xs={6}
                                                                        className="grey2 font-boldest"
                                                                        style={{
                                                                            textAlign:
                                                                                "right",
                                                                        }}
                                                                    >
                                                                        {!CustomerdetailDataLoading
                                                                            ? customerdetailsinfo[0]
                                                                                  ?.CustomerInfo
                                                                                  ?.accountnumber
                                                                            : ""}
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>

                                                            <Grid item xs={12}>
                                                                {" "}
                                                                <Grid container>
                                                                    <Grid
                                                                        item
                                                                        xs={6}
                                                                        className="grey font-bold"
                                                                    >
                                                                        Customer
                                                                        Status
                                                                    </Grid>

                                                                    <Grid
                                                                        item
                                                                        xs={6}
                                                                        className="grey2 font-boldest"
                                                                        style={{
                                                                            textAlign:
                                                                                "right",
                                                                        }}
                                                                    >
                                                                        {!CustomerdetailDataLoading
                                                                            ? customerdetailsinfo[0]
                                                                                  ?.CustomerInfo
                                                                                  ?.customerstatus
                                                                            : ""}
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>

                                                            <Grid item xs={12}>
                                                                {" "}
                                                                <Grid container>
                                                                    <Grid
                                                                        item
                                                                        xs={6}
                                                                        className="grey font-bold"
                                                                    >
                                                                        Primary
                                                                        Contact
                                                                    </Grid>

                                                                    <Grid
                                                                        item
                                                                        xs={6}
                                                                        className="grey2 font-boldest"
                                                                        style={{
                                                                            textAlign:
                                                                                "right",
                                                                        }}
                                                                    >
                                                                        {!CustomerdetailDataLoading
                                                                            ? customerdetailsinfo[0]
                                                                                  ?.CustomerInfo
                                                                                  ?.primarycontact
                                                                            : ""}
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>

                                                            <Grid item xs={12}>
                                                                {" "}
                                                                <Grid container>
                                                                    <Grid
                                                                        item
                                                                        xs={6}
                                                                        className="grey font-bold"
                                                                    >
                                                                        Region
                                                                    </Grid>

                                                                    <Grid
                                                                        item
                                                                        xs={6}
                                                                        className="grey2 font-boldest"
                                                                        style={{
                                                                            textAlign:
                                                                                "right",
                                                                        }}
                                                                    >
                                                                        {!CustomerdetailDataLoading
                                                                            ? customerdetailsinfo[0]
                                                                                  ?.CustomerInfo
                                                                                  ?.region
                                                                            : ""}
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid
                                                        item
                                                        xs={6}
                                                        style={{
                                                            padding:
                                                                "1% 4% 1% 4%",
                                                        }}
                                                    >
                                                        <Grid
                                                            container
                                                            spacing={2}
                                                            style={{
                                                                padding: "2%",
                                                            }}
                                                        >
                                                            <Grid item xs={12}>
                                                                {" "}
                                                                <Grid container>
                                                                    <Grid
                                                                        item
                                                                        xs={4}
                                                                        className="grey font-bold"
                                                                    >
                                                                        Billing
                                                                        Street
                                                                    </Grid>

                                                                    <Grid
                                                                        item
                                                                        xs={8}
                                                                        className="grey2 font-boldest"
                                                                        style={{
                                                                            textAlign:
                                                                                "right",
                                                                        }}
                                                                    >
                                                                        {!CustomerdetailDataLoading
                                                                            ? customerdetailsinfo[0]
                                                                                  ?.StreetBilling
                                                                                  ?.billingstreet
                                                                            : ""}
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>

                                                            <Grid item xs={12}>
                                                                {" "}
                                                                <Grid container>
                                                                    <Grid
                                                                        item
                                                                        xs={4}
                                                                        className="grey font-bold"
                                                                    >
                                                                        Billing
                                                                        City
                                                                    </Grid>

                                                                    <Grid
                                                                        item
                                                                        xs={8}
                                                                        style={{
                                                                            textAlign:
                                                                                "right",
                                                                        }}
                                                                        className="grey2 font-boldest"
                                                                    >
                                                                        {!CustomerdetailDataLoading
                                                                            ? customerdetailsinfo[0]
                                                                                  ?.StreetBilling
                                                                                  ?.billingcity
                                                                            : ""}
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>

                                                            <Grid item xs={12}>
                                                                {" "}
                                                                <Grid container>
                                                                    <Grid
                                                                        item
                                                                        xs={4}
                                                                        className="grey font-bold"
                                                                    >
                                                                        Billing
                                                                        State
                                                                    </Grid>

                                                                    <Grid
                                                                        item
                                                                        xs={8}
                                                                        style={{
                                                                            textAlign:
                                                                                "right",
                                                                        }}
                                                                        className="grey2 font-boldest"
                                                                    >
                                                                        {!CustomerdetailDataLoading
                                                                            ? customerdetailsinfo[0]
                                                                                  ?.StreetBilling
                                                                                  ?.billingstate
                                                                            : ""}
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>

                                                            <Grid item xs={12}>
                                                                {" "}
                                                                <Grid container>
                                                                    <Grid
                                                                        item
                                                                        xs={4}
                                                                        className="grey font-bold"
                                                                    >
                                                                        E-Mail
                                                                    </Grid>

                                                                    <Grid
                                                                        item
                                                                        xs={8}
                                                                        style={{
                                                                            textAlign:
                                                                                "right",
                                                                        }}
                                                                        className="grey2 font-boldest"
                                                                    >
                                                                        {!CustomerdetailDataLoading
                                                                            ? customerdetailsinfo[0]
                                                                                  ?.StreetBilling
                                                                                  ?.email
                                                                            : ""}
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Paper>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                )}
            </Grid>
        </>
    );
};

const mapStateToProps = (state: any) => {
    return {
        currentContext: state.context?.userDefaults,
        userName: state.context?.name,
        currency: state.currency,
        selectedCustomer: state.selectedCustomer,
    };
};
export default connect(mapStateToProps)(InvoicesDetails);

// export default InvoicesDetails;s
