const customerdetailQuery= `
mutation( $entity: String
    $ou: Int
    $service: String
    $User: String
    $languageId: Int
    $invoiceno: String
    $customerName: String
    $customerCode: String
    $siteId: String
    $buildName: String
    $contractno:String
    $merrorid: String)
{
    Customerdetails(
        entity: $entity
        ou: $ou
        service: $service
        User: $User
        languageId: $languageId
        invoiceno: $invoiceno
        customerName: $customerName
        customerCode: $customerCode
        siteId: $siteId
        buildName: $buildName
        contractno:$contractno
        merrorid: $merrorid 
    ) {
    
        CustomerInfo{
            accountname
            accountnumber
            customerstatus
            primarycontact
            region
        }
        StreetBilling{
            billingstreet
            billingcity
            billingstate
            email

        } 
    }
  }
  `;

export default customerdetailQuery;