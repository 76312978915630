const detailExpectationsQuerySV = `
query Query{
    DetailExpectationsSV{
    BuildingDetails{
        TotalSf
        CleanableSf
        MonthlyBilling
    }
    ExceptionDetails{
        startDate
        endDate
    }

    }
  }
  `;

export default detailExpectationsQuerySV;
