import { Grid, Paper, Tooltip } from "@material-ui/core";
import React, { useState } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import Icons from "Common/Icons/Icons";
import { connect } from "react-redux";
import { storeSelectedCustomer } from "stateManagement/actions/Common/customerDetails";
import { Redirect } from "react-router-dom";
import { storePortal } from "stateManagement/actions/Common/storePortal";
import { storeSelectedVendor } from "stateManagement/actions/Common/vendorDetails";

interface Detail {
    companyName: string;
    companyCode: string;
    phone: string;
    mail: string;
    companyLogo: string;
}

interface DashboardDetails {
    customer: Detail[];
    searchactivelist: string;
    setSelectedCustomer: any;
    setPortal: any;
    portalCode: string;
    setSelectedVendor: any;
}

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        padding: "12px",
    },
    companyImg: {
        background: "rgba(0, 115, 230, 0.05)",
        padding: "3%",
    },
    company: {
        color: "#0073E6",
        textAlign: "left",
        overflow: "hidden",
    },
    code: {
        color: "#33415C",
        fontWeight: 600,
    },
    search: {
        textAlign: "left",
        fontWeight: 600,
        color: "#33415C",
        marginBottom: "5%",
    },
    buttonGroup: {
        paddingLeft: "6%",
        paddingBottom: "5%",
        paddingTop: "5%",
    },
    textpadding: {
        padding: "3% 3%",
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
}));

function DashBoardDetailsCardAZ(props: DashboardDetails) {
    const classes = useStyles();
    const {
        customer,
        searchactivelist,
        setSelectedCustomer,
        setPortal,
        portalCode,
        setSelectedVendor,
    } = props;
    const [redirect, setRedirect] = useState(false);
    const [redirectRoute, setRedirectRoute] = useState("");
    console.log("customer", customer);
    const handleEachCustomer = (element: any) => {
        if (portalCode === "CUS") {
            setSelectedCustomer(element);
            setPortal(portalCode);
            setRedirect(true);
            setRedirectRoute("Contacts");
        } else if (portalCode === "VEN") {
            setSelectedVendor(element);
            setPortal(portalCode);
            setRedirect(true);
            setRedirectRoute("Contact");
        }
    };

    if (redirect) {
        return (
            <Redirect
                to={{
                    pathname: redirectRoute,
                }}
            />
        );
    }

    return (
        <>
            {customer.map((element: Detail, index: number) => {
                return (
                    <>
                        {searchactivelist === "All" ? (
                            <Grid xs={6}>
                                <Paper
                                    style={{
                                        marginBottom: "2%",
                                        marginRight: "5%",
                                        cursor: "pointer",
                                    }}
                                    onClick={() => handleEachCustomer(element)}
                                >
                                    <Grid container>
                                        <Grid
                                            item
                                            xs={3}
                                            className={classes.companyImg}
                                        >
                                            {/* <img
                                                src={element?.companyLogo}
                                                style={{
                                                    maxWidth: "110px",
                                                    maxHeight: "42px",
                                                    minWidth: "50px",
                                                    minHeight: "22px",
                                                }}
                                                alt="Logo"
                                            /> */}
                                            <Icons Icon="buildingDummy"></Icons>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={9}
                                            className={classes.root}
                                        >
                                            <Grid container>
                                                <Grid
                                                    xs={12}
                                                    style={{
                                                        paddingBottom: "2%",
                                                    }}
                                                >
                                                    <Grid container>
                                                        <Grid
                                                            item
                                                            xs={8}
                                                            className={
                                                                classes.company
                                                            }
                                                        >
                                                            {
                                                                element.companyName
                                                            }
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xs={4}
                                                            className={
                                                                classes.code
                                                            }
                                                        >
                                                            {
                                                                element.companyCode
                                                            }
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                                <Grid item xs={5}>
                                                    <Grid container spacing={1}>
                                                        <Grid
                                                            item
                                                            xs={2}
                                                            spacing={1}
                                                        >
                                                            <Icons Icon="mobileDash" />
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xs={9}
                                                            style={{
                                                                color:
                                                                    "#5C677D",
                                                                overflow:
                                                                    "hidden",
                                                            }}
                                                        >
                                                            {element.phone}
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={7}>
                                                    <Grid container spacing={1}>
                                                        <Grid item xs={2}>
                                                            <Icons Icon="mailDash" />
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xs={9}
                                                            style={{
                                                                color:
                                                                    "#5C677D",
                                                                overflow:
                                                                    "hidden",
                                                            }}
                                                        >
                                                            {/* <Tooltip
                                                    // disableFocusListener
                                                    title={
                                                        element.mail
                                                            ? (element.mail as any)
                                                            : ""
                                                    }
                                                >
                                                    {element.mail &&
                                                        ((element.mail.slice(
                                                            0,
                                                            18,
                                                        ) + " ") as any)}
                                                </Tooltip> */}
                                                            {element.mail}
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                        ) : searchactivelist ===
                          element.companyName.charAt(0) ? (
                            <Grid xs={6}>
                                <Paper
                                    style={{
                                        marginBottom: "2%",
                                        cursor: "pointer",
                                        marginRight: "5%",
                                    }}
                                    onClick={() => handleEachCustomer(element)}
                                >
                                    <Grid container>
                                        <Grid
                                            item
                                            xs={3}
                                            className={classes.companyImg}
                                        >
                                            {/* <img
                                                src={element?.companyLogo}
                                                style={{
                                                    maxWidth: "110px",
                                                    maxHeight: "42px",
                                                    minWidth: "50px",
                                                    minHeight: "22px",
                                                }}
                                                alt="Logo"
                                            /> */}
                                            <Icons Icon="buildingDummy"></Icons>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={9}
                                            className={classes.root}
                                        >
                                            <Grid container>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    style={{
                                                        paddingBottom: "2%",
                                                    }}
                                                >
                                                    <Grid container>
                                                        <Grid
                                                            item
                                                            xs={8}
                                                            className={
                                                                classes.company
                                                            }
                                                        >
                                                            {
                                                                element.companyName
                                                            }
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xs={4}
                                                            className={
                                                                classes.code
                                                            }
                                                        >
                                                            {
                                                                element.companyCode
                                                            }
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                                <Grid item xs={5}>
                                                    <Grid container spacing={1}>
                                                        <Grid
                                                            item
                                                            xs={2}
                                                            spacing={1}
                                                        >
                                                            <Icons Icon="mobileDash" />
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xs={9}
                                                            style={{
                                                                color:
                                                                    "#5C677D",
                                                                overflow:
                                                                    "hidden",
                                                            }}
                                                        >
                                                            {element.phone}
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={7}>
                                                    <Grid container spacing={1}>
                                                        <Grid item xs={2}>
                                                            <Icons Icon="mailDash" />
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xs={9}
                                                            style={{
                                                                color:
                                                                    "#5C677D",
                                                                overflow:
                                                                    "hidden",
                                                            }}
                                                        >
                                                            {/* <Tooltip
                                                    // disableFocusListener
                                                    title={
                                                        element.mail
                                                            ? (element.mail as any)
                                                            : ""
                                                    }
                                                >
                                                    {element.mail &&
                                                        ((element.mail.slice(
                                                            0,
                                                            18,
                                                        ) + " ") as any)}
                                                </Tooltip> */}
                                                            {element.mail}
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                        ) : (
                            ""
                        )}
                    </>
                );
            })}
        </>
    );
}

const mapStateToProps = (state: any) => {
    return {
        currentContext: state.context?.userDefaults,
        userName: state.context?.name,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        setSelectedCustomer: (data: any) => {
            dispatch(storeSelectedCustomer(data));
        },
        setPortal: (data: any) => {
            dispatch(storePortal(data));
        },
        setSelectedVendor: (data: any) => {
            dispatch(storeSelectedVendor(data));
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(DashBoardDetailsCardAZ);
