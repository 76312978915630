const payableQuerySV = `
mutation(
        $entity:String
        $ou:Int
        $service:String
        $User:String
        $languageId:Int
        $VendorCode:String
        $Vendorname:String
        $merrorid:String
){
  PayableSV(
        entity:$entity
        ou:$ou
        service:$service
        User:$User
        languageId:$languageId
        VendorCode:$VendorCode
        Vendorname:$Vendorname
        merrorid:$merrorid
  ) {
    AccountNumber
    Building{
        name 
        id
    }
    BuildingID
    Subcontract
    VendorNetAmount
    Percent
    VendorAmount
    Description
    Adjustment
    SalesOrder
    Assignment
    StartEndDate
    CreatedOn
    }
  }
  `;

export default payableQuerySV;
