const InvoiceTabQuery = `
mutation(
    $entity: String
    $ou: Int
    $service: String
    $User: String
    $languageId: Int
    $invoiceno: String
    $customerName: String
    $customerCode: String
    $siteId: String
    $buildName: String
    $contractno:String
    $merrorid: String
) {
    GetInvoiceTab(
        entity: $entity
        ou: $ou
        service: $service
        User: $User
        languageId: $languageId
        invoiceno: $invoiceno
        customerName: $customerName
        customerCode: $customerCode
        siteId: $siteId
        buildName: $buildName
        contractno:$contractno
        merrorid: $merrorid 
    ) {
        InvoiceNo
        AccountName
        AccountNo,
        TotalAmount
        TotalAndTax {
            line1,
            line2,
        },
        BuildingDetails{
            line1,
            line2
        },
        Comments,
        MonthYear,
  }
}
  `;

export default InvoiceTabQuery;
