import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { List, ListItem, ListItemText, ListItemIcon } from "@material-ui/core";
import NavComponentCategories from "../subComponents/NavMenuSchema";
import { useHistory, useRouteMatch } from "react-router-dom";
import useGetPosts from "ReactQuery/reactQuery";
import { userRoleQuery } from "../../../ReactQuery/Query/index";
import { connect } from "react-redux";
import { storeBreadcrumbs } from "stateManagement/actions/Common/breadcrumbs";
import { storePortal } from "stateManagement/actions/Common/storePortal";

const useStyles = makeStyles({
    list: {
        width: 350,
    },
    fullList: {
        width: "auto",
    },
});

function TemporaryDrawer(props: any) {
    const { storeRole, setBreadcrumbsData, portal, setPortal } = props;
    const classes = useStyles();
    const [menuName, setMenuName] = useState("BPC");
    const [arr, setarr] = useState([] as any);
    const [open, setOpen] = useState(false);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const { data: userRoleData, error: userRoleError } = useGetPosts({
        name: `userRoleQuery_${storeRole.value}`,
        query: userRoleQuery,
        variables: { userScreenRoleName: storeRole.value },
    });

    useEffect(() => {
        console.log(portal);
    });

    useEffect(() => {
        // if (userRoleData?.userScreen) {
        //   const screenData = userRoleData?.userScreen;
        //   setRoleData(userRoleData?.userScreen);
        setarr(
            NavComponentCategories.filter((element: any) => {
                if (element.level === "BPC" || element.level === "BPCActivity")
                    return element;
            }),
        );
        // }
    }, []);

    useEffect(() => {
        // if (userRoleData?.userScreen) {
        //   const screenData = userRoleData?.userScreen;
        //   setRoleData(userRoleData?.userScreen);
        setarr(
            NavComponentCategories.filter((element: any) => {
                console.log(portal, "Hi portal");
                if (element?.type === portal || element?.type === "CMN")
                    return element;
            }),
        );
        // }
    }, [portal]);

    const mainMenuListArr = NavComponentCategories.filter(
        (element: any) => element.level === "BPC",
    );

    const backMenu = () => {
        const level =
            menuName === "Activity"
                ? "Component"
                : menuName === "Component"
                ? "BPC"
                : "BPC";
        const subMenuObj = NavComponentCategories.filter(
            (element: any) => element.level === level, //&& (roleData as any)[element.value]
        );
        setarr(subMenuObj);
    };

    useEffect(() => {
        if (arr.length > 0) setMenuName(arr[0].level);
    }, [arr]);

    const Lists = (path: string, url: string) => {
        const history = useHistory();
        const clickListener = (
            text: number,
            level: string,
            breadcrumb: any,
        ) => {
            console.log("BreadCrumb Data ==>", breadcrumb);
            if (breadcrumb !== undefined) {
                setBreadcrumbsData(breadcrumb);
                console.log("nav menu", breadcrumb);
            }
            // if (level !== "Component") {
            //     const subMenuObj = NavComponentCategories.filter(
            //         (element: any) => element.parent === text,
            //     );
            //     setarr(subMenuObj);
            // }
        };

        const listNav = (text: any) => {
            const Icon = text.icon;
            return (
                <ListItem
                    button
                    key={text.id}
                    onClick={() =>
                        clickListener(text.id, text.level, text.breadcrumb)
                    }
                >
                    <ListItemIcon>
                        <Icon />
                    </ListItemIcon>
                    <ListItemText primary={text.title} />
                    {/* {menuName !== "Activity" && <Icon>chevron_right</Icon>} */}
                </ListItem>
            );
        };

        return (
            <div className={clsx(classes.list)} role="presentation">
                <List>
                    {arr.map((text: any, index: number) => {
                        // if (text.level === "Component")
                        return (
                            <span
                                key={index}
                                style={{
                                    textDecoration: "none",
                                    color: "inherit",
                                }}
                                onClick={() => {
                                    console.log(
                                        "Path--------",
                                        url,
                                        path.replace("*/", ""),
                                    );
                                    text?.route === "Dashboard" &&
                                        setPortal("CMN");
                                    handleDrawerOpen();
                                    history.push(
                                        text?.subRoute
                                            ? `${path.replace("*/", "")}/${
                                                  text.route
                                              }/${text?.subRoute}`
                                            : `${path.replace("*/", "")}/${
                                                  text.route
                                              }`,
                                    );
                                }}
                            >
                                {listNav(text)}
                            </span>
                            // <Link
                            //     to={
                            //         text?.subRoute
                            //             ? `${url}/${text.subRoute}`
                            //             : `${text.route}`
                            //     }
                            //     key={index}
                            //     style={{
                            //         textDecoration: "none",
                            //         color: "inherit",
                            //     }}
                            // >
                            //     {listNav(text)}
                            // </Link>
                        );

                        return listNav(text);
                    })}
                </List>
            </div>
        );
    };
    const { path, url } = useRouteMatch();
    return (
        <div>
            <React.Fragment key={"right"}>
                {/* {menuName !== "BPC" && (
                    <ListItem button onClick={backMenu}>
                        <ListItemIcon>
                            <ChevronLeftIcon />
                        </ListItemIcon>
                        <ListItemText primary="Back" />
                    </ListItem>
                )} */}
                {Lists(path, url)}
            </React.Fragment>
        </div>
    );
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        setBreadcrumbsData: (data: any) => {
            dispatch(storeBreadcrumbs(data));
        },
        setPortal: (data: any) => {
            dispatch(storePortal(data));
        },
    };
};
const mapStateToProps = (state: any) => {
    return {
        storeRole: state.storeRole,
        portal: state.portalData,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TemporaryDrawer);
