const InvoicePaymentsQuery = `
mutation(
  $entity: String
    $ou: Int
    $service: String
    $User: String
    $languageId: Int
    $invoiceno:String
    $customerCode: String
    $customerName: String
    $siteId: String
    $buildName: String
    $contractNo: String
    $merrorid: String
){
    GetInvoicePayments(
      entity: $entity
        ou: $ou
        service: $service
        User: $User
        languageId: $languageId
        invoiceno:$invoiceno
        customerCode: $customerCode
        customerName: $customerName
        siteId: $siteId
        buildName: $buildName
        contractNo: $contractNo
        merrorid: $merrorid
  
    ) {
        Name,
        PaymentMethod,
        ChequeNo,
        PaymentAmount,
        PaymentDate,
  }
}
  `;

export default InvoicePaymentsQuery;
