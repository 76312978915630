const AssgnDetBuildDetailsQuerySV = `
mutation(
    $entity:String
    $ou:Int
    $service:String
    $User:String
    $languageId:Int
    $suppCode:String
    $suppName:String
    $pono:String
    $merrorid:String
){
    AssgnDetBuildDetailsQuerySV(
    entity:$entity
    ou:$ou
    service:$service
    User:$User
    languageId:$languageId
    suppCode:$suppCode
    suppName:$suppName
    pono:$pono
    merrorid:$merrorid
    ) {
        EndDate
        LastMonthProrate
        LastMonthNetProrate
        ReasonForEnd
    }
}`;

export default AssgnDetBuildDetailsQuerySV;
