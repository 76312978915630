const AssgnDetQuerySV = `
mutation(
   $entity:String
   $ou:Int
   $service:String
   $User:String
   $languageId:Int
   $VendorCode:String
   $Vendorname:String
   $ponum:String
   $merrorid:String
){
    AssgnDetQuerySV(
        entity:$entity
        ou:$ou
        service:$service
        User:$User
        languageId:$languageId
        VendorCode:$VendorCode
        Vendorname:$Vendorname
        ponum:$ponum
        merrorid:$merrorid  
    ) {
        Assignment
        Customer
        Address{
            Area
            City
            State
            Pincode
        }
        Status
        Amount
        Vendor
        StartDate
        FirstMonthProrate
        AssignmentType
        VendorNet
    }
}`;

export default AssgnDetQuerySV;
