const ContractsQuerySV = `
mutation(
  $entity: String
  $ou: Int
  $service: String
  $User: String
  $languageId: Int
  $supplierCode:String
  $suppliername:String
  $status:String
  $fromDate: String
  $toDate: String
  $contractNo: String
  $merrorid:String 
){
    GetContractsSV( 
        entity:$entity
        ou:$ou
        service:$service
        User:$User
        languageId:$languageId
        supplierCode:$supplierCode
        suppliername:$suppliername
        status:$status
        fromDate: $fromDate
        toDate: $toDate
        contractNo: $contractNo
        merrorid:$merrorid 
    ) 
    {
        Contract
        AccountNumber
        Vendor
        Region
        ContractR
        PurchaseDate
        StartEndDate
        PackagePurchased
        VolumePurchased
        TotalCost
        TargetFulfillment
        VolumeOwned
    }
}
  `;

export default ContractsQuerySV;
