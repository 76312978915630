import React, { useState, useEffect, useCallback } from "react";
import ReactDOM from "react-dom";
import MUIDataTable from "mui-datatables";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import AddIcon from "@material-ui/icons/Add";
import { Tooltip, IconButton, Grid } from "@material-ui/core";
import { createTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/core/styles";
import ContentCopyIcon from "@material-ui/icons/FileCopy";
import DeleteIcon from "@material-ui/icons/Delete";
import zIndex from "@material-ui/core/styles/zIndex";

const theme = createTheme({
    palette: {
        primary: {
            main: "#0073e6",
        },
        secondary: {
            main: "#0073e6",
        },
    },
    overrides: {
        MuiTableCell: {
            head: {
                backgroundColor: "#023E7D !important",
                color: "white !important",
            },
            root: {
                padding: "4px 2px 4px 2px !important",
            },
        },
        MUIDataTableToolbar: {
            root: {
                margin: "-0.5% 0",
            },
        },
        MUIDataTableToolbarSelect: {
            root: {
                margin: "-0.5% 0",
                // justifyContent: "flex-end !important",
            },
        },
        MuiTablePagination: {
            toolbar: {
                maxHeight: "35px !important",
            },
        },
        MuiOutlinedInput: {
            input: {
                padding: "18.5px 12px !important",
            },
        },
        // column headers to be centered
        MUIDataTableHeadCell: {
            root: {
                textAlign: "center !important",
            },
        },
    },
});

const Tables = (props) => {
    const {
        header,
        detail,
        title,
        handleAddSideEffect,
        handleDeleteSideEffect,
        initRow,
        checkboxNotVisible,
        hideAddButton,
        handleSelectedSideEffect,
        height,
        rowsPerPage,
    } = props;
    const [rowData, setRowData] = detail;
    const [selected, setSelected] = useState([]);

    useEffect(() => {
        console.log("heightComparison", height ? height : "250px");
    }, [height]);

    const newData = () => {
        handleAddSideEffect();
        const data = header.reduce((accumulator, current) => {
            const name = current.name;
            const value =
                current.type === "string"
                    ? ""
                    : current.type === "number"
                    ? ""
                    : current.type === "date"
                    ? new Date()
                    : "";

            accumulator[name] = value;
            return accumulator;
        }, {});
        console.log(data);
        setRowData((prevState) => [...prevState, data]);
        console.log(rowData);
    };

    const removeData = ({ data }) => {
        const consolidated = data
            .map((element) => element?.index)
            .sort(function (a, b) {
                return b - a;
            });
        handleDeleteSideEffect(consolidated);
        setRowData((prevState) => {
            const newData = [...prevState];

            consolidated.map((element) => {
                newData.splice(element, 1);
                return true;
            });

            return newData;
        });
        setSelected([]);
        const index = data.map((element) => {
            return element?.index;
        });
        return false;
    };

    // const deleteFunct = (indexselected, setSelectedRows) => {
    //     console.log(indexselected);
    //     console.log(rowData.splice(selected, 1));

    //     rowData.map((data, index) => {
    //         if (index == indexselected) {
    //             setRowData(rowData.splice(selected, 1));
    //         }
    //     });

    //     console.log(rowData, selected);
    // };
    const saveData = () => {
        let newdata = JSON.parse(JSON.stringify(rowData));
        let duplicate = [];
        selected.map((element, index) => {
            newdata?.map((el, ind) => {
                if (ind == element) {
                    console.log(el, ind, index);

                    duplicate.push(el);
                }
            });
        });
        setRowData([...rowData, ...duplicate]);
    };

    const AddButton = () => (
        <Tooltip disableFocusListener title="Add New Row">
            <IconButton onClick={newData}>
                <AddIcon />
            </IconButton>
        </Tooltip>
    );
    const options = {
        filter: true,
        filterType: "dropdown",
        resizableColumns: false,
        responsive: "standard",
        tableBodyHeight: height ? height : "250px",
        // tableBodyHeight: "300px",
        tableBodyMaxHeight: "",
        rowsPerPage: rowsPerPage ? rowsPerPage : 5,
        elevation: 0,
        rowsSelected: selected,
        rowsPerPageOptions: [5, 8, 12],
        // selectableRowsHideCheckboxes: checkboxNotVisible
        //     ? checkboxNotVisible
        //     : false,o
        selectableRowsHideCheckboxes: true,
        customToolbar: hideAddButton ? () => {} : () => {},
        // customToolbar: hideAddButton ? () => {} : AddButton,
        customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
            <>
                <Grid
                    container
                    spacing={0}
                    justifyContent="flex-end"
                    alignItems="center"
                >
                    <Grid item xs={9} style={{ textAlign: "left" }}>
                        {" "}
                    </Grid>
                    <Grid item xs={2} style={{ textAlign: "right" }}>
                        <Tooltip disableFocusListener title="Copy and Append">
                            <IconButton
                                onClick={() => {
                                    saveData();
                                    setSelectedRows([]);
                                }}
                            >
                                <ContentCopyIcon />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                    <Grid item xs={1} style={{ textAlign: "right" }}>
                        <Tooltip disableFocusListener title="Delete Row">
                            <IconButton
                                onClick={() => {
                                    removeData(selectedRows);
                                    setSelectedRows([]);
                                }}
                            >
                                <DeleteIcon />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </Grid>
            </>
        ),
        onRowsDelete: removeData,
        onRowSelectionChange: (
            currentRowsSelected,
            allRowsSelected,
            rowsSelected,
        ) => {
            console.log(allRowsSelected);
            setSelected(rowsSelected);
            if (handleSelectedSideEffect)
                handleSelectedSideEffect(rowsSelected);
        },
    };
    // const saveData = (setSelectedRows, selecteddata) => {
    //     console.log(rowData, selected, selecteddata);

    //     rowData.map((el, ind) => {
    //         if (ind == selected) {
    //             console.log(el);
    //             setRowData([...rowData, el]);
    //             console.log(rowData, "rowdata");
    //         }
    //     });
    // };
    useEffect(() => {
        if (initRow && rowData.length === 0) {
            newData();
        }
        console.log("Row Data ==>", rowData);
    }, [rowData]);

    return (
        <ThemeProvider theme={theme}>
            <div className="spcl">
                <MUIDataTable
                    title={title ? title : ""}
                    data={rowData}
                    columns={header}
                    options={options}
                    style={{
                        minWidth: "100%",
                        maxWidth: "100%",
                        border: "1px ",
                    }}
                />
            </div>
        </ThemeProvider>
    );
};
export default Tables;
