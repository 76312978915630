import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import axios from "axios";
import Loading from "../Common/Loading";
import { storeContextInfo } from "../stateManagement/actions/HomePage/storeContext";
import { storeSessionId } from "../stateManagement/actions/LoginPage/storeSessionId";
import { postLoginTenantDetails } from "../stateManagement/actions/PostLogin/postLogin";
import { Redirect } from "react-router-dom";
import { useLazyQuery, gql } from "@apollo/client";
import { storeDefaultContext } from "stateManagement/actions/SwitchContext/switchContext";
import Configuration from "Configuration";
import { storeTermsConditionsInfo } from "../stateManagement/actions/HomePage/storeTermsConditions";
import { contextApiQuery } from "ReactQuery/Query";
import useGetPosts from "ReactQuery/reactQuery";
// import storePrecision from "stateManagement/reducers/storePrecision";
// import { storePrecisionInfo } from "stateManagement/actions/HomePage/storePrecision";
import {
    Backdrop,
    CircularProgress,
    makeStyles,
    Theme,
} from "@material-ui/core";
// import TermsConditions from "../pages/VendorPortalPage/components/TermsConditions";

const useStyles = makeStyles((theme: Theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
}));

const PostLogin = (props: any) => {
    const {
        sId,
        session,
        storeContextInfo,
        storeSessionId,
        setDefaultContext,
        defaultContext,
        setTenantDetails,
        termsConditions,
        setTermsConditions,
        // storePrecisionInfo,
    } = props;
    const classes = useStyles();
    const [contextState, setContextState] = useState(false);
    const [terms, setTerms] = useState(termsConditions);
    const handleTermsConditions = () => {
        setTerms(!terms);
        setTermsConditions(!terms);
    };
    const [contextData, setContextData] = useState({
        contexts: { userDefaults: {} },
    });
    const {
        data: contData,
        error: contfError,
        isLoading: loading,
        isFetching: fetching,
    } = useGetPosts({
        name: `getcontextApiQuery_${contextState ? "one" : "two"}`,
        query: contextApiQuery,
        variables: {},
        retry: true,
    });

    if (sId) storeSessionId(sId);

    useEffect(() => {
        (async () => {
            try {
                const response = await axios(
                    `${Configuration.getConfiguration().getAppAPILocation()}/api/auth/checksession?sId=${sId}`,
                );
                if (response.status === 200) {
                    console.log("Session is valid", sId);
                    setContextState((prevState) => !prevState);
                    try {
                        const brandDetails = await axios(
                            `${Configuration.getConfiguration().getAppAPILocation()}/api/server/properties`,
                        );
                        if (brandDetails.status === 200) {
                            setTenantDetails(brandDetails.data);
                        }
                    } catch (err) {}
                    if (contData) {
                        console.log("context data", contData);
                        // Set to state
                        storeContextInfo(contData?.contexts);
                        // storePrecisionInfo(contData?.contexts?.precision);
                        setDefaultContext(contData?.contexts?.userDefaults);
                    } else {
                        // throw ""; // FIXME - Have error page in react and take it there
                    }
                }
            } catch (error) {
                console.log("Context Error in postlogin", error);
                storeSessionId("");
                <Redirect to="/" />;
            }
        })();
    }, [contData]);

    if (loading && fetching) {
        return (
            <Backdrop className={classes.backdrop} open={loading && fetching}>
                <CircularProgress color="inherit" />
            </Backdrop>
        );
    }

    return (
        <div>
            {/* {
        !terms ? <TermsConditions terms={terms} handleTerms={handleTermsConditions} /> :  */}
            (defaultContext.ouId && <Redirect to="/app/*/Dashboard" />)
        </div>
    );
};

//Redux
const mapStateToProps = (state: any) => {
    return {
        session: state.sessionId,
        defaultContext: state.defaultContext,
        termsConditions: state.termsConditions,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        storeSessionId: (context: never) => {
            dispatch(storeSessionId(context));
        },
        storeContextInfo: (context: never) => {
            dispatch(storeContextInfo(context));
        },
        // storePrecisionInfo: (precision: never) => {
        //     dispatch(storePrecisionInfo(precision));
        // },
        setDefaultContext: (data: never) => {
            dispatch(storeDefaultContext(data));
        },
        setTenantDetails: (data: never) => {
            dispatch(postLoginTenantDetails(data));
        },
        setTermsConditions: (data: any) => {
            dispatch(storeTermsConditionsInfo(data));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PostLogin);
