const visitsQuerySV = `
query Query {
  visitsSV {
    StatusReason
    AccountNumber
    CompanyName
    Building{
        name
        id
    }
    DateofVisit
    Vendor
    Note
    }
  }
  `;

export default visitsQuerySV;
