const AssignmentListQuerySV = `
mutation(
    $entity:String
    $ou:Int
    $service:String
    $User:String
    $languageId:Int
    $supplierCode:String
    $suppliername:String
    $bpono:String
    $merrorid:String
){
    GetAssignmentListSV(
        entity:$entity
        ou:$ou
        service:$service
        User:$User
        languageId:$languageId
        supplierCode:$supplierCode
        suppliername:$suppliername
        bpono:$bpono
        merrorid:$merrorid
    ) {
        Customer,
        ContractNumber,
        Revisions,
        StartEndDate,
        PrincipalAmount,
        Replaceable,
        VendorNetAmount
        Percent
    }
}
  `;

export default AssignmentListQuerySV;
