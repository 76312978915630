import { Grid } from "@material-ui/core";
import "Common/Main.scss";

export const ContactSchema = [
    {
        name: "AccountNumber",
        Label: "AccountNumber",
        options: {
            filter: true,
            sort: false,
            customHeadLabelRender: () => (
                <span style={{ color: "white" }}>Account Number</span>
            ),
            setCellHeaderProps: () => {
                return { style: { borderRight: "1px solid #D5D5D5" } };
            },
            setCellProps: () => {
                return {
                    style: { borderRight: "1px solid #D5D5D5", width: "10%" },
                };
            },
            customBodyRender: (
                value: any,
                tableMeta: any,
                updateValue: any,
            ) => {
                console.log("table data", value);
                return <b className="blue">{value}</b>;
            },
        },
    },
    {
        name: "CompanyName",
        Label: "CompanyName",
        options: {
            filter: true,
            sort: false,
            customHeadLabelRender: () => (
                <span style={{ color: "white" }}>Company Name</span>
            ),
            setCellHeaderProps: () => {
                return { style: { borderRight: "1px solid #D5D5D5" } };
            },
            setCellProps: () => {
                return {
                    style: { borderRight: "1px solid #D5D5D5", width: "10%" },
                };
            },
            customBodyRender: (
                value: any,
                tableMeta: any,
                updateValue: any,
            ) => {
                console.log("table data", value);
                return <b className="blue">{value}</b>;
            },
        },
    },
    {
        name: "Detail",
        Label: "Detail",
        options: {
            filter: true,
            sort: false,
            setCellHeaderProps: () => {
                return { style: { borderRight: "1px solid #D5D5D5" } };
            },
            setCellProps: () => {
                return {
                    style: {
                        borderRight: "1px solid #D5D5D5",
                        width: "11%",
                    },
                };
            },
            customHeadLabelRender: () => (
                <span style={{ color: "white" }}>First Name-Job Title</span>
            ),
            customBodyRender: (
                value: {
                    firstname: string;
                    jobtitle: string;
                },
                tableMeta: any,
                updateValue: any,
            ) => {
                console.log("table data", value);
                return (
                    <Grid container>
                        <Grid item xs={12}>
                            <b className="blue">{value.firstname}</b>
                        </Grid>
                        <Grid item xs={12}>
                            <b className="grey2">{value.jobtitle}</b>
                        </Grid>
                    </Grid>
                );
            },
        },
    },
    {
        name: "Address",
        Label: "Address",
        options: {
            filter: true,
            sort: false,
            setCellHeaderProps: () => {
                return { style: { borderRight: "1px solid #D5D5D5" } };
            },
            setCellProps: () => {
                return {
                    style: {
                        borderRight: "1px solid #D5D5D5",
                        width: "11%",
                    },
                };
            },
            customHeadLabelRender: () => (
                <span style={{ color: "white" }}>Address</span>
            ),
            customBodyRender: (
                value: {
                    hno: string;
                    street: string;
                },
                tableMeta: any,
                updateValue: any,
            ) => {
                console.log("table data", value);
                return (
                    <Grid container>
                        <Grid item xs={12}>
                            <b className="blue">{value.hno}</b>
                        </Grid>
                        <Grid item xs={12}>
                            <b className="grey2">{value.street}</b>
                        </Grid>
                    </Grid>
                );
            },
        },
    },
    {
        name: "Information",
        Label: "Information",
        options: {
            filter: true,
            sort: false,
            setCellHeaderProps: () => {
                return { style: { borderRight: "1px solid #D5D5D5" } };
            },
            setCellProps: () => {
                return {
                    style: {
                        borderRight: "1px solid #D5D5D5",
                        width: "11%",
                    },
                };
            },
            customHeadLabelRender: () => (
                <span style={{ color: "white" }}>Contact Details</span>
            ),
            customBodyRender: (
                value: {
                    number: string;
                    email: string;
                },
                tableMeta: any,
                updateValue: any,
            ) => {
                console.log("table data", value);
                return (
                    <Grid container>
                        <Grid item xs={12}>
                            <b className="blue">{value.number}</b>
                        </Grid>
                        <Grid item xs={12}>
                            <b className="grey2">{value.email}</b>
                        </Grid>
                    </Grid>
                );
            },
        },
    },
];
