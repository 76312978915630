const invoiceCardHeaderQuery = `
mutation(
    $entity: String
    $ou: Int
    $service: String
    $User: String
    $languageId: Int
    $invoiceno:String
    $customerCode: String
    $customerName: String
    $siteId: String
    $buildName: String
    $contractNo: String
    $merrorid: String
){
    invoiceContact(
        entity: $entity
        ou: $ou
        service: $service
        User: $User
        languageId: $languageId
        invoiceno:$invoiceno
        customerCode: $customerCode
        customerName: $customerName
        siteId: $siteId
        buildName: $buildName
        contractNo: $contractNo
        merrorid: $merrorid
  
    ) {
        VendorID
        ContractID
     Building
    
 
    Amount 
        DocDate{
            date
            PastDueDays
        }
    ExtendedAmount
    StatusReason
    
    }
  }
  `;

export default invoiceCardHeaderQuery;