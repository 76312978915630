const AssgnDetCustomerQuerySV = `
mutation(
   $entity:String
   $ou:Int
   $service:String
   $User:String
   $languageId:Int
   $VendorCode:String
   $Vendorname:String
   $ASSgnno:String
   $merrorid:String
){
    AssgnDetCustomerQuerySV(
        entity:$entity
        ou:$ou
        service:$service
        User: $User
        languageId:$languageId
        VendorCode:$VendorCode
        Vendorname:$Vendorname
        ASSgnno:$ASSgnno
        merrorid:$merrorid
    ) {
        CustomerContract
        Customer
        CustomerBuilding
        Contract
        Revision
        ContractStartEnd
        ServiceCode
    }
}`;

export default AssgnDetCustomerQuerySV;
