import currencyFormatter from "Common/Utils/currencyFormatter";

const AssignmentSchema = [
    {
        name: "Customer",
        Label: "Customer",
        options: {
            filter: true,
            sort: false,
            customHeadLabelRender: () => (
                <span style={{ color: "white" }}>Customer</span>
            ),
            setCellHeaderProps: () => {
                return { style: { borderRight: "1px solid #D5D5D5" } };
            },
            setCellProps: () => {
                return {
                    style: {
                        borderRight: "1px solid #D5D5D5",
                        textAlign: "center",
                    },
                };
            },
            customBodyRender: (
                value: any,
                tableMeta: any,
                updateValue: any,
            ) => {
                console.log("table data", value, tableMeta.rowData);
                return <b style={{ color: "#0073E6" }}>{value}</b>;
            },
        },
    },
    {
        name: "ContractNumber",
        Label: "ContractNumber",
        type: "number",
        options: {
            filter: true,
            sort: false,
            customHeadLabelRender: () => (
                <span style={{ color: "white" }}>Contract Number</span>
            ),
            setCellHeaderProps: () => {
                return { style: { borderRight: "1px solid #D5D5D5" } };
            },
            setCellProps: () => {
                return {
                    style: {
                        borderRight: "1px solid #D5D5D5",
                        textAlign: "center",
                    },
                };
            },
            customBodyRender: (
                value: any,
                tableMeta: any,
                updateValue: any,
            ) => {
                console.log("table data", value);
                return <b style={{ color: "#5C677D" }}>{value}</b>;
            },
        },
    },
    {
        name: "Revisions",
        Label: "Revisions",
        type: "number",
        options: {
            filter: true,
            sort: false,
            customHeadLabelRender: () => (
                <span style={{ color: "white" }}>Revisions</span>
            ),
            setCellHeaderProps: () => {
                return { style: { borderRight: "1px solid #D5D5D5" } };
            },
            setCellProps: () => {
                return {
                    style: {
                        borderRight: "1px solid #D5D5D5",
                        textAlign: "center",
                    },
                };
            },
            customBodyRender: (
                value: any,
                tableMeta: any,
                updateValue: any,
            ) => {
                console.log("table data", value);
                return <b style={{ color: "#5C677D" }}>{value}</b>;
            },
        },
    },
    {
        name: "StartEndDate",
        Label: "StartEndDate",
        options: {
            filter: true,
            sort: false,
            customHeadLabelRender: () => (
                <span style={{ color: "white" }}>Start-End Date</span>
            ),
            setCellHeaderProps: () => {
                return { style: { borderRight: "1px solid #D5D5D5" } };
            },
            setCellProps: () => {
                return {
                    style: {
                        borderRight: "1px solid #D5D5D5",
                        textAlign: "center",
                    },
                };
            },
            customBodyRender: (
                value: any,
                tableMeta: any,
                updateValue: any,
            ) => {
                console.log("table data", value);
                return <b style={{ color: "#5C677D" }}>{value}</b>;
            },
        },
    },
    {
        name: "PrincipalAmount",
        Label: "PrincipalAmount",
        type: "string",
        options: {
            filter: true,
            sort: false,
            customHeadLabelRender: () => (
                <span style={{ color: "white" }}>Principal Amount</span>
            ),
            setCellHeaderProps: () => {
                return { style: { borderRight: "1px solid #D5D5D5" } };
            },
            setCellProps: () => {
                return {
                    style: {
                        borderRight: "1px solid #D5D5D5",
                        textAlign: "center",
                    },
                };
            },
            customBodyRender: (
                value: any,
                tableMeta: any,
                updateValue: any,
            ) => {
                console.log("table data", value);
                return (
                    <b
                        style={{
                            color: "#00AE50",
                            display: "flex",
                            justifyContent: "flex-end",
                        }}
                    >
                        {currencyFormatter(value, "USD")}
                    </b>
                );
            },
        },
    },

    {
        name: "Replaceable",
        Label: "Replaceable",
        options: {
            filter: true,
            sort: false,
            customHeadLabelRender: () => (
                <span style={{ color: "white" }}>Replaceable</span>
            ),
            setCellHeaderProps: () => {
                return { style: { borderRight: "1px solid #D5D5D5" } };
            },
            setCellProps: () => {
                return {
                    style: {
                        borderRight: "1px solid #D5D5D5",
                        textAlign: "center",
                    },
                };
            },
            customBodyRender: (
                value: any,
                tableMeta: any,
                updateValue: any,
            ) => {
                console.log("table data", value);
                return <b style={{ color: "#5C677D" }}>{value}</b>;
            },
        },
    },
    {
        name: "VendorNetAmount",
        Label: "VendorNetAmount",
        options: {
            filter: true,
            sort: false,
            customHeadLabelRender: () => (
                <span style={{ color: "white" }}>Vendor Net Amount</span>
            ),
            setCellHeaderProps: () => {
                return { style: { borderRight: "1px solid #D5D5D5" } };
            },
            setCellProps: () => {
                return {
                    style: {
                        borderRight: "1px solid #D5D5D5",
                        textAlign: "center",
                    },
                };
            },
            customBodyRender: (
                value: any,
                tableMeta: any,
                updateValue: any,
            ) => {
                console.log("table data", value);
                return (
                    <b
                        className="grey2 font-bolder"
                        style={{
                            display: "flex",
                            justifyContent: "flex-end",
                        }}
                    >
                        {currencyFormatter((value as any) && value, "USD")}
                    </b>
                );
            },
        },
    },
    {
        name: "Percent",
        Label: "Percent",
        options: {
            filter: true,
            sort: false,
            customHeadLabelRender: () => (
                <span style={{ color: "white" }}>Percentage</span>
            ),
            setCellHeaderProps: () => {
                return { style: { borderRight: "1px solid #D5D5D5" } };
            },
            setCellProps: () => {
                return {
                    style: {
                        borderRight: "1px solid #D5D5D5",
                        textAlign: "center",
                    },
                };
            },
            customBodyRender: (
                value: any,
                tableMeta: any,
                updateValue: any,
            ) => {
                console.log("table data", value);
                return (
                    <b
                        className="grey"
                        style={{
                            display: "flex",
                            justifyContent: "flex-end",
                        }}
                    >
                        {`${(value as any) && value}%`}
                    </b>
                );
            },
        },
    },
];

export default AssignmentSchema;
