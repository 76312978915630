const VendorDetailsListQuery = `
mutation(
    $entity: String
    $ou: Int
    $service: String
    $User: String
    $languageId: Int
    $contractno: String
    $conttype: String
    $customerCode: String
    $siteId: String
    $buildName: String
    $pono: String
    $merrorid: String
) {
    GetVendorDetailsList(
        entity: $entity
        ou: $ou
        service: $service
        User: $User
        languageId: $languageId
        contractno: $contractno
        conttype: $conttype
        customerCode: $customerCode
        siteId: $siteId
        buildName: $buildName
        pono: $pono
        merrorid: $merrorid
    ) {
        Vendor,
        ContractNumber,
        AccountNumber,
        Customer,
        BuildingDetails
        BuildingID
        City
        Contracts,
        StartEndDate,
        ServiceCode,
        VendorAmount
        VendorPercent
        Replace,
        firstMonthProrate,
        LastMonthProrate,
  }
}
  `;

export default VendorDetailsListQuery;
