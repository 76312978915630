import { Grid, Paper, Backdrop, CircularProgress } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import "Common/Main.scss";
import useGetPosts from "ReactQuery/reactQuery";
import {
    AssgnDetBuildDetailsQuerySV,
    AssgnDetCustomerQuerySV,
    AssgnDetQuerySV,
} from "ReactQuery/Query";
import Alert from "Common/Alerts/Alerts";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import currencyFormatter from "Common/Utils/currencyFormatter";
import Icons from "Common/Icons/Icons";

interface assgnDet {
    Assignment: String;
    Customer: String;
    Address: {
        Area: String;
        City: String;
        State: String;
        Pincode: Number;
    };
    Status: String;
    Amount: String;
    Vendor: String;
    StartDate: String;
    FirstMonthProrate: String;
    AssignmentType: String;
    VendorNet: String;
}

interface assgnDetBuildDetails {
    EndDate: String;
    LastMonthProrate: String;
    LastMonthNetProrate: String;
    ReasonForEnd: String;
}

interface assgnDetCustomer {
    CustomerContract: String;
    Customer: String;
    CustomerBuilding: String;
    Contract: Number;
    Revision: Number;
    ContractStartEnd: String;
    ServiceCode: String;
}

const useStyles = makeStyles((theme: Theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
}));

const Details = (props: any) => {
    const classes = useStyles();
    const { currentContext, userName, selectedCustomer, currency } = props;
    const [assgnDet, setAssgnDet] = useState<assgnDet[]>([]);
    const [assgnDetBuildDetails, setAssgnDetBuildDetails] = useState<
        assgnDetBuildDetails[]
    >([]);
    const [assgnDetCustomer, setAssgnDetCustomer] = useState<
        assgnDetCustomer[]
    >([]);

    const assignmentData = useLocation<any>();
    const assignmentNo: any = assignmentData?.state?.assignmentSelected;

    const [popoverBlack, setPopoverBlack] = useState(false);
    const [alert, setAlert] = useState(false);
    const [errr, seterr] = useState({
        code: 0,
        msg: "",
    });

    const handleErrorAlert = (code: number, message: string) => {
        Alert({
            statusCode: code,
            message: message,
            willClose: () => {
                setAlert(false);
                seterr({ code: 0, msg: "" });
            },
        });
    };

    const {
        data: AssgnDetData,
        error: AssgnDetError,
        isLoading: AssgnDetLoading,
    } = useGetPosts({
        name: `AssgnDetQuerySV_${assignmentNo}`,
        query: AssgnDetQuerySV,
        variables: {
            entity: "OW_Vendor_portal_vendorassignment_header",
            ou: currentContext?.ouId,
            service: "",
            User: userName,
            languageId: currentContext?.langId,
            VendorCode: selectedCustomer?.companyCode,
            Vendorname: selectedCustomer?.companyName,
            ponum: assignmentNo,
            merrorid: "",
        },
    });
    const {
        data: AssgnDetBuildDetailsData,
        error: AssgnDetBuildDetailsError,
        isLoading: AssgnDetBuildDetailsLoading,
    } = useGetPosts({
        name: `AssgnDetBuildDetailsQuerySV_${assignmentNo}`,
        query: AssgnDetBuildDetailsQuerySV,
        variables: {
            entity: "OW_Vendor_portal_Assignbuiddtls",
            ou: currentContext?.ouId,
            service: "",
            User: userName,
            languageId: currentContext?.langId,
            suppCode: selectedCustomer?.companyCode,
            suppName: selectedCustomer?.companyName,
            pono: assignmentNo,
            merrorid: "",
        },
    });
    const {
        data: AssgnDetCustomerData,
        error: AssgnDetCustomerError,
        isLoading: AssgnDetCustomerLoading,
    } = useGetPosts({
        name: `AssgnDetCustomerQuerySV_${assignmentNo}`,
        query: AssgnDetCustomerQuerySV,
        variables: {
            entity: "OW_Vendor_Assignment_Customer",
            ou: currentContext?.ouId,
            service: "",
            User: userName,
            languageId: currentContext?.langId,
            VendorCode: selectedCustomer?.companyCode,
            Vendorname: selectedCustomer?.companyName,
            ASSgnno: assignmentNo,
            merrorid: "",
        },
    });
    useEffect(() => {
        if (AssgnDetData?.AssgnDetQuerySV) {
            setAssgnDet(AssgnDetData?.AssgnDetQuerySV);
        }
    }, [AssgnDetData]);
    useEffect(() => {
        if (AssgnDetError && !AssgnDetLoading) {
            const data = JSON.parse(JSON.stringify(AssgnDetError));
            const errmsg = data?.response?.errors[0].message;
            seterr({
                code: 400,
                msg: errmsg,
            });
            setAlert(true);
        }
    }, [AssgnDetError, AssgnDetLoading]);

    useEffect(() => {
        if (AssgnDetBuildDetailsData?.AssgnDetBuildDetailsQuerySV) {
            setAssgnDetBuildDetails(
                AssgnDetBuildDetailsData?.AssgnDetBuildDetailsQuerySV,
            );
        }
    }, [AssgnDetBuildDetailsData]);

    useEffect(() => {
        if (AssgnDetBuildDetailsError && !AssgnDetBuildDetailsLoading) {
            const data = JSON.parse(JSON.stringify(AssgnDetBuildDetailsError));
            const errmsg = data?.response?.errors[0].message;
            seterr({
                code: 400,
                msg: errmsg,
            });
            setAlert(true);
        }
    }, [AssgnDetBuildDetailsError, AssgnDetBuildDetailsLoading]);

    useEffect(() => {
        if (AssgnDetCustomerData?.AssgnDetCustomerQuerySV) {
            setAssgnDetCustomer(AssgnDetCustomerData?.AssgnDetCustomerQuerySV);
        }
    }, [AssgnDetCustomerData]);

    useEffect(() => {
        if (AssgnDetCustomerError && !AssgnDetCustomerLoading) {
            const data = JSON.parse(JSON.stringify(AssgnDetCustomerError));
            const errmsg = data?.response?.errors[0].message;
            seterr({
                code: 400,
                msg: errmsg,
            });
            setAlert(true);
        }
    }, [AssgnDetCustomerError, AssgnDetCustomerLoading]);

    return (
        <>
            {alert ? handleErrorAlert(errr.code, errr.msg) : <></>}
            <Backdrop
                className={classes.backdrop}
                open={
                    AssgnDetCustomerLoading ||
                    AssgnDetLoading ||
                    AssgnDetBuildDetailsLoading
                }
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Backdrop
                className={classes.backdrop}
                open={popoverBlack}
            ></Backdrop>

            <Grid container style={{ padding: "0 0 2%" }}>
                <Grid item xs={9}>
                    <Paper elevation={1}>
                        {assgnDet.map((element: any, index: any) => (
                            <Grid
                                container
                                direction="row"
                                spacing={2}
                                style={{ padding: "1% 2%" }}
                            >
                                <Grid
                                    item
                                    xs={6}
                                    style={{
                                        margin: "0%",
                                        padding: "1%",
                                        borderRight: "1px dashed #C9CDD6",
                                    }}
                                >
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <Grid
                                                container
                                                justifyContent="space-between"
                                            >
                                                <Grid
                                                    item
                                                    className="grey2 font-boldest"
                                                    xs={4}
                                                >
                                                    <Grid container spacing={1}>
                                                        <Grid item xs={2}>
                                                            <Icons Icon="contractDetail" />
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            className="text-left"
                                                            xs={9}
                                                        >
                                                            {
                                                                element?.Assignment
                                                            }
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid
                                                    item
                                                    className="grey2 font-boldest"
                                                    xs={8}
                                                >
                                                    <Grid container>
                                                        <Grid item xs={1}>
                                                            <Icons Icon="buildingDetail" />
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xs={9}
                                                            className="text-left"
                                                            style={{
                                                                paddingLeft:
                                                                    "2%",
                                                            }}
                                                        >
                                                            {" "}
                                                            {element?.Customer}
                                                        </Grid>
                                                    </Grid>

                                                    {/* {contactName?.contractSelected[2]} */}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={9}>
                                            <Grid container>
                                                <Grid
                                                    item
                                                    xs={1}
                                                    className="text-left"
                                                >
                                                    <Icons Icon="locationDetail"></Icons>
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={10}
                                                    className="grey text-left"
                                                >
                                                    {element.Address.Area} |
                                                    {element.Address.City} |
                                                    {element.Address.State}|
                                                    {element.Address.Pincode}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid
                                                container
                                                justifyContent="space-between"
                                            >
                                                {element?.Status ? (
                                                    <Grid
                                                        item
                                                        xs={2}
                                                        style={{
                                                            display: "flex",
                                                            justifyContent:
                                                                "center",
                                                            border: `${
                                                                element?.Status.toLowerCase() ===
                                                                "active"
                                                                    ? "1px solid #00DA64"
                                                                    : "1px solid #DD9200"
                                                            }`,
                                                            borderRadius: "3px",
                                                            background: `${
                                                                element?.Status.toLowerCase() ===
                                                                "active"
                                                                    ? "#EDFDF2"
                                                                    : "#FCF5E9"
                                                            }`,
                                                            alignItems:
                                                                "center",
                                                        }}
                                                    >
                                                        {element.Status}
                                                    </Grid>
                                                ) : (
                                                    <></>
                                                )}
                                                <Grid
                                                    item
                                                    xs={3}
                                                    className="green font-biggest font-bolder"
                                                >
                                                    {currencyFormatter(
                                                        element.Amount,
                                                        currency,
                                                    )}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                {/* <Grid item xs></Grid> */}
                                <Grid
                                    item
                                    xs={6}
                                    style={{ padding: "8px 8px 8px 16px" }}
                                >
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <Grid container>
                                                <Grid item xs={12} sm={5}>
                                                    <Grid container>
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            className="text-left grey"
                                                        >
                                                            Vendor
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            className="grey2 font-boldest text-left"
                                                        >
                                                            {element.Vendor}
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12} sm={3}>
                                                    <Grid container>
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            className="text-left grey"
                                                        >
                                                            Start Date
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            className="text-left grey2 font-boldest"
                                                        >
                                                            {element.StartDate}
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12} sm={4}>
                                                    <Grid container>
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            className="text-left grey"
                                                        >
                                                            First Month's Net
                                                            Prorate
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            className="text-left grey2 font-boldest"
                                                        >
                                                            {
                                                                currencyFormatter(element.FirstMonthProrate, currency)
                                                            }
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container>
                                                <Grid item xs={12} sm={5}>
                                                    <Grid container>
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            className="text-left grey"
                                                        >
                                                            Assignment Type
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            className="text-left grey2 font-boldest"
                                                        >
                                                            {
                                                                element.AssignmentType
                                                            }
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12} sm={4}>
                                                    <Grid container>
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            className="text-left grey"
                                                        >
                                                            Vendor Net
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            className="text-left grey2 font-boldest"
                                                        >
                                                            {currencyFormatter(
                                                                element.VendorNet.toFixed(
                                                                    2,
                                                                ),
                                                                currency,
                                                            )}
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        ))}
                    </Paper>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12}></Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid item xs={4}>
                    <Grid container>
                        <Grid
                            item
                            xs={12}
                            className="text-left grey2 font-boldest"
                            style={{ padding: "3% 0 6% 0" }}
                        >
                            Customer
                        </Grid>
                        <Grid item xs={12}>
                            <Paper elevation={1}>
                                {assgnDetCustomer.map(
                                    (
                                        element: {
                                            CustomerContract: String;
                                            Customer: String;
                                            CustomerBuilding: String;
                                            Contract: Number;
                                            Revision: Number;
                                            ContractStartEnd: String;
                                            ServiceCode: String;
                                        },
                                        index: any,
                                    ) => (
                                        <Grid
                                            container
                                            spacing={2}
                                            style={{ padding: "3% 6%" }}
                                        >
                                            <Grid item xs={12}>
                                                <Grid
                                                    container
                                                    justifyContent="space-between"
                                                >
                                                    <Grid item className="grey">
                                                        Customer Contract
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        className="grey2 font-bolder"
                                                    >
                                                        {
                                                            element.CustomerContract
                                                        }
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid
                                                    container
                                                    justifyContent="space-between"
                                                >
                                                    <Grid item className="grey">
                                                        Customer
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        className="grey2 font-bolder"
                                                    >
                                                        {element.Customer}
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid
                                                    container
                                                    justifyContent="space-between"
                                                >
                                                    <Grid item className="grey">
                                                        Customer Building
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        className="grey2 font-bolder"
                                                    >
                                                        {
                                                            element.CustomerBuilding
                                                        }
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid
                                                    container
                                                    justifyContent="space-between"
                                                >
                                                    <Grid item className="grey">
                                                        Contract
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        className="grey2 font-bolder"
                                                    >
                                                        {element.Contract}
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid
                                                    container
                                                    justifyContent="space-between"
                                                >
                                                    <Grid item className="grey">
                                                        Revision
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        className="grey2 font-bolder"
                                                    >
                                                        {element.Revision}
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid
                                                    container
                                                    justifyContent="space-between"
                                                >
                                                    <Grid item className="grey">
                                                        Contract Start-End
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        className="grey2 font-bolder"
                                                    >
                                                        {
                                                            element.ContractStartEnd
                                                        }
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid
                                                    container
                                                    justifyContent="space-between"
                                                >
                                                    <Grid item className="grey">
                                                        Service Code
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        className="grey2 font-bolder"
                                                    >
                                                        {element.ServiceCode}
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    ),
                                )}
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={4}>
                    <Grid container>
                        <Grid
                            item
                            xs={12}
                            className="text-left grey2 font-boldest"
                            style={{ padding: "3% 0 6% 0" }}
                        >
                            Building Details
                        </Grid>

                        <Grid item xs={12}>
                            <Paper elevation={1}>
                                {assgnDetBuildDetails.map(
                                    (element: any, index: any) => (
                                        <Grid
                                            container
                                            spacing={2}
                                            style={{ padding: "3% 6%" }}
                                        >
                                            <Grid item xs={12}>
                                                <Grid
                                                    container
                                                    justifyContent="space-between"
                                                >
                                                    <Grid
                                                        item
                                                        xs={5}
                                                        className="grey text-left"
                                                    >
                                                        End Date
                                                    </Grid>
                                                    <Grid item xs></Grid>
                                                    <Grid
                                                        item
                                                        xs={5}
                                                        className="text-left grey2 font-boldest"
                                                    >
                                                        {element.EndDate}
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid
                                                    container
                                                    justifyContent="space-between"
                                                >
                                                    <Grid
                                                        item
                                                        xs={5}
                                                        className="grey text-left"
                                                    >
                                                        Last Month's Prorate
                                                    </Grid>
                                                    <Grid item xs></Grid>
                                                    <Grid
                                                        item
                                                        xs={5}
                                                        className="text-left grey2 font-boldest"
                                                    >
                                                        {element.LastMonthProrate !==
                                                        null
                                                            ? currencyFormatter(
                                                                  element.LastMonthProrate.toFixed(
                                                                      2,
                                                                  ),
                                                                  currency,
                                                              )
                                                            : ""}
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid
                                                    container
                                                    justifyContent="space-between"
                                                >
                                                    <Grid
                                                        item
                                                        xs={5}
                                                        className="grey text-left"
                                                    >
                                                        Last Month's Net Prorate
                                                    </Grid>
                                                    <Grid item xs></Grid>
                                                    <Grid
                                                        item
                                                        xs={5}
                                                        className="text-left grey2 font-boldest"
                                                    >
                                                        {element.LastMonthNetProrate !==
                                                        null
                                                            ? currencyFormatter(
                                                                  element.LastMonthNetProrate.toFixed(
                                                                      2,
                                                                  ),
                                                                  currency,
                                                              )
                                                            : ""}
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid
                                                    container
                                                    justifyContent="space-between"
                                                >
                                                    <Grid
                                                        item
                                                        xs={5}
                                                        className="grey text-left"
                                                    >
                                                        Reason for End
                                                    </Grid>
                                                    <Grid item xs></Grid>
                                                    <Grid
                                                        item
                                                        xs={5}
                                                        className="text-left grey2 font-boldest"
                                                    >
                                                        {element.ReasonForEnd}
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    ),
                                )}
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

const mapStateToProps = (state: any) => {
    return {
        currentContext: state.context?.userDefaults,
        userName: state.context?.name,
        selectedCustomer: state.selectedCustomer,
        currency: state.currency,
    };
};

export default connect(mapStateToProps)(Details);
