import { Backdrop, CircularProgress, Grid, Paper } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import MuiDatatables from "Common/Mui-DataTables/MuiDatatables";
import TextFields from "Common/TextField/TextField";
import Dropdown from "Common/Dropdown/Dropdown";
import CustomDatePickerBorder from "Common/DatePicker/DatePicker";
import MUIButton from "Common/Button/Button";
import { Redirect } from "react-router-dom";
import useGetPosts from "ReactQuery/reactQuery";
import {
    AssignmentHeaderQuerySV,
    AssignmentQuerySV,
    StatusComboQuery,
} from "ReactQuery/Query";
import Alert from "Common/Alerts/Alerts";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { connect } from "react-redux";
import currencyFormatter from "Common/Utils/currencyFormatter";
import dateFormatter from "Common/Utils/dateFormatter";

interface DashCards {
    desc: string;
    value: string;
    visible: boolean;
    frequent: boolean;
    detail: {
        desc: string;
        value: number;
        color: string;
    }[];
}

const useStyles = makeStyles((theme: Theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
}));

interface BodyProps {
    currentContext: any;
    userName: any;
    selectedVendor: any;
    currency: any;
}

const Body = (props: BodyProps) => {
    const [data, setData] = useState([]);
    const classes = useStyles();
    const { currentContext, userName, selectedVendor, currency } = props;
    const [header, setHeader] = useState<DashCards>();
    const [active, setActive] = useState("");
    const [assgnNoToggle, setAssgnNoToggle] = useState(false);
    const [vendContToggle, setVendContToggle] = useState(false);
    // const [buildingToggle, setBuildingToggle] = useState(false);
    const [assignmentSelected, setassignmentSelected] = useState();
    const [contractSelected, setcontractSelected] = useState();
    const [showTable, setShowTable] = useState<boolean>(false);
    const handleActive = (index: string) => {
        setActive(index);
        setShowTable(false);
        console.log("index", index);
    };
    const [statusList, setStatusList] = useState<any>([]);
    const [contractData, setContractData] = useState({
        fromDate: new Date(),
        toDate: new Date(),
        contractno: "",
        status: "",
    });

    const [popoverBlack, setPopoverBlack] = useState(false);
    const [alert, setAlert] = useState(false);
    const [errr, seterr] = useState({
        code: 0,
        msg: "",
    });

    const handleErrorAlert = (code: number, message: string) => {
        Alert({
            statusCode: code,
            message: message,
            willClose: () => {
                setAlert(false);
                seterr({ code: 0, msg: "" });
            },
        });
    };

    const {
        data: StatusComboData,
        error: StatusComboDataError,
        isLoading: StatusComboDataLoading,
    } = useGetPosts({
        name: `StatusComboQuery_${selectedVendor?.companyCode}_PO`,
        query: StatusComboQuery,
        variables: {
            entity: "ow_getcombo_status",
            ou: currentContext?.ouId,
            service: "",
            user: userName,
            langId: currentContext?.langId,
            screen: "PO",
            tranType: "",
            merrorid: "",
        },
    });

    useEffect(() => {
        if (StatusComboData?.statusCombo) {
            console.log("StatusData", StatusComboData?.statusCombo);
            setStatusList(StatusComboData?.statusCombo);
            setContractData((prevState: any) => {
                return {
                    ...prevState,
                    status: StatusComboData?.statusCombo[0]?.value,
                };
            });
        }
    }, [StatusComboData]);

    useEffect(() => {
        if (StatusComboDataError && !StatusComboDataLoading) {
            const data = JSON.parse(JSON.stringify(StatusComboDataError));
            const errmsg = data?.response?.errors[0].message;
            seterr({
                code: 400,
                msg: errmsg,
            });
            setAlert(true);
        }
    }, [StatusComboDataError, StatusComboDataLoading]);

    const {
        data: AssignmentData,
        error: AssignmentError,
        isLoading: AssignmentLoading,
    } = useGetPosts({
        name: `AssignmentQuerySV_${contractData?.status}_${contractData?.contractno}_${contractData?.fromDate}_${contractData?.toDate}_${active}_${selectedVendor?.companyCode}`,
        query: AssignmentQuerySV,
        variables: {
            entity: "OW_Vendor_portal_Assignlisting",
            ou: currentContext?.ouId,
            service: "",
            User: userName,
            languageId: currentContext?.langId,
            suppCode: selectedVendor?.companyCode,
            suppName: selectedVendor?.companyName,
            pono: contractData?.contractno,
            status:
                active === header?.detail[0]?.desc
                    ? "AC"
                    : contractData?.status,
            fromdate: dateFormatter("sqlDate", contractData?.fromDate),
            todate: dateFormatter("sqlDate", contractData?.toDate),
            merrorid: "",
        },
        skip: active === header?.detail[0]?.desc ? false : !showTable,
    });

    useEffect(() => {
        if (AssignmentData?.AssignmentQuerySV) {
            setData(AssignmentData?.AssignmentQuerySV);
        }
    }, [AssignmentData]);

    useEffect(() => {
        if (AssignmentError && !AssignmentLoading) {
            const data = JSON.parse(JSON.stringify(AssignmentError));
            const errmsg = data?.response?.errors[0].message;
            seterr({
                code: 400,
                msg: errmsg,
            });
            setAlert(true);
        }
    }, [AssignmentError, AssignmentLoading]);

    const {
        data: AssignmentHeaderData,
        error: AssignmentHeaderError,
        isLoading: AssignmentHeaderLoading,
    } = useGetPosts({
        name: `AssignmentHeaderQuery_${selectedVendor?.companyCode}`,
        query: AssignmentHeaderQuerySV,
        variables: {
            entity: "OW_Vendor_portal_Assignliststatus",
            ou: currentContext?.ouId,
            service: "",
            User: userName,
            languageId: currentContext?.langId,
            suppCode: selectedVendor?.companyCode,
            suppName: selectedVendor?.companyName,
            merrorid: "",
        },
    });

    useEffect(() => {
        console.log(AssignmentHeaderData?.AssignmentHeaderQuerySV);
        if (AssignmentHeaderData?.AssignmentHeaderQuerySV) {
            setHeader(AssignmentHeaderData?.AssignmentHeaderQuerySV);
            setActive(
                AssignmentHeaderData?.AssignmentHeaderQuerySV.detail[0]?.desc,
            );
        }
    }, [AssignmentHeaderData]);

    useEffect(() => {
        if (AssignmentHeaderError && !AssignmentHeaderLoading) {
            const data = JSON.parse(JSON.stringify(AssignmentHeaderError));
            const errmsg = data?.response?.errors[0].message;
            seterr({
                code: 400,
                msg: errmsg,
            });
            setAlert(true);
        }
    }, [AssignmentHeaderError, AssignmentHeaderLoading]);

    if (assgnNoToggle) {
        return (
            <Redirect
                to={{
                    pathname: "/app/AssgnDetailSV",
                    state: {
                        assignmentSelected: assignmentSelected,
                    },
                }}
            />
        );
    }
    if (vendContToggle) {
        return (
            <Redirect
                to={{
                    pathname: "/app/CONTDETSV",
                    state: {
                        contractSelected: contractSelected,
                    },
                }}
            />
        );
    }
    // if (buildingToggle) {
    //     return (
    //         <Redirect
    //             to={{
    //                 pathname: "/app/details",
    //             }}
    //         />
    //     );
    // }
    const handleChange = (e: any) => {
        setShowTable(false);
        setContractData((prevState) => ({
            ...prevState,
            contractno: e?.target?.value,
        }));
    };

    const handleChangeStatus = (e: any) => {
        setShowTable(false);
        setContractData((prevState) => ({
            ...prevState,
            status: e?.target?.value,
        }));
    };

    const handleDate = (type: string, e: any) => {
        setShowTable(false);
        if (type === "toDate") {
            setContractData((prevstate: any) => ({
                ...prevstate,
                toDate: e,
            }));
        } else if (type === "fromDate") {
            setContractData((prevstate: any) => ({
                ...prevstate,
                fromDate: e,
            }));
        }
    };

    const handleAssignmentNo = (val: any) => {
        setAssgnNoToggle((prevState) => !prevState);
        setassignmentSelected(val);
    };
    const handleVendCont = (val: any) => {
        setVendContToggle((prevState) => !prevState);
        setcontractSelected(val);
    };
    // const handleBuilding = () => {
    //     setBuildingToggle((prevState) => !prevState);
    // };

    const handleSearch = () => {
        setShowTable(true);
    };

    const AssignmentSchema = [
        {
            name: "Assignment",
            Label: "Assignment",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Assignment</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return (
                        <b
                            className="blue"
                            style={{ cursor: "pointer" }}
                            onClick={() => handleAssignmentNo(value)}
                        >
                            {value}
                        </b>
                    );
                },
            },
        },
        {
            name: "ContractID",
            Label: "ContractID",
            type: "string",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Contract ID</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return <b className="grey2">{value}</b>;
                },
            },
        },
        {
            name: "VendorContract",
            Label: "VendorContract",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Vendor Contract</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return (
                        <b
                            className="blue"
                            // style={{ cursor: "pointer" }}
                            // onClick={() => handleVendCont(value)}
                        >
                            {value}
                        </b>
                    );
                },
            },
        },
        {
            name: "Building",
            Label: "Building",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Building Name</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return <b className="blue">{value}</b>;
                },
            },
        },
        {
            name: "BuildingID",
            Label: "BuildingID",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Building ID</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return <b className="grey2">{value}</b>;
                },
            },
        },
        {
            name: "Customer",
            Label: "Customer",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Customer</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return <b className="grey2">{value}</b>;
                },
            },
        },
        {
            name: "Contract",
            Label: "Contract",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Contract</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return <b className="grey2">{value}</b>;
                },
            },
        },
        {
            name: "StartEndDate",
            Label: "StartEndDate",
            options: {
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Start - End Date</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return <b className="grey2">{value}</b>;
                },
            },
        },
        {
            name: "VendorAmt",
            Label: "VendorAmt",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Vendor Amount</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return (
                        <b
                            className="green"
                            style={{
                                display: "flex",
                                justifyContent: "flex-end",
                            }}
                        >
                            {currencyFormatter(value, currency)}
                        </b>
                    );
                },
            },
        },
        {
            name: "ServiceCode",
            Label: "ServiceCode",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Service Code</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return <b className="grey2">{value}</b>;
                },
            },
        },
        {
            name: "VendorNetAmount",
            Label: "VendorNetAmount",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Vendor Net Amount</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return (
                        <b
                            className="grey2 font-bolder"
                            style={{
                                display: "flex",
                                justifyContent: "flex-end",
                            }}
                        >
                            {currencyFormatter(
                                (value as any) && value,
                                currency,
                            )}
                        </b>
                    );
                },
            },
        },
        {
            name: "Percent",
            Label: "Percent",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Percentage</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return (
                        <b
                            className="grey2"
                            style={{
                                display: "flex",
                                justifyContent: "flex-end",
                            }}
                        >
                            {`${(value as any) && value}%`}
                        </b>
                    );
                },
            },
        },
        {
            name: "Replace",
            Label: "Replace",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Replace</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return <b className="grey2">{value}</b>;
                },
            },
        },
        {
            name: "FirstMonth",
            Label: "FirstMonth",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>First Month</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return (
                        <b
                            className="green"
                            style={{
                                display: "flex",
                                justifyContent: "flex-end",
                            }}
                        >
                            {currencyFormatter(value, currency)}
                        </b>
                    );
                },
            },
        },
        {
            name: "LastMonth",
            Label: "LastMonth",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Last Month</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return (
                        <b
                            className="green"
                            style={{
                                display: "flex",
                                justifyContent: "flex-end",
                            }}
                        >
                            {currencyFormatter(value, currency)}
                        </b>
                    );
                },
            },
        },
    ];

    return (
        <>
            {alert ? handleErrorAlert(errr.code, errr.msg) : <></>}
            <Backdrop
                className={classes.backdrop}
                open={AssignmentHeaderLoading || AssignmentLoading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Backdrop
                className={classes.backdrop}
                open={popoverBlack}
            ></Backdrop>

            <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} sm={12} style={{ paddingTop: "2%" }}>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={12} sm={4} md={3}>
                            <Paper elevation={2}>
                                <Grid
                                    container
                                    spacing={2}
                                    style={{
                                        padding: "5% 0% 0% 0%",
                                    }}
                                    justifyContent="space-around"
                                >
                                    {header?.detail.map((element, index) => {
                                        return (
                                            <Grid
                                                item
                                                xs={4}
                                                sm={4}
                                                className={
                                                    active === element?.desc
                                                        ? `${element?.color}_border_bottom pointer`
                                                        : "pointer"
                                                }
                                                onClick={() =>
                                                    handleActive(element?.desc)
                                                }
                                            >
                                                <Grid container spacing={1}>
                                                    <Grid item xs={12} sm={12}>
                                                        <span
                                                            // className={`${element?.color} font-biggest font-boldest`}
                                                            className={
                                                                active ===
                                                                element?.desc
                                                                    ? `${element?.color} font-biggest font-boldest`
                                                                    : `grey font-biggest font-boldest`
                                                            }
                                                        >
                                                            {element?.value}
                                                        </span>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12}>
                                                        <span
                                                            className={
                                                                active ===
                                                                element?.desc
                                                                    ? `font-boldest font-smaller`
                                                                    : "grey font-smallest"
                                                            }
                                                        >
                                                            {element?.desc}
                                                        </span>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        );
                                    })}
                                </Grid>
                            </Paper>
                        </Grid>
                        {active === header?.detail[1]?.desc && (
                            <Grid item sm={12} md={8}>
                                <Paper elevation={2}>
                                    <Grid
                                        container
                                        spacing={2}
                                        style={{ padding: "2%" }}
                                    >
                                        <Grid item xs sm={10} style={{}}>
                                            <Grid
                                                container
                                                spacing={2}
                                                alignItems="center"
                                            >
                                                <Grid
                                                    item
                                                    sm
                                                    xs={12}
                                                    style={{}}
                                                >
                                                    {/* <Grid item xs sm={5}> */}
                                                    <TextFields
                                                        label={"Assignment No."}
                                                        id={"assignmentNo"}
                                                        value={
                                                            contractData?.contractno
                                                        }
                                                        handleChange={
                                                            handleChange
                                                        }
                                                        width={"100%"}
                                                        multiline={false}
                                                        rows={0}
                                                    />
                                                </Grid>
                                                <Grid
                                                    item
                                                    sm
                                                    xs={12}
                                                    style={{}}
                                                >
                                                    <Dropdown
                                                        label={"Status"}
                                                        id={"Status"}
                                                        value={
                                                            contractData?.status
                                                        }
                                                        list={statusList}
                                                        handleChange={
                                                            handleChangeStatus
                                                        }
                                                        width={"100%"}
                                                    />
                                                </Grid>
                                                <Grid item sm xs={2} style={{}}>
                                                    <CustomDatePickerBorder
                                                        label="From Date"
                                                        width="100%"
                                                        value={
                                                            contractData?.fromDate
                                                        }
                                                        onChange={(e) =>
                                                            handleDate(
                                                                "fromDate",
                                                                e,
                                                            )
                                                        }
                                                        format="MM/dd/yyyy"
                                                    />
                                                </Grid>
                                                <Grid item sm xs={2} style={{}}>
                                                    <CustomDatePickerBorder
                                                        label="To Date"
                                                        width="100%"
                                                        value={
                                                            contractData?.toDate
                                                        }
                                                        onChange={(e) =>
                                                            handleDate(
                                                                "toDate",
                                                                e,
                                                            )
                                                        }
                                                        format="MM/dd/yyyy"
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid item sm={2} xs={12}>
                                            <MUIButton
                                                label="Search"
                                                type="filled"
                                                font="font-small"
                                                onClick={handleSearch}
                                            />
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
                {active === header?.detail[0]?.desc && (
                    <Grid
                        item
                        xs={12}
                        sm={12}
                        style={{ marginRight: "2%", paddingTop: "2%" }}
                    >
                        <MuiDatatables
                            title={""}
                            header={AssignmentSchema}
                            detail={[data, setData]}
                            // handleAddSideEffect={handleAddSideEffect}
                            // handleDeleteSideEffect={handleDeleteSideEffect}
                            initRow={false}
                        />
                    </Grid>
                )}
                {showTable && active === header?.detail[1]?.desc && (
                    <Grid
                        item
                        xs={12}
                        sm={12}
                        style={{ marginRight: "2%", paddingTop: "2%" }}
                    >
                        <MuiDatatables
                            title={""}
                            header={AssignmentSchema}
                            detail={[data, setData]}
                            // handleAddSideEffect={handleAddSideEffect}
                            // handleDeleteSideEffect={handleDeleteSideEffect}
                            initRow={false}
                        />
                    </Grid>
                )}
            </Grid>
        </>
    );
};

const mapStateToProps = (state: any) => {
    return {
        currentContext: state.context?.userDefaults,
        userName: state.context?.name,
        selectedVendor: state.selectedVendor,
        currency: state.currency,
    };
};

export default connect(mapStateToProps)(Body);
