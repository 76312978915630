const BuildDetServiceQuery = `
mutation(
    $entity: String
    $ou: Int
    $service: String
    $User: String
    $languageId: Int
    $customerCode: String
    $customerName: String
    $siteId: String
    $buildName: String
    $contractNo: String
    $merrorid: String
) {
    BuildDetServiceQuery(
        entity: $entity
        ou: $ou
        service: $service
        User: $User
        languageId: $languageId
        customerCode: $customerCode
        customerName: $customerName
        siteId: $siteId
        buildName: $buildName
        contractNo: $contractNo
        merrorid: $merrorid
    ) {
        ContractID
        Monday
        Tuesday
        Wednesday
        Thursday
        Friday
        Saturday
        Sunday 
    }
}`;

export default BuildDetServiceQuery;
