const generalQuerySV = `
query Query {
  GeneralSV {
    AccountNumber
    CompanyName
     Detail {
        name
        jobtitle
    },
    Address{
        area
        street
    }
    }
  }
  `;

export default generalQuerySV;
