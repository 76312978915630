const InvoicesQuery = `
mutation(
  $entity: String
  $ou: Int
  $service: String
  $User: String
  $languageId: Int
  $Status:String
  $customerCode: String
  $customerName: String
  $siteId: String
  $buildName: String
  $contractNo: String
  $invoiceNo: String
  $fromDate: String
  $toDate: String
  $merrorid: String
){
    InvoicesList(
        entity: $entity
        ou: $ou
        service: $service
        User: $User
        languageId: $languageId
        Status:$Status
        customerCode: $customerCode
        customerName: $customerName
        siteId: $siteId
        buildName: $buildName
        contractNo: $contractNo
        invoiceNo: $invoiceNo
        fromDate: $fromDate
        toDate: $toDate
        merrorid: $merrorid
    ) 
    {
        InvoiceNo,
        InvCatPayterm{
            line1,
            line2
        },
        CustomerLevel,
        TotalAmount,
        PaymentStatus,
        DocDate,
        PastDueDate,
    }
}
  `;

export default InvoicesQuery;
