import React from "react";
import { Dialog, DialogTitle } from "@material-ui/core";

export default function FormDialog(props: any) {
    const { open, title, Children } = props;
    const [openDialog, setOpenDialog] = open;

    const handleClose = () => {
        setOpenDialog(false);
    };

    return (
        <div>
            <Dialog
                open={openDialog}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
                fullWidth={true}
                maxWidth="md"
            >
                <DialogTitle id="form-dialog-title">{title}</DialogTitle>
                <Children handleClose={handleClose} />
            </Dialog>
        </div>
    );
}
