import "Common/Main.scss";
// import ButtonGroups from "Common/ButtonGroup/ButtonGroup";
import Body from "./Contact/Body"


const Contact= () => {
    return <Body/>
	
}

export default Contact;