import { Backdrop, CircularProgress, Grid, Paper } from "@material-ui/core";
import { useEffect, useState } from "react";
import "Common/Main.scss";
import ButtonGroups from "Common/ButtonGroup/ButtonGroup";
import {
    contactQuery,
    fulfillmentQuery,
    primaryContactQuery,
} from "ReactQuery/Query";
import useGetPosts from "ReactQuery/reactQuery";
import Alert from "Common/Alerts/Alerts";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { connect } from "react-redux";
import Icons from "Common/Icons/Icons";
import currencyFormatter from "Common/Utils/currencyFormatter";

interface contactdata {
    Billinginfo: {
        companyname: string;
        area: string;
        address: {
            line1: string;
            line2: string;
        };
    };
    ContactPerson: {
        name: string;
        email: string;
        phonenumber: string;
    };
    DateIncorporated: {
        date: string;
        rating: string;
        ratingumber: string;
        SupplierGroup: string;
    };
}

interface fullfillmentdata {
    Volumeinfo: {
        purchased: number;
        assigned: number;
        lastUpdated: string;
    };
    Loaninfo: {
        openAssigned: number;
        currentbalance: number;
        owed: number;
    };
    Deduct: {
        workerscomp: string;
        insurance: string;
    };
}
interface primaryinfodata {
    Account: string;
    Contact: string;
    cycle: string;
    name: string;
    email: string;
}

const useStyles = makeStyles((theme: Theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
}));
interface BodyProps {
    currentContext: any;
    userName: any;
    selectedVendor: any;
    currency: any;
}

const Body = (props: BodyProps) => {
    const { currentContext, userName, selectedVendor, currency } = props;
    const classes = useStyles();
    const controlList = [
        {
            desc: "Fulfillment",
            value: "Fulfillment",
        },
        {
            desc: "Primary Contact",
            value: "Primary Contact",
        },
    ];

    const [activeList, setActiveList] = useState(controlList[0].desc);

    const handleDocList = (listType: string) => {
        console.log("listType", listType);
        setActiveList(listType);
    };
    const [popoverBlack, setPopoverBlack] = useState(false);
    const [alert, setAlert] = useState(false);
    const [errr, seterr] = useState({
        code: 0,
        msg: "",
    });

    const handleErrorAlert = (code: number, message: string) => {
        Alert({
            statusCode: code,
            message: message,
            willClose: () => {
                setAlert(false);
                seterr({ code: 0, msg: "" });
            },
        });
    };

    const [contactInfo, setcontactInfo] = useState<contactdata[]>([]);
    const [fullfilldata, setfullfilldata] = useState<fullfillmentdata[]>([]);
    const [primarydata, setprimaryData] = useState<primaryinfodata[]>([]);

    const {
        data: contactsData,
        error: contactsDataError,
        isLoading: contactsDataLoading,
    } = useGetPosts({
        name: `contacts_${selectedVendor?.companyCode}`,
        query: contactQuery,
        variables: {
            entity: "OW_Vendor_portal_contact_hdr",
            ou: currentContext?.ouId,
            service: "",
            User: userName,
            languageId: currentContext?.langId,
            VendorCode: selectedVendor?.companyCode,
            Vendorname: selectedVendor?.companyName,
            merrorid: "",
        },
    });

    useEffect(() => {
        if (contactsData?.billingdata) {
            setcontactInfo(contactsData?.billingdata);
        }
    }, [contactsData]);

    useEffect(() => {
        if (contactsDataError && !contactsDataLoading) {
            const data = JSON.parse(JSON.stringify(contactsDataError));
            const errmsg = data?.response?.errors[0].message;
            seterr({
                code: 400,
                msg: errmsg,
            });
            setAlert(true);
        }
    }, [contactsDataError, contactsDataLoading]);

    const {
        data: FulfillmentData,
        error: FulfillmentDataError,
        isLoading: FulfillmentDataLoading,
    } = useGetPosts({
        name: ` FulfillmentQuery_${selectedVendor?.companyCode}`,
        query: fulfillmentQuery,
        variables: {
            entity: "OW_Vendor_portal_contr_fulfilmnt",
            ou: currentContext?.ouId,
            service: "",
            User: userName,
            languageId: currentContext?.langId,
            supplierCode: selectedVendor?.companyCode,
            suppliername: selectedVendor?.companyName,
            merrorid: "",
        },
    });

    useEffect(() => {
        if (FulfillmentData?.Fulfillment) {
            setfullfilldata(FulfillmentData?.Fulfillment);
        }
    }, [FulfillmentData]);

    useEffect(() => {
        if (FulfillmentDataError && !FulfillmentDataLoading) {
            const data = JSON.parse(JSON.stringify(FulfillmentDataError));
            const errmsg = data?.response?.errors[0].message;
            seterr({
                code: 400,
                msg: errmsg,
            });
            setAlert(true);
        }
    }, [FulfillmentDataError, FulfillmentDataLoading]);

    const {
        data: primarycontactData,
        error: primarycontactDataError,
        isLoading: primarycontactDataLoading,
    } = useGetPosts({
        name: `PrimarycontactQuery_${selectedVendor?.companyCode}`,
        query: primaryContactQuery,
        variables: {
            entity: "OW_Vendor_primarycontact",
            ou: currentContext?.ouId,
            service: "",
            User: userName,
            languageId: currentContext?.langId,
            supplierCode: selectedVendor?.companyCode,
            suppliername: selectedVendor?.companyName,
            merrorid: "",
        },
    });

    useEffect(() => {
        if (primarycontactData?.primarycontact) {
            setprimaryData(primarycontactData?.primarycontact);
        }
    }, [primarycontactData]);

    useEffect(() => {
        if (primarycontactDataError && !primarycontactDataLoading) {
            const data = JSON.parse(JSON.stringify(primarycontactDataError));
            const errmsg = data?.response?.errors[0].message;
            seterr({
                code: 400,
                msg: errmsg,
            });
            setAlert(true);
        }
    }, [primarycontactDataError, primarycontactDataLoading]);

    return (
        <>
            {alert ? handleErrorAlert(errr.code, errr.msg) : <></>}
            <Backdrop
                className={classes.backdrop}
                open={
                    contactsDataLoading ||
                    primarycontactDataLoading ||
                    FulfillmentDataLoading
                }
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Backdrop
                className={classes.backdrop}
                open={popoverBlack}
            ></Backdrop>

            <Grid
                container
                spacing={2}
                style={{ padding: "0 0 2%", marginTop: "2%" }}
            >
                <Grid item xs={9}>
                    <Paper elevation={1}>
                        <Grid
                            container
                            spacing={2}
                            className="padding-1-percent"
                        >
                            <Grid
                                item
                                xs={3}
                                style={{ borderRight: "1px dashed lightgray" }}
                            >
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Grid container spacing={1}>
                                            <Grid
                                                item
                                                xs={12}
                                                className="text-left grey font-boldest"
                                            >
                                                Logo
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}
                                                className="text-center grey2 font-bolder"
                                            >
                                                <Paper
                                                    variant="outlined"
                                                    style={{
                                                        maxWidth: "75px",
                                                        maxHeight: "75px",
                                                        minWidth: "75px",
                                                        minHeight: "75px",
                                                        borderRadius: "100px",
                                                        display: "inline-block",
                                                    }}
                                                >
                                                    <Icons
                                                        Icon="buildingDummy"
                                                        style={{
                                                            display:
                                                                "inline-block",
                                                            marginTop: "15px",
                                                            maxWidth: "45px",
                                                            maxHeight: "45px",
                                                            minHeight: "45px",
                                                            minWidth: "45px",
                                                        }}
                                                    ></Icons>
                                                </Paper>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>

                            {/* <Grid
                                xs={1}
                                container
                                style={{
                                    margin: "0%",
                                    padding: "2% 0% 0% 0%",
                                    borderRight: "1px dashed #C9CDD6",
                                }}
                            ></Grid> */}
                            <Grid item xs={3}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Grid container spacing={1}>
                                            <Grid
                                                item
                                                xs={12}
                                                className="text-left grey font-boldest"
                                            >
                                                Billing Info
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}
                                                className="text-left grey2 font-bolder"
                                            >
                                                {!contactsDataLoading
                                                    ? contactInfo[0]
                                                          ?.Billinginfo
                                                          ?.companyname
                                                    : ""}
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}
                                                className="text-left grey2 font-bolder"
                                            >
                                                {!contactsDataLoading
                                                    ? contactInfo[0]
                                                          ?.Billinginfo?.area
                                                    : ""}
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}
                                                className="text-left grey2 font-bolder"
                                            >
                                                {!contactsDataLoading
                                                    ? contactInfo[0]
                                                          ?.Billinginfo?.address
                                                          ?.line1
                                                    : ""}
                                                {!contactsDataLoading
                                                    ? contactInfo[0]
                                                          ?.Billinginfo?.address
                                                          ?.line2
                                                    : ""}
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}
                                                className="text-left grey2 font-bolder"
                                            >
                                                {selectedVendor?.companyCode}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={3}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Grid container spacing={1}>
                                            <Grid
                                                item
                                                xs={12}
                                                className="text-left grey font-boldest"
                                            >
                                                Contact Person
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}
                                                className="text-left grey2 font-bolder"
                                            >
                                                {!contactsDataLoading
                                                    ? contactInfo[0]
                                                          ?.ContactPerson?.name
                                                    : ""}
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}
                                                className="text-left grey2 font-bolder"
                                            >
                                                {!contactsDataLoading
                                                    ? contactInfo[0]
                                                          ?.ContactPerson?.email
                                                    : ""}
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}
                                                className="text-left grey2 font-bolder"
                                            >
                                                {!contactsDataLoading
                                                    ? contactInfo[0]
                                                          ?.ContactPerson
                                                          ?.phonenumber
                                                    : ""}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={3}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Grid container spacing={1}>
                                            <Grid
                                                item
                                                xs={12}
                                                className="text-left grey font-boldest"
                                            >
                                                Date Incorporated
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}
                                                className="text-left grey2 font-bolder"
                                            >
                                                {!contactsDataLoading
                                                    ? contactInfo[0]
                                                          ?.DateIncorporated
                                                          ?.date
                                                    : ""}
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}
                                                className="text-left grey font-bolder"
                                            >
                                                Vendor Rating
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}
                                                className="text-left grey2 font-bolder"
                                            >
                                                {!contactsDataLoading
                                                    ? contactInfo[0]
                                                          ?.DateIncorporated
                                                          ?.rating
                                                    : ""}
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}
                                                className="text-left grey2 font-bolder"
                                            >
                                                {!contactsDataLoading
                                                    ? contactInfo[0]
                                                          ?.DateIncorporated
                                                          ?.SupplierGroup
                                                    : ""}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>

                <Grid item xs={12}></Grid>
                <Grid item xs={12}></Grid>

                <Grid item sm={8} xs={12}>
                    <ButtonGroups
                        controlList={controlList}
                        handleDocList={handleDocList}
                        gridSpacing={2}
                        externalActive={activeList}
                    />
                </Grid>
                <Grid item sm={4}></Grid>

                {activeList === "Fulfillment" && (
                    <>
                        <Grid item xs={12} sm={12}></Grid>
                        <Grid item xs={12} sm={12}>
                            <Grid container spacing={3}>
                                <Grid item xs={6}>
                                    <Grid container spacing={2}>
                                        <Grid
                                            item
                                            xs={12}
                                            className="text-left grey2 font-bigger font-bolder"
                                            style={{ padding: "1.5% 1.25%" }}
                                        >
                                            Fulfillment
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Paper elevation={1}>
                                                <Grid
                                                    container
                                                    spacing={2}
                                                    className="padding-2-percent"
                                                >
                                                    <Grid
                                                        item
                                                        xs={6}
                                                        style={{
                                                            borderRight:
                                                                "1px dashed lightgray",
                                                            padding: "2%",
                                                        }}
                                                    >
                                                        <Grid
                                                            container
                                                            spacing={2}
                                                        >
                                                            <Grid item xs={12}>
                                                                <Grid container>
                                                                    <Grid
                                                                        item
                                                                        xs={8}
                                                                    >
                                                                        <Grid
                                                                            container
                                                                        >
                                                                            <Grid
                                                                                item
                                                                                xs={
                                                                                    12
                                                                                }
                                                                                className="text-left grey-2 font-boldest"
                                                                            >
                                                                                Total
                                                                                Volume
                                                                                Purchased
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                    <Grid
                                                                        item
                                                                        xs={4}
                                                                        className="text-right"
                                                                    >
                                                                        <Grid
                                                                            container
                                                                        >
                                                                            <Grid
                                                                                item
                                                                                xs={
                                                                                    12
                                                                                }
                                                                                className="text-right grey-1 font-boldest"
                                                                            >
                                                                                {!FulfillmentDataLoading
                                                                                    ? currencyFormatter(
                                                                                          fullfilldata[0]
                                                                                              ?.Volumeinfo
                                                                                              ?.purchased,
                                                                                          currency,
                                                                                      )
                                                                                    : ""}
                                                                            </Grid>
                                                                            {/* <Grid
                                                                    item
                                                                    xs={12}
                                                                    className="text-right grey font-boldest"
                                                                >
                                                                </Grid> */}
                                                                        </Grid>
                                                                    </Grid>
                                                                    <Grid
                                                                        item
                                                                        xs={10}
                                                                        className="text-left grey font-bold"
                                                                    >
                                                                        Last
                                                                        updated:
                                                                        {!FulfillmentDataLoading
                                                                            ? `           ${fullfilldata[0]?.Volumeinfo?.lastUpdated}`
                                                                            : ""}
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Grid container>
                                                                    <Grid
                                                                        item
                                                                        xs={6}
                                                                        className="text-left grey font-boldest"
                                                                    >
                                                                        Total
                                                                        Assigned
                                                                    </Grid>
                                                                    <Grid
                                                                        item
                                                                        xs={6}
                                                                        className="text-right green"
                                                                    >
                                                                        {!FulfillmentDataLoading
                                                                            ? currencyFormatter(fullfilldata[0]
                                                                                  ?.Volumeinfo
                                                                                  ?.assigned, currency)
                                                                            : ""}
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    {/* <Grid
                                            xs={1}
                                            container
                                            style={{
                                                margin: "0%",
                                                padding: "2% 0% 0% 0%",
                                                borderRight: "1px dashed #C9CDD6",
                                            }}
                                        ></Grid> */}
                                                    <Grid
                                                        item
                                                        xs={6}
                                                        style={{
                                                            padding: "2%",
                                                        }}
                                                    >
                                                        <Grid
                                                            container
                                                            spacing={2}
                                                        >
                                                            <Grid item xs={12}>
                                                                <Grid container>
                                                                    <Grid
                                                                        item
                                                                        xs={6}
                                                                        className="text-left grey font-boldest"
                                                                    >
                                                                        Total
                                                                        open
                                                                        Assigned
                                                                    </Grid>
                                                                    <Grid
                                                                        item
                                                                        xs={6}
                                                                        className="text-right grey2 font-bolder"
                                                                    >
                                                                        {!FulfillmentDataLoading
                                                                            ? currencyFormatter(
                                                                                  fullfilldata[0]
                                                                                      ?.Loaninfo
                                                                                      ?.openAssigned,
                                                                                  currency,
                                                                              )
                                                                            : ""}
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Grid container>
                                                                    <Grid
                                                                        item
                                                                        xs={6}
                                                                        className="text-left grey font-boldest"
                                                                    >
                                                                        Current
                                                                        Loan
                                                                        Balance
                                                                    </Grid>
                                                                    <Grid
                                                                        item
                                                                        xs={6}
                                                                        className="text-right grey2 font-bolder"
                                                                    >
                                                                        {!FulfillmentDataLoading
                                                                            ? currencyFormatter(
                                                                                  fullfilldata[0]
                                                                                      ?.Loaninfo
                                                                                      ?.currentbalance,
                                                                                  currency,
                                                                              )
                                                                            : ""}
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Grid container>
                                                                    <Grid
                                                                        item
                                                                        xs={6}
                                                                        className="text-left grey font-boldest"
                                                                    >
                                                                        Total
                                                                        Volume
                                                                        Owed
                                                                    </Grid>
                                                                    <Grid
                                                                        item
                                                                        xs={6}
                                                                        className="text-right grey2 font-bolder"
                                                                    >
                                                                        {!FulfillmentDataLoading
                                                                            ? currencyFormatter(
                                                                                  fullfilldata[0]
                                                                                      ?.Loaninfo
                                                                                      ?.owed,
                                                                                  currency,
                                                                              )
                                                                            : ""}
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Paper>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                {/* <Grid item xs={1}></Grid>                                               */}
                                <Grid item xs={3}>
                                    <Grid container spacing={2}>
                                        <Grid
                                            item
                                            xs={12}
                                            className="text-left grey2 font-bigger font-bolder"
                                            style={{ padding: "3% 2.5%" }}
                                        >
                                            Deduct Workers
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Paper elevation={1}>
                                                <Grid
                                                    container
                                                    spacing={2}
                                                    // className="padding-1-percent"
                                                    style={{ padding: "2% 5%" }}
                                                >
                                                    <Grid item xs={12}>
                                                        <Grid
                                                            container
                                                            spacing={2}
                                                        >
                                                            <Grid item xs={12}>
                                                                <Grid container>
                                                                    <Grid
                                                                        item
                                                                        xs={6}
                                                                        className="text-left grey font-boldest"
                                                                    >
                                                                        Deduct
                                                                        Workers
                                                                        Comp
                                                                    </Grid>
                                                                    <Grid
                                                                        item
                                                                        xs={6}
                                                                        className="text-right grey2 font-bolder"
                                                                    >
                                                                        {!FulfillmentDataLoading
                                                                            ? fullfilldata[0]
                                                                                  ?.Deduct
                                                                                  ?.workerscomp
                                                                            : ""}
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Grid container>
                                                                    <Grid
                                                                        item
                                                                        xs={6}
                                                                        className="text-left grey font-boldest"
                                                                    >
                                                                        Deduct
                                                                        Insurance
                                                                    </Grid>
                                                                    <Grid
                                                                        item
                                                                        xs={6}
                                                                        className="text-right grey2 font-bolder"
                                                                    >
                                                                        {!FulfillmentDataLoading
                                                                            ? fullfilldata[0]
                                                                                  ?.Deduct
                                                                                  ?.insurance
                                                                            : ""}
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Paper>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </>
                )}
                {activeList === "Primary Contact" && (
                    <>
                        <Grid item xs={4} sm={4}>
                            <Grid container spacing={2}>
                                <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    className="text-left grey2 font-bigger font-bolder"
                                    style={{ padding: "4% 2.5%" }}
                                >
                                    Primary Contact Person
                                </Grid>
                            </Grid>

                            <Paper elevation={1}>
                                <Grid
                                    container
                                    spacing={2}
                                    className="padding-2-percent"
                                    style={{ padding: "2% 4%" }}
                                >
                                    <Grid item xs={12}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <Grid container>
                                                    <Grid
                                                        item
                                                        xs={6}
                                                        className="text-left grey font-boldest"
                                                    >
                                                        Parent Account
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={6}
                                                        className="text-right grey2 font-bolder"
                                                    >
                                                        {!primarycontactDataLoading
                                                            ? primarydata[0]
                                                                  ?.Account
                                                            : ""}
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                            <Grid item xs={12}>
                                                <Grid container>
                                                    <Grid
                                                        item
                                                        xs={6}
                                                        className="text-left grey font-boldest"
                                                    >
                                                        Primary Contact
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={6}
                                                        className="text-right grey2 font-bolder"
                                                    >
                                                        {!primarycontactDataLoading
                                                            ? primarydata[0]
                                                                  ?.Contact
                                                            : ""}
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                            <Grid item xs={12}>
                                                <Grid container>
                                                    <Grid
                                                        item
                                                        xs={6}
                                                        className="text-left grey font-boldest"
                                                    >
                                                        Primary Cycle
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={6}
                                                        className="text-right grey2 font-bolder"
                                                    >
                                                        {!primarycontactDataLoading
                                                            ? primarydata[0]
                                                                  ?.cycle
                                                            : ""}
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                            <Grid item xs={12}>
                                                <Grid container>
                                                    <Grid
                                                        item
                                                        xs={6}
                                                        className="text-left grey font-boldest"
                                                    >
                                                        Check Name
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={6}
                                                        className="text-right grey2 font-bolder"
                                                    >
                                                        {!primarycontactDataLoading
                                                            ? primarydata[0]
                                                                  ?.name
                                                            : ""}
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                            <Grid item xs={12}>
                                                <Grid container>
                                                    <Grid
                                                        item
                                                        xs={6}
                                                        className="text-left grey font-boldest"
                                                    >
                                                        E-Mail
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={6}
                                                        className="text-right grey2 font-bolder"
                                                    >
                                                        {!primarycontactDataLoading
                                                            ? primarydata[0]
                                                                  ?.email
                                                            : ""}
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                    </>
                )}
            </Grid>
        </>
    );
};

const mapStateToProps = (state: any) => {
    return {
        currentContext: state.context?.userDefaults,
        userName: state.context?.name,
        selectedVendor: state.selectedVendor,
        currency: state.currency,
    };
};

export default connect(mapStateToProps)(Body);
