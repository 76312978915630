import { STORE_VENDOR_DETAILS } from "../actionTypes";

const initialState = {
    data: {
        vendorName: "",
        vendorPhone: "",
        vendorMail: "",
        vendorCode: "",
    },
};

const storeSelectedVendor = (prevState = initialState.data, action: any) => {
    switch (action.type) {
        case STORE_VENDOR_DETAILS:
            return action.payload;

        default:
            return prevState;
    }
};

export default storeSelectedVendor;
