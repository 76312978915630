const ContractsQuery = `
mutation(
    $entity:String,
    $ou:Int,
    $service:String,
    $User:String,
    $languageId:Int,
    $customerName:String,
    $customerCode:String,
    $status:String,
    $contractno:String,
    $fromdate:String,
    $todate:String,
    $merrorid:String 
) {
  contractdeatils(
      entity:$entity,
        ou:$ou,
        service:$service,
        User:$User,
        languageId:$languageId,
        customerName:$customerName,
        customerCode:$customerCode,
        contractno:$contractno,
        fromdate:$fromdate,
        todate:$todate,
        merrorid:$merrorid
        status:$status
    
    ) {
      Region,
      ContractID,
      BuildingDetails
      BuildingAddress
      AccountNumber,
      BuildingAmount,
      Contracts,
      Revision,
      RevisionStartEnd,
      ActiveAssignment,
      Status,
      siteId
  }
}
  `;

export default ContractsQuery;
