import React from "react";
import MainLayout from "./MainLayout/Components/ApplicationLayout";
import PageErrorBoundary from "Common/ErrorBoundary";

function Application() {
    return (
        <div className="App">
            {/* <PageErrorBoundary> */}
                <MainLayout />
            {/* </PageErrorBoundary> */}
        </div>
    );
}

export default Application;
