const BuildDetQuerySV = `
mutation(
    $entity: String
    $ou: Int
    $service: String
    $User: String
    $languageId: Int
    $customerCode: String
    $customerName: String
    $siteId: String
    $merrorid: String
) {
    BuildDetQuerySV(
        entity: $entity
        ou: $ou
        service: $service
        User: $User
        languageId: $languageId
        customerCode: $customerCode
        customerName: $customerName
        siteId: $siteId
        merrorid: $merrorid
    ) {
        Building{
            name
            id
        }
        Address{
            Area
            City
            State
            Pincode
        }
        Phone
        Status
        PrimaryContact
        AccountManager1
        AccountManager2
        AccountManager3
        ContactFreq
        Region
        Latitude
        Longitude
    }
}`;

export default BuildDetQuerySV;
