import React from "react";
import TextField from "@material-ui/core/TextField";
import "./TextField.scss";

interface TextFieldProps {
    label: string;
    id: string;
    multiline?: boolean;
    rows?: number;
    type?: string;
    value: string | number | undefined;
    handleChange: (e: React.ChangeEvent<{ value: unknown }>) => void;
    width: string;
}

const TextFields = (props: TextFieldProps) => {
    const {
        label,
        id,
        value,
        handleChange,
        width,
        multiline,
        rows,
        type,
    } = props;

    const handleNumeric = (e: React.ChangeEvent<{
        value: any;
    }>) => {
        if (type === "number"){
            if(e.target.value < 0 || isNaN(parseFloat(e.target.value)))
            e.target.value=""
            console.log("Numeric Checking",e.target.value,parseFloat(e.target.value))
            handleChange(e)
        }
        else 
        handleChange(e)
    }
    return (
        <div className="text-field-customised">
            <TextField
                label={label}
                id={id}
                name={id}
                value={value}
                variant="outlined"
                size="small"
                type={type}
                onChange={handleNumeric}
                multiline={multiline}
                rows={rows ? rows : 1}
                style={{ width: width }}
                color="secondary"
                InputLabelProps={{ style: { fontSize: 20 }, shrink: true }}
                InputProps={{ inputProps: { min: 0 } }}
            />
        </div>
    );
};

export default TextFields;
