import { Breadcrumbs, Link } from "@material-ui/core";
import * as React from "react";
import { connect } from "react-redux";

function handleClick(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    event.preventDefault();
    console.info("You clicked a breadcrumb.");
}
interface breadCrumbProps {
    breadcrumb :  { value: string; href: string }[]
}
function BreadCrumbsComp(props: breadCrumbProps) {
    const { breadcrumb } = props;

    React.useEffect(() => {
        console.log("Breadcrumb Links ==>",breadcrumb)
    }, [breadcrumb])

    return (
        <div role="presentation" onClick={handleClick}>
            <Breadcrumbs aria-label="breadcrumb">
                {/* <span className="blue font-smaller font-bolder">

                </span> */}
                {console.log("nav", breadcrumb)}
                {breadcrumb?.map((element: any, index: number) => {
                    return (
                        <>
                            {index + 1 === breadcrumb.length ? (
                                <span className="font-smaller font-bolder grey2">
                                    {element.value}
                                </span>
                            ) : (
                                <Link
                                    className="blue font-smaller font-bolder"
                                    href={element.href}
                                >
                                    {element.value}
                                </Link>
                            )}
                        </>
                    );
                })}
            </Breadcrumbs>
        </div>
    );
}

const mapStateToProps = (state: any) => {
    return {
        breadcrumb: state?.breadcrumb,
    };
};

export default connect(mapStateToProps)(BreadCrumbsComp);