import { Grid, Backdrop, CircularProgress } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import MuiDatatables from "Common/Mui-DataTables/MuiDatatables";
import useGetPosts from "ReactQuery/reactQuery";
import { Redirect } from "react-router-dom";
import BuildingQuery from "ReactQuery/Query/BuildingQuery";
import "Common/Main.scss";
import { connect } from "react-redux";
import { makeStyles, Theme } from "@material-ui/core/styles";
import Alert from "Common/Alerts/Alerts";
import currencyFormatter from "Common/Utils/currencyFormatter";

const useStyles = makeStyles((theme: Theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
}));

const Body = (props: any) => {
    const { currentContext, userName, selectedCustomer, currency } = props;
    const classes = useStyles();
    const [data, setData] = useState([]);
    const [siteId, setSiteId] = useState("");
    const [toggle, setToggle] = useState(false);
    const [popoverBlack, setPopoverBlack] = useState(false);
    const [alert, setAlert] = useState(false);
    const [errr, seterr] = useState({
        code: 0,
        msg: "",
    });

    const handleErrorAlert = (code: number, message: string) => {
        Alert({
            statusCode: code,
            message: message,
            willClose: () => {
                setAlert(false);
                seterr({ code: 0, msg: "" });
            },
        });
    };

    useEffect(() => {
        console.log("selectedCustomer", selectedCustomer);
    }, [selectedCustomer]);

    const {
        data: BuildingData,
        error: BuildingError,
        isLoading: BuildingLoading,
    } = useGetPosts({
        name: `BuildingQuery_${selectedCustomer?.companyCode}`,
        query: BuildingQuery,
        variables: {
            entity: "OW_Cust_portal_BuildingSmry",
            ou: currentContext?.ouId,
            service: "",
            User: userName,
            languageId: currentContext?.langId,
            customerCode: selectedCustomer?.companyCode,
            customerName: selectedCustomer?.companyName,
            merrorid: "",
        },
    });

    useEffect(() => {
        if (BuildingData?.BuildingQuery) {
            setData(BuildingData?.BuildingQuery);
            console.log("BuildingSummary", BuildingData?.BuildingQuery);
        }
    }, [BuildingData]);

    useEffect(() => {
        if (BuildingError && !BuildingLoading) {
            const data = JSON.parse(JSON.stringify(BuildingError));
            const errmsg = data?.response?.errors[0].message;
            seterr({
                code: 400,
                msg: errmsg,
            });
            setAlert(true);
        }
    }, [BuildingError, BuildingLoading]);

    if (toggle) {
        return (
            <Redirect
                to={{
                    pathname: "/app/BuildDetail",
                    state: {
                        siteId: siteId,
                    },
                }}
            />
        );
    }

    const handleClick = (data: any) => {
        setSiteId(data);
        setToggle((prevState) => !prevState);
    };

    const BuildingSchema = [
        {
            name: "Building",
            Label: "Building",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Building Details</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: {
                        area: string;
                        number: string;
                        city: string;
                    },
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    // console.log("tableMeta", tableMeta);
                    return (
                        <Grid
                            container
                            onClick={() => handleClick(value?.number)}
                            style={{ cursor: "pointer" }}
                        >
                            <Grid item xs={12}>
                                <b
                                    style={{ cursor: "pointer" }}
                                    className="blue font-bolder"
                                >
                                    {value.area}
                                </b>
                            </Grid>
                            <Grid item xs={12}>
                                <b className="grey">
                                    {value.number} | {value.city}
                                </b>
                            </Grid>
                        </Grid>
                    );
                },
            },
        },
        {
            name: "AccNo",
            Label: "AccNo",
            type: "string",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Account No.</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return <b className="grey2 font-bolder">{value}</b>;
                },
            },
        },
        {
            name: "CustLevel",
            Label: "CustLevel",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Customer Level</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return <b className="grey2 font-bolder">{value}</b>;
                },
            },
        },
        {
            name: "Address",
            Label: "Address",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Address</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: {
                        name: string;
                        city: string;
                        state: string;
                        pincode: string;
                    },
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return (
                        <Grid container>
                            <Grid item xs={12}>
                                <b className="grey2 font-bolder">
                                    {value.name}
                                </b>
                            </Grid>
                            <Grid item xs={12}>
                                <b className="grey">
                                    {value.city} | {value.state} |{" "}
                                    {value.pincode}
                                </b>
                            </Grid>
                        </Grid>
                    );
                },
            },
        },
        {
            name: "VendorInfo",
            Label: "VendorInfo",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Vendor Info</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: {
                        name: string;
                        company: string;
                    },
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return (
                        <Grid container>
                            <Grid item xs={12}>
                                <b className="grey2 font-bolder">
                                    {value.name}
                                </b>
                            </Grid>
                            <Grid item xs={12}>
                                <b className="grey">{value.company}</b>
                            </Grid>
                        </Grid>
                    );
                },
            },
        },
        {
            name: "TotalAmt",
            Label: "TotalAmt",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span
                        style={{
                            color: "white",
                        }}
                    >
                        Total Amount
                    </span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return (
                        <b
                            className="green font-bolder text-right"
                            style={{
                                display: "flex",
                                justifyContent: "flex-end",
                            }}
                        >
                            {currencyFormatter(value, currency)}
                        </b>
                    );
                },
            },
        },
        {
            name: "LastQOS",
            Label: "LastQOS",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span
                        style={{
                            color: "white",
                        }}
                    >
                        Last QOS
                    </span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return <b className="grey2 font-bolder">{value}</b>;
                },
            },
        },
    ];
    return (
        <>
            {alert ? handleErrorAlert(errr.code, errr.msg) : <></>}
            <Backdrop className={classes.backdrop} open={BuildingLoading}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <Backdrop
                className={classes.backdrop}
                open={popoverBlack}
            ></Backdrop>
            <MuiDatatables
                title={""}
                header={BuildingSchema}
                detail={[data, setData]}
                // handleAddSideEffect={handleAddSideEffect}
                // handleDeleteSideEffect={handleDeleteSideEffect}
                initRow={false}
            />
        </>
    );
};
const mapStateToProps = (state: any) => {
    return {
        currentContext: state.context?.userDefaults,
        userName: state.context?.name,
        selectedCustomer: state.selectedCustomer,
        currency: state.currency,
    };
};

export default connect(mapStateToProps)(Body);
