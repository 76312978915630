const AssignmentQuery = `
mutation(
    $entity:String
    $ou:Int
    $service:String
    $User:String
    $languageId:Int
    $suppCode:String
    $suppName:String
    $pono:String
    $status:String
    $fromdate:String
    $todate:String
    $merrorid:String
) {
    AssignmentQuery(
        entity:$entity
        ou:$ou
        service:$service
        User:$User
        languageId:$languageId
        suppCode:$suppCode
        suppName:$suppName
        pono:$pono
        status:$status
        fromdate:$fromdate
        todate:$todate
        merrorid:$merrorid 
    ) {
        AssignmentNo
        AccountName
        ContractNo
        VendorContract
        Building{
            name
            id
        }
        BuildingID
        City
        StartEndDate
        ServiceCode
        VendorAmt
        VendorPercent
        FirstMonthProrate
        LastMonthProrate
    }
}`;

export default AssignmentQuery;
