import { Backdrop, CircularProgress, Grid, Paper } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import MUIButton from "Common/Button/Button";
import useGetPosts from "ReactQuery/reactQuery";
import MuiDatatables from "Common/Mui-DataTables/MuiDatatables";
import { Redirect } from "react-router-dom";
import Dropdown from "Common/Dropdown/Dropdown";
import TextFields from "Common/TextField/TextField";
import CustomDatePickerBorder from "Common/DatePicker/DatePicker";
import { makeStyles, Theme } from "@material-ui/core/styles";
import {
    InvoiceHeaderQuery,
    InvoicesQuery,
    StatusComboQuery,
} from "ReactQuery/Query/index";
import { connect } from "react-redux";
import Alert from "Common/Alerts/Alerts";
import currencyFormatter from "Common/Utils/currencyFormatter";
import dateFormatter from "Common/Utils/dateFormatter";

interface DashCards {
    desc: string;
    value: string;
    visible: boolean;
    frequent: boolean;
    detail: {
        desc: string;
        value: number;
        color: string;
    }[];
}

const useStyles = makeStyles((theme: Theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
}));
const Body = (props: any) => {
    const { currentContext, userName, selectedCustomer, currency } = props;
    const classes = useStyles();
    const [header, setHeader] = useState<DashCards>();
    const [InvoiceDet, setInvoiceDet] = useState<boolean>(false);

    const [active, setActive] = useState<string>("");

    const [showTable, setShowTable] = useState<boolean>(false);

    const [InvoiceDetailsList, setInvoiceDetailsList] = useState([]);

    const [InvoiceSelected, setInvoiceSelected] = useState();

    const handleredirect = (val: any) => {
        setInvoiceDet((prev) => !prev);
        setInvoiceSelected(val);
    };
    const [popoverBlack, setPopoverBlack] = useState(false);
    const [statusList, setStatusList] = useState<any>([]);
    const [contractData, setContractData] = useState({
        fromDate: new Date(),
        toDate: new Date(),
        contractno: "",
        status: "",
    });

    const [alert, setAlert] = useState(false);
    const [errr, seterr] = useState({
        code: 0,
        msg: "",
    });

    const handleErrorAlert = (code: number, message: string) => {
        Alert({
            statusCode: code,
            message: message,
            willClose: () => {
                setAlert(false);
                seterr({ code: 0, msg: "" });
            },
        });
    };

    const {
        data: StatusComboData,
        error: StatusComboDataError,
        isLoading: StatusComboDataLoading,
    } = useGetPosts({
        name: `StatusComboQuery_${selectedCustomer?.companyCode}_CustInvoice`,
        query: StatusComboQuery,
        variables: {
            entity: "ow_getcombo_status",
            ou: currentContext?.ouId,
            service: "",
            user: userName,
            langId: currentContext?.langId,
            screen: "CustInvoice",
            tranType: "",
            merrorid: "",
        },
    });

    useEffect(() => {
        if (StatusComboData?.statusCombo) {
            console.log("StatusData", StatusComboData?.statusCombo);
            setStatusList(StatusComboData?.statusCombo);
            setContractData((prevState: any) => {
                return {
                    ...prevState,
                    status: StatusComboData?.statusCombo[0]?.value,
                };
            });
        }
    }, [StatusComboData]);

    useEffect(() => {
        if (StatusComboDataError && !StatusComboDataLoading) {
            const data = JSON.parse(JSON.stringify(StatusComboDataError));
            const errmsg = data?.response?.errors[0].message;
            seterr({
                code: 400,
                msg: errmsg,
            });
            setAlert(true);
        }
    }, [StatusComboDataError, StatusComboDataLoading]);

    const {
        data: InvoicesData,
        error: InvoicesDataError,
        isLoading: InvoicesDataLoading,
    } = useGetPosts({
        name: `InvoicesQuery_get_data_${contractData?.status}_${contractData?.contractno}_${contractData?.fromDate}_${contractData?.toDate}_${active}_${selectedCustomer?.companyCode}`,
        query: InvoicesQuery,
        variables: {
            entity: "OW_Cust_portal_InvSummary",
            ou: currentContext?.ouId,
            service: "",
            User: userName,
            languageId: currentContext?.langId,
            Status: active === header?.detail[0]?.desc ? "AC" : contractData?.status,
            customerCode: selectedCustomer?.companyCode,
            customerName: selectedCustomer?.companyName,
            siteId: "",
            buildName: "",
            contractNo: "",
            invoiceNo: contractData?.contractno,
            fromDate: dateFormatter("sqlDate", contractData?.fromDate),
            toDate: dateFormatter("sqlDate", contractData?.toDate),
            merrorid: "",
        },
        skip: active === header?.detail[0]?.desc ? false : !showTable
    });

    useEffect(() => {
        console.log("Invoice Table", InvoicesData?.InvoicesList);
        if (InvoicesData?.InvoicesList) {
            setInvoiceDetailsList(InvoicesData?.InvoicesList);
        }
    }, [InvoicesData]);

    useEffect(() => {
        if (InvoicesDataError && !InvoicesDataLoading) {
            const data = JSON.parse(JSON.stringify(InvoicesDataError));
            const errmsg = data?.response?.errors[0].message;
            seterr({
                code: 400,
                msg: errmsg,
            });
            setAlert(true);
        }
    }, [InvoicesDataError, InvoicesDataLoading]);

    const {
        data: InvoiceHeaderData,
        error: InvoiceHeaderDataError,
        isLoading: InvoicesHeaderDataLoading,
    } = useGetPosts({
        name: `InvoiceHeaderQuery_get_data_${selectedCustomer?.companyCode}`,
        query: InvoiceHeaderQuery,
        variables: {
            entity: "OW_Cust_portal_InvStatus",
            ou: currentContext?.ouId,
            service: "",
            User: userName, //"ramcouser",
            languageId: currentContext?.langId,
            customerName: selectedCustomer?.companyName,
            customerCode: selectedCustomer?.companyCode,
            merrorid: "0",
        },
    });

    useEffect(() => {
        console.log("InvoiceHeaderData", InvoiceHeaderData?.GetInvoiceHeader);
        if (InvoiceHeaderData?.GetInvoiceHeader) {
            setHeader(InvoiceHeaderData?.GetInvoiceHeader);
            setActive(InvoiceHeaderData?.GetInvoiceHeader?.detail[0]?.desc);
        }
    }, [InvoiceHeaderData]);

    useEffect(() => {
        if (InvoiceHeaderDataError && !InvoicesHeaderDataLoading) {
            const data = JSON.parse(JSON.stringify(InvoiceHeaderDataError));
            const errmsg = data?.response?.errors[0].message;
            seterr({
                code: 400,
                msg: errmsg,
            });
            setAlert(true);
        }
    }, [InvoiceHeaderDataError, InvoicesHeaderDataLoading]);

    const InvoiceSchema = [
        {
            name: "InvoiceNo",
            Label: "InvoiceNo",
            type: "string",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Invoice No.</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return (
                        <b
                            style={{ color: "#0073E6", cursor: "pointer" }}
                            onClick={() => handleredirect(value)}
                        >
                            {value}
                        </b>
                    );
                },
            },
        },
        {
            name: "InvCatPayterm",
            Label: "InvCatPayterm",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>
                        Invoice Category - <br /> Payterm
                    </span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: {
                        line1: string;
                        line2: string;
                    },
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return (
                        <Grid container>
                            <Grid item xs={12}>
                                <b style={{ color: "#5C677D" }}>
                                    {value.line1}
                                </b>
                            </Grid>
                            <Grid item xs={12}>
                                <b className="grey">{value.line2}</b>
                            </Grid>
                        </Grid>
                    );
                },
            },
        },

        {
            name: "CustomerLevel",
            Label: "CustomerLevel",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Customer Level</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return <b style={{ color: "#5C677D" }}>{value}</b>;
                },
            },
        },
        {
            name: "TotalAmount",
            Label: "TotalAmount",

            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Total Amount</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return (
                        <b
                            style={{
                                color: "#00AE50",
                                display: "flex",
                                justifyContent: "flex-end",
                            }}
                        >
                            {currencyFormatter(value, currency)}
                        </b>
                    );
                },
            },
        },
        {
            name: "PaymentStatus",
            Label: "PaymentStatus",
            type: "string",
            options: {
                filter: true,
                sort: false,
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Payment Status</span>
                ),
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return value === "Paid" ? (
                        <>
                            <b className="Completed_dot"></b>
                            <b style={{ color: "#00AE50", padding: "3%" }}>
                                {value}
                            </b>
                        </>
                    ) : value === "Not Paid" ? (
                        <>
                            <b className="Failed_dot"></b>
                            <b style={{ color: "#E01E37", padding: "3%" }}>
                                {value}
                            </b>
                        </>
                    ) : (
                        <>
                            <b className="Pending_dot"></b>
                            <b style={{ color: "#DD9200", padding: "3%" }}>
                                {value}
                            </b>
                        </>
                    );
                },
            },
        },

        {
            name: "DocDate",
            Label: "DocDate",

            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Doc Date</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return <b style={{ color: "#5C677D" }}>{value}</b>;
                },
            },
        },

        {
            name: "PastDueDate",
            Label: "PastDueDate",
            type: "number",

            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Past Due Date</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return <b style={{ color: "#E01E37" }}>{value}</b>;
                },
            },
        },
    ];

    const handleActive = (index: string) => {
        setActive(index);
        setShowTable(false);
        console.log("index", index);
        // setshipmentCardData((shipmentConstData as any)[index]);
    };

    const handleChange = (e: any) => {
        setShowTable(false);
        setContractData((prevState) => ({
            ...prevState,
            contractno: e?.target?.value,
        }));
    };
    const handleChangeStatus = (e: any) => {
        setShowTable(false);
        setContractData((prevState) => ({
            ...prevState,
            status: e?.target?.value,
        }));
    };
    const handleDate = (type: string, e: any) => {
        setShowTable(false);
        if (type === "toDate") {
            setContractData((prevstate: any) => ({
                ...prevstate,
                toDate: e,
            }));
        } else if (type === "fromDate") {
            setContractData((prevstate: any) => ({
                ...prevstate,
                fromDate: e,
            }));
        }
    };

    const handleSearch = () => {
        setShowTable(true);
    };

    if (InvoiceDet) {
        return (
            <Redirect
                to={{
                    pathname: "/app/INVDET",
                    state: {
                        InvoiceSelected: InvoiceSelected,
                    },
                }}
            />
        );
    }

    return (
        <>
            {alert ? handleErrorAlert(errr.code, errr.msg) : <></>}
            <Backdrop className={classes.backdrop} open={InvoicesDataLoading}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <Backdrop
                className={classes.backdrop}
                open={popoverBlack}
            ></Backdrop>

            <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} sm={12} style={{ paddingTop: "2%" }}>
                    {/* <Paper variant="outlined"> */}
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={12} sm={4} md={3}>
                            <Paper elevation={2}>
                                <Grid
                                    container
                                    spacing={2}
                                    style={{
                                        padding: "5% 0% 0% 0%",
                                    }}
                                    justifyContent="space-around"
                                >
                                    {header?.detail.map((element, index) => {
                                        return (
                                            <Grid
                                                item
                                                xs={4}
                                                sm={4}
                                                className={
                                                    active === element?.desc
                                                        ? `${element?.color}_border_bottom pointer`
                                                        : "pointer"
                                                }
                                                onClick={() =>
                                                    handleActive(element?.desc)
                                                }
                                            >
                                                <Grid container spacing={1}>
                                                    <Grid item xs={12} sm={12}>
                                                        <span
                                                            // className={`${element?.color} font-biggest font-boldest`}
                                                            className={
                                                                active ===
                                                                element?.desc
                                                                    ? `${element?.color} font-biggest font-boldest`
                                                                    : `grey font-biggest font-boldest`
                                                            }
                                                        >
                                                            {element?.value}
                                                        </span>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12}>
                                                        <span
                                                            className={
                                                                active ===
                                                                element?.desc
                                                                    ? `font-boldest font-smaller`
                                                                    : "grey font-smallest"
                                                            }
                                                        >
                                                            {element?.desc}
                                                        </span>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        );
                                    })}
                                </Grid>
                            </Paper>
                        </Grid>

                        {active === header?.detail[1]?.desc && (
                            <Grid item sm={12} md={8}>
                                <Paper elevation={2}>
                                    <Grid
                                        container
                                        spacing={2}
                                        style={{ padding: "2%" }}
                                    >
                                        <Grid item xs sm={10} style={{}}>
                                            <Grid
                                                container
                                                spacing={2}
                                                alignItems="center"
                                            >
                                                <Grid
                                                    item
                                                    sm
                                                    xs={12}
                                                    style={{}}
                                                >
                                                    {/* <Grid item xs sm={5}> */}
                                                    {/* <TextFields
                                                    label={"Invoice No."}
                                                    id={"InvoiceNo"}
                                                    value={"Inv009"}
                                                    handleChange={handleChange}
                                                    width={"100%"}
                                                    multiline={false}
                                                    rows={0}
                                                /> */}
                                                    <TextFields
                                                        label={"Invoice No.."}
                                                        id={"invoiceNo"}
                                                        value={
                                                            contractData?.contractno
                                                        }
                                                        handleChange={
                                                            handleChange
                                                        }
                                                        width={"100%"}
                                                        multiline={false}
                                                        rows={0}
                                                    />
                                                </Grid>
                                                <Grid
                                                    item
                                                    sm
                                                    xs={12}
                                                    style={{}}
                                                >
                                                    {/* <Dropdown
                                                    label={"Payment Status"}
                                                    id={"Status"}
                                                    value={   controlList[0]
                                                        ?.value}
                                                    list={controlList}
                                                    handleChange={handleChange}
                                                    width={"100%"}
                                                /> */}

                                                    <Dropdown
                                                        label={"Status"}
                                                        id={"Status"}
                                                        value={
                                                            contractData?.status
                                                        }
                                                        // value={
                                                        //     contractData?.status
                                                        // }
                                                        list={statusList}
                                                        handleChange={
                                                            handleChangeStatus
                                                        }
                                                        width={"100%"}
                                                    />
                                                </Grid>
                                                <Grid item sm xs={2} style={{}}>
                                                    <CustomDatePickerBorder
                                                        label="From Date"
                                                        width="100%"
                                                        value={
                                                            contractData?.fromDate
                                                        }
                                                        onChange={(e) =>
                                                            handleDate(
                                                                "fromDate",
                                                                e,
                                                            )
                                                        }
                                                        format="MM/dd/yyyy"
                                                    />
                                                </Grid>
                                                <Grid item sm xs={2} style={{}}>
                                                    <CustomDatePickerBorder
                                                        label="To Date"
                                                        width="100%"
                                                        value={
                                                            contractData?.toDate
                                                        }
                                                        onChange={(e) =>
                                                            handleDate(
                                                                "toDate",
                                                                e,
                                                            )
                                                        }
                                                        format="MM/dd/yyyy"
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid item sm={2} xs={12}>
                                            <MUIButton
                                                label="Search"
                                                type="filled"
                                                font="font-small"
                                                onClick={handleSearch}
                                            />
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                        )}
                    </Grid>
                    {/* </Paper> */}
                </Grid>

                {active === header?.detail[0]?.desc && (
                    <Grid
                        item
                        xs={12}
                        style={{ marginRight: "2%", paddingTop: "2%" }}
                    >
                        <MuiDatatables
                            header={InvoiceSchema}
                            title={""}
                            // rowData={checklistDetailsList}
                            detail={[InvoiceDetailsList, setInvoiceDetailsList]}
                            // handleAddSideEffect={handleAddSideEffect}
                            // handleDeleteSideEffect={handleDeleteSideEffect}
                            // handleSelectedSideEffect={handleSelectedSideEffect}
                            // initRow={true}
                            height="270px"
                        />
                    </Grid>
                )}

                {showTable && active === header?.detail[1]?.desc && (
                    <Grid
                        item
                        xs={12}
                        style={{ marginRight: "2%", paddingTop: "2%" }}
                    >
                        <MuiDatatables
                            header={InvoiceSchema}
                            title={""}
                            // rowData={checklistDetailsList}
                            detail={[InvoiceDetailsList, setInvoiceDetailsList]}
                            // handleAddSideEffect={handleAddSideEffect}
                            // handleDeleteSideEffect={handleDeleteSideEffect}
                            // handleSelectedSideEffect={handleSelectedSideEffect}
                            // initRow={true}
                            height="270px"
                        />
                    </Grid>
                )}
            </Grid>
        </>
    );
};

const mapStateToProps = (state: any) => {
    return {
        currentContext: state.context?.userDefaults,
        userName: state.context?.name,
        selectedCustomer: state.selectedCustomer,
        currency: state.currency,
    };
};

export default connect(mapStateToProps)(Body);
