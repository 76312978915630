const InvoiceDetailsListQuery = `
mutation(
    $entity: String
    $ou: Int
    $service: String
    $User: String
    $languageId: Int
    $contractno: String
    $conttype: String
    $customerCode: String
    $siteId: String
    $buildName: String
    $merrorid: String
) {
    GetInvoiceDetailsList(
        entity: $entity
        ou: $ou
        service: $service
        User: $User
        languageId: $languageId
        contractno: $contractno
        conttype: $conttype
        customerCode: $customerCode
        siteId: $siteId
        buildName: $buildName
        merrorid: $merrorid
    ) {  
        InvoiceNo,    
        StatusReason,
        TotalAmount,  
        Customer,
        BuildingAddress {
            line1
            line2
        }   
        BuildingID,    
        City,    
        DocDate,   
        GPInvoiceID, 
        GPTransaction,
        CreatedOn,    
        MonthYear, 
        PastDueDays {
            days
            color
        }
        PaymentStatus {
            status
            color
        }
    }
}
  `;

export default InvoiceDetailsListQuery;
