const accNoCombo = `
query Query($UserId:String!, $languageId:Int!, $ouId:String!, $queryString:String!, $apiType:String!, $blank: String,$all: String) {
    accNoCombo(UserId:$UserId, languageId:$languageId, ouId:$ouId, queryString:$queryString, apiType:$apiType,blank:$blank,all:$all) {
  desc
  value
  }
}
  `;

export default accNoCombo;
