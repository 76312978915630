
const CustomerSearchQuery = `
mutation (
    $entity:String,
    $ou:Int,
    $service:String,
    $User:String,
    $languageId:Int,
    $customerName:String,
    $customerCode:String,
    $mobileNumber:String,
    $email:String,
    $merrorid:String){
    customerSearch(
        entity:$entity,
        ou:$ou,
        service:$service,
        User:$User,
        languageId:$languageId,
        customerName:$customerName,
        customerCode:$customerCode,
        mobileNumber:$mobileNumber,
        email:$email,
        merrorid:$merrorid) {
        companyName
        companyCode
        phone
        mail
        companyLogo
    }
}`;

export default CustomerSearchQuery;
