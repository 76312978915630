const BuildDetAssgnQuery = `
mutation(
    $entity: String
    $ou: Int
    $service: String
    $User: String
    $languageId: Int
    $customerCode: String
    $customerName: String
    $siteId: String
    $buildName: String
    $contractNo: String
    $merrorid: String
) {
    BuildDetAssgnQuery(
        entity: $entity
        ou: $ou
        service: $service
        User: $User
        languageId: $languageId
        customerCode: $customerCode
        customerName: $customerName
        siteId: $siteId
        buildName: $buildName
        contractNo: $contractNo
        merrorid: $merrorid
    ) {
        Customer
        RevisionStartEnd
        Vendor
        VendorAmt
        Replaceable
        VendorNetAmount
        VendorNetPercent
        FirstMonthProrate
        LastMonthProrate
        CreatedOn
    }
}`;

export default BuildDetAssgnQuery;
