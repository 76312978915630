import "Common/Main.scss";
import { CheckCircle } from "@material-ui/icons";
const ServiceDaysSchema = [
    {
        name: "ContractID",
        Label: "ContractID",
        options: {
            filter: true,
            sort: false,
            customHeadLabelRender: () => (
                <span style={{ color: "white" }}>Contract ID</span>
            ),
            setCellHeaderProps: () => {
                return { style: { borderRight: "1px solid #D5D5D5" } };
            },
            setCellProps: () => {
                return {
                    style: {
                        borderRight: "1px solid #D5D5D5",
                        textAlign: "center",
                    },
                };
            },
            customBodyRender: (
                value: any,
                tableMeta: any,
                updateValue: any,
            ) => {
                console.log("table data", value);
                return <b className="blue font-bolder">{value}</b>;
            },
        },
    },
    {
        name: "Monday",
        Label: "Monday",
        options: {
            filter: true,
            sort: false,
            customHeadLabelRender: () => (
                <span
                    style={{
                        color: "white",
                        display: "flex",
                        justifyContent: "center",
                    }}
                >
                    Monday
                </span>
            ),
            setCellHeaderProps: () => {
                return { style: { borderRight: "1px solid #D5D5D5" } };
            },
            setCellProps: () => {
                return {
                    style: {
                        borderRight: "1px solid #D5D5D5",
                        textAlign: "center",
                    },
                };
            },
            customBodyRender: (
                value: any,
                tableMeta: any,
                updateValue: any,
            ) => {
                console.log("table data", value);
                return (
                    <b
                        className="green"
                        style={{ display: "flex", justifyContent: "center" }}
                    >
                        {value === "1" ? <CheckCircle /> : <></>}
                    </b>
                );
            },
        },
    },
    {
        name: "Tuesday",
        Label: "Tuesday",
        options: {
            filter: true,
            sort: false,
            customHeadLabelRender: () => (
                <span
                    style={{
                        color: "white",
                        display: "flex",
                        justifyContent: "center",
                    }}
                >
                    Tuesday
                </span>
            ),
            setCellHeaderProps: () => {
                return { style: { borderRight: "1px solid #D5D5D5" } };
            },
            setCellProps: () => {
                return {
                    style: {
                        borderRight: "1px solid #D5D5D5",
                        textAlign: "center",
                    },
                };
            },
            customBodyRender: (
                value: any,
                tableMeta: any,
                updateValue: any,
            ) => {
                console.log("table data", value);
                return (
                    <b
                        className="green"
                        style={{ display: "flex", justifyContent: "center" }}
                    >
                        {value === "1" ? <CheckCircle /> : <></>}
                    </b>
                );
            },
        },
    },
    {
        name: "Wednesday",
        Label: "Wednesday",
        options: {
            filter: true,
            sort: false,
            customHeadLabelRender: () => (
                <span
                    style={{
                        color: "white",
                        display: "flex",
                        justifyContent: "center",
                    }}
                >
                    Wednesday
                </span>
            ),
            setCellHeaderProps: () => {
                return { style: { borderRight: "1px solid #D5D5D5" } };
            },
            setCellProps: () => {
                return {
                    style: {
                        borderRight: "1px solid #D5D5D5",
                        textAlign: "center",
                    },
                };
            },
            customBodyRender: (
                value: any,
                tableMeta: any,
                updateValue: any,
            ) => {
                console.log("table data", value);
                return (
                    <b
                        className="green"
                        style={{ display: "flex", justifyContent: "center" }}
                    >
                        {value === "1" ? <CheckCircle /> : <></>}
                    </b>
                );
            },
        },
    },
    {
        name: "Thursday",
        Label: "Thursday",
        options: {
            filter: true,
            sort: false,
            customHeadLabelRender: () => (
                <span
                    style={{
                        color: "white",
                        display: "flex",
                        justifyContent: "center",
                    }}
                >
                    Thursday
                </span>
            ),
            setCellHeaderProps: () => {
                return { style: { borderRight: "1px solid #D5D5D5" } };
            },
            setCellProps: () => {
                return {
                    style: {
                        borderRight: "1px solid #D5D5D5",
                        textAlign: "center",
                    },
                };
            },
            customBodyRender: (
                value: any,
                tableMeta: any,
                updateValue: any,
            ) => {
                console.log("table data", value);
                return (
                    <b
                        className="green"
                        style={{ display: "flex", justifyContent: "center" }}
                    >
                        {value === "1" ? <CheckCircle /> : <></>}
                    </b>
                );
            },
        },
    },
    {
        name: "Friday",
        Label: "Friday",
        options: {
            filter: true,
            sort: false,
            customHeadLabelRender: () => (
                <span
                    style={{
                        color: "white",
                        display: "flex",
                        justifyContent: "center",
                    }}
                >
                    Friday
                </span>
            ),
            setCellHeaderProps: () => {
                return { style: { borderRight: "1px solid #D5D5D5" } };
            },
            setCellProps: () => {
                return {
                    style: {
                        borderRight: "1px solid #D5D5D5",
                        textAlign: "center",
                    },
                };
            },
            customBodyRender: (
                value: any,
                tableMeta: any,
                updateValue: any,
            ) => {
                console.log("table data", value);
                return (
                    <b
                        className="green"
                        style={{ display: "flex", justifyContent: "center" }}
                    >
                        {value === "1" ? <CheckCircle /> : <></>}
                    </b>
                );
            },
        },
    },
    {
        name: "Saturday",
        Label: "Saturday",
        options: {
            filter: true,
            sort: false,
            customHeadLabelRender: () => (
                <span
                    style={{
                        color: "white",
                        display: "flex",
                        justifyContent: "center",
                    }}
                >
                    Saturday
                </span>
            ),
            setCellHeaderProps: () => {
                return { style: { borderRight: "1px solid #D5D5D5" } };
            },
            setCellProps: () => {
                return {
                    style: {
                        borderRight: "1px solid #D5D5D5",
                        textAlign: "center",
                    },
                };
            },
            customBodyRender: (
                value: any,
                tableMeta: any,
                updateValue: any,
            ) => {
                console.log("table data", value);
                return (
                    <b
                        className="green"
                        style={{ display: "flex", justifyContent: "center" }}
                    >
                        {value === "1" ? <CheckCircle /> : <></>}
                    </b>
                );
            },
        },
    },
    {
        name: "Sunday",
        Label: "Sunday",
        options: {
            filter: true,
            sort: false,
            customHeadLabelRender: () => (
                <span
                    style={{
                        color: "white",
                        display: "flex",
                        justifyContent: "center",
                    }}
                >
                    Sunday
                </span>
            ),
            setCellHeaderProps: () => {
                return { style: { borderRight: "1px solid #D5D5D5" } };
            },
            setCellProps: () => {
                return {
                    style: {
                        borderRight: "1px solid #D5D5D5",
                        textAlign: "center",
                    },
                };
            },
            customBodyRender: (
                value: any,
                tableMeta: any,
                updateValue: any,
            ) => {
                console.log("table data", value);
                return (
                    <b
                        className="green"
                        style={{ display: "flex", justifyContent: "center" }}
                    >
                        {value === "1" ? <CheckCircle /> : <></>}
                    </b>
                );
            },
        },
    },
];
export default ServiceDaysSchema;
