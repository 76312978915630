import currencyFormatter from "Common/Utils/currencyFormatter";

const AmortizationSchema = [
    {
        name: "PaymentNo",
        Label: "PaymentNo",
        options: {
            filter: true,
            sort: false,
            customHeadLabelRender: () => (
                <span style={{ color: "white" }}>Payment No.</span>
            ),
            setCellHeaderProps: () => {
                return { style: { borderRight: "1px solid #D5D5D5" } };
            },
            setCellProps: () => {
                return {
                    style: {
                        borderRight: "1px solid #D5D5D5",
                        textAlign: "center",
                    },
                };
            },
            customBodyRender: (
                value: any,
                tableMeta: any,
                updateValue: any,
            ) => {
                console.log("table data", value, tableMeta.rowData);
                return <b style={{ color: "#0073E6" }}>{value}</b>;
            },
        },
    },
    {
        name: "DueDate",
        Label: "DueDate",
        type: "string",
        options: {
            filter: true,
            sort: false,
            customHeadLabelRender: () => (
                <span style={{ color: "white" }}>Due Date</span>
            ),
            setCellHeaderProps: () => {
                return { style: { borderRight: "1px solid #D5D5D5" } };
            },
            setCellProps: () => {
                return {
                    style: {
                        borderRight: "1px solid #D5D5D5",
                        textAlign: "center",
                    },
                };
            },
            customBodyRender: (
                value: any,
                tableMeta: any,
                updateValue: any,
            ) => {
                console.log("table data", value);
                return <b style={{ color: "#5C677D" }}>{value}</b>;
            },
        },
    },
    {
        name: "Payment",
        Label: "Payment",
        options: {
            filter: true,
            sort: false,
            customHeadLabelRender: () => (
                <span style={{ color: "white" }}>Payment</span>
            ),
            setCellHeaderProps: () => {
                return { style: { borderRight: "1px solid #D5D5D5" } };
            },
            setCellProps: () => {
                return {
                    style: {
                        borderRight: "1px solid #D5D5D5",
                        textAlign: "center",
                    },
                };
            },
            customBodyRender: (
                value: any,
                tableMeta: any,
                updateValue: any,
            ) => {
                console.log("table data", value);
                return (
                    <b
                        style={{
                            color: "#5C677D",
                            display: "flex",
                            justifyContent: "flex-end",
                        }}
                    >
                        {currencyFormatter(value, "USD")}
                    </b>
                );
            },
        },
    },
    {
        name: "PrincipalAmount",
        Label: "PrincipalAmount",
        options: {
            filter: true,
            sort: false,
            customHeadLabelRender: () => (
                <span style={{ color: "white" }}>Principal Amount</span>
            ),
            setCellHeaderProps: () => {
                return { style: { borderRight: "1px solid #D5D5D5" } };
            },
            setCellProps: () => {
                return {
                    style: {
                        borderRight: "1px solid #D5D5D5",
                        textAlign: "center",
                    },
                };
            },
            customBodyRender: (
                value: any,
                tableMeta: any,
                updateValue: any,
            ) => {
                console.log("table data", value);
                return (
                    <b
                        style={{
                            color: "#00AE50",
                            display: "flex",
                            justifyContent: "flex-end",
                        }}
                    >
                        {currencyFormatter(value, "USD")}
                    </b>
                );
            },
        },
    },
    {
        name: "InterestAmount",
        Label: "InterestAmount",
        options: {
            filter: true,
            sort: false,
            customHeadLabelRender: () => (
                <span style={{ color: "white" }}>Interest Amount</span>
            ),
            setCellHeaderProps: () => {
                return { style: { borderRight: "1px solid #D5D5D5" } };
            },
            setCellProps: () => {
                return {
                    style: {
                        borderRight: "1px solid #D5D5D5",
                        textAlign: "center",
                    },
                };
            },
            customBodyRender: (
                value: any,
                tableMeta: any,
                updateValue: any,
            ) => {
                console.log("table data", value);
                return (
                    <b
                        style={{
                            color: "#5C677D",
                            display: "flex",
                            justifyContent: "flex-end",
                        }}
                    >
                        {currencyFormatter(value, "USD")}
                    </b>
                );
            },
        },
    },

    {
        name: "Balance",
        Label: "Balance",
        options: {
            filter: true,
            sort: false,
            customHeadLabelRender: () => (
                <span style={{ color: "white" }}>Balance</span>
            ),
            setCellHeaderProps: () => {
                return { style: { borderRight: "1px solid #D5D5D5" } };
            },
            setCellProps: () => {
                return {
                    style: {
                        borderRight: "1px solid #D5D5D5",
                        textAlign: "center",
                    },
                };
            },
            customBodyRender: (
                value: any,
                tableMeta: any,
                updateValue: any,
            ) => {
                console.log("table data", value);
                return (
                    <b
                        style={{
                            color: "#5C677D",
                            display: "flex",
                            justifyContent: "flex-end",
                        }}
                    >
                        {currencyFormatter(value, "USD")}
                    </b>
                );
            },
        },
    },
    {
        name: "Paid",
        Label: "Paid",
        options: {
            filter: true,
            sort: false,
            customHeadLabelRender: () => (
                <span style={{ color: "white" }}>Paid</span>
            ),
            setCellHeaderProps: () => {
                return { style: { borderRight: "1px solid #D5D5D5" } };
            },
            setCellProps: () => {
                return {
                    style: {
                        borderRight: "1px solid #D5D5D5",
                        textAlign: "center",
                    },
                };
            },
            customBodyRender: (
                value: any,
                tableMeta: any,
                updateValue: any,
            ) => {
                console.log("table data", value);
                return (
                    <b
                        style={{
                            color: "#5C677D",
                            display: "flex",
                            justifyContent: "flex-end",
                        }}
                    >
                        {currencyFormatter(value, "USD")}
                    </b>
                );
            },
        },
    },
    {
        name: "DatePaid",
        Label: "DatePaid",
        type: "string",
        options: {
            filter: true,
            sort: false,
            customHeadLabelRender: () => (
                <span style={{ color: "white" }}>Date Paid</span>
            ),
            setCellHeaderProps: () => {
                return { style: { borderRight: "1px solid #D5D5D5" } };
            },
            setCellProps: () => {
                return {
                    style: {
                        borderRight: "1px solid #D5D5D5",
                        textAlign: "center",
                    },
                };
            },
            customBodyRender: (
                value: any,
                tableMeta: any,
                updateValue: any,
            ) => {
                console.log("table data", value);
                return <b style={{ color: "#5C677D" }}>{value}</b>;
            },
        },
    },
    {
        name: "VoucherNo",
        Label: "VoucherNo",
        type: "string",
        options: {
            filter: true,
            sort: false,
            customHeadLabelRender: () => (
                <span style={{ color: "white" }}>Voucher No.</span>
            ),
            setCellHeaderProps: () => {
                return { style: { borderRight: "1px solid #D5D5D5" } };
            },
            setCellProps: () => {
                return {
                    style: {
                        borderRight: "1px solid #D5D5D5",
                        textAlign: "center",
                    },
                };
            },
            customBodyRender: (
                value: any,
                tableMeta: any,
                updateValue: any,
            ) => {
                console.log("table data", value);
                return <b style={{ color: "#5C677D" }}>{value}</b>;
            },
        },
    },
    {
        name: "CreatedOn",
        Label: "CreatedOn",
        type: "string",
        options: {
            filter: true,
            sort: false,
            customHeadLabelRender: () => (
                <span style={{ color: "white" }}>Created On</span>
            ),
            setCellHeaderProps: () => {
                return { style: { borderRight: "1px solid #D5D5D5" } };
            },
            setCellProps: () => {
                return {
                    style: {
                        borderRight: "1px solid #D5D5D5",
                        textAlign: "center",
                    },
                };
            },
            customBodyRender: (
                value: any,
                tableMeta: any,
                updateValue: any,
            ) => {
                console.log("table data", value);
                return <b style={{ color: "#5C677D" }}>{value}</b>;
            },
        },
    },
];

export default AmortizationSchema;
