const deductionQuerySV = `
mutation(
    $entity:String
    $ou:Int
    $service:String
    $User:String
    $languageId:Int
    $supplierCode:String
    $suppliername:String
    $merrorid:String
){
    deductionSV(
        entity:$entity
        ou:$ou
        service:$service
        User:$User
        languageId:$languageId
        supplierCode:$supplierCode
        suppliername:$suppliername
        merrorid:$merrorid
    ){
        ContractNumber
        ContractRevision
        Type
        Amount
        Date
        Description
        Designation
        Franchisee
        FranchiseeNo
        ItemNumber
        Approved
        MonthYear
    }
}
`;

export default deductionQuerySV;
