import { Grid } from "@material-ui/core";
import "Common/Main.scss";

const VisitSchema = [
    {
        name: "StatusReason",
        Label: "StatusReason",
        options: {
            filter: true,
            sort: false,
            customHeadLabelRender: () => (
                <span style={{ color: "white" }}>Status Reason</span>
            ),
            setCellHeaderProps: () => {
                return { style: { borderRight: "1px solid #D5D5D5" } };
            },
            setCellProps: () => {
                return {
                    style: {
                        borderRight: "1px solid #D5D5D5",
                        textAlign: "center",
                    },
                };
            },
            customBodyRender: (
                value: any,
                tableMeta: any,
                updateValue: any,
            ) => {
                console.log("table data", value);
                return <b className="green font-bolder">{value}</b>;
            },
        },
    },
    {
        name: "CompName",
        Label: "CompName",
        type: "string",
        options: {
            filter: true,
            sort: false,
            customHeadLabelRender: () => (
                <span style={{ color: "white" }}>Company Name</span>
            ),
            setCellHeaderProps: () => {
                return { style: { borderRight: "1px solid #D5D5D5" } };
            },
            setCellProps: () => {
                return {
                    style: {
                        borderRight: "1px solid #D5D5D5",
                        textAlign: "center",
                    },
                };
            },
            customBodyRender: (
                value: any,
                tableMeta: any,
                updateValue: any,
            ) => {
                console.log("table data", value);
                return <b className="grey2 font-bolder">{value}</b>;
            },
        },
    },
    {
        name: "BuildVisitBy",
        Label: "BuildVisitBy",
        options: {
            filter: true,
            sort: false,
            customHeadLabelRender: () => (
                <span style={{ color: "white" }}>Building - Visited By</span>
            ),
            setCellHeaderProps: () => {
                return { style: { borderRight: "1px solid #D5D5D5" } };
            },
            setCellProps: () => {
                return {
                    style: {
                        borderRight: "1px solid #D5D5D5",
                        textAlign: "center",
                    },
                };
            },
            customBodyRender: (
                value: {
                    building: string;
                    visitedBy: string;
                },
                tableMeta: any,
                updateValue: any,
            ) => {
                console.log("table data", value);
                return (
                    <Grid container>
                        <Grid item xs={12}>
                            <b className="blue font-bolder">{value.building}</b>
                        </Grid>
                        <Grid item xs={12}>
                            <b className="grey">{value.visitedBy}</b>
                        </Grid>
                    </Grid>
                );
            },
        },
    },
    {
        name: "DateOfVisit",
        Label: "DateOfVisit",
        options: {
            filter: true,
            sort: false,
            customHeadLabelRender: () => (
                <span style={{ color: "white" }}>Date Of Visit</span>
            ),
            setCellHeaderProps: () => {
                return { style: { borderRight: "1px solid #D5D5D5" } };
            },
            setCellProps: () => {
                return {
                    style: {
                        borderRight: "1px solid #D5D5D5",
                        textAlign: "center",
                    },
                };
            },
            customBodyRender: (
                value: any,
                tableMeta: any,
                updateValue: any,
            ) => {
                console.log("table data", value);
                return <b className="grey2 font-bolder">{value}</b>;
            },
        },
    },
    {
        name: "Vendor",
        Label: "Vendor",
        options: {
            filter: true,
            sort: false,
            customHeadLabelRender: () => (
                <span style={{ color: "white" }}>Vendor</span>
            ),
            setCellHeaderProps: () => {
                return { style: { borderRight: "1px solid #D5D5D5" } };
            },
            setCellProps: () => {
                return {
                    style: {
                        borderRight: "1px solid #D5D5D5",
                        textAlign: "center",
                    },
                };
            },
            customBodyRender: (
                value: any,
                tableMeta: any,
                updateValue: any,
            ) => {
                console.log("table data", value);
                return <b className="grey2 font-bolder">{value}</b>;
            },
        },
    },
    {
        name: "Note",
        Label: "Note",
        options: {
            filter: true,
            sort: false,
            customHeadLabelRender: () => (
                <span style={{ color: "white" }}>Note</span>
            ),
            setCellHeaderProps: () => {
                return { style: { borderRight: "1px solid #D5D5D5" } };
            },
            setCellProps: () => {
                return {
                    style: {
                        borderRight: "1px solid #D5D5D5",
                        textAlign: "center",
                    },
                };
            },
            customBodyRender: (
                value: any,
                tableMeta: any,
                updateValue: any,
            ) => {
                console.log("table data", value);
                return <b className="grey2 font-bolder">{value}</b>;
            },
        },
    },
];

export default VisitSchema;
