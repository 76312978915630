const userRoleQuery = `
query UserRoleQuery($userScreenRoleName: String!) {
    userScreen(roleName: $userScreenRoleName) {
      home
      dashboard
      attachNotes
      manage
      rfq
      quotation
      shipment
      view
      viewInvoice
      orders
      additionalDetails
      notification
      create
      uploadInvoice
    }
  }
  `;

export default userRoleQuery;
