import { Grid, Paper, Backdrop, CircularProgress } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import MUIButton from "Common/Button/Button";
import { connect } from "react-redux";
import useGetPosts from "ReactQuery/reactQuery";
import MuiDatatables from "Common/Mui-DataTables/MuiDatatables";
import { Redirect } from "react-router-dom";
import Dropdown from "Common/Dropdown/Dropdown";
import TextFields from "Common/TextField/TextField";
import CustomDatePickerBorder from "Common/DatePicker/DatePicker";
import { ContractHeaderQuerySV, ContractsQuerySV, StatusComboQuery } from "ReactQuery/Query";
import Alert from "Common/Alerts/Alerts";
import { makeStyles, Theme } from "@material-ui/core/styles";
import currencyFormatter from "Common/Utils/currencyFormatter";
import dateFormatter from "Common/Utils/dateFormatter";
interface DashCards {
    desc: string;
    value: string;
    visible: boolean;
    frequent: boolean;
    detail: {
        desc: string;
        value: number;
        color: string;
    }[];
}

const useStyles = makeStyles((theme: Theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
}));
interface BodyProps {
    currentContext: any;
    userName: any;
    selectedVendor: any;
    currency: any;
}
const Body = (props: BodyProps) => {
    const [header, setHeader] = useState<DashCards>();
    const classes = useStyles();
    const [contractDet, setContractDet] = useState<boolean>(false);
    const { currentContext, userName, selectedVendor, currency } = props;

    const [active, setActive] = useState("");

    const [showTable, setShowTable] = useState<boolean>(false);

    const [contractDetailsList, setContractDetailsList] = useState([]);

    const [contractSelected, setcontractSelected] = useState();

    const handleContractClick = (val: any) => {
        setContractDet(true);
        setcontractSelected(val);
    };
    const [popoverBlack, setPopoverBlack] = useState(false);
    const [alert, setAlert] = useState(false);
    const [errr, seterr] = useState({
        code: 0,
        msg: "",
    });
    const [statusList, setStatusList] = useState<any>([]);
    const [contractData, setContractData] = useState({
        fromDate: new Date(),
        toDate: new Date(),
        contractno: "",
        status: "",
    });

    const handleErrorAlert = (code: number, message: string) => {
        Alert({
            statusCode: code,
            message: message,
            willClose: () => {
                setAlert(false);
                seterr({ code: 0, msg: "" });
            },
        });
    };

    const {
        data: StatusComboData,
        error: StatusComboDataError,
        isLoading: StatusComboDataLoading,
    } = useGetPosts({
        name: `StatusComboQuery_${selectedVendor?.companyCode}_BPO_Contract`,
        query: StatusComboQuery,
        variables: {
            entity: "ow_getcombo_status",
            ou: currentContext?.ouId,
            service: "",
            user: userName,
            langId: currentContext?.langId,
            screen: "BPO_Contract",
            tranType: "",
            merrorid: "",
        },
    });

    useEffect(() => {
        if (StatusComboData?.statusCombo) {
            console.log("StatusData", StatusComboData?.statusCombo);
            setStatusList(StatusComboData?.statusCombo);
            setContractData((prevState: any) => {
                return {
                    ...prevState,
                    status: StatusComboData?.statusCombo[0]?.value,
                };
            });
        }
    }, [StatusComboData]);

    useEffect(() => {
        if (StatusComboDataError && !StatusComboDataLoading) {
            const data = JSON.parse(JSON.stringify(StatusComboDataError));
            const errmsg = data?.response?.errors[0].message;
            seterr({
                code: 400,
                msg: errmsg,
            });
            setAlert(true);
        }
    }, [StatusComboDataError, StatusComboDataLoading]);

    const {
        data: ContractsData,
        error: ContractsDataError,
        isLoading: ContractsDataLoading,
    } = useGetPosts({
        name: `ContractsQuerySV_get_data_${contractData?.status}_${contractData?.contractno}_${contractData?.fromDate}_${contractData?.toDate}_${active}_${selectedVendor?.companyCode}`,
        query: ContractsQuerySV,
        variables: {
            entity: "OW_Vendor_portal_contrHdr",
            ou: currentContext?.ouId,
            service: "",
            User: userName,
            languageId: currentContext?.langId,
            contractNo: contractData?.contractno,
            supplierCode: selectedVendor?.companyCode,
            suppliername: selectedVendor?.companyName,
            status: active === header?.detail[0]?.desc ? "AC" : contractData?.status,
            fromDate: dateFormatter("sqlDate", contractData?.fromDate),
            toDate: dateFormatter("sqlDate", contractData?.toDate),
            merrorid: "",
        },
        skip: active === header?.detail[0]?.desc ? false : !showTable
    });

    useEffect(() => {
        if (ContractsData?.GetContractsSV) {
            setContractDetailsList(ContractsData?.GetContractsSV);
        }
    }, [ContractsData]);

    useEffect(() => {
        if (ContractsDataError && !ContractsDataLoading) {
            const data = JSON.parse(JSON.stringify(ContractsDataError));
            const errmsg = data?.response?.errors[0].message;
            seterr({
                code: 400,
                msg: errmsg,
            });
            setAlert(true);
        }
    }, [ContractsDataError, ContractsDataLoading]);

    const {
        data: ContractHeaderData,
        error: ContractHeaderDataError,
        isLoading: ContractHeaderDataLoading,
    } = useGetPosts({
        name: `ContractHeaderQuerySV_get_data_${selectedVendor?.companyCode}`,
        query: ContractHeaderQuerySV,
        variables: {
            entity: "OW_Vendor_portal_status",
            ou: currentContext?.ouId,
            service: "",
            User: userName,
            languageId: currentContext?.langId,
            suppCode: selectedVendor?.companyCode,
            merrorid: "",
        },
    });

    useEffect(() => {
        if (ContractHeaderData?.GetContractHeaderSV) {
            setHeader(ContractHeaderData?.GetContractHeaderSV);
            setActive(ContractHeaderData?.GetContractHeaderSV?.detail[0]?.desc);
        }
    }, [ContractHeaderData]);

    useEffect(() => {
        if (ContractHeaderDataError && !ContractHeaderDataLoading) {
            const data = JSON.parse(JSON.stringify(ContractHeaderDataError));
            const errmsg = data?.response?.errors[0].message;
            seterr({
                code: 400,
                msg: errmsg,
            });
            setAlert(true);
        }
    }, [ContractHeaderDataError, ContractHeaderDataLoading]);

    const contractSchema = [
        {
            name: "Contract",
            Label: "Contract",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Contract</span>
                ),
                setCellHeaderProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                        },
                    };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                            padding: "15px 8px !important",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value, tableMeta.rowData);
                    return (
                        <b
                            className="blue"
                            style={{ cursor: "pointer" }}
                            onClick={() => handleContractClick(value)}
                        >
                            {value}
                        </b>
                    );
                },
            },
        },
        {
            name: "AccountNumber",
            Label: "Account Number",
            type: "string",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Account Number</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                            padding: "15px 8px !important",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return (
                        <b
                            style={{
                                color: "#5C677D",
                                // color: "red",
                            }}
                        >
                            {value}
                        </b>
                    );
                },
            },
        },
        {
            name: "Vendor",
            Label: "Vendor",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Vendor</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                            padding: "15px 8px !important",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return <b style={{ color: "#5C677D" }}>{value}</b>;
                },
            },
        },
        {
            name: "Region",
            Label: "Region",
            type: "string",
            options: {
                filter: true,
                sort: false,
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                            padding: "15px 8px !important",
                        },
                    };
                },
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Region</span>
                ),
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return <b style={{ color: "#5C677D" }}>{value}</b>;
                },
            },
        },

        // {
        //     name: "Designation",
        //     Label: "Designation",
        //     options: {
        //         filter: true,
        //         sort: false,
        //         customHeadLabelRender: () => (
        //             <span style={{ color: "white" }}>Designation</span>
        //         ),
        //         setCellHeaderProps: () => {
        //             return { style: { borderRight: "1px solid #D5D5D5" } };
        //         },
        //         setCellProps: () => {
        //             return { style: { borderRight: "1px solid #D5D5D5" } };
        //         },
        //         customBodyRender: (
        //             value: any,
        //             tableMeta: any,
        //             updateValue: any,
        //         ) => {
        //             console.log("table data", value);
        //             return <b style={{ color: "#5C677D" }}>{value}</b>;
        //         },
        //     },
        // },
        // {
        //     name: "ContractNumber",
        //     Label: "Contract Number",
        //     type: "number",
        //     options: {
        //         filter: true,
        //         sort: false,
        //         customHeadLabelRender: () => (
        //             <span style={{ color: "white" }}>Contract Number</span>
        //         ),
        //         setCellHeaderProps: () => {
        //             return { style: { borderRight: "1px solid #D5D5D5" } };
        //         },
        //         setCellProps: () => {
        //             return { style: { borderRight: "1px solid #D5D5D5" } };
        //         },
        //         customBodyRender: (
        //             value: any,
        //             tableMeta: any,
        //             updateValue: any,
        //         ) => {
        //             console.log("table data", value);
        //             return <b style={{ color: "#5C677D" }}>{value}</b>;
        //         },
        //     },
        // },

        {
            name: "ContractR",
            Label: "ContractR",
            type: "number",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Contract Revision No.</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                            padding: "15px 8px !important",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return <b style={{ color: "#5C677D" }}>{value}</b>;
                },
            },
        },

        {
            name: "PurchaseDate",
            Label: "Purchase Date",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Purchase Date</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                            padding: "15px 8px !important",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return <b style={{ color: "#5C677D" }}>{value}</b>;
                },
            },
        },
        {
            name: "StartEndDate",
            Label: "Start-End Date",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Start-End Date</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                            padding: "15px 8px !important",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return <b style={{ color: "#5C677D" }}>{value}</b>;
                },
            },
        },
        {
            name: "PackagePurchased",
            Label: "Package Purchased",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Package Purchased</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                            padding: "15px 8px !important",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return <b style={{ color: "#5C677D" }}>{value}</b>;
                },
            },
        },
        {
            name: "VolumePurchased",
            Label: "Volume Purchased",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Volume Purchased</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                            padding: "15px 8px !important",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return (
                        <b
                            style={{
                                color: "#5C677D",
                                display: "flex",
                                justifyContent: "flex-end",
                            }}
                        >
                            {currencyFormatter(value, currency)}
                        </b>
                    );
                },
            },
        },
        {
            name: "TotalCost",
            Label: "Total Cost",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Total Cost</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                            padding: "15px 8px !important",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return (
                        <b
                            style={{
                                color: "#5C677D",
                                display: "flex",
                                justifyContent: "flex-end",
                            }}
                        >
                            {currencyFormatter(value, currency)}
                        </b>
                    );
                },
            },
        },

        {
            name: "TargetFulfillment",
            Label: "Target Fulfillment",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Target Fulfillment</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                            padding: "15px 8px !important",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return (
                        <b
                            style={{
                                color: "#5C677D",
                                display: "flex",
                                justifyContent: "flex-end",
                            }}
                        >
                            {currencyFormatter(value, currency)}
                        </b>
                    );
                },
            },
        },
        {
            name: "VolumeOwned",
            Label: "Volume Owned",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Volume Owned</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                            padding: "15px 8px !important",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return (
                        <b
                            style={{
                                color: "#5C677D",
                                display: "flex",
                                justifyContent: "flex-end",
                            }}
                        >
                            {currencyFormatter(value, currency)}
                        </b>
                    );
                },
            },
        },
    ];

    const handleActive = (index: string) => {
        setActive(index);
        setShowTable(false);
        console.log("index", index);
        // setshipmentCardData((shipmentConstData as any)[index]);
    };

    const handleChange = (e: any) => {
        setShowTable(false);
        setContractData((prevState) => ({
            ...prevState,
            contractno: e?.target?.value,
        }));
    };

    const handleChangeStatus = (e: any) => {
        setShowTable(false);
        setContractData((prevState) => ({
            ...prevState,
            status: e?.target?.value,
        }));
    };

    const handleDate = (type: string, e: any) => {
        setShowTable(false);
        if (type === "toDate") {
            setContractData((prevstate: any) => ({
                ...prevstate,
                toDate: e,
            }));
        } else if (type === "fromDate") {
            setContractData((prevstate: any) => ({
                ...prevstate,
                fromDate: e,
            }));
        }
    };

    const handleSearch = () => {
        setShowTable(true);
    };

    if (contractDet) {
        return (
            <Redirect
                to={{
                    pathname: "/app/CONTDETSV",
                    state: {
                        contractSelected: contractSelected,
                    },
                }}
            />
        );
    }

    return (
        <>
            {alert ? handleErrorAlert(errr.code, errr.msg) : <></>}
            <Backdrop
                className={classes.backdrop}
                open={ContractsDataLoading || ContractHeaderDataLoading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Backdrop
                className={classes.backdrop}
                open={popoverBlack}
            ></Backdrop>

            <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} sm={12} style={{ paddingTop: "2%" }}>
                    {/* <Paper variant="outlined"> */}
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={12} sm={4} md={3}>
                            <Paper elevation={2}>
                                <Grid
                                    container
                                    spacing={2}
                                    style={{
                                        padding: "5% 0% 0% 0%",
                                    }}
                                    justifyContent="space-around"
                                >
                                    {header?.detail.map((element, index) => {
                                        return (
                                            <Grid
                                                item
                                                xs={4}
                                                sm={4}
                                                className={
                                                    active === element?.desc
                                                        ? `${element?.color}_border_bottom pointer`
                                                        : "pointer"
                                                }
                                                onClick={() =>
                                                    handleActive(element?.desc)
                                                }
                                            >
                                                <Grid container spacing={1}>
                                                    <Grid item xs={12} sm={12}>
                                                        <span
                                                            // className={`${element?.color} font-biggest font-boldest`}
                                                            className={
                                                                active ===
                                                                element?.desc
                                                                    ? `${element?.color} font-biggest font-boldest`
                                                                    : `grey font-biggest font-boldest`
                                                            }
                                                        >
                                                            {element?.value}
                                                        </span>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12}>
                                                        <span
                                                            className={
                                                                active ===
                                                                element?.desc
                                                                    ? `font-boldest font-smaller`
                                                                    : "grey font-smallest"
                                                            }
                                                        >
                                                            {element?.desc}
                                                        </span>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        );
                                    })}
                                </Grid>
                            </Paper>
                        </Grid>

                        {active === header?.detail[1]?.desc && (
                            <Grid item sm={12} md={8}>
                                <Paper elevation={2}>
                                    <Grid
                                        container
                                        spacing={2}
                                        style={{ padding: "2%" }}
                                    >
                                        <Grid item xs sm={10} style={{}}>
                                            <Grid
                                                container
                                                spacing={2}
                                                alignItems="center"
                                            >
                                                <Grid
                                                    item
                                                    sm
                                                    xs={12}
                                                    style={{}}
                                                >
                                                    {/* <Grid item xs sm={5}> */}
                                                    <TextFields
                                                        label={"Contract No."}
                                                        id={"contractno"}
                                                        value={
                                                            contractData?.contractno
                                                        }
                                                        handleChange={
                                                            handleChange
                                                        }
                                                        width={"100%"}
                                                        multiline={false}
                                                        rows={0}
                                                    />
                                                </Grid>
                                                <Grid
                                                    item
                                                    sm
                                                    xs={12}
                                                    style={{}}
                                                >
                                                    <Dropdown
                                                        label={"Status"}
                                                        id={"Status"}
                                                        value={
                                                            contractData?.status
                                                        }
                                                        list={statusList}
                                                        handleChange={
                                                            handleChangeStatus
                                                        }
                                                        width={"100%"}
                                                    />
                                                </Grid>
                                                <Grid item sm xs={2} style={{}}>
                                                    <CustomDatePickerBorder
                                                        label="From Date"
                                                        width="100%"
                                                        value={
                                                            contractData?.fromDate
                                                        }
                                                        onChange={(e) =>
                                                            handleDate(
                                                                "fromDate",
                                                                e,
                                                            )
                                                        }
                                                        format="MM/dd/yyyy"
                                                    />
                                                </Grid>
                                                <Grid item sm xs={2} style={{}}>
                                                    <CustomDatePickerBorder
                                                        label="To Date"
                                                        width="100%"
                                                        value={
                                                            contractData?.toDate
                                                        }
                                                        onChange={(e) =>
                                                            handleDate(
                                                                "toDate",
                                                                e,
                                                            )
                                                        }
                                                        format="MM/dd/yyyy"
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid item sm={2} xs={12}>
                                            <MUIButton
                                                label="Search"
                                                type="filled"
                                                font="font-small"
                                                onClick={handleSearch}
                                            />
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                        )}
                    </Grid>
                </Grid>

                {active === header?.detail[0]?.desc && (
                    <Grid
                        item
                        xs={12}
                        style={{ marginRight: "2%", paddingTop: "2%" }}
                    >
                        <MuiDatatables
                            header={contractSchema}
                            title={""}
                            // rowData={checklistDetailsList}
                            detail={[
                                contractDetailsList,
                                setContractDetailsList,
                            ]}
                            // handleAddSideEffect={handleAddSideEffect}
                            // handleDeleteSideEffect={handleDeleteSideEffect}
                            // handleSelectedSideEffect={handleSelectedSideEffect}
                            // initRow={true}
                        />
                    </Grid>
                )}

                {active === header?.detail[1]?.desc && showTable && (
                    <Grid
                        item
                        xs={12}
                        style={{ marginRight: "2%", paddingTop: "2%" }}
                    >
                        <MuiDatatables
                            header={contractSchema}
                            title={""}
                            // rowData={checklistDetailsList}
                            detail={[
                                contractDetailsList,
                                setContractDetailsList,
                            ]}
                            // handleAddSideEffect={handleAddSideEffect}
                            // handleDeleteSideEffect={handleDeleteSideEffect}
                            // handleSelectedSideEffect={handleSelectedSideEffect}
                            // initRow={true}
                        />
                    </Grid>
                )}
            </Grid>
        </>
    );
};

const mapStateToProps = (state: any) => {
    return {
        currentContext: state.context?.userDefaults,
        userName: state.context?.name,
        selectedVendor: state.selectedVendor,
        currency: state.currency,
    };
};

export default connect(mapStateToProps)(Body);
