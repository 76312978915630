const ContractHeaderQuerySV = `
    mutation(
        $entity:String
        $ou:Int
        $service:String
        $User:String
        $languageId:Int
        $suppCode:String
        $merrorid:String

    ){
        GetContractHeaderSV(
        entity:$entity
        ou:$ou
        service:$service
        User: $User
        languageId:$languageId
        suppCode: $suppCode
        merrorid: $merrorid
        ){
            desc
            frequent
            value
            visible
            detail {
                desc
                value
                color
            }
        }
    }
`;

export default ContractHeaderQuerySV;
