import { STORE_CUSTOMER_DETAILS } from "../../actionTypes";

const storeCustomerDetails = (data: any) => {
    return {
        type: STORE_CUSTOMER_DETAILS,
        payload: data,
    };
};

/**
 * Common File redux
 * @param code
 */
export function storeSelectedCustomer<T>(data: any) {
    return (dispatch: any) => {
        dispatch(storeCustomerDetails(data));
    };
}
