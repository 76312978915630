const headerPayableQuerySV = `
mutation(
    $entity:String
    $ou:Int
    $service:String
    $User:String
    $languageId:Int
    $suppCode:String
    $merrorid:String
){
    headerPayableSV
    (
        entity:$entity
        ou:$ou
        service:$service
        User: $User
        languageId: $languageId
        suppCode: $suppCode
        merrorid: $merrorid
    )
    {
        desc
        value
        visible
        frequent
        detail
        {
            desc
            count
            color
        }
    }
}
`;

export default headerPayableQuerySV;
