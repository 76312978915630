import { Grid } from "@material-ui/core";

const COGSSchema = [
    {
        name: "Name",
        Label: "Name",
        options: {
            filter: true,
            sort: false,
            customHeadLabelRender: () => (
                <span style={{ color: "white" }}>Name</span>
            ),
            setCellHeaderProps: () => {
                return { style: { borderRight: "1px solid #D5D5D5" } };
            },
            setCellProps: () => {
                return { style: { borderRight: "1px solid #D5D5D5" } };
            },
            customBodyRender: (
                value: any,
                tableMeta: any,
                updateValue: any,
            ) => {
                console.log("table data", value, tableMeta.rowData);
                return <b style={{ color: "#0073E6" }}>{value}</b>;
            },
        },
    },
    {
        name: "Customer",
        Label: "Customer",

        options: {
            filter: true,
            sort: false,
            customHeadLabelRender: () => (
                <span style={{ color: "white" }}>Customer</span>
            ),
            setCellHeaderProps: () => {
                return { style: { borderRight: "1px solid #D5D5D5" } };
            },
            setCellProps: () => {
                return {
                    style: {
                        borderRight: "1px solid #D5D5D5",
                        // width: "10%",
                    },
                };
            },
            customBodyRender: (
                value: {
                    line1: string;
                    line2: string;
                   

                },
                tableMeta: any,
                updateValue: any,
            ) => {
                console.log("table data", value);
                return (
                    <Grid container>
                        <Grid item xs={12}>
                            <b
                                // style={{ color: "#0073E6" }}
                                // onClick={
                                //     (value) => {}
                                //     // handleBuildingClick()
                                // }
                                className="grey2 font-bolder "
                            >
                                {value.line1}
                            </b>
                        </Grid>
                        <Grid item xs={12}>
                            <b className="grey ">
                                {value.line2}
                            </b>
                        </Grid>
                    </Grid>
                );
            },
        },
    },
    {
        name: "Order",
        Label: "Order",
        type: "string",
        options: {
            filter: true,
            sort: false,
            customHeadLabelRender: () => (
                <span style={{ color: "white" }}>Order</span>
            ),
            setCellHeaderProps: () => {
                return { style: { borderRight: "1px solid #D5D5D5" } };
            },
            setCellProps: () => {
                return { style: { borderRight: "1px solid #D5D5D5" } };
            },
            customBodyRender: (
                value: any,
                tableMeta: any,
                updateValue: any,
            ) => {
                console.log("table data", value);
                return <b style={{ color: "#0073E6" }}>{value}</b>;
            },
        },
    },
    {
        name: "Assignment",
        Label: "Assignment",
        options: {
            filter: true,
            sort: false,
            customHeadLabelRender: () => (
                <span style={{ color: "white" }}>Assignment</span>
            ),
            setCellHeaderProps: () => {
                return { style: { borderRight: "1px solid #D5D5D5" } };
            },
            setCellProps: () => {
                return { style: { borderRight: "1px solid #D5D5D5" } };
            },
            customBodyRender: (
                value: any,
                tableMeta: any,
                updateValue: any,
            ) => {
                console.log("table data", value);
                return <b style={{ color: "#0073E6" }}>{value}</b>;
            },
        },
    },
    

    {
        name: "GLRef",
        Label: "GLRef",
        options: {
            filter: true,
            sort: false,
            customHeadLabelRender: () => (
                <span style={{ color: "white" }}>GL Ref.</span>
            ),
            setCellHeaderProps: () => {
                return { style: { borderRight: "1px solid #D5D5D5" } };
            },
            setCellProps: () => {
                return { style: { borderRight: "1px solid #D5D5D5" } };
            },
            customBodyRender: (
                value: any,
                tableMeta: any,
                updateValue: any,
            ) => {
                console.log("table data", value);
                return <b style={{ color: "#5C677D" }}>{value}</b>;
            },
        },
    },
    {
        name: "COGsDetails",
        Label: "COGsDetails",
        type: "string",
        options: {
            filter: true,
            sort: false,
            customHeadLabelRender: () => (
                <span style={{ color: "white" }}>COGs Details</span>
            ),
            setCellHeaderProps: () => {
                return { style: { borderRight: "1px solid #D5D5D5" } };
            },
            setCellProps: () => {
                return { style: { borderRight: "1px solid #D5D5D5" } };
            },
            customBodyRender: (
                value: {
                    line1: string;
                    line2: string;
                   

                },
                tableMeta: any,
                updateValue: any,
            ) => {
                console.log("table data", value);
                return (
                    <Grid container>
                        <Grid item xs={12}>
                            <b
                                // style={{ color: "#0073E6" }}
                                // onClick={
                                //     (value) => {}
                                //     // handleBuildingClick()
                                // }
                                className="green font-bolder "
                            >
                                {value.line1}
                            </b>
                        </Grid>
                        <Grid item xs={12}>
                            <b className="grey ">
                                {value.line2}
                            </b>
                        </Grid>
                    </Grid>
                );
            },
        },
    },
    {
        name: "Proccessed",
        Label: "Proccessed",
        options: {
            filter: true,
            sort: false,
            customHeadLabelRender: () => (
                <span style={{ color: "white" }}>Proccessed?</span>
            ),
            setCellHeaderProps: () => {
                return { style: { borderRight: "1px solid #D5D5D5" } };
            },
            setCellProps: () => {
                return { style: { borderRight: "1px solid #D5D5D5" } };
            },
            customBodyRender: (
                value: any,
                tableMeta: any,
                updateValue: any,
            ) => {
                console.log("table data", value);
                return  value==="Yes"?
                <>
                <b className="Completed_dot" ></b>     
                <b style={{ color: "#00AE50", padding:"3%" }}>{value}</b>

                </>
                :
                <>
                <b className="Failed_dot" ></b>     
                <b style={{ color: "#E01E37",padding:"3%"  }}>{value}</b>
                  </>
            },
        },
    },
    {
        name: "Desc",
        Label: "Desc",
        options: {
            filter: true,
            sort: false,
            customHeadLabelRender: () => (
                <span style={{ color: "white" }}>Desc.</span>
            ),
            setCellHeaderProps: () => {
                return { style: { borderRight: "1px solid #D5D5D5" } };
            },
            setCellProps: () => {
                return { style: { borderRight: "1px solid #D5D5D5" } };
            },
            customBodyRender: (
                value: any,
                tableMeta: any,
                updateValue: any,
            ) => {
                console.log("table data", value);
                return <b style={{ color: "#5C677D" }}>{value}</b>;
            },
        },
    },
    {
        name: "VendorAmt",
        Label: "VendorAmt",
        options: {
            filter: true,
            sort: false,
            customHeadLabelRender: () => (
                <span style={{ color: "white" }}>Vendor Amt.</span>
            ),
            setCellHeaderProps: () => {
                return { style: { borderRight: "1px solid #D5D5D5" } };
            },
            setCellProps: () => {
                return { style: { borderRight: "1px solid #D5D5D5" } };
            },
            customBodyRender: (
                value: {
                    line1: string;
                    line2: string;
                   

                },
                tableMeta: any,
                updateValue: any,
            ) => {
                console.log("table data", value);
                return (
                    <Grid container >
                        <Grid item xs={12}>
                            <b
                                // style={{ color: "#0073E6" }}
                                // onClick={
                                //     (value) => {}
                                //     // handleBuildingClick()
                                // }
                                className="grey2 font-bolder "
                            >
                                {value.line1}
                            </b>
                        </Grid>
                        <Grid item xs={12}>
                            <b className="grey " >
                                {value.line2}
                            </b>
                        </Grid>
                    </Grid>
                );
            },
        },
    },
    {
        name: "VendorNetAmt",
        Label: "VendorNetAmt",
        options: {
            filter: true,
            sort: false,
            customHeadLabelRender: () => (
                <span style={{ color: "white" }}>Vendor Net Amt.</span>
            ),
            setCellHeaderProps: () => {
                return { style: { borderRight: "1px solid #D5D5D5" } };
            },
            setCellProps: () => {
                return { style: { borderRight: "1px solid #D5D5D5" } };
            },
            customBodyRender: (
                value: any,
                tableMeta: any,
                updateValue: any,
            ) => {
                console.log("table data", value);
                return <b style={{ color: "#5C677D" }}>{value}</b>;
            },
        },
    },
    {
        name: "CreatedOn",
        Label: "CreatedOn",
        options: {
            filter: true,
            sort: false,
            customHeadLabelRender: () => (
                <span style={{ color: "white" }}>Created On</span>
            ),
            setCellHeaderProps: () => {
                return { style: { borderRight: "1px solid #D5D5D5" } };
            },
            setCellProps: () => {
                return { style: { borderRight: "1px solid #D5D5D5" } };
            },
            customBodyRender: (
                value: any,
                tableMeta: any,
                updateValue: any,
            ) => {
                console.log("table data", value);
                return <b style={{ color: "#5C677D" }}>{value}</b>;
            },
        },
    },
];

export default COGSSchema;
