const AssignmentQuerySV = `
mutation(
    $entity:String
    $ou:Int
    $service:String
    $User:String
    $languageId:Int
    $suppCode:String
    $suppName:String
    $pono:String
    $status:String
    $fromdate:String
    $todate:String
    $merrorid:String
){
AssignmentQuerySV(
    entity:$entity
    ou:$ou
    service:$service
    User:$User
    languageId:$languageId
    suppCode:$suppCode
    suppName:$suppName
    pono:$pono
    status:$status
    fromdate:$fromdate
    todate:$todate
    merrorid:$merrorid
) {
    Assignment
    ContractID
    VendorContract
    Building
    BuildingID
    Customer
    Contract
    StartEndDate
    VendorAmt
    ServiceCode
    VendorNetAmount
    Percent
    Replace
    FirstMonth
    LastMonth
  }
}`;

export default AssignmentQuerySV;
