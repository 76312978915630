const BuildDetContactQuery = `
mutation(
    $entity: String
    $ou: Int
    $service: String
    $User: String
    $languageId: Int
    $customerCode: String
    $customerName: String
    $siteId: String
    $buildName: String
    $merrorid: String
) {
    BuildDetContactQuery(
        entity: $entity
        ou: $ou
        service: $service
        User: $User
        languageId: $languageId
        customerCode: $customerCode
        customerName: $customerName
        siteId: $siteId
        buildName: $buildName
        merrorid: $merrorid
    ) {
        AccNo
        CompName
        NameJobTitle{
            title
            name
        }
        Address{
            name
            city
            state
            pincode
        }
    }
}`;

export default BuildDetContactQuery;
