const AssignmentHeaderQuerySV = `
mutation(
    $entity:String
        $ou:Int
        $service:String
        $User:String
        $languageId:Int
        $suppCode:String
        $suppName:String
        $merrorid:String
){
AssignmentHeaderQuerySV(
    entity:$entity
    ou:$ou
    service:$service
    User: $User
    languageId:$languageId
    suppCode: $suppCode
    suppName:$suppName
    merrorid: $merrorid
) {
    desc
    frequent
    value
    visible
    detail {
        desc
        value
        color
    }
}
}`;

export default AssignmentHeaderQuerySV;
