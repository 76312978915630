import NavBar from "Common/NavBar/NavBar";
import {
    Backdrop,
    Button,
    CircularProgress,
    Container,
    Grid,
} from "@material-ui/core";
import axios from "axios";
import Configuration from "Configuration";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import ChildComponent from "./ChildComponent";
import sessionsSVG from "./Images/session.svg";

const ApplicationLayout = (props: any) => {
    const { session } = props;
    const [login, setLogin] = useState(false);
    const [timerCheck, setTimerCheck] = useState(true);

    useEffect(() => {
        (async () => {
            try {
                const response = await axios(
                    `${Configuration.getConfiguration().getAppAPILocation()}/api/auth/checksession?sId=${session}`,
                );
                if (response.status === 200) {
                    console.log("Session is valid", session);
                    setLogin(true);
                }
            } catch (error) {
                console.log(
                    "Context Error ==> Inside APplication layout",
                    error,
                );
            }
        })();
    }, []);

    const handleClick = () => {
        localStorage.removeItem("state");
        localStorage.removeItem("session");
        window.location.replace(
            `${Configuration.getConfiguration().getWebAppLocation()}`,
        );
    };

    useEffect(() => {
        setTimeout(() => {
            setTimerCheck(false);
        }, 1000);
    }, []);

    if (timerCheck)
        return (
            <Backdrop
                style={{
                    zIndex: 1,
                    color: "#fff",
                }}
                open={true}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        );

    if (!login)
        return (
            <>
                <Container maxWidth="sm">
                    <Grid container>
                        <Grid item md={12} sm={12} xs={12}>
                            <img
                                src={sessionsSVG}
                                style={{
                                    display: "block",
                                    marginTop: "30%",
                                    marginLeft: "auto",
                                    marginRight: "auto",
                                    width: "70%",
                                }}
                                alt="Unauthenticated/MultipleSessions"
                            />
                        </Grid>
                        <br />
                        <br />
                        <Grid
                            item
                            md={12}
                            sm={12}
                            xs={12}
                            style={{ textAlign: "center", marginTop: "3%" }}
                        >
                            <Grid>
                                <Grid item md={12} sm={12} xs={12}>
                                    <h2>
                                        No Valid Session found, Please login to
                                        continue.
                                    </h2>
                                </Grid>
                                <br />
                                <Grid
                                    item
                                    md={12}
                                    sm={12}
                                    xs={12}
                                    justify="center"
                                >
                                    <Button
                                        id="logout"
                                        color="primary"
                                        variant="contained"
                                        onClick={handleClick}
                                    >
                                        {" "}
                                        Login
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </>
        );
    return (
        <>
            <NavBar Child={<ChildComponent />} />
        </>
    );
};

const mapStateToProps = (state: any) => {
    return {
        session: state.sessionId,
    };
};

export default connect(mapStateToProps)(ApplicationLayout);

// import NavBar from "Common/NavBar/NavBar";
// import SwipeableDrawerComp from "Common/SideDrawer/SideDrawer";
// import React, { useState } from "react";
// import ChildComponent from "./ChildComponent";

// const ApplicationLayout = () => {
//     return (
//         <>
//             <NavBar Child={<ChildComponent />} />
//         </>
//     );
// };

// export default ApplicationLayout;
