import React, { useEffect, useState } from "react";
import { Grid, Paper, Backdrop, CircularProgress } from "@material-ui/core";
import useGetPosts from "ReactQuery/reactQuery";
import {
    BuildDetAssgnQuery,
    BuildDetContactQuery,
    BuildDetContractQuery,
    BuildDetExceptionsQuery,
    BuildDetQuery,
    BuildDetServiceQuery,
    BuildDetVendorsQuery,
    BuildDetVisitQuery,
} from "ReactQuery/Query";
import ButtonGroups from "Common/ButtonGroup/ButtonGroup";
import MuiDatatables from "Common/Mui-DataTables/MuiDatatables";
import ContactSchema from "./Schemas/Contact";
import VisitSchema from "./Schemas/Visits";
import { ActiveRecurring } from "./Schemas/ActiveRecurring";
import ServiceDaysSchema from "./Schemas/ServiceDays";
import BuildDetailsAssgnSchema from "./Schemas/Assignment";
import { Redirect, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { makeStyles, Theme } from "@material-ui/core/styles";
import Alert from "Common/Alerts/Alerts";
import { Apartment, ContactlessTwoTone, Phone } from "@material-ui/icons";
import Icons from "Common/Icons/Icons";
import currencyFormatter from "Common/Utils/currencyFormatter";
import AssignmentCards from "Components/ContractsSV/AssigmentRefusal";

const useStyles = makeStyles((theme: Theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
}));
interface buildDet {
    Building: {
        name: String;
        id: String;
    };
    Address: {
        Area: String;
        City: String;
        State: String;
        Pincode: Number;
    };
    Phone: String;
    Status: String;
    PrimaryContact: String;
    AccountManager1: String;
    AccountManager2: String;
    AccountManager3: String;
    ContactFreq: String;
    Region: String;
    Latitude: String;
    Longitude: String;
}

interface ExceptionInfo {
    BuildingDetails: {
        TotalSF: String;
        CleanableSF: String;
        TotalMonthlyBilling: String;
        LastUpdate: String;
    };
    Exception: {
        StartDate: String;
        EndDate: String;
    };
}

const BuildingDetails = (props: any) => {
    const { currentContext, userName, selectedCustomer, currency } = props;
    const redirectData = useLocation<any>();
    const siteId = redirectData?.state?.siteId;
    const classes = useStyles();
    const controlList = [
        {
            desc: "General",
            value: "General",
        },
        {
            desc: "Contract",
            value: "Contract",
        },
        {
            desc: "Assignment",
            value: "Assignment",
        },
    ];

    const verticarbarList = [
        {
            desc: "Contacts",
            value: "Contacts",
        },
        {
            desc: "Visits",
            value: "Visits",
        },
        {
            desc: "Active Recurring Vendors",
            value: "Active Recurring Vendors",
        },
        {
            desc: "Service Days",
            value: "Service Days",
        },
        {
            desc: "Details & Exceptions",
            value: "Details & Exceptions",
        },
    ];
    const [selectedAssignment, setSelectedAssignment] = useState(
        verticarbarList[0]?.desc,
    );
    const [AssignmentComp, setAssignmentComp] = useState<any>(verticarbarList);
    const [buildDet, setBuildDet] = useState<buildDet[]>([]);
    const [contractInfo, setcontractInfo] = useState([]);
    const [generalInfo, setgeneralInfo] = useState([]);
    const [AssignmentInfo, setAssignmentInfo] = useState([]);
    const [visitsInfo, setvisitsInfo] = useState([]);
    const [servicedaysInfo, setservicedaysInfo] = useState([]);
    const [exceptioninfo, setexceptioninfo] = useState<ExceptionInfo[]>([]);
    const [activerecurringInfo, setactiverecurringInfo] = useState("");
    const [activeList, setActiveList] = useState(controlList[0].desc);
    const [toggle, setToggle] = useState(false);
    const [contractSelected, setcontractSelected] = useState();
    const [popoverBlack, setPopoverBlack] = useState(false);
    const [alert, setAlert] = useState(false);
    const [errr, seterr] = useState({
        code: 0,
        msg: "",
    });

    const handleErrorAlert = (code: number, message: string) => {
        Alert({
            statusCode: code,
            message: message,
            willClose: () => {
                setAlert(false);
                seterr({ code: 0, msg: "" });
            },
        });
    };

    const handleDocList = (listType: string) => {
        console.log("listType", listType);
        setActiveList(listType);
    };

    const {
        data: BuildDetData,
        error: BuildDetError,
        isLoading: BuildDetLoading,
    } = useGetPosts({
        name: `BuildDetQuery_${siteId}`,
        query: BuildDetQuery,
        variables: {
            entity: "OW_Cust_portal_BuildingHdr",
            ou: currentContext?.ouId,
            service: "",
            User: userName,
            languageId: currentContext?.langId,
            customerCode: selectedCustomer?.companyCode,
            customerName: selectedCustomer?.companyName,
            siteId: siteId,
            merrorid: "",
        },
    });
    useEffect(() => {
        if (BuildDetData?.BuildDetQuery) {
            setBuildDet(BuildDetData?.BuildDetQuery);
        }
    }, [BuildDetData]);

    useEffect(() => {
        if (BuildDetError && !BuildDetLoading) {
            const data = JSON.parse(JSON.stringify(BuildDetError));
            const errmsg = data?.response?.errors[0].message;
            seterr({
                code: 400,
                msg: errmsg,
            });
            setAlert(true);
        }
    }, [BuildDetError, BuildDetLoading]);

    const {
        data: BuildDetContractData,
        error: BuildDetContractError,
        isLoading: BuildDetContractLoading,
    } = useGetPosts({
        name: `BuildDetContractQuery_${siteId}`,
        query: BuildDetContractQuery,
        variables: {
            entity: "OW_Cust_portal_buildCntcts",
            ou: currentContext?.ouId,
            service: "",
            User: userName,
            languageId: currentContext?.langId,
            customerCode: selectedCustomer?.companyCode,
            customerName: selectedCustomer?.companyName,
            siteId: siteId,
            buildName: "",
            contractNo: "",
            merrorid: "",
        },
        skip: !(activeList === "Contract"),
    });

    useEffect(() => {
        if (BuildDetContractData?.BuildDetContractQuery) {
            setcontractInfo(BuildDetContractData?.BuildDetContractQuery);
        }
    }, [BuildDetContractData]);

    useEffect(() => {
        if (BuildDetContractError && !BuildDetContractLoading) {
            const data = JSON.parse(JSON.stringify(BuildDetContractError));
            const errmsg = data?.response?.errors[0].message;
            seterr({
                code: 400,
                msg: errmsg,
            });
            setAlert(true);
        }
    }, [BuildDetContractError, BuildDetContractLoading]);

    const {
        data: BuildDetAssgnData,
        error: BuildDetAssgnError,
        isLoading: BuildDetAssgnLoading,
    } = useGetPosts({
        name: `BuildDetAssgnQuery_${siteId}`,
        query: BuildDetAssgnQuery,
        variables: {
            entity: "OW_Cust_portal_Buildings_Assignments",
            ou: currentContext?.ouId,
            service: "",
            User: userName,
            languageId: currentContext?.langId,
            customerCode: selectedCustomer?.companyCode,
            customerName: selectedCustomer?.companyName,
            siteId: siteId,
            buildName: "",
            contractNo: "",
            merrorid: "",
        },
        skip: !(activeList === "Assignment"),
    });

    useEffect(() => {
        if (BuildDetAssgnData?.BuildDetAssgnQuery) {
            setAssignmentInfo(BuildDetAssgnData?.BuildDetAssgnQuery);
        }
    }, [BuildDetAssgnData]);

    useEffect(() => {
        if (BuildDetAssgnError && !BuildDetAssgnLoading) {
            const data = JSON.parse(JSON.stringify(BuildDetAssgnError));
            const errmsg = data?.response?.errors[0].message;
            seterr({
                code: 400,
                msg: errmsg,
            });
            setAlert(true);
        }
    }, [BuildDetAssgnError, BuildDetAssgnLoading]);

    const {
        data: buildDetContactData,
        error: buildDetContactError,
        isLoading: buildDetContactLoading,
    } = useGetPosts({
        name: `BuildDetContactQuery_${siteId}`,
        query: BuildDetContactQuery,
        variables: {
            entity: "OW_Cust_portal_BuildGenCntcs",
            ou: currentContext?.ouId,
            service: "",
            User: userName,
            languageId: currentContext?.langId,
            customerCode: selectedCustomer?.companyCode,
            customerName: selectedCustomer?.companyName,
            siteId: siteId,
            buildName: "",
            merrorid: "",
        },
        skip: !(activeList === "General" && selectedAssignment === "Contacts"),
    });

    useEffect(() => {
        if (buildDetContactData?.BuildDetContactQuery) {
            setgeneralInfo(buildDetContactData?.BuildDetContactQuery);
        }
    }, [buildDetContactData]);

    useEffect(() => {
        if (buildDetContactError && !buildDetContactLoading) {
            const data = JSON.parse(JSON.stringify(buildDetContactError));
            const errmsg = data?.response?.errors[0].message;
            seterr({
                code: 400,
                msg: errmsg,
            });
            setAlert(true);
        }
    }, [buildDetContactError, buildDetContactLoading]);

    const {
        data: BuildDetVisitData,
        error: BuildDetVisitError,
        isLoading: BuildDetVisitLoading,
    } = useGetPosts({
        name: `BuildDetVisitQuery_${siteId}`,
        query: BuildDetVisitQuery,
        variables: {
            entity: "OW_Cust_portal_BuildingVisits",
            ou: currentContext?.ouId,
            service: "",
            User: userName,
            languageId: currentContext?.langId,
            customerCode: selectedCustomer?.companyCode,
            customerName: selectedCustomer?.companyName,
            siteId: siteId,
            buildName: "",
            merrorid: "",
        },
        skip: !(activeList === "General" && selectedAssignment === "Visits"),
    });

    useEffect(() => {
        if (BuildDetVisitData?.BuildDetVisitQuery) {
            setvisitsInfo(BuildDetVisitData?.BuildDetVisitQuery);
        }
    }, [BuildDetVisitData]);

    useEffect(() => {
        if (BuildDetVisitError && !BuildDetVisitLoading) {
            const data = JSON.parse(JSON.stringify(BuildDetVisitError));
            const errmsg = data?.response?.errors[0].message;
            seterr({
                code: 400,
                msg: errmsg,
            });
            setAlert(true);
        }
    }, [BuildDetVisitError, BuildDetVisitLoading]);

    const {
        data: BuildDetServiceData,
        error: BuildDetServiceError,
        isLoading: BuildDetServiceLoading,
    } = useGetPosts({
        name: `BuildDetServiceQuery_${siteId}`,
        query: BuildDetServiceQuery,
        variables: {
            entity: "OW_Cust_portal_servicedays",
            ou: currentContext?.ouId,
            service: "",
            User: userName,
            languageId: currentContext?.langId,
            customerCode: selectedCustomer?.companyCode,
            customerName: selectedCustomer?.companyName,
            siteId: siteId,
            buildName: "",
            contractNo: "",
            merrorid: "",
        },
        skip: !(
            activeList === "General" && selectedAssignment === "Service Days"
        ),
    });

    useEffect(() => {
        if (BuildDetServiceData?.BuildDetServiceQuery) {
            setservicedaysInfo(BuildDetServiceData?.BuildDetServiceQuery);
        }
    }, [BuildDetServiceData]);

    useEffect(() => {
        if (BuildDetServiceError && !BuildDetServiceLoading) {
            const data = JSON.parse(JSON.stringify(BuildDetServiceError));
            const errmsg = data?.response?.errors[0].message;
            seterr({
                code: 400,
                msg: errmsg,
            });
            setAlert(true);
        }
    }, [BuildDetServiceError, BuildDetServiceLoading]);

    const {
        data: BuildDetExceptionsData,
        error: BuildDetExceptionsError,
        isLoading: BuildDetExceptionsLoading,
    } = useGetPosts({
        name: ` BuildDetExceptionsQuery_${siteId}`,
        query: BuildDetExceptionsQuery,
        variables: {
            entity: "OW_Cust_portal_biildexp",
            ou: currentContext?.ouId,
            service: "",
            User: userName,
            languageId: currentContext?.langId,
            customerCode: selectedCustomer?.companyCode,
            customerName: selectedCustomer?.companyName,
            siteId: siteId,
            buildName: "",
            contractNo: "",
            merrorid: "",
        },
        skip: !(
            activeList === "General" &&
            selectedAssignment === "Details & Exceptions"
        ),
    });

    useEffect(() => {
        if (BuildDetExceptionsData?.BuildDetExceptionsQuery) {
            setexceptioninfo(BuildDetExceptionsData?.BuildDetExceptionsQuery);
        }
    }, [BuildDetExceptionsData]);

    useEffect(() => {
        if (BuildDetExceptionsError && !BuildDetExceptionsLoading) {
            const data = JSON.parse(JSON.stringify(BuildDetExceptionsError));
            const errmsg = data?.response?.errors[0].message;
            seterr({
                code: 400,
                msg: errmsg,
            });
            setAlert(true);
        }
    }, [BuildDetExceptionsError, BuildDetExceptionsLoading]);

    const {
        data: BuildDetVendorsData,
        error: BuildDetVendorsError,
        isLoading: BuildDetVendorsLoading,
    } = useGetPosts({
        name: `BuildDetVendorsQuery_${siteId}`,
        query: BuildDetVendorsQuery,
        variables: {
            entity: "OW_Cust_portal_buildGenARV",
            ou: currentContext?.ouId,
            service: "",
            User: userName,
            languageId: currentContext?.langId,
            customerCode: selectedCustomer?.companyCode,
            customerName: selectedCustomer?.companyName,
            siteId: siteId,
            buildName: "",
            merrorid: "",
        },
        // skip: !(
        //     activeList === "General" &&
        //     selectedAssignment === "Active Recurring Vendors"
        // ),
    });

    useEffect(() => {
        if (BuildDetVendorsData?.BuildDetVendorsQuery) {
            setactiverecurringInfo(BuildDetVendorsData?.BuildDetVendorsQuery);
        }
    }, [BuildDetVendorsData]);

    useEffect(() => {
        if (BuildDetVendorsError && !BuildDetVendorsLoading) {
            const data = JSON.parse(JSON.stringify(BuildDetVendorsError));
            const errmsg = data?.response?.errors[0].message;
            seterr({
                code: 400,
                msg: errmsg,
            });
            setAlert(true);
        }
    }, [BuildDetVendorsError, BuildDetVendorsLoading]);

    if (toggle) {
        return (
            <Redirect
                to={{
                    pathname: "/app/CONTDET",
                    state: {
                        contractSelected: contractSelected,
                        siteId: "",
                    },
                }}
            />
        );
    }

    const handleClick = (val: any) => {
        setcontractSelected(val);
        setToggle((prevState) => !prevState);
    };

    const handleSelect = (data: any) => {
        setSelectedAssignment(data.desc);
        console.log("selectedAssignment", data);
    };

    const ContractSchema = [
        {
            name: "ContactID",
            Label: "ContactID",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Contact ID</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return (
                        <b
                            className="blue font-bolder"
                            style={{ cursor: "pointer" }}
                            onClick={() => handleClick(value)}
                        >
                            {value}
                        </b>
                    );
                },
            },
        },
        {
            name: "BuildingDetails",
            Label: "BuildingDetails",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Building Details</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return <b className="blue font-bolder">{value}</b>;
                },
            },
        },
        {
            name: "BuildingID",
            Label: "BuildingID",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Building ID</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return <b className="grey">{value}</b>;
                },
            },
        },
        {
            name: "City",
            Label: "City",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>City</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return <b className="grey">{value}</b>;
                },
            },
        },
        {
            name: "Contracts",
            Label: "Contracts",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Contracts</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return <b className="grey2 font-bolder">{value}</b>;
                },
            },
        },
        {
            name: "Revision",
            Label: "Revision",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Revision</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return <b className="grey2 font-bolder">{value}</b>;
                },
            },
        },
        {
            name: "RevisionStartEnd",
            Label: "RevisionStartEnd",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Revision Start-End</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return <b className="grey2 font-bolder">{value}</b>;
                },
            },
        },
        {
            name: "TotalAmt",
            Label: "TotalAmt",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Total Amount</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return (
                        <b
                            className="green font-bolder"
                            style={{
                                display: "flex",
                                justifyContent: "flex-end",
                            }}
                        >
                            {currencyFormatter(value, currency)}
                        </b>
                    );
                },
            },
        },
        {
            name: "Type",
            Label: "Type",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Type</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return <b className="grey2 font-bolder">{value}</b>;
                },
            },
        },
        {
            name: "ServiceCode",
            Label: "ServiceCode",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Service Code</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return <b className="grey2 font-bolder">{value}</b>;
                },
            },
        },
        {
            name: "Desc",
            Label: "Desc",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Description</span>
                ),
                setCellHeaderProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return <b className="grey2 font-bolder">{value}</b>;
                },
            },
        },
        {
            name: "PONumConsolidation",
            Label: "PONumConsolidation",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>
                        PO Number - Consolidation
                    </span>
                ),
                setCellHeaderProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: {
                        poNum: string;
                        consolidation: string;
                    },
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return (
                        <Grid container>
                            <Grid item xs={12}>
                                <b className="grey2 font-bolder">
                                    {value.poNum}
                                </b>
                            </Grid>
                            <Grid item xs={12}>
                                <b className="grey">{value.consolidation}</b>
                            </Grid>
                        </Grid>
                    );
                },
            },
        },
    ];

    return (
        <>
            {alert ? handleErrorAlert(errr.code, errr.msg) : <></>}
            <Backdrop
                className={classes.backdrop}
                open={
                    BuildDetLoading ||
                    buildDetContactLoading ||
                    BuildDetAssgnLoading ||
                    BuildDetServiceLoading ||
                    BuildDetVendorsLoading ||
                    BuildDetContractLoading ||
                    BuildDetVisitLoading ||
                    BuildDetExceptionsLoading
                }
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Backdrop
                className={classes.backdrop}
                open={popoverBlack}
            ></Backdrop>
            <Grid container style={{ padding: "0 0 2%" }}>
                <Grid item xs={12}>
                    <Paper elevation={1}>
                        {buildDet.map(
                            (
                                element: {
                                    Building: {
                                        name: String;
                                        id: String;
                                    };
                                    Address: {
                                        Area: String;
                                        City: String;
                                        State: String;
                                        Pincode: Number;
                                    };
                                    Phone: String;
                                    Status: String;
                                    PrimaryContact: String;
                                    AccountManager1: String;
                                    AccountManager2: String;
                                    AccountManager3: String;
                                    ContactFreq: String;
                                    Region: String;
                                    Latitude: String;
                                    Longitude: String;
                                },
                                index: any,
                            ) => (
                                <Grid
                                    container
                                    direction="row"
                                    spacing={2}
                                    className="padding-2-percent"
                                >
                                    <Grid
                                        item
                                        xs={4}
                                        style={{
                                            borderRight:
                                                "0.2px dashed lightgray",
                                        }}
                                    >
                                        <Grid container spacing={1}>
                                            <Grid item xs={12}>
                                                <Grid container>
                                                    <Grid
                                                        item
                                                        xs={4}
                                                        className="grey2 text-left font-boldest"
                                                    >
                                                        <Grid container>
                                                            <Grid item>
                                                                <ContactlessTwoTone />
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                style={{
                                                                    paddingLeft:
                                                                        "2%",
                                                                }}
                                                            >
                                                                {
                                                                    element
                                                                        .Building
                                                                        .id
                                                                }
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={8}
                                                        className="grey2 font-boldest text-right"
                                                    >
                                                        <Grid
                                                            container
                                                            alignItems="center"
                                                        >
                                                            <Grid item>
                                                                <Apartment />
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                style={{
                                                                    paddingLeft:
                                                                        "2%",
                                                                }}
                                                            >
                                                                {
                                                                    element
                                                                        .Building
                                                                        .name
                                                                }
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container>
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        className="grey text-left font-bigger"
                                                    >
                                                        <Grid container>
                                                            <Grid item>
                                                                <Icons Icon="location"></Icons>
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                style={{
                                                                    paddingLeft:
                                                                        "2%",
                                                                }}
                                                            >
                                                                {
                                                                    element
                                                                        .Address
                                                                        .Area
                                                                }{" "}
                                                                |
                                                                {
                                                                    element
                                                                        .Address
                                                                        .City
                                                                }{" "}
                                                                |
                                                                {
                                                                    element
                                                                        .Address
                                                                        .State
                                                                }
                                                                |
                                                                {
                                                                    element
                                                                        .Address
                                                                        .Pincode
                                                                }
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid
                                                    container
                                                    justifyContent="space-between"
                                                >
                                                    <Grid
                                                        item
                                                        xs={6}
                                                        className="grey2 font-bolder"
                                                    >
                                                        <Grid container>
                                                            <Grid item>
                                                                <Phone />
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                style={{
                                                                    paddingLeft:
                                                                        "2%",
                                                                    textAlign:
                                                                        "left",
                                                                }}
                                                            >
                                                                {element.Phone}
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    {element?.Status !== "" ? (
                                                        <Grid
                                                            item
                                                            xs={3}
                                                            style={{
                                                                display: "flex",
                                                                justifyContent:
                                                                    "center",
                                                                border: `${
                                                                    element?.Status.toLowerCase() ===
                                                                    "active"
                                                                        ? "1px solid #00DA64"
                                                                        : "1px solid #DD9200"
                                                                }`,
                                                                borderRadius:
                                                                    "3px",
                                                                background: `${
                                                                    element?.Status.toLowerCase() ===
                                                                    "active"
                                                                        ? "#EDFDF2"
                                                                        : "#FCF5E9"
                                                                }`,
                                                                alignItems:
                                                                    "center",
                                                            }}
                                                        >
                                                            {element?.Status}
                                                        </Grid>
                                                    ) : (
                                                        <></>
                                                    )}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={8}
                                        style={{ paddingLeft: "2%" }}
                                    >
                                        <Grid container spacing={1}>
                                            <Grid item xs={12}>
                                                <Grid container>
                                                    <Grid item xs={12} sm={3}>
                                                        <Grid container>
                                                            <Grid
                                                                item
                                                                xs={12}
                                                                className="text-left grey"
                                                            >
                                                                Primary Contact
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                xs={12}
                                                                className="grey2 font-boldest text-left"
                                                            >
                                                                {
                                                                    element.PrimaryContact
                                                                }
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={12} sm={3}>
                                                        <Grid container>
                                                            <Grid
                                                                item
                                                                xs={12}
                                                                className="text-left grey"
                                                            >
                                                                Account Manager
                                                                1
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                xs={12}
                                                                className="text-left grey2 font-boldest"
                                                            >
                                                                {
                                                                    element.AccountManager1
                                                                }
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={12} sm={3}>
                                                        <Grid container>
                                                            <Grid
                                                                item
                                                                xs={12}
                                                                className="text-left grey"
                                                            >
                                                                Account Manager
                                                                2
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                xs={12}
                                                                className="text-left grey2 font-boldest"
                                                            >
                                                                {
                                                                    element.AccountManager2
                                                                }
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={12} sm={3}>
                                                        <Grid container>
                                                            <Grid
                                                                item
                                                                xs={12}
                                                                className="text-left grey"
                                                            >
                                                                Account Manager
                                                                3
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                xs={12}
                                                                className="text-left grey2 font-boldest"
                                                            >
                                                                {
                                                                    element.AccountManager3
                                                                }
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container>
                                                    <Grid item xs={12} sm={3}>
                                                        <Grid container>
                                                            <Grid
                                                                item
                                                                xs={12}
                                                                className="text-left grey"
                                                            >
                                                                Contact
                                                                Frequency
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                xs={12}
                                                                className="text-left grey2 font-boldest"
                                                            >
                                                                {
                                                                    element.ContactFreq
                                                                }
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={12} sm={3}>
                                                        <Grid container>
                                                            <Grid
                                                                item
                                                                xs={12}
                                                                className="text-left grey"
                                                            >
                                                                Region
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                xs={12}
                                                                className="text-left grey2 font-boldest"
                                                            >
                                                                {element.Region}
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={12} sm={3}>
                                                        <Grid container>
                                                            <Grid
                                                                item
                                                                xs={12}
                                                                className="text-left grey"
                                                            >
                                                                Latitude
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                xs={12}
                                                                className="text-left grey2 font-boldest"
                                                            >
                                                                {
                                                                    element.Latitude
                                                                }
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={12} sm={3}>
                                                        <Grid container>
                                                            <Grid
                                                                item
                                                                xs={12}
                                                                className="text-left grey"
                                                            >
                                                                Longitude
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                xs={12}
                                                                className="text-left grey2 font-boldest"
                                                            >
                                                                {
                                                                    element.Longitude
                                                                }
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            ),
                        )}
                    </Paper>
                </Grid>
            </Grid>
            <Grid container style={{ padding: "0 0 2%" }}>
                <Grid item xs={12} sm={6}>
                    <ButtonGroups
                        controlList={controlList}
                        handleDocList={handleDocList}
                        gridSpacing={2}
                        externalActive={activeList}
                    />
                </Grid>
            </Grid>
            {activeList === "General" ? (
                <>
                    <Grid container spacing={3}>
                        <Grid item xs={2} style={{ marginTop: "8px" }}>
                            <AssignmentCards
                                data={AssignmentComp}
                                // setCompCode={setCompCode}
                                handleSelect={handleSelect}
                            />
                        </Grid>

                        {selectedAssignment === "Contacts" ? (
                            <Grid item xs={9}>
                                <MuiDatatables
                                    title={""}
                                    header={ContactSchema}
                                    detail={[generalInfo, setgeneralInfo]}
                                    // handleAddSideEffect={handleAddSideEffect}
                                    // handleDeleteSideEffect={handleDeleteSideEffect}
                                    initRow={false}
                                />
                            </Grid>
                        ) : selectedAssignment === "Visits" ? (
                            <Grid item xs={9}>
                                <MuiDatatables
                                    title={""}
                                    header={VisitSchema}
                                    detail={[visitsInfo, setvisitsInfo]}
                                    // handleAddSideEffect={handleAddSideEffect}
                                    // handleDeleteSideEffect={handleDeleteSideEffect}
                                    initRow={false}
                                />
                            </Grid>
                        ) : selectedAssignment ===
                          "Active Recurring Vendors" ? (
                            <Grid item xs={9}>
                                <MuiDatatables
                                    title={""}
                                    header={ActiveRecurring}
                                    detail={[
                                        activerecurringInfo,
                                        setactiverecurringInfo,
                                    ]}
                                    // handleAddSideEffect={handleAddSideEffect}
                                    // handleDeleteSideEffect={handleDeleteSideEffect}
                                    initRow={false}
                                    height="175px"
                                />
                            </Grid>
                        ) : selectedAssignment === "Service Days" ? (
                            <Grid item xs={9}>
                                <MuiDatatables
                                    title={""}
                                    header={ServiceDaysSchema}
                                    detail={[
                                        servicedaysInfo,
                                        setservicedaysInfo,
                                    ]}
                                    // handleAddSideEffect={handleAddSideEffect}
                                    // handleDeleteSideEffect={handleDeleteSideEffect}
                                    initRow={false}
                                />
                            </Grid>
                        ) : (
                            <></>
                        )}

                        <Grid item xs={7}>
                            {selectedAssignment === "Details & Exceptions" &&
                                exceptioninfo.map(
                                    (
                                        element: {
                                            BuildingDetails: {
                                                TotalSF: String;
                                                CleanableSF: String;
                                                TotalMonthlyBilling: any;
                                                LastUpdate: String;
                                            };
                                            Exception: {
                                                StartDate: String;
                                                EndDate: String;
                                            };
                                        },
                                        index: any,
                                    ) => (
                                        <Grid container spacing={2}>
                                            <Grid item xs={4}>
                                                <Grid container spacing={1}>
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        className="text-left grey2 font-bigger font-bolder"
                                                        style={{
                                                            padding: "3% 0",
                                                        }}
                                                    >
                                                        Building Details
                                                    </Grid>

                                                    <Grid item xs={12}>
                                                        <Paper elevation={1}>
                                                            <Grid
                                                                container
                                                                spacing={2}
                                                                className="padding-2-percent"
                                                            >
                                                                <Grid
                                                                    item
                                                                    xs={12}
                                                                >
                                                                    <Grid
                                                                        container
                                                                        spacing={
                                                                            2
                                                                        }
                                                                    >
                                                                        <Grid
                                                                            item
                                                                            xs={
                                                                                12
                                                                            }
                                                                        >
                                                                            <Grid
                                                                                container
                                                                            >
                                                                                <Grid
                                                                                    item
                                                                                    xs={
                                                                                        6
                                                                                    }
                                                                                    className="text-left grey font-boldest"
                                                                                >
                                                                                    Total
                                                                                    SF
                                                                                </Grid>
                                                                                <Grid
                                                                                    item
                                                                                    xs={
                                                                                        6
                                                                                    }
                                                                                    className="text-right grey2 font-bolder"
                                                                                >
                                                                                    {
                                                                                        element
                                                                                            .BuildingDetails
                                                                                            .TotalSF
                                                                                    }
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Grid>
                                                                        <Grid
                                                                            item
                                                                            xs={
                                                                                12
                                                                            }
                                                                        >
                                                                            <Grid
                                                                                container
                                                                            >
                                                                                <Grid
                                                                                    item
                                                                                    xs={
                                                                                        6
                                                                                    }
                                                                                    className="text-left grey font-boldest"
                                                                                >
                                                                                    Cleanable
                                                                                    SF
                                                                                </Grid>
                                                                                <Grid
                                                                                    item
                                                                                    xs={
                                                                                        6
                                                                                    }
                                                                                    className="text-right grey2 font-bolder"
                                                                                >
                                                                                    {
                                                                                        element
                                                                                            .BuildingDetails
                                                                                            .CleanableSF
                                                                                    }
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Grid>
                                                                        <Grid
                                                                            item
                                                                            xs={
                                                                                12
                                                                            }
                                                                        >
                                                                            <Grid
                                                                                container
                                                                            >
                                                                                <Grid
                                                                                    item
                                                                                    xs={
                                                                                        6
                                                                                    }
                                                                                >
                                                                                    <Grid
                                                                                        container
                                                                                    >
                                                                                        <Grid
                                                                                            item
                                                                                            xs={
                                                                                                12
                                                                                            }
                                                                                            className="text-left grey font-boldest"
                                                                                        >
                                                                                            Total
                                                                                            Monthly
                                                                                            Billing
                                                                                        </Grid>
                                                                                        {/* <Grid
                                                                                            item
                                                                                            xs={
                                                                                                12
                                                                                            }
                                                                                            className="text-left grey font-boldest"
                                                                                        >
                                                                                            Last
                                                                                            updated
                                                                                        </Grid> */}
                                                                                    </Grid>
                                                                                </Grid>
                                                                                <Grid
                                                                                    item
                                                                                    xs={
                                                                                        6
                                                                                    }
                                                                                    className="text-right green"
                                                                                >
                                                                                    {currencyFormatter(
                                                                                        element
                                                                                            .BuildingDetails
                                                                                            .TotalMonthlyBilling,
                                                                                        currency,
                                                                                    )}
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Paper>
                                                    </Grid>

                                                    <Grid
                                                        item
                                                        xs={12}
                                                        className="text-left grey2 font-bigger font-bolder"
                                                        style={{
                                                            padding: "3% 0",
                                                        }}
                                                    >
                                                        Exception
                                                    </Grid>

                                                    <Grid item xs={12}>
                                                        <Paper elevation={1}>
                                                            <Grid
                                                                container
                                                                spacing={2}
                                                                className="padding-2-percent"
                                                            >
                                                                <Grid
                                                                    item
                                                                    xs={12}
                                                                >
                                                                    <Grid
                                                                        container
                                                                        spacing={
                                                                            2
                                                                        }
                                                                    >
                                                                        <Grid
                                                                            item
                                                                            xs={
                                                                                12
                                                                            }
                                                                        >
                                                                            <Grid
                                                                                container
                                                                            >
                                                                                <Grid
                                                                                    item
                                                                                    xs={
                                                                                        6
                                                                                    }
                                                                                    className="text-left grey font-boldest"
                                                                                >
                                                                                    Exception
                                                                                    Start
                                                                                    Date
                                                                                </Grid>
                                                                                <Grid
                                                                                    item
                                                                                    xs={
                                                                                        6
                                                                                    }
                                                                                    className="text-right grey2 font-bolder"
                                                                                >
                                                                                    {
                                                                                        element
                                                                                            .Exception
                                                                                            .StartDate
                                                                                    }
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Grid>
                                                                        <Grid
                                                                            item
                                                                            xs={
                                                                                12
                                                                            }
                                                                        >
                                                                            <Grid
                                                                                container
                                                                            >
                                                                                <Grid
                                                                                    item
                                                                                    xs={
                                                                                        6
                                                                                    }
                                                                                    className="text-left grey font-boldest"
                                                                                >
                                                                                    Exception
                                                                                    End
                                                                                    Date
                                                                                </Grid>
                                                                                <Grid
                                                                                    item
                                                                                    xs={
                                                                                        6
                                                                                    }
                                                                                    className="text-right grey2 font-bolder"
                                                                                >
                                                                                    {
                                                                                        element
                                                                                            .Exception
                                                                                            .EndDate
                                                                                    }
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Paper>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    ),
                                )}
                        </Grid>
                    </Grid>
                </>
            ) : activeList === "Contract" ? (
                <MuiDatatables
                    title={""}
                    header={ContractSchema}
                    detail={[contractInfo, setcontractInfo]}
                    // handleAddSideEffect={handleAddSideEffect}
                    // handleDeleteSideEffect={handleDeleteSideEffect}
                    initRow={false}
                />
            ) : (
                <MuiDatatables
                    title={""}
                    header={BuildDetailsAssgnSchema}
                    detail={[AssignmentInfo, setAssignmentInfo]}
                    // handleAddSideEffect={handleAddSideEffect}
                    // handleDeleteSideEffect={handleDeleteSideEffect}
                    initRow={false}
                />
            )}
        </>
    );
};

const mapStateToProps = (state: any) => {
    return {
        currentContext: state.context?.userDefaults,
        userName: state.context?.name,
        selectedCustomer: state.selectedCustomer,
        currency: state.currency,
    };
};

export default connect(mapStateToProps)(BuildingDetails);
