import { useLocation } from "react-router";
import { Grid, Paper, Backdrop, CircularProgress } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import ButtonGroups from "Common/ButtonGroup/ButtonGroup";
import { connect } from "react-redux";
import useGetPosts from "ReactQuery/reactQuery";
import MuiDatatables from "Common/Mui-DataTables/MuiDatatables";
import vendorDetailsListSchema from "./Schema/VendorDetailsListSchema";
import {
    VendorDetailsListQuery,
    InvoiceDetailsListQuery,
    contactCardQuery,
} from "ReactQuery/Query/index";
import { makeStyles, Theme } from "@material-ui/core/styles";
import Alert from "Common/Alerts/Alerts";
import { Redirect } from "react-router-dom";
import currencyFormatter from "Common/Utils/currencyFormatter";
import Icons from "Common/Icons/Icons";

const useStyles = makeStyles((theme: Theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
}));

const ContractDetails = (props: any) => {
    const contractData = useLocation<any>();
    const contractName: any = contractData?.state?.contractSelected;
    const siteIdContract: any = contractData?.state?.siteId;
    const classes = useStyles();
    const { currentContext, userName, currency, selectedCustomer } = props;

    console.log("contactName", contractName);
    useEffect(() => {
        console.log("contactData", contractData);
    });

    const controlList = [
        {
            desc: "Vendors",
            value: "Vendors",
        },
        {
            desc: "Invoices",
            value: "Invoices",
        },
    ];
    const [invoiceRedirect, setInvoiceRedirect] = useState(false);
    const [InvoiceSelected, setInvoiceSelected] = useState();
    const [buildingToggle, setBuildingToggle] = useState(false);
    const [siteId, setSiteId] = useState();
    const [activeList, setActiveList] = useState(controlList[0].desc);
    const [popoverBlack, setPopoverBlack] = useState(false);
    const [alert, setAlert] = useState(false);
    const [errr, seterr] = useState({
        code: 0,
        msg: "",
    });

    const handleErrorAlert = (code: number, message: string) => {
        Alert({
            statusCode: code,
            message: message,
            willClose: () => {
                setAlert(false);
                seterr({ code: 0, msg: "" });
            },
        });
    };

    const handleDocList = (listType: string) => {
        console.log("listType", listType);
        setActiveList(listType);
    };

    const [ContactCardList, setContactCardList] = useState<any>();

    const {
        data: ContactCardListData,
        error: ContactCardListDataError,
        isLoading: ContactCardListDataLoading,
    } = useGetPosts({
        name: `ContactCardListQuery_get_data_${contractName}`,
        query: contactCardQuery,
        variables: {
            entity: "OW_Cust_portal_Cnrct_hdr",
            ou: currentContext?.ouId,
            service: "",
            User: userName,
            languageId: currentContext?.langId,
            contractno: contractName,
            conttype: "",
            customerCode: selectedCustomer?.companyCode,
            siteId: "",
            buildName: "",
            merrorid: "",
        },
    });

    useEffect(() => {
        console.log(
            "ContactCardListData",
            ContactCardListData?.ContractCardQuery[0],
        );
        if (ContactCardListData?.ContractCardQuery) {
            setContactCardList(ContactCardListData?.ContractCardQuery[0]);
        }
    }, [ContactCardListData]);

    useEffect(() => {
        if (ContactCardListDataError && !ContactCardListDataLoading) {
            const data = JSON.parse(JSON.stringify(ContactCardListDataError));
            const errmsg = data?.response?.errors[0].message;
            seterr({
                code: 400,
                msg: errmsg,
            });
            setAlert(true);
        }
    }, [ContactCardListDataError, ContactCardListDataLoading]);

    const [VendorDetailsList, setVendorDetailsList] = useState([]);

    const {
        data: VendorDetailsListData,
        error: VendorDetailsListDataError,
        isLoading: VendorDetailsListDataLoading,
    } = useGetPosts({
        name: `VendorDetailsListQuery_get_data_${contractName}`,
        query: VendorDetailsListQuery,
        variables: {
            entity: "OW_Cust_portal_VendorDtls",
            ou: currentContext?.ouId,
            service: "",
            User: userName,
            languageId: currentContext?.langId,
            contractno: contractName,
            conttype: "",
            customerCode: selectedCustomer?.companyCode,
            siteId: siteIdContract,
            buildName: "",
            pono: "",
            merrorid: "",
        },
        skip: !(activeList === "Vendors"),
    });

    useEffect(() => {
        console.log(
            "VendorDetailsList",
            VendorDetailsListData?.GetVendorDetailsList,
        );
        if (VendorDetailsListData?.GetVendorDetailsList) {
            setVendorDetailsList(VendorDetailsListData?.GetVendorDetailsList);
        }
    }, [VendorDetailsListData]);

    useEffect(() => {
        if (VendorDetailsListDataError && !VendorDetailsListDataLoading) {
            const data = JSON.parse(JSON.stringify(VendorDetailsListDataError));
            const errmsg = data?.response?.errors[0].message;
            seterr({
                code: 400,
                msg: errmsg,
            });
            setAlert(true);
        }
    }, [VendorDetailsListDataError, VendorDetailsListDataLoading]);

    const [InvoiceDetailsList, setInvoiceDetailsList] = useState([]);

    const {
        data: InvoiceDetailsListData,
        error: InvoiceDetailsListDataError,
        isLoading: InvoiceDetailsListLoading,
    } = useGetPosts({
        name: `InvoiceDetailsListQuery_get_data_${contractName}`,
        query: InvoiceDetailsListQuery,
        variables: {
            entity: "OW_Cust_portal_InvoiceDtls",
            ou: currentContext?.ouId,
            service: "",
            User: userName,
            languageId: currentContext?.langId,
            contractno: contractName,
            conttype: "",
            customerCode: selectedCustomer?.companyCode,
            siteId: siteIdContract,
            buildName: "",
            merrorid: "",
        },
        skip: !(activeList === "Invoices"),
    });

    useEffect(() => {
        console.log(
            "InvoiceDetailsList",
            InvoiceDetailsListData?.GetInvoiceDetailsList,
        );
        if (InvoiceDetailsListData?.GetInvoiceDetailsList) {
            setInvoiceDetailsList(
                InvoiceDetailsListData?.GetInvoiceDetailsList,
            );
        }
    }, [InvoiceDetailsListData]);

    useEffect(() => {
        if (InvoiceDetailsListDataError && !InvoiceDetailsListLoading) {
            const data = JSON.parse(
                JSON.stringify(InvoiceDetailsListDataError),
            );
            const errmsg = data?.response?.errors[0].message;
            seterr({
                code: 400,
                msg: errmsg,
            });
            setAlert(true);
        }
    }, [InvoiceDetailsListDataError, InvoiceDetailsListLoading]);

    // const [CommissionList, setCommissionList] = useState([]);

    // const {
    //     data: CommissionListData,
    //     error: CommissionListDataError,
    // } = useGetPosts({
    //     name: `CommissionListQuery_get_data`,
    //     query: CommissionListQuery,
    //     variables: {},
    // });

    // useEffect(() => {
    //     if (CommissionListData?.GetCommissionList) {
    //         setCommissionList(CommissionListData?.GetCommissionList);
    //     }
    // }, [CommissionListData]);

    // const handleSelect = (data: any) => {
    //     // setSelectedInvoiceDetailsList(data.desc);
    //     console.log("data", data);
    // };

    if (invoiceRedirect) {
        return (
            <Redirect
                to={{
                    pathname: "/app/INVDET",
                    state: {
                        InvoiceSelected: InvoiceSelected,
                    },
                }}
            />
        );
    }

    if (buildingToggle) {
        return (
            <Redirect
                to={{
                    pathname: "/app/BuildDetail",
                    state: {
                        siteId: siteId,
                    },
                }}
            />
        );
    }

    const handleRedirect = (val: any) => {
        setInvoiceRedirect((prevState) => !prevState);
        setInvoiceSelected(val);
    };

    const handleBuilding = (val: any) => {
        setSiteId(val);
        setBuildingToggle((prevState) => !prevState);
    };

    const InvoiceDetailsListSchema = [
        {
            name: "InvoiceNo",
            Label: "InvoiceNo",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Invoice No.</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value, tableMeta.rowData);
                    return (
                        <b
                            style={{ color: "#0073E6", cursor: "pointer" }}
                            onClick={() => handleRedirect(value)}
                        >
                            {value}
                        </b>
                    );
                },
            },
        },
        {
            name: "StatusReason",
            Label: "StatusReason",

            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Status Reason</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return <b style={{ color: "#5C677D" }}>{value}</b>;
                },
            },
        },
        {
            name: "TotalAmount",
            Label: "TotalAmount",
            type: "string",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Total Amount</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return (
                        <b
                            style={{
                                color: "#00AE50",
                                display: "flex",
                                justifyContent: "flex-end",
                            }}
                            className="text-right"
                        >
                            {currencyFormatter(value, currency)}
                        </b>
                    );
                },
            },
        },
        {
            name: "Customer",
            Label: "Customer",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Customer</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return <b style={{ color: "#5C677D" }}>{value}</b>;
                },
            },
        },
        {
            name: "BuildingAddress",
            Label: "BuildingAddress",
            type: "string",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Building Address</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return (
                        <b
                            style={{
                                color: "#0073E6",
                                cursor: "pointer",
                            }}
                            onClick={() => handleBuilding(value.line2)}
                        >
                            {value.line1}
                        </b>
                    );
                },
            },
        },
        {
            name: "BuildingID",
            Label: "BuildingID",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Building ID</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return <b className="grey">{value}</b>;
                },
            },
        },
        {
            name: "City",
            Label: "City",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>City</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return <b className="grey">{value}</b>;
                },
            },
        },
        {
            name: "DocDate",
            Label: "DocDate",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Doc Date</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return <b style={{ color: "#5C677D" }}>{value}</b>;
                },
            },
        },
        // {
        //     name: "GPInvoiceID",
        //     Label: "GPInvoiceID",
        //     type: "string",
        //     options: {
        //         filter: true,
        //         sort: false,
        //         customHeadLabelRender: () => (
        //             <span style={{ color: "white" }}>GP Invoice ID</span>
        //         ),
        //         // setCellHeaderProps: () => {
        //         //     return { style: { borderRight: "1px solid #D5D5D5" } };
        //         // },
        //         // setCellProps: () => {
        //         //     return { style: { borderRight: "1px solid #D5D5D5" } };
        //         // },
        //         customBodyRender: (
        //             value: any,
        //             tableMeta: any,
        //             updateValue: any,
        //         ) => {
        //             console.log("table data", value);
        //             return <b style={{ color: "#5C677D" }}>{value}</b>;
        //         },
        //     },
        // },
        // {
        //     name: "GPTransaction",
        //     Label: "GPTransaction",
        //     options: {
        //         filter: true,
        //         sort: false,
        //         customHeadLabelRender: () => (
        //             <span style={{ color: "white" }}>GP Transaction</span>
        //         ),
        //         // setCellHeaderProps: () => {
        //         //     return { style: { borderRight: "1px solid #D5D5D5" } };
        //         // },
        //         // setCellProps: () => {
        //         //     return { style: { borderRight: "1px solid #D5D5D5" } };
        //         // },
        //         customBodyRender: (
        //             value: any,
        //             tableMeta: any,
        //             updateValue: any,
        //         ) => {
        //             console.log("table data", value);
        //             return <b style={{ color: "#5C677D" }}>{value}</b>;
        //         },
        //     },
        // },
        {
            name: "CreatedOn",
            Label: "CreatedOn",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Created On</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return <b style={{ color: "#5C677D" }}>{value}</b>;
                },
            },
        },
        {
            name: "MonthYear",
            Label: "MonthYear",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Month & Year</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return <b style={{ color: "#5C677D" }}>{value}</b>;
                },
            },
        },
        {
            name: "PastDueDays",
            Label: "PastDueDays",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Past Due Days</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return value?.color ? (
                        <b style={{ color: value?.color }}>{value.days}</b>
                    ) : (
                        <b style={{ color: "#5C677D" }}>{value.days}</b>
                    );
                },
            },
        },
        {
            name: "PaymentStatus",
            Label: "PaymentStatus",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Payment Status</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return value.status === "Paid" ? (
                        <>
                            <b className="Completed_dot"></b>
                            <b style={{ color: "#00AE50", padding: "3%" }}>
                                {value.status}
                            </b>
                        </>
                    ) : value.status === "Not Paid" ? (
                        <>
                            {value.color == "#00AE50" ? (
                                <b className="Completed_dot"></b>
                            ) : (
                                <b className="Failed_dot"></b>
                            )}
                            <b style={{ color: value.color, padding: "3%" }}>
                                {value.status}
                            </b>
                        </>
                    ) : (
                        <>
                            <b className="Pending_dot"></b>
                            <b style={{ color: "#dd9200", padding: "3%" }}>
                                {value.status}
                            </b>
                        </>
                    );
                },
            },
        },
    ];

    return (
        <>
            {alert ? handleErrorAlert(errr.code, errr.msg) : <></>}
            <Backdrop
                className={classes.backdrop}
                open={
                    InvoiceDetailsListLoading ||
                    ContactCardListDataLoading ||
                    VendorDetailsListDataLoading
                }
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Backdrop
                className={classes.backdrop}
                open={popoverBlack}
            ></Backdrop>
            <Grid container spacing={2} style={{ textAlign: "left" }}>
                <Grid
                    container
                    spacing={2}
                    style={{ paddingTop: "4%", textAlign: "left" }}
                >
                    <Grid item xs={11}>
                        <Paper elevation={3}>
                            <Grid container style={{ padding: "1%" }}>
                                <Grid
                                    item
                                    xs={4}
                                    style={{
                                        margin: "0%",
                                        padding: "1% 1.5% 1% 1%",
                                        borderRight: "1px dashed #C9CDD6",
                                    }}
                                >
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <Grid
                                                container
                                                justifyContent="space-between"
                                                // style={{paddingLeft:"2%"}}
                                            >
                                                <Grid
                                                    item
                                                    className="grey2 font-boldest"
                                                    xs={4}
                                                >
                                                    <Grid
                                                        container
                                                        alignItems="center"
                                                        spacing={1}
                                                    >
                                                        <Grid item xs={2}>
                                                            <Icons Icon="contractDetail" />
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xs={9}
                                                            style={{
                                                                paddingLeft:
                                                                    "2%",
                                                            }}
                                                        >
                                                            {
                                                                ContactCardList?.ContractID
                                                            }
                                                        </Grid>
                                                    </Grid>
                                                    {/* {contactName?.contractSelected[0]} */}
                                                </Grid>
                                                <Grid
                                                    item
                                                    className="grey2 font-boldest"
                                                    xs={6}
                                                    style={{
                                                        textAlign: "right",
                                                    }}
                                                >
                                                    <Grid
                                                        container
                                                        alignItems="center"
                                                    >
                                                        <Grid item xs={2}>
                                                            <Icons Icon="buildingContract" />
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xs={10}
                                                            style={{
                                                                paddingLeft:
                                                                    "1%",
                                                                textAlign:
                                                                    "center",
                                                            }}
                                                        >
                                                            {" "}
                                                            {
                                                                ContactCardList?.Building
                                                            }
                                                        </Grid>
                                                    </Grid>

                                                    {/* {contactName?.contractSelected[2]} */}
                                                </Grid>
                                                <Grid
                                                    item
                                                    className="grey2 font-boldest"
                                                    xs={2}
                                                ></Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            // style={{ padding: "2%" }}
                                        >
                                            <Grid container alignItems="center">
                                                <Grid item>
                                                    <Icons Icon="settingDetail" />
                                                </Grid>
                                                <Grid
                                                    item
                                                    className="grey2 "
                                                    style={{
                                                        paddingLeft: "2%",
                                                    }}
                                                >
                                                    {
                                                        ContactCardList?.VendorContract
                                                    }
                                                </Grid>
                                            </Grid>

                                            {/* {contactName?.contractSelected[4]} */}
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container>
                                                {ContactCardList?.Status ? (
                                                    <Grid
                                                        item
                                                        xs={3}
                                                        style={{
                                                            display: "flex",
                                                            justifyContent:
                                                                "center",
                                                            border: `${
                                                                ContactCardList?.Status.toLowerCase() ===
                                                                "authorized"
                                                                    ? "1px solid #00DA64"
                                                                    : "1px solid #DD9200"
                                                            }`,
                                                            borderRadius: "3px",
                                                            background: `${
                                                                ContactCardList?.Status.toLowerCase() ===
                                                                "authorized"
                                                                    ? "#EDFDF2"
                                                                    : "#FCF5E9"
                                                            }`,
                                                            alignItems:
                                                                "center",
                                                        }}
                                                    >
                                                        {
                                                            ContactCardList?.Status
                                                        }
                                                    </Grid>
                                                ) : (
                                                    <></>
                                                )}
                                                <Grid
                                                    item
                                                    xs={9}
                                                    className="font-bolder font-bigger"
                                                    style={{
                                                        paddingLeft: "2%",
                                                        color: "#00AE50",
                                                        textAlign: "right",
                                                    }}
                                                >
                                                    {currencyFormatter(
                                                        ContactCardList?.Amount,
                                                        currency,
                                                    )}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid
                                    item
                                    xs={8}
                                    style={{ padding: "1% 1% 1% 1.5%" }}
                                >
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={3}>
                                                    <Grid container>
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            className="grey"
                                                        >
                                                            Customer
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            className="grey2 font-boldest"
                                                        >
                                                            {
                                                                ContactCardList?.Customer
                                                            }
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <Grid container>
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            className="grey"
                                                        >
                                                            Sales Person
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            className="grey2 font-boldest"
                                                        >
                                                            {
                                                                ContactCardList?.SalesPerson
                                                            }
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <Grid container>
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            className="grey"
                                                        >
                                                            Contract Start - End
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            className="grey2 font-boldest"
                                                        >
                                                            {
                                                                ContactCardList?.ContractStartEnd
                                                            }
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <Grid container>
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            className="grey"
                                                        >
                                                            Prorated Amount
                                                            Start - End
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            className="grey2 font-boldest"
                                                        >
                                                            {ContactCardList?.ProrstedAmtStartEnd
                                                                ? `${currencyFormatter(
                                                                      ContactCardList
                                                                          ?.ProrstedAmtStartEnd
                                                                          ?.line1,
                                                                      currency,
                                                                  )}
                                                             - 
                                                            ${currencyFormatter(
                                                                ContactCardList
                                                                    ?.ProrstedAmtStartEnd
                                                                    ?.line2,
                                                                currency,
                                                            )}`
                                                                : ""}
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={3}>
                                                    <Grid container>
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            className="grey"
                                                        >
                                                            Revision
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            className="grey2 font-boldest"
                                                        >
                                                            {
                                                                ContactCardList?.Revision
                                                            }
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <Grid container>
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            className="grey"
                                                        >
                                                            Contract Expiration
                                                            Date
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            className="grey2 font-boldest"
                                                        >
                                                            {
                                                                ContactCardList?.ContractExpirationDate
                                                            }
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <Grid container>
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            className="grey"
                                                        >
                                                            Revision Start - End
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            className="grey2 font-boldest"
                                                        >
                                                            {
                                                                ContactCardList?.RevisionStratEnd
                                                            }
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={3}></Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>

                <Grid container style={{ paddingTop: "2%" }}>
                    {/* <Grid item > */}
                    <ButtonGroups
                        controlList={controlList}
                        handleDocList={handleDocList}
                        gridSpacing={2}
                        externalActive={activeList}
                    />
                    {/* </Grid> */}
                </Grid>

                {/* {activeList === "Commission" && (
                    <Grid container spacing={2} style={{ paddingTop: "2%" }}>
                        <Grid item xs={12}>
                            <MuiDatatables
                                header={CommissionSchema}
                                title={""}
                                checkboxNotVisible={true}
                                // rowData={checklistDetailsList}
                                detail={[CommissionList, setCommissionList]}
                                // handleAddSideEffect={handleAddSideEffect}
                                // handleDeleteSideEffect={handleDeleteSideEffect}
                                // handleSelectedSideEffect={handleSelectedSideEffect}
                                // initRow={true}
                            />
                        </Grid>
                    </Grid>
                )} */}

                {activeList === "Vendors" && (
                    <Grid container spacing={2} style={{ paddingTop: "2%" }}>
                        <Grid item xs={12}>
                            <MuiDatatables
                                header={vendorDetailsListSchema}
                                title={""}
                                // rowData={checklistDetailsList}
                                detail={[
                                    VendorDetailsList,
                                    setVendorDetailsList,
                                ]}
                                checkboxNotVisible={true}
                                // handleAddSideEffect={handleAddSideEffect}
                                // handleDeleteSideEffect={handleDeleteSideEffect}
                                // handleSelectedSideEffect={handleSelectedSideEffect}
                                // initRow={true}
                            />
                        </Grid>
                    </Grid>
                )}

                {activeList === "Invoices" && (
                    <Grid container spacing={2} style={{ paddingTop: "2%" }}>
                        <Grid item xs={12}>
                            <MuiDatatables
                                header={InvoiceDetailsListSchema}
                                title={""}
                                // rowData={checklistDetailsList}
                                detail={[
                                    InvoiceDetailsList,
                                    setInvoiceDetailsList,
                                ]}
                                checkboxNotVisible={true}
                                // handleAddSideEffect={handleAddSideEffect}
                                // handleDeleteSideEffect={handleDeleteSideEffect}
                                // handleSelectedSideEffect={handleSelectedSideEffect}
                                // initRow={true}
                            />
                        </Grid>
                    </Grid>
                )}
            </Grid>
        </>
    );
};

const mapStateToProps = (state: any) => {
    return {
        currentContext: state.context?.userDefaults,
        userName: state.context?.name,
        selectedCustomer: state.selectedCustomer,
        currency: state.currency,
    };
};

export default connect(mapStateToProps)(ContractDetails);
