export const STORE_USER_ROLE = "STORE_USER_ROLE";
export const STORE_COMPANY_LIST = "STORE_COMPANY_LIST";
export const STORE_OU_LIST = "STORE_OU_LIST";
export const STORE_SESSION_ID = "STORE_SESSION_ID";
export const STORE_DASH_DATA = "STORE_DASH_DATA";
export const STORE_CONTEXT_INFO = "STORE_CONTEXT_INFO";
export const SET_TERMS_AND_CONDITIONS = "SET_TERMS_AND_CONDITIONS";
export const POST_LOGIN_TENANT_DETAILS = "POST_LOGIN_TENANT_DETAILS";
export const STORE_HIDDEN_SUPPLIER = "STORE_HIDDEN_SUPPLIER";
export const STORE_BREADCRUMBS = "STORE_BREADCRUMBS";
export const STORE_SELECTED_COMPANY = "STORE_SELECTED_COMPANY";
export const STORE_CUSTOMER_DETAILS = "STORE_CUSTOMER_DETAILS";
export const STORE_VENDOR_DETAILS = "STORE_VENDOR_DETAILS";
export const STORE_PORTAL = "STORE_PORTAL";
export const STORE_CURRENCY = "STORE_CURRENCY";
