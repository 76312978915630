import { STORE_USER_ROLE } from "../../actionTypes";

const initialState = {
    defaultContext: {
        label: "",
        value: "",
    },
};

const storeSwitchContext = (
    prevState = initialState.defaultContext,
    action: any,
) => {
    switch (action.type) {
        case STORE_USER_ROLE:
            return action.payload;

        default:
            return prevState;
    }
};

export default storeSwitchContext;
