import { Grid } from "@material-ui/core";
import "Common/Main.scss";

export const ContractSchema = [
    {
        name: "ContactID",
        Label: "ContactID",
        options: {
            filter: true,
            sort: false,
            customHeadLabelRender: () => (
                <span style={{ color: "white" }}>Contact ID</span>
            ),
            // setCellHeaderProps: () => {
            //     return { style: { borderRight: "1px solid #D5D5D5" } };
            // },
            // setCellProps: () => {
            //     return { style: { borderRight: "1px solid #D5D5D5" } };
            // },
            customBodyRender: (
                value: any,
                tableMeta: any,
                updateValue: any,
            ) => {
                console.log("table data", value);
                return <b className="blue">{value}</b>;
            },
        },
    },
    {
        name: "Building",
        Label: "Building",
        options: {
            filter: true,
            sort: false,
            // setCellHeaderProps: () => {
            //     return { style: { borderRight: "1px solid #D5D5D5" } };
            // },
            // setCellProps: () => {
            //     return {
            //         style: {
            //             borderRight: "1px solid #D5D5D5",
            //             width: "11%",
            //         },
            //     };
            // },
            customHeadLabelRender: () => (
                <span style={{ color: "white" }}>
                    Building Name - Building ID
                </span>
            ),
            customBodyRender: (
                value: {
                    name: string;
                    id: string;
                },
                tableMeta: any,
                updateValue: any,
            ) => {
                console.log("table data", value);
                return (
                    <Grid container>
                        <Grid item xs={12}>
                            <b className="blue">{value.name}</b>
                        </Grid>
                        <Grid item xs={12}>
                            <b className="grey2">{value.id}</b>
                        </Grid>
                    </Grid>
                );
            },
        },
    },

    {
        name: "Contracts",
        Label: "Contracts",
        options: {
            filter: true,
            sort: false,
            customHeadLabelRender: () => (
                <span style={{ color: "white" }}>Contracts</span>
            ),
            // setCellHeaderProps: () => {
            //     return { style: { borderRight: "1px solid #D5D5D5" } };
            // },
            // setCellProps: () => {
            //     return { style: { borderRight: "1px solid #D5D5D5" } };
            // },
            customBodyRender: (
                value: any,
                tableMeta: any,
                updateValue: any,
            ) => {
                console.log("table data", value);
                return <b className="blue">{value}</b>;
            },
        },
    },

    {
        name: "Revision",
        Label: "Revision",
        options: {
            filter: true,
            sort: false,
            customHeadLabelRender: () => (
                <span style={{ color: "white" }}>Revision</span>
            ),
            // setCellHeaderProps: () => {
            //     return { style: { borderRight: "1px solid #D5D5D5" } };
            // },
            // setCellProps: () => {
            //     return { style: { borderRight: "1px solid #D5D5D5" } };
            // },
            customBodyRender: (
                value: any,
                tableMeta: any,
                updateValue: any,
            ) => {
                console.log("table data", value);
                return <b className="grey2">{value}</b>;
            },
        },
    },

    {
        name: "RevisionStartEnd",
        Label: "RevisionStartEnd",
        options: {
            filter: true,
            sort: false,
            customHeadLabelRender: () => (
                <span style={{ color: "white" }}>Revision Start-End</span>
            ),
            // setCellHeaderProps: () => {
            //     return { style: { borderRight: "1px solid #D5D5D5" } };
            // },
            // setCellProps: () => {
            //     return { style: { borderRight: "1px solid #D5D5D5" } };
            // },
            customBodyRender: (
                value: any,
                tableMeta: any,
                updateValue: any,
            ) => {
                console.log("table data", value);
                return <b className="grey2">{value}</b>;
            },
        },
    },
    {
        name: "TotalAmount",
        Label: "TotalAmount",
        options: {
            customHeadLabelRender: () => (
                <span style={{ color: "white" }}>Total Amount</span>
            ),
            // setCellHeaderProps: () => {
            //     return { style: { borderRight: "1px solid #D5D5D5" } };
            // },
            // setCellProps: () => {
            //     return { style: { borderRight: "1px solid #D5D5D5" } };
            // },
            customBodyRender: (
                value: any,
                tableMeta: any,
                updateValue: any,
            ) => {
                console.log("table data", value);
                return <b className="grey2">{value}</b>;
            },
        },
    },
    {
        name: "Type",
        Label: "Type",
        options: {
            filter: true,
            sort: false,
            customHeadLabelRender: () => (
                <span style={{ color: "white" }}>Type</span>
            ),
            // setCellHeaderProps: () => {
            //     return { style: { borderRight: "1px solid #D5D5D5" } };
            // },
            // setCellProps: () => {
            //     return {
            //         style: {
            //             borderRight: "1px solid #D5D5D5",
            //             width: "10%",
            //             maxWidth: "50px !important",
            //         },
            //     };
            // },
            customBodyRender: (
                value: any,
                tableMeta: any,
                updateValue: any,
            ) => {
                console.log("table data", value);
                return <b className="green">{value}</b>;
            },
        },
    },
    {
        name: "ServiceCode",
        Label: "ServiceCode",
        options: {
            filter: true,
            sort: false,
            customHeadLabelRender: () => (
                <span style={{ color: "white" }}>ServiceCode</span>
            ),
            // setCellHeaderProps: () => {
            //     return { style: { borderRight: "1px solid #D5D5D5" } };
            // },
            // setCellProps: () => {
            //     return { style: { borderRight: "1px solid #D5D5D5" } };
            // },
            customBodyRender: (
                value: any,
                tableMeta: any,
                updateValue: any,
            ) => {
                console.log("table data", value);
                return <b className="grey2">{value}</b>;
            },
        },
    },
    {
        name: "Description",
        Label: "Description",
        options: {
            filter: true,
            sort: false,
            customHeadLabelRender: () => (
                <span style={{ color: "white" }}>Description</span>
            ),
            // setCellHeaderProps: () => {
            //     return { style: { borderRight: "1px solid #D5D5D5" } };
            // },
            // setCellProps: () => {
            //     return { style: { borderRight: "1px solid #D5D5D5" } };
            // },
            customBodyRender: (
                value: any,
                tableMeta: any,
                updateValue: any,
            ) => {
                console.log("table data", value);
                return <b className="grey2">{value}</b>;
            },
        },
    },
    {
        name: "Po",
        Label: "Po",
        options: {
            filter: true,
            sort: false,
            customHeadLabelRender: () => (
                <span style={{ color: "white" }}>
                    PO Number-
                    <br />
                    Consolidation
                </span>
            ),
            // setCellHeaderProps: () => {
            //     return { style: { borderRight: "1px solid #D5D5D5" } };
            // },
            // setCellProps: () => {
            //     return { style: { borderRight: "1px solid #D5D5D5" } };
            // },
            customBodyRender: (
                value: any,
                tableMeta: any,
                updateValue: any,
            ) => {
                console.log("table data", value);
                return <b className="grey2">{value}</b>;
            },
        },
    },
];
