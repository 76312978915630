import { combineReducers } from "redux";
import storeSwitchContext from "./SwitchContext/switchContext";
import storeCompanyList from "./Common/companyList";
import storeOuList from "./Common/ouList";
import storeDashData from "./Common/dashData";
import storeSessionId from "./storeSessionId";
import storeContext from "./storeContext";
import storeSupplier from "./storeHiddenSupplier";
import breadcrumb from "./Common/breadcrumbs";
import storeSelectedCompany from "./storeStoredCompany";
import storeSelectedCustomer from "./storeCustomerdetails";
import storeSelectedVendor from "./storeVendordetails";
import storePortal from "./Common/storePortal";
import storeCurrency from "./storeCurrency";

export default combineReducers({
    sessionId: storeSessionId,
    storeRole: storeSwitchContext,
    storeCompanies: storeCompanyList,
    storeOus: storeOuList,
    storeDashDatas: storeDashData,
    context: storeContext,
    hiddenSupplier: storeSupplier,
    breadcrumb: breadcrumb,
    selectedCompany: storeSelectedCompany,
    selectedCustomer: storeSelectedCustomer,
    selectedVendor: storeSelectedVendor,
    portalData: storePortal,
    currency: storeCurrency,
});
