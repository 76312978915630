const contactsQuery = `
query ContactInfoQuery($ActionFlag : String, $CustomerCode : String) {
    contactInfo (ActionFlag : $ActionFlag, CustomerCode : $CustomerCode)
    {
        companyDataset 
        {
            Billinginfo{
                companyname
                area
                address{
                    line1
                    line2
                }
            }
            ContactPerson{
                name
                email
                phonenumber
            }
            accountrjs
        }
        infodataset
        {
            AccountNumber
            CompanyName
            Detail{
                firstname
                jobtitle
            }
            Address{
                hno
                street
            }
            Information{
                number
                email
            }
        }
        relatedDataset
        {
            Startinfo{
                primary
                email
                phonenumber
            }
            Companyinfo{
                email
                businessnumber
            }
        }
    }
}
`;

export default contactsQuery;
