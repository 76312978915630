const AssgnQuerySV = `
query Query {
  AssignmentSV {
    Customer
    RevisionStart
    Vendor
    VendorAmount
    Replaceable
    VendorNetPercent
    {
    net
    percent
    }
    FirstMonthpro
    LastMonthpro
    CreatedOn
    }
  }
  `;

export default AssgnQuerySV;
