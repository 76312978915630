import { Grid } from "@material-ui/core";
import "Common/Main.scss";



export const ActiveRecurring= [
    {
        name: "Vendor",
        Label: "Vendor",
        options: {
            filter: true,
            sort: false,
            customHeadLabelRender: () => (
                <span style={{ color: "white" }}>Vendor</span>
            ),
            // setCellHeaderProps: () => {
            //     return { style: { borderRight: "1px solid #D5D5D5" } };
            // },
            // setCellProps: () => {
            //     return { style: { borderRight: "1px solid #D5D5D5" } };
            // },
            customBodyRender: (
                value: any,
                tableMeta: any,
                updateValue: any,
            ) => {
                console.log("table data", value);
                return <b className="blue">{value}</b>;
            },
        },
    },
    {
        name:"ServiceCode",
        Label: "ServiceCode",
        options: {
            filter: true,
            sort: false,
            customHeadLabelRender: () => (
                <span style={{ color: "white" }}>Service Code(Customer Contract)</span>
            ),
            // setCellHeaderProps: () => {
            //     return { style: { borderRight: "1px solid #D5D5D5" } };
            // },
            // setCellProps: () => {
            //     return { style: { borderRight: "1px solid #D5D5D5" } };
            // },
            customBodyRender: (
                value: any,
                tableMeta: any,
                updateValue: any,
            ) => {
                console.log("table data", value);
                return <b className="blue">{value}</b>;
            },
        },
    },
   
    
    {
        name: "Date",
        Label: "Date",
        options: {
            filter: true,
            sort: false,
            customHeadLabelRender: () => (
                <span style={{ color: "white" }}>Date</span>
            ),
            // setCellHeaderProps: () => {
            //     return { style: { borderRight: "1px solid #D5D5D5" } };
            // },
            // setCellProps: () => {
            //     return { style: { borderRight: "1px solid #D5D5D5" } };
            // },
            customBodyRender: (
                value: any,
                tableMeta: any,
                updateValue: any,
            ) => {
                console.log("table data", value);
                return <b className="blue">{value}</b>;
            },
        },
    },
    
];
