import currencyFormatter from "Common/Utils/currencyFormatter";

const RefusalSchema = [
    {
        name: "CustomerContract",
        Label: "CustomerContract",
        options: {
            filter: true,
            sort: false,
            customHeadLabelRender: () => (
                <span style={{ color: "white" }}>Customer Contract</span>
            ),
            setCellHeaderProps: () => {
                return { style: { borderRight: "1px solid #D5D5D5" } };
            },
            setCellProps: () => {
                return {
                    style: {
                        borderRight: "1px solid #D5D5D5",
                        textAlign: "center",
                    },
                };
            },
            customBodyRender: (
                value: any,
                tableMeta: any,
                updateValue: any,
            ) => {
                console.log("table data", value, tableMeta.rowData);
                return <b style={{ color: "#0073E6" }}>{value}</b>;
            },
        },
    },
    {
        name: "OfferDate",
        Label: "OfferDate",
        type: "string",
        options: {
            filter: true,
            sort: false,
            customHeadLabelRender: () => (
                <span style={{ color: "white" }}>Offer Date</span>
            ),
            setCellHeaderProps: () => {
                return { style: { borderRight: "1px solid #D5D5D5" } };
            },
            setCellProps: () => {
                return {
                    style: {
                        borderRight: "1px solid #D5D5D5",
                        textAlign: "center",
                    },
                };
            },
            customBodyRender: (
                value: any,
                tableMeta: any,
                updateValue: any,
            ) => {
                console.log("table data", value);
                return <b style={{ color: "#5C677D" }}>{value}</b>;
            },
        },
    },
    {
        name: "RefusalDate",
        Label: "RefusalDate",
        type: "string",
        options: {
            filter: true,
            sort: false,
            customHeadLabelRender: () => (
                <span style={{ color: "white" }}>Refusal Date</span>
            ),
            setCellHeaderProps: () => {
                return { style: { borderRight: "1px solid #D5D5D5" } };
            },
            setCellProps: () => {
                return {
                    style: {
                        borderRight: "1px solid #D5D5D5",
                        textAlign: "center",
                    },
                };
            },
            customBodyRender: (
                value: any,
                tableMeta: any,
                updateValue: any,
            ) => {
                console.log("table data", value);
                return <b style={{ color: "#5C677D" }}>{value}</b>;
            },
        },
    },

    {
        name: "AmountOffer",
        Label: "AmountOffer",
        options: {
            filter: true,
            sort: false,
            customHeadLabelRender: () => (
                <span style={{ color: "white" }}> Amount Offer</span>
            ),
            setCellHeaderProps: () => {
                return { style: { borderRight: "1px solid #D5D5D5" } };
            },
            setCellProps: () => {
                return {
                    style: {
                        borderRight: "1px solid #D5D5D5",
                        textAlign: "center",
                    },
                };
            },
            customBodyRender: (
                value: any,
                tableMeta: any,
                updateValue: any,
            ) => {
                console.log("table data", value);
                return (
                    <b
                        style={{
                            color: "#00AE50",
                            display: "flex",
                            justifyContent: "flex-end",
                        }}
                    >
                        {currencyFormatter(value, "USD")}
                    </b>
                );
            },
        },
    },
    {
        name: "Fulfillment",
        Label: "Fulfillment",
        options: {
            filter: true,
            sort: false,
            customHeadLabelRender: () => (
                <span style={{ color: "white" }}> Fulfillment%</span>
            ),
            setCellHeaderProps: () => {
                return { style: { borderRight: "1px solid #D5D5D5" } };
            },
            setCellProps: () => {
                return {
                    style: {
                        borderRight: "1px solid #D5D5D5",
                        textAlign: "center",
                    },
                };
            },
            customBodyRender: (
                value: any,
                tableMeta: any,
                updateValue: any,
            ) => {
                console.log("table data", value);
                return (
                    <b
                        style={{
                            color: "#00AE50",
                            display: "flex",
                            justifyContent: "flex-end",
                        }}
                    >
                        {value.toFixed(2)}
                    </b>
                );
            },
        },
    },
];

export default RefusalSchema;
