import { Grid, Paper } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { AssignmentSchema } from "./Schemas/AssignmentSchema";
import { ContractSchema } from "./Schemas/ContractSchema";
import { GeneralSchema } from "./Schemas/GeneralSchema";
import MuiDatatables from "Common/Mui-DataTables/MuiDatatables";
import ButtonGroups from "Common/ButtonGroup/ButtonGroup";
import ButtonGroupVertical from "Common/ButtonGroup/ButtonGroupVertical";
import useGetPosts from "ReactQuery/reactQuery";
import { Apartment, ContactlessTwoTone, Phone } from "@material-ui/icons";
import {
    activerecurringQuerySV,
    AssgnQuerySV,
    BuildDetQuerySV,
    // contactQuerySV,
    contractQuerySV,
    detailExpectationsQuerySV,
    generalQuerySV,
    servicedayQuerySV,
    visitsQuerySV,
} from "ReactQuery/Query";
import { Visits } from "./Schemas/Visits";
import { ActiveRecurring } from "./Schemas/ActiveRecurring";
import { Servicedays } from "./Schemas/Servicedays";
import Icons from "Common/Icons/Icons";

interface buildDet {
    Building: {
        name: String;
        id: String;
    };
    Address: {
        Area: String;
        City: String;
        State: String;
        Pincode: Number;
    };
    Phone: String;
    Status: String;
    PrimaryContact: String;
    AccountManager1: String;
    AccountManager2: String;
    AccountManager3: String;
    ContactFreq: String;
    Region: String;
    Latitude: String;
    Longitude: String;
}

interface DashCards {
    desc: string;
    value: string;
    visible: boolean;
    frequent: boolean;
    detail: {
        desc: string;
        count: number;
        color: string;
    }[];
}
interface sfdata {
    BuildingDetails: {
        TotalSf: string;
        CleanableSf: string;
        MonthlyBilling: string;
    };
    ExceptionDetails: {
        startDate: string;
        endDate: string;
    };
}

const PayableDetails = () => {
    const controlList = [
        {
            desc: "General",
            value: "General",
        },
        {
            desc: "Contract",
            value: "Contract",
        },
        {
            desc: "Assignment",
            value: "Assignment",
        },
    ];

    const verticarbarList = [
        {
            desc: "Contacts",
            value: "Contacts",
        },
        {
            desc: "Visits",
            value: "Visits",
        },
        {
            desc: "Active Recurring Vendors",
            value: "Active Recurring Vendors",
        },
        {
            desc: "Service Days",
            value: "Service Days",
        },
        {
            desc: "Details & Exceptions",
            value: "Details & Exceptions",
        },
    ];

    const [contactInfo, setcontactInfo] = useState("");
    const [contractInfo, setcontractInfo] = useState([]);

    const [generalInfo, setgeneralInfo] = useState([]);

    const [AssignmentInfo, setAssignmentInfo] = useState([]);
    const [visitsInfo, setvisitsInfo] = useState([]);
    const [servicedaysInfo, setservicedaysInfo] = useState([]);
    const [expectationinfo, setexpectationinfo] = useState<sfdata[]>([]);
    const [activerecurringInfo, setactiverecurringInfo] = useState("");
    const [activeList, setActiveList] = useState(controlList[0].desc);
    const [activeListone, setActiveListone] = useState(verticarbarList[0].desc);

    const handleIntermeditaeScreen = (listType: string) => {
        console.log("listType", listType);
        setActiveListone(listType);
    };

    const handleDocList = (listType: string) => {
        console.log("listType", listType);
        setActiveList(listType);
    };
    const [buildDet, setBuildDet] = useState<buildDet[]>([]);
    const [active, setActive] = useState("");
    const handleActive = (index: string) => {
        setActive(index);
        console.log("index", index);
    };

    // const { data: contactData, error: contactDataError } = useGetPosts({
    //     name: `contactQuerySV`,
    //     query: contactQuerySV,
    //     variables: {},
    // });

    // useEffect(() => {
    //     if (contactData?.contactDataDetailsSV) {
    //         setcontactInfo(contactData?.contactDataDetailsSV);
    //     }
    // }, [contactData]);

    const {
        data: contractData,
        error: contractDataError,
        isLoading: contractDataLoading,
    } = useGetPosts({
        name: `contractQuerySV`,
        query: contractQuerySV,
        variables: {},
    });

    useEffect(() => {
        if (contractData?.contractSV) {
            setcontractInfo(contractData?.contractSV);
        }
    }, [contractData]);

    const {
        data: AssignmentData,
        error: AssignmentDataError,
        isLoading: AssignmentDataLoading,
    } = useGetPosts({
        name: `AssgnQuerySV`,
        query: AssgnQuerySV,
        variables: {},
    });

    useEffect(() => {
        if (AssignmentData?.AssignmentSV) {
            setAssignmentInfo(AssignmentData?.AssignmentSV);
        }
    }, [AssignmentData]);

    const {
        data: generalData,
        error: generalDataError,
        isLoading: generalDataLoading,
    } = useGetPosts({
        name: `generalQuerySV`,
        query: generalQuerySV,
        variables: {},
    });

    useEffect(() => {
        if (generalData?.GeneralSV) {
            setgeneralInfo(generalData?.GeneralSV);
        }
    }, [generalData]);

    const {
        data: visitsData,
        error: visitsDataError,
        isLoading: visitsDataDataLoading,
    } = useGetPosts({
        name: `visitsQuerySV`,
        query: visitsQuerySV,
        variables: {},
    });

    useEffect(() => {
        if (visitsData?.visitsSV) {
            setvisitsInfo(visitsData?.visitsSV);
        }
    }, [visitsData]);

    const {
        data: servicedayData,
        error: servicedayDataError,
        isLoading: servicedayDataDataLoading,
    } = useGetPosts({
        name: `servicedayQuerySV`,
        query: servicedayQuerySV,
        variables: {},
    });

    useEffect(() => {
        if (servicedayData?.ServicedaysSV) {
            setservicedaysInfo(servicedayData?.ServicedaysSV);
        }
    }, [servicedayData]);

    const {
        data: ExpectationDetailData,
        error: ExpectationDetailDataError,
        isLoading: ExpectationDetailDataLoading,
    } = useGetPosts({
        name: ` detailExpectationsQuerySV`,
        query: detailExpectationsQuerySV,
        variables: {},
    });

    useEffect(() => {
        if (ExpectationDetailData?.DetailExpectationsSV) {
            setexpectationinfo(ExpectationDetailData?.DetailExpectationsSV);
        }
    }, [ExpectationDetailData]);

    const {
        data: activerecurringData,
        error: activerecurringError,
        isLoading: activerecurringDataLoading,
    } = useGetPosts({
        name: `activerecurringQuerySV`,
        query: activerecurringQuerySV,
        variables: {},
    });

    useEffect(() => {
        if (activerecurringData?.activerecurringSV) {
            setactiverecurringInfo(activerecurringData?.activerecurringSV);
        }
    }, [activerecurringData]);

    const {
        data: BuildDetData,
        error: BuildDetError,
        isLoading: BuildDetLoading,
    } = useGetPosts({
        name: "BuildDetQuerySV",
        query: BuildDetQuerySV,
        variables: {
            entity: "OW_Cust_portal_BuildingHdr",
            ou: 5,
            service: "",
            User: "ramcouser",
            languageId: 1,
            customerCode: "C1000000",
            customerName: "",
            siteId: "A1",
            merrorid: "",
        },
    });
    useEffect(() => {
        if (BuildDetData?.BuildDetQuerySV) {
            setBuildDet(BuildDetData?.BuildDetQuerySV);
        }
    }, [BuildDetData]);

    // useEffect(() => {
    //     if (BuildDetError && !BuildDetLoading) {
    //         const data = JSON.parse(JSON.stringify(BuildDetError));
    //         const errmsg = data?.response?.errors[0].message;
    //         seterr({
    //             code: 400,
    //             msg: errmsg,
    //         });
    //         setAlert(true);
    //     }
    // }, [BuildDetError, BuildDetLoading]);

    return (
        <>
            <Grid container spacing={1} style={{ padding: "0 0 2%" }}>
                <Grid item xs={12}>
                    <Paper variant="outlined">
                        {buildDet.map(
                            (
                                element: {
                                    Building: {
                                        name: String;
                                        id: String;
                                    };
                                    Address: {
                                        Area: String;
                                        City: String;
                                        State: String;
                                        Pincode: Number;
                                    };
                                    Phone: String;
                                    Status: String;
                                    PrimaryContact: String;
                                    AccountManager1: String;
                                    AccountManager2: String;
                                    AccountManager3: String;
                                    ContactFreq: String;
                                    Region: String;
                                    Latitude: String;
                                    Longitude: String;
                                },
                                index: any,
                            ) => (
                                <Grid
                                    container
                                    direction="row"
                                    spacing={2}
                                    className="padding-2-percent"
                                >
                                    <Grid
                                        item
                                        xs={4}
                                        style={{
                                            borderRight:
                                                "0.2px dashed lightgray",
                                        }}
                                    >
                                        <Grid container spacing={1}>
                                            <Grid item xs={12}>
                                                <Grid container>
                                                    <Grid
                                                        item
                                                        xs={4}
                                                        className="grey2 text-left font-boldest"
                                                    >
                                                        <Grid container>
                                                            <Grid item>
                                                                <ContactlessTwoTone />
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                style={{
                                                                    paddingLeft:
                                                                        "2%",
                                                                }}
                                                            >
                                                                {
                                                                    element
                                                                        .Building
                                                                        .id
                                                                }
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={8}
                                                        className="grey2 font-boldest text-right"
                                                    >
                                                        <Grid
                                                            container
                                                            alignItems="center"
                                                        >
                                                            <Grid item>
                                                                <Apartment />
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                style={{
                                                                    paddingLeft:
                                                                        "2%",
                                                                }}
                                                            >
                                                                {
                                                                    element
                                                                        .Building
                                                                        .name
                                                                }
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container>
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        className="grey text-left font-bigger"
                                                    >
                                                        <Grid container>
                                                            <Grid item>
                                                                <Icons Icon="location"></Icons>
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                style={{
                                                                    paddingLeft:
                                                                        "2%",
                                                                }}
                                                            >
                                                                {
                                                                    element
                                                                        .Address
                                                                        .Area
                                                                }{" "}
                                                                |
                                                                {
                                                                    element
                                                                        .Address
                                                                        .City
                                                                }{" "}
                                                                |
                                                                {
                                                                    element
                                                                        .Address
                                                                        .State
                                                                }
                                                                |
                                                                {
                                                                    element
                                                                        .Address
                                                                        .Pincode
                                                                }
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid
                                                    container
                                                    justifyContent="space-between"
                                                >
                                                    <Grid
                                                        item
                                                        xs={6}
                                                        className="grey2 font-bolder"
                                                    >
                                                        <Grid container>
                                                            <Grid item>
                                                                <Phone />
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                style={{
                                                                    paddingLeft:
                                                                        "2%",
                                                                    textAlign:
                                                                        "left",
                                                                }}
                                                            >
                                                                {element.Phone}
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        className="font-bolder text-center"
                                                    >
                                                        {element.Status}
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={8}
                                        style={{ paddingLeft: "2%" }}
                                    >
                                        <Grid container spacing={1}>
                                            <Grid item xs={12}>
                                                <Grid container>
                                                    <Grid item xs={12} sm={3}>
                                                        <Grid container>
                                                            <Grid
                                                                item
                                                                xs={12}
                                                                className="text-left grey"
                                                            >
                                                                Primary Contact
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                xs={12}
                                                                className="grey2 font-boldest text-left"
                                                            >
                                                                {
                                                                    element.PrimaryContact
                                                                }
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={12} sm={3}>
                                                        <Grid container>
                                                            <Grid
                                                                item
                                                                xs={12}
                                                                className="text-left grey"
                                                            >
                                                                Account Manager
                                                                1
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                xs={12}
                                                                className="text-left grey2 font-boldest"
                                                            >
                                                                {
                                                                    element.AccountManager1
                                                                }
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={12} sm={3}>
                                                        <Grid container>
                                                            <Grid
                                                                item
                                                                xs={12}
                                                                className="text-left grey"
                                                            >
                                                                Account Manager
                                                                2
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                xs={12}
                                                                className="text-left grey2 font-boldest"
                                                            >
                                                                {
                                                                    element.AccountManager2
                                                                }
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={12} sm={3}>
                                                        <Grid container>
                                                            <Grid
                                                                item
                                                                xs={12}
                                                                className="text-left grey"
                                                            >
                                                                Account Manager
                                                                3
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                xs={12}
                                                                className="text-left grey2 font-boldest"
                                                            >
                                                                {
                                                                    element.AccountManager3
                                                                }
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container>
                                                    <Grid item xs={12} sm={3}>
                                                        <Grid container>
                                                            <Grid
                                                                item
                                                                xs={12}
                                                                className="text-left grey"
                                                            >
                                                                Contact
                                                                Frequency
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                xs={12}
                                                                className="text-left grey2 font-boldest"
                                                            >
                                                                {
                                                                    element.ContactFreq
                                                                }
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={12} sm={3}>
                                                        <Grid container>
                                                            <Grid
                                                                item
                                                                xs={12}
                                                                className="text-left grey"
                                                            >
                                                                Region
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                xs={12}
                                                                className="text-left grey2 font-boldest"
                                                            >
                                                                {element.Region}
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={12} sm={3}>
                                                        <Grid container>
                                                            <Grid
                                                                item
                                                                xs={12}
                                                                className="text-left grey"
                                                            >
                                                                Latitude
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                xs={12}
                                                                className="text-left grey2 font-boldest"
                                                            >
                                                                {
                                                                    element.Latitude
                                                                }
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={12} sm={3}>
                                                        <Grid container>
                                                            <Grid
                                                                item
                                                                xs={12}
                                                                className="text-left grey"
                                                            >
                                                                Longitude
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                xs={12}
                                                                className="text-left grey2 font-boldest"
                                                            >
                                                                {
                                                                    element.Longitude
                                                                }
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            ),
                        )}
                    </Paper>
                </Grid>
                {/* <Grid item xs={3}>
                    <Paper variant="outlined">
                        <Grid container spacing={2} alignItems="center">
                            <Grid item xs></Grid>
                            <Grid item xs={10}>
                                <Grid
                                    container
                                    spacing={2}
                                    style={{
                                        margin: "0%",
                                        padding: "2% 0% 0% 0%",
                                    }}
                                >
                                    {header?.detail.map(
                                        (
                                            element:any,
                                            index,
                                        ) => {
                                            return (
                                                <Grid
                                                    item
                                                    xs={6}
                                                    sm={6}
                                                    className={
                                                        active === element?.desc
                                                            ? `${element?.color}_border_bottom pointer`
                                                            : "pointer"
                                                    }
                                                    onClick={() =>
                                                        handleActive(
                                                            element?.desc,
                                                        )
                                                    }
                                                >
                                                    <Grid container spacing={1}>
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            sm={12}
                                                        >
                                                            <span
                                                                className={`${
                                                                    active ===
                                                                    element?.desc
                                                                        ? element?.color
                                                                        : "grey"
                                                                } font-biggest font-boldest`}
                                                            >
                                                                {element?.count}
                                                            </span>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            sm={12}
                                                        >
                                                            <span
                                                                className={
                                                                    active ===
                                                                    element?.desc
                                                                        ? `font-boldest font-smaller`
                                                                        : "grey font-smaller"
                                                                }
                                                            >
                                                                {element?.desc}
                                                            </span>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            );
                                        },
                                    )}
                                </Grid>
                            </Grid>
                            <Grid item xs></Grid>
                        </Grid>
                    </Paper>
                </Grid> */}

                <Grid item xs={12}>
                    <ButtonGroups
                        controlList={controlList}
                        handleDocList={handleDocList}
                        gridSpacing={2}
                        externalActive={activeList}
                    />
                </Grid>
            </Grid>

            {activeList === "General" ? (
                <>
                    <Grid container spacing={3}>
                        <Grid item xs={2}>
                            <ButtonGroupVertical
                                controlList={verticarbarList}
                                handleDocList={handleIntermeditaeScreen}
                                gridSpacing={12}
                                externalActive={activeListone}
                            />
                        </Grid>
                        <Grid item xs={7}>
                            {activeListone === "Contacts" ? (
                                <MuiDatatables
                                    title={""}
                                    header={GeneralSchema}
                                    detail={[generalInfo, setgeneralInfo]}
                                    // handleAddSideEffect={handleAddSideEffect}
                                    // handleDeleteSideEffect={handleDeleteSideEffect}
                                    initRow={false}
                                />
                            ) : activeListone === "Visits" ? (
                                <MuiDatatables
                                    title={""}
                                    header={Visits}
                                    detail={[visitsInfo, setvisitsInfo]}
                                    // handleAddSideEffect={handleAddSideEffect}
                                    // handleDeleteSideEffect={handleDeleteSideEffect}
                                    initRow={false}
                                />
                            ) : activeListone === "Active Recurring Vendors" ? (
                                <MuiDatatables
                                    title={""}
                                    header={ActiveRecurring}
                                    detail={[
                                        activerecurringInfo,
                                        setactiverecurringInfo,
                                    ]}
                                    // handleAddSideEffect={handleAddSideEffect}
                                    // handleDeleteSideEffect={handleDeleteSideEffect}
                                    initRow={false}
                                />
                            ) : activeListone === "Service Days" ? (
                                <MuiDatatables
                                    title={""}
                                    header={Servicedays}
                                    detail={[
                                        servicedaysInfo,
                                        setservicedaysInfo,
                                    ]}
                                    // handleAddSideEffect={handleAddSideEffect}
                                    // handleDeleteSideEffect={handleDeleteSideEffect}
                                    initRow={false}
                                />
                            ) : (
                                <Grid>
                                    <Grid item xs={4}>
                                        <Grid container>
                                            <Grid
                                                item
                                                xs={12}
                                                className="text-left grey2 font-bigger font-bolder"
                                                style={{ padding: "3% 0" }}
                                            >
                                                Building Details
                                            </Grid>
                                        </Grid>

                                        <Paper variant="outlined">
                                            <Grid
                                                container
                                                spacing={2}
                                                className="padding-2-percent"
                                            >
                                                <Grid item xs={12}>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={12}>
                                                            <Grid container>
                                                                <Grid
                                                                    item
                                                                    xs={6}
                                                                    className="text-left grey font-boldest"
                                                                >
                                                                    Total SF
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xs={6}
                                                                    className="text-right grey2 font-bolder"
                                                                >
                                                                    {!ExpectationDetailDataLoading
                                                                        ? expectationinfo[0]
                                                                              ?.BuildingDetails
                                                                              ?.TotalSf
                                                                        : ""}
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid item xs={12}>
                                                            <Grid container>
                                                                <Grid
                                                                    item
                                                                    xs={6}
                                                                    className="text-left grey font-boldest"
                                                                >
                                                                    Cleanable SF
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xs={6}
                                                                    className="text-right grey2 font-bolder"
                                                                >
                                                                    {!ExpectationDetailDataLoading
                                                                        ? expectationinfo[0]
                                                                              ?.BuildingDetails
                                                                              ?.CleanableSf
                                                                        : ""}
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid item xs={12}>
                                                            <Grid container>
                                                                <Grid
                                                                    item
                                                                    xs={6}
                                                                >
                                                                    <Grid
                                                                        container
                                                                    >
                                                                        <Grid
                                                                            item
                                                                            xs={
                                                                                12
                                                                            }
                                                                            className="text-left grey font-boldest"
                                                                        >
                                                                            Total
                                                                            Monthly
                                                                            Billing
                                                                        </Grid>
                                                                        <Grid
                                                                            item
                                                                            xs={
                                                                                12
                                                                            }
                                                                            className="text-left grey font-boldest"
                                                                        >
                                                                            Last
                                                                            updated
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xs={6}
                                                                    className="text-right green"
                                                                >
                                                                    {!ExpectationDetailDataLoading
                                                                        ? expectationinfo[0]
                                                                              ?.BuildingDetails
                                                                              ?.MonthlyBilling
                                                                        : ""}
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Paper>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Grid container>
                                            <Grid
                                                item
                                                xs={12}
                                                className="text-left grey2 font-bigger font-bolder"
                                                style={{ padding: "3% 0" }}
                                            >
                                                Exception
                                            </Grid>
                                        </Grid>

                                        <Paper variant="outlined">
                                            <Grid
                                                container
                                                spacing={2}
                                                className="padding-2-percent"
                                            >
                                                <Grid item xs={12}>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={12}>
                                                            <Grid container>
                                                                <Grid
                                                                    item
                                                                    xs={6}
                                                                    className="text-left grey font-boldest"
                                                                >
                                                                    Exception
                                                                    Start Date
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xs={6}
                                                                    className="text-right grey2 font-bolder"
                                                                >
                                                                    {!ExpectationDetailDataLoading
                                                                        ? expectationinfo[0]
                                                                              ?.ExceptionDetails
                                                                              ?.startDate
                                                                        : ""}
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid item xs={12}>
                                                            <Grid container>
                                                                <Grid
                                                                    item
                                                                    xs={6}
                                                                    className="text-left grey font-boldest"
                                                                >
                                                                    Exception
                                                                    End Date
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xs={6}
                                                                    className="text-right grey2 font-bolder"
                                                                >
                                                                    {!ExpectationDetailDataLoading
                                                                        ? expectationinfo[0]
                                                                              ?.ExceptionDetails
                                                                              ?.endDate
                                                                        : ""}
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Paper>
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </>
            ) : activeList === "Contract" ? (
                <MuiDatatables
                    title={""}
                    header={ContractSchema}
                    detail={[contractInfo, setcontractInfo]}
                    // handleAddSideEffect={handleAddSideEffect}
                    // handleDeleteSideEffect={handleDeleteSideEffect}
                    initRow={false}
                />
            ) : (
                <MuiDatatables
                    title={""}
                    header={AssignmentSchema}
                    detail={[AssignmentInfo, setAssignmentInfo]}
                    // handleAddSideEffect={handleAddSideEffect}
                    // handleDeleteSideEffect={handleDeleteSideEffect}
                    initRow={false}
                />
            )}
        </>
    );
};

export default PayableDetails;
