import {
    Grid,
    Paper,
    Backdrop,
    CircularProgress,
    makeStyles,
    Theme,
} from "@material-ui/core";
import { useEffect, useState } from "react";
import "Common/Main.scss";
import ButtonGroups from "Common/ButtonGroup/ButtonGroup";
import MuiDatatables from "Common/Mui-DataTables/MuiDatatables";
import { ContactSchema } from "./Schemas/ContactSchema";
import {
    contactsQuery,
    // information,
    // primarycontactQuery,
} from "ReactQuery/Query";
import useGetPosts from "ReactQuery/reactQuery";
import { storeSupplier } from "stateManagement/actions/HomePage/storeHiddenSupplier";
import { connect } from "react-redux";
import Icons from "Common/Icons/Icons";

const useStyles = makeStyles((theme: Theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
}));

interface contactInfo {
    companyDataset: {
        Billinginfo: {
            companyname: string;
            area: String;
            address: {
                line1: String;
                line2: String;
            };
        };
        ContactPerson: {
            name: String;
            email: String;
            phonenumber: String;
        };
        accountrjs: String;
    };
    infodataset: {
        AccountNumber: String;
        CompanyName: String;
        Detail: {
            firstname: String;
            jobtitle: String;
        };
        Address: {
            hno: String;
            street: String;
        };
        Information: {
            number: String;
            email: String;
        };
    }[];
    relatedDataset: {
        Startinfo: {
            primary: String;
            email: String;
            phonenumber: String;
        };
        Companyinfo: {
            email: String;
            businessnumber: String;
        };
    }[];
}

const Body = ({ currentContext, selectedCustomer }: any) => {
    const classes = useStyles();
    const controlList = [
        {
            desc: "Contacts",
            value: "Contacts",
        },
        {
            desc: "Primary Contacts",
            value: "Primary Contacts",
        },
    ];

    const [activeList, setActiveList] = useState(controlList[0].desc);

    const handleDocList = (listType: string) => {
        console.log("listType", listType);
        setActiveList(listType);
    };

    const [contactInfo, setcontactInfo] = useState<contactInfo>();
    const [informationDetails, setinformationDetails] = useState([]);
    // const [primarycontactinfo, setprimarycontactinfo] = useState<
    //     startinfodata[]
    // >([]);

    // const {
    //     data: informationData,
    //     error: informationDataError,
    //     isLoading: informationDataLoading,
    // } = useGetPosts({
    //     name: `information`,
    //     query: information,
    //     variables: {},
    // });

    // useEffect(() => {
    //     if (informationData?.information) {
    //         setinformationDetails(informationData?.information);
    //     }
    // }, [informationData]);

    // const {
    //     data: primarycontactData,
    //     error: primarycontactDataError,
    //     isLoading: primarycontactDataLoading,
    // } = useGetPosts({
    //     name: `primarycontact`,
    //     query: primarycontactQuery,
    //     variables: {},
    // });
    // useEffect(() => {
    //     if (primarycontactData?.Primarycontact) {
    //         setprimarycontactinfo(primarycontactData?.Primarycontact);
    //     }
    // }, [primarycontactData]);

    // console.log("=========>>primarycontactinfo", primarycontactinfo);

    const {
        data: contactsData,
        error: contactsDataError,
        isLoading: contactsDataLoading,
    } = useGetPosts({
        name: `contacts_${selectedCustomer?.companyCode}`,
        query: contactsQuery,
        variables: {
            ActionFlag: "ALL",
            CustomerCode: selectedCustomer?.companyCode,
        },
        header: {
            ou: currentContext?.ouId,
            role: currentContext?.roleName,
            lang: currentContext?.langId,
        },
    });

    useEffect(() => {
        if (contactsData?.contactInfo) {
            setcontactInfo(contactsData?.contactInfo);
            setinformationDetails(contactsData?.contactInfo?.infodataset);
            console.log(contactsData?.contactInfo);
        }
    }, [contactsData]);

    return (
        <>
            <Backdrop className={classes.backdrop} open={contactsDataLoading}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <Grid
                container
                spacing={2}
                style={{ padding: "0 0 2%", marginTop: "2%" }}
            >
                <Grid item xs={9}>
                    <Paper elevation={1}>
                        <Grid
                            container
                            spacing={2}
                            className="padding-1-percent"
                        >
                            <Grid
                                item
                                xs={3}
                                style={{ borderRight: "1px dashed lightgray" }}
                            >
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Grid container spacing={1}>
                                            <Grid
                                                item
                                                xs={12}
                                                className="text-left grey font-boldest"
                                            >
                                                Logo
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}
                                                className="text-left grey2 font-bolder"
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                }}
                                            >
                                                <Paper
                                                    variant="outlined"
                                                    style={{
                                                        maxWidth: "75px",
                                                        maxHeight: "75px",
                                                        minWidth: "75px",
                                                        minHeight: "75px",
                                                        borderRadius: "100px",
                                                        display: "flex",
                                                        justifyContent:
                                                            "center",
                                                    }}
                                                >
                                                    <Icons
                                                        Icon="buildingDummy"
                                                        style={{
                                                            display:
                                                                "inline-block",
                                                            marginTop: "15px",
                                                            maxWidth: "45px",
                                                            maxHeight: "45px",
                                                            minHeight: "45px",
                                                            minWidth: "45px",
                                                        }}
                                                    ></Icons>
                                                </Paper>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>

                            {/* <Grid
                                xs={1}
                                container
                                style={{
                                    margin: "0%",
                                    padding: "2% 0% 0% 0%",
                                    borderRight: "1px dashed #C9CDD6",
                                }}
                            ></Grid> */}
                            <Grid item xs={3}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Grid container spacing={1}>
                                            <Grid
                                                item
                                                xs={12}
                                                className="text-left grey font-boldest"
                                            >
                                                Billing Info
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}
                                                className="text-left grey2 font-bolder"
                                            >
                                                {
                                                    contactInfo?.companyDataset
                                                        ?.Billinginfo
                                                        ?.companyname
                                                }
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}
                                                className="text-left grey2 font-bolder"
                                            >
                                                {
                                                    contactInfo?.companyDataset
                                                        ?.Billinginfo?.area
                                                }
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}
                                                className="text-left grey2 font-bolder"
                                            >
                                                {
                                                    contactInfo?.companyDataset
                                                        ?.Billinginfo?.address
                                                        ?.line1
                                                }
                                                {
                                                    contactInfo?.companyDataset
                                                        ?.Billinginfo?.address
                                                        ?.line2
                                                }
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={3}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Grid container spacing={1}>
                                            <Grid
                                                item
                                                xs={12}
                                                className="text-left grey font-boldest"
                                            >
                                                Contact Person
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}
                                                className="text-left grey2 font-bolder"
                                            >
                                                {
                                                    contactInfo?.companyDataset
                                                        ?.ContactPerson?.name
                                                }
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}
                                                className="text-left grey2 font-bolder"
                                            >
                                                {
                                                    contactInfo?.companyDataset
                                                        ?.ContactPerson?.email
                                                }
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}
                                                className="text-left grey2 font-bolder"
                                            >
                                                {
                                                    contactInfo?.companyDataset
                                                        ?.ContactPerson
                                                        ?.phonenumber
                                                }
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={3}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Grid container spacing={1}>
                                            <Grid
                                                item
                                                xs={12}
                                                className="text-left grey font-boldest"
                                            >
                                                Account RJS
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}
                                                className="text-left  green"
                                            >
                                                {
                                                    contactInfo?.companyDataset
                                                        ?.accountrjs
                                                }
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>

                <Grid item xs={12}></Grid>

                <Grid item xs={12} sm={8}>
                    <ButtonGroups
                        controlList={controlList}
                        handleDocList={handleDocList}
                        gridSpacing={2}
                        externalActive={activeList}
                    />
                </Grid>
                <Grid item xs={12}></Grid>
                <Grid item xs={11}>
                    {activeList === "Contacts" ? (
                        <MuiDatatables
                            title={""}
                            header={ContactSchema}
                            detail={[informationDetails, setinformationDetails]}
                            // handleAddSideEffect={handleAddSideEffect}
                            // handleDeleteSideEffect={handleDeleteSideEffect}
                            initRow={false}
                        />
                    ) : (
                        <Grid container spacing={2}>
                            <Grid item xs={4}>
                                <Grid container>
                                    <Grid
                                        item
                                        xs={12}
                                        className="text-left grey2 font-bigger font-bolder"
                                        style={{ padding: "3% 0 6% 0%" }}
                                    >
                                        Primary Contact Person
                                    </Grid>
                                    <Grid item xs={12}></Grid>
                                    <Grid item xs={12}>
                                        <Paper elevation={1}>
                                            <Grid
                                                container
                                                spacing={2}
                                                style={{ padding: "3% 5%" }}
                                            >
                                                <Grid item xs={12}>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={12}>
                                                            <Grid container>
                                                                <Grid
                                                                    item
                                                                    xs={6}
                                                                    className="text-left grey font-boldest"
                                                                >
                                                                    Primary
                                                                    Contact
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xs={6}
                                                                    className="text-right grey2 font-bolder"
                                                                >
                                                                    {
                                                                        contactInfo
                                                                            ?.relatedDataset[0]
                                                                            ?.Startinfo
                                                                            ?.primary
                                                                    }
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Grid container>
                                                                <Grid
                                                                    item
                                                                    xs={6}
                                                                    className="text-left grey font-boldest"
                                                                >
                                                                    Phone Number
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xs={6}
                                                                    className="text-right grey2 font-bolder"
                                                                >
                                                                    {
                                                                        contactInfo
                                                                            ?.relatedDataset[0]
                                                                            ?.Startinfo
                                                                            ?.phonenumber
                                                                    }
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Grid container>
                                                                <Grid
                                                                    item
                                                                    xs={6}
                                                                    className="text-left grey font-boldest"
                                                                >
                                                                    E-Mail
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xs={6}
                                                                    className="text-right grey2 font-bolder"
                                                                >
                                                                    {
                                                                        contactInfo
                                                                            ?.relatedDataset[0]
                                                                            ?.Startinfo
                                                                            ?.email
                                                                    }
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Paper>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item xs={4}>
                                <Grid container>
                                    <Grid
                                        item
                                        xs={12}
                                        className="text-left grey2 font-bigger font-bolder"
                                        style={{ padding: "3% 0 6% 0%" }}
                                    >
                                        Company Contact
                                    </Grid>
                                    <Grid item xs={12}></Grid>
                                    <Grid item xs={12}>
                                        <Paper elevation={1}>
                                            <Grid
                                                container
                                                spacing={2}
                                                style={{ padding: "3% 5%" }}
                                            >
                                                <Grid item xs={12}>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={12}>
                                                            <Grid container>
                                                                <Grid
                                                                    item
                                                                    xs={4}
                                                                    className="text-left grey font-boldest"
                                                                >
                                                                    E-Mail
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xs
                                                                    className="text-right grey2 font-bolder"
                                                                >
                                                                    {
                                                                        contactInfo
                                                                            ?.relatedDataset[0]
                                                                            ?.Companyinfo
                                                                            ?.email
                                                                    }
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Grid container>
                                                                <Grid
                                                                    item
                                                                    xs={4}
                                                                    className="text-left grey font-boldest"
                                                                >
                                                                    Business
                                                                    Number
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xs
                                                                    className="text-right grey2 font-bolder"
                                                                >
                                                                    {
                                                                        contactInfo
                                                                            ?.relatedDataset[0]
                                                                            ?.Companyinfo
                                                                            ?.businessnumber
                                                                    }
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Paper>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </>
    );
};

const mapStateToProps = (state: any) => {
    return {
        currentContext: state.context?.userDefaults,
        selectedCustomer: state.selectedCustomer,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        setSupplier: (data: any) => {
            dispatch(storeSupplier(data));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Body);
