import "Common/Main.scss";

export const ActiveRecurring = [
    {
        name: "Vendor",
        Label: "Vendor",
        options: {
            filter: true,
            sort: false,
            customHeadLabelRender: () => (
                <span style={{ color: "white" }}>Vendor</span>
            ),
            setCellHeaderProps: () => {
                return { style: { borderRight: "1px solid #D5D5D5" } };
            },
            setCellProps: () => {
                return {
                    style: {
                        borderRight: "1px solid #D5D5D5",
                        textAlign: "center",
                    },
                };
            },

            customBodyRender: (
                value: any,
                tableMeta: any,
                updateValue: any,
            ) => {
                console.log("table data", value);
                return <b className="grey2">{value}</b>;
            },
        },
    },
    {
        name: "ServiceCode",
        Label: "ServiceCode",
        options: {
            filter: true,
            sort: false,
            customHeadLabelRender: () => (
                <span style={{ color: "white" }}>
                    Service Code (Customer Contract)
                </span>
            ),
            setCellHeaderProps: () => {
                return {
                    style: {
                        borderRight: "1px solid #D5D5D5",
                        minWidth: "100px",
                        maxWidth: "100px",
                    },
                };
            },
            setCellProps: () => {
                return {
                    style: {
                        borderRight: "1px solid #D5D5D5",
                        textAlign: "center",
                    },
                };
            },

            customBodyRender: (
                value: any,
                tableMeta: any,
                updateValue: any,
            ) => {
                console.log("table data", value);
                return <b className="blue">{value}</b>;
            },
        },
    },
    {
        name: "StartDate",
        Label: "StartDate",
        options: {
            filter: true,
            sort: false,
            customHeadLabelRender: () => (
                <span style={{ color: "white" }}>Date</span>
            ),
            setCellHeaderProps: () => {
                return { style: { borderRight: "1px solid #D5D5D5" } };
            },
            setCellProps: () => {
                return {
                    style: {
                        borderRight: "1px solid #D5D5D5",
                        textAlign: "center",
                    },
                };
            },
            customBodyRender: (
                value: any,
                tableMeta: any,
                updateValue: any,
            ) => {
                console.log("table data", value);
                return <b className="blue">{value}</b>;
            },
        },
    },
];
