const contactQuery = `
mutation(
    $entity:String
    $ou:Int
    $service:String
    $User:String
    $languageId:Int
    $VendorCode:String
    $Vendorname:String
    $merrorid:String
){
    billingdata(
        entity:$entity
        ou:$ou
        service:$service
        User:$User
        languageId:$languageId
        VendorCode:$VendorCode
        Vendorname:$Vendorname
        merrorid:$merrorid
    ){
        Billinginfo{
            companyname
            area
            address{
                line1
                line2
            }
        }
        ContactPerson{
            name
            email
            phonenumber
        }
        DateIncorporated{
            date
            rating
            ratingumber
            SupplierGroup
        }
    }
}
`;

export default contactQuery;
