const contactCardQuery = `
mutation(
    $entity: String
    $ou: Int
    $service: String
    $User: String
    $languageId: Int
    $contractno: String
    $conttype: String
    $customerCode: String
    $siteId: String
    $buildName: String
    $merrorid: String
) {
    ContractCardQuery(
        entity: $entity
        ou: $ou
        service: $service
        User: $User
        languageId: $languageId
        contractno: $contractno
        conttype: $conttype
        customerCode: $customerCode
        siteId: $siteId
        buildName: $buildName
        merrorid: $merrorid
    )
    {
        ContractID
        Building
        VendorContract
        Amount
        Customer 
        SalesPerson
        ContractStartEnd
        ProrstedAmtStartEnd{
            line1
            line2
        }
        Revision 
        ContractExpirationDate
        RevisionStratEnd
        Status
    }
    
  }
  `;

export default contactCardQuery;
