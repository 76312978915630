import Add from "./Images/Add.svg";
import AddCircle from "./Images/AddCircle.svg";
import Address from "./Images/Address.svg";
import AlertsNotifs from "./Images/AlertsNotifs.svg";
import ArrowDown from "./Images/ArrowDown.svg";
import BlueAttach from "./Images/Attach.svg";
import Attachments from "./Images/Attachments.svg";
import AttachFileIcon from "./Images/AttachmentsBig.svg";
import Basket from "./Images/Basket.svg";
import BlueFlag from "./Images/BlueFlag.svg";
import Calendar from "./Images/Calendar.svg";
import Calls from "./Images/Calls.svg";
import Catalogue from "./Images/Catalogue.svg";
import Checklist from "./Images/Checklist.svg";
import ClientBuilding from "./Images/ClientBuilding.svg";
import Consignment from "./Images/Consignment.svg";
import Delete from "./Images/Delete.svg";
import Filter from "./Images/Filter.svg";
import FrequentAction from "./Images/FrequentAction.svg";
import GreenTick from "./Images/GreenTick.svg";
import GreyTick from "./Images/GreyTick.svg";
import GridView from "./Images/GridView.svg";
import GridViewDark from "./Images/GridViewDark.svg";
import Home from "./Images/Home.svg";
import Info from "./Images/Info.svg";
import ListView from "./Images/ListView.svg";
import ListViewDark from "./Images/ListViewDark.svg";
import Options from "./Images/Options.svg";
import PendingActions from "./Images/PendingActions.svg";
import Print from "./Images/Print.svg";
import QRScanner from "./Images/QRScanner.svg";
import Revision from "./Images/Revision.svg";
import Recycle from "./Images/Recycle.svg";
import Search from "./Images/Search.svg";
import SearchDocs from "./Images/SearchDocs.svg";
import Setting from "./Images/Setting.svg";
import SettingMedium from "./Images/SettingMedium.svg";
import SmallArrowRight from "./Images/SmallArrowRight.svg";
import SmallTimer from "./Images/SmallTimer.svg";
import StopWatch from "./Images/StopWatch.svg";
import Timer from "./Images/Timer.svg";
import TurnOver from "./Images/TurnOver.svg";
import Van from "./Images/Van.svg";
import Location from "./Images/Location.svg";
import User from "./Images/User.svg";
import DocumentAttach from "./Images/DocumentAttach.svg";
import Map from "./Images/Map.svg";
import UploadImg from "./Images/UploadWhite.svg";
import ArrowRightCircleBlue from "./Images/ArrowRightCircleBlue.svg";
import EditCircleBlue from "./Images/EditCircleBlue.svg";
import YellowDot from "./Images/YellowDot.svg";
import GreenDot from "./Images/GreenDot.svg";
import TurnoverWhite from "./Images/TurnoverWhite.svg";
import AttachmentsIconBlue from "./Images/AttachmentIconBlue.svg";
import PendingDocs from "./Images/PendingDocs.svg";
import Inquire from "./Images/Inquire.svg";
import StatementAccount from "./Images/StatementAccount.svg";
import RecentPayments from "./Images/RecentPayments.svg";
import HighlightedAttachment from "./Images/HighlightedAttachment.svg";
import Receipts from "./Images/Receipts.svg";
import SearchBlue from "./Images/SearchBlue.svg";
import GreyHouse from "./Images/GreyHouse.svg";
import DocReceipt from "./Images/DocReceipt.svg";
import DocSelected from "./Images/DocSelected.svg";
import CalendarBig from "./Images/CalendarBig.svg";
import Retry from "./Images/Retry.svg";
import More from "./Images/More.svg";
import Edit from "./Images/Edit.svg";
import Confirm from "./Images/Confirm.svg";
import DeleteBlue from "./Images/DeleteBlue.svg";
import PrintWithoutCircle from "./Images/PrintWithoutCircle.svg";
import Tracking from "./Images/Tracking.svg";
import InvoiceSummary from "./Images/InvoiceSummary.svg";
import Notes from "./Images/Notes.svg";
import Request from "./Images/Request.svg";
import ChecklistBlue from "./Images/ChecklistBlue.svg";
import InfoWhiteList from "./Images/InfoWhite.svg";
import CloseGrey from "./Images/CloseGrey.svg";
import Building from "./Images/Building.svg";
import CloseRed from "./Images/CloseRed.svg";
import BuildingDummy from "./Images/BuildingIcon.svg";
import MobileDash from "./Images/MobileDash.svg";
import MailDash from "./Images/MailDash.svg";
import ContractDetail from "./Images/ContractDetail.svg";
import BuildingDetail from "./Images/BuildingDetail.svg";
import VendorContract from "./Images/VendorContract.svg";
import LocationDetail from "./Images/LocationDetail.svg";
import SettingDetail from "./Images/SettingDetail.svg";
import buildingContract from "./Images/buildingContract.svg";

interface IconProps {
    Icon: string;
    info?: string;
    style?: any;
}

const Icons = (props: IconProps) => {
    const { Icon, info, style } = props;
    switch (Icon) {
        case "buildingContract":
            return <img alt="" style={style} src={buildingContract} />;
        case "settingDetail":
            return <img alt="" style={style} src={SettingDetail} />;
        case "locationDetail":
            return <img alt="" style={style} src={LocationDetail} />;
        case "vendorContract":
            return <img alt="" style={style} src={VendorContract} />;
        case "buildingDetail":
            return <img alt="" style={style} src={BuildingDetail} />;
        case "contractDetail":
            return <img alt="" style={style} src={ContractDetail} />;
        case "mailDash":
            return <img alt="" style={style} src={MailDash} />;
        case "mobileDash":
            return <img alt="" style={style} src={MobileDash} />;
        case "buildingDummy":
            return <img alt="" style={style} src={BuildingDummy} />;
        case "closeRed":
            return <img alt="" style={style} src={CloseRed} />;
        case "add":
            return <img alt="" style={style} src={Add} />;
        case "building":
            return <img alt="" style={style} src={Building} />;
        case "closeGrey":
            return <img alt="" style={style} src={CloseGrey} />;
        case "infowhite":
            return <img alt="" style={style} src={InfoWhiteList} />;
        case "checklistBlue":
            return <img alt="" style={style} src={ChecklistBlue} />;
        case "edit":
            return <img alt="" style={style} src={Edit} />;
        case "confirm":
            return <img alt="" style={style} src={Confirm} />;
        case "deleteBlue":
            return <img alt="" style={style} src={DeleteBlue} />;
        case "printWithoutCircle":
            return <img alt="" style={style} src={PrintWithoutCircle} />;
        case "tracking":
            return <img alt="" style={style} src={Tracking} />;
        case "invoiceSummary":
            return <img alt="" style={style} src={InvoiceSummary} />;
        case "notes":
            return <img alt="" style={style} src={Notes} />;
        case "request":
            return <img alt="" style={style} src={Request} />;
        case "calendarBig":
            return <img alt="" style={style} src={CalendarBig} />;
        case "more":
            return <img alt="" style={style} src={More} />;
        case "addCircle":
            return <img alt="" style={style} src={AddCircle} />;
        case "address":
            return <img alt="" style={style} src={Address} />;
        case "alertsNotifs":
            return <img alt="" style={style} src={AlertsNotifs} />;
        case "arrowDown":
            return <img alt="" style={style} src={ArrowDown} />;
        case "attachBlue":
            return <img alt="" style={style} src={BlueAttach} />;
        case "yellowDot":
            return <img alt="" style={style} src={YellowDot} />;
        case "greenDot":
            return <img alt="" style={style} src={GreenDot} />;
        case "editCircleBlue":
            return <img alt="" style={style} src={EditCircleBlue} />;
        case "arrowRightCircleBlue":
            return <img alt="" style={style} src={ArrowRightCircleBlue} />;
        case "attachments":
            return <img alt="" style={style} src={Attachments} />;
        case "attachmentsIcon":
            return <img alt="" style={style} src={AttachFileIcon} />;
        case "basket":
            return <img alt="" style={style} src={Basket} />;
        case "blueFlag":
            return <img alt="" style={style} src={BlueFlag} />;
        case "calendar":
            return <img alt="" style={style} src={Calendar} />;
        case "consignment":
            return <img alt="" style={style} src={Consignment} />;
        case "calls":
            return <img alt="" style={style} src={Calls} />;
        case "catalogue":
            return <img alt="" style={style} src={Catalogue} />;
        case "checklist":
            return <img alt="" style={style} src={Checklist} />;
        case "clientBuilding":
            return <img alt="" style={style} src={ClientBuilding} />;
        case "delete":
            return <img alt="" style={style} src={Delete} />;
        case "filter":
            return <img alt="" style={style} src={Filter} />;
        case "frequentAction":
            return <img alt="" style={style} src={FrequentAction} />;
        case "greenTick":
            return <img alt="" style={style} src={GreenTick} />;
        case "greyTick":
            return <img alt="" style={style} src={GreyTick} />;
        case "turnoverWhite":
            return (
                <img
                    alt=""
                    src={TurnoverWhite}
                    style={{ height: "15px", paddingTop: "15%" }}
                />
            );
        case "gridView":
            return <img alt="" style={style} src={GridView} />;
        case "gridViewDark":
            return <img alt="" style={style} src={GridViewDark} />;
        case "home":
            return <img alt="" style={style} src={Home} />;
        case "print":
            return <img alt="" style={style} src={Print} />;
        case "qrScanner":
            return <img alt="" style={style} src={QRScanner} />;
        case "search":
            return <img alt="" style={style} src={Search} />;
        case "searchDocs":
            return <img alt="" style={style} src={SearchDocs} />;
        case "setting":
            return <img alt="" style={style} src={Setting} />;
        case "settingMedium":
            return <img alt="" style={style} src={SettingMedium} />;
        case "smallArrowRight":
            return <img alt="" style={style} src={SmallArrowRight} />;
        case "smallTimer":
            return <img alt="" style={style} src={SmallTimer} />;
        case "info":
            return <img alt="" style={style} src={Info} />;
        case "listView":
            return <img alt="" style={style} src={ListView} />;
        case "listViewDark":
            return <img alt="" style={style} src={ListViewDark} />;
        case "location":
            return <img alt="" style={style} src={Location} />;
        case "options":
            return <img alt="" style={style} src={Options} />;
        case "pendingActions":
            return <img alt="" style={style} src={PendingActions} />;
        case "revision":
            return <img alt="" style={style} src={Revision} />;
        case "recycle":
            return <img alt="" style={style} src={Recycle} />;
        case "stopWatch":
            return <img alt="" style={style} src={StopWatch} />;
        case "timer":
            return <img alt="" style={style} src={Timer} />;
        case "van":
            return <img alt="" style={style} src={Van} />;
        //Van
        case "turnOver":
            return <img alt="" style={style} src={TurnOver} />;
        case "user":
            return <img alt="" style={style} src={User} />;
        case "documentAttach":
            return <img alt="" style={style} src={DocumentAttach} />;
        case "map":
            return <img alt="" style={style} src={Map} />;
        case "attachmentsIconBlue":
            return <img alt="" style={style} src={AttachmentsIconBlue} />;
        case "uploadWhite":
            return <img alt="" src={UploadImg} style={{ height: "21px" }} />;
        case "pendingDocs":
            return <img alt="" style={style} src={PendingDocs} />;
        case "inquire":
            return <img alt="" style={style} src={Inquire} />;
        case "statementAccount":
            return <img alt="" style={style} src={StatementAccount} />;
        case "recentPayments":
            return (
                <img alt="" src={RecentPayments} style={{ height: "21px" }} />
            );
        case "highlightedAttachment":
            return <img alt="" style={style} src={HighlightedAttachment} />;
        case "receipts":
            return <img alt="" style={style} src={Receipts} />;
        case "searchBlue":
            return <img alt="" style={style} src={SearchBlue} />;
        case "greyHouse":
            return <img alt="" style={style} src={GreyHouse} />;
        case "docSelected":
            return <img alt="" style={style} src={DocSelected} />;
        case "docReceipt":
            return <img alt="" style={style} src={DocReceipt} />;
        case "retry":
            return <img alt="" style={style} src={Retry} />;
        //Map
        default:
            return <></>;
    }
};

export default Icons;
