import Dashboard from "Components/Dashboard";
import {
    Apartment,
    Assignment,
    ContactlessTwoTone,
    DashboardOutlined,
    Phone,
    QuestionAnswer,
    Receipt,
    ContactsSharp,
    Drafts,
    NoteAdd,
    MonetizationOn,
} from "@material-ui/icons";
import { MenuCategorySchema } from "./types";
import HomeIcon from "@material-ui/icons/Home";
import Buildings from "Components/Buildings";
import BuildingDetails from "Components/Buildings/Details";
import Details from "Components/AssignmentsSC/Details";
import Assignments from "Components/Assignments";
import Invoices from "Components/Invoices";
import Contracts from "Components/Contracts";
import Contacts from "Components/Contacts";
import ContractDetails from "Components/Contracts/ContractDetail";
import InvoicesDetails from "Components/Invoices/InvoicesDetails";
// Service Vendor Portal
import Contact from "Components/Contact";
import AssignmentDetailsSV from "Components/AssignmentsSV/AssignmentDetailsSV";
import AssignmentsSV from "Components/AssignmentsSV";
import ContractsSV from "Components/ContractsSV";
import ContractDetailsSV from "Components/ContractsSV/ContractDetailsSV";
import PayableDetails from "Components/PayableAging/PayableDetails";
import PayableAging from "Components/PayableAging";
// import NavContact from "Common/Icons/NavImages/Contact.svg";
// import NavBuilding from "Common/Icons/NavImages/Building.svg";
// import NavContract from "Common/Icons/NavImages/Contract.svg";
// import NavAssignment from "Common/Icons/NavImages/Assignment.svg";
// import NavInvoice from "Common/Icons/NavImages/Invoice.svg";
// import NavPayable from "Common/Icons/NavImages/Payable.svg";

const NavMenuSchema: MenuCategorySchema[] = [
    {
        id: 0,
        title: "Home",
        value: "home",
        subcategories: [2],
        route: "0",
        level: "BPC",
        icon: HomeIcon,
    },
    {
        id: 2,
        title: "Dashboard",
        value: "Dashboard",
        subcategories: [],
        route: "Dashboard",
        level: "BPCActivity",
        parent: 0,
        child: Dashboard,
        icon: DashboardOutlined,
        type: "CMN",
    },
    {
        id: 3,
        title: "Contacts",
        value: "Contacts",
        subcategories: [],
        route: "Contacts",
        level: "Component",
        parent: 0,
        child: Contacts,
        icon: ContactsSharp,
        type: "CUS",
    },
    {
        id: 4,
        title: "Buildings",
        value: "Buildings",
        subcategories: [],
        route: "Buildings",
        level: "Component",
        parent: 0,
        child: Buildings,
        icon: Apartment,
        type: "CUS",
    },
    {
        id: 5,
        title: "Details",
        value: "Details",
        subcategories: [],
        route: "BuildDetail",
        level: "Component",
        parent: 100,
        child: BuildingDetails,
    },
    {
        id: 6,
        title: "Contracts",
        value: "Contracts",
        subcategories: [],
        route: "Contracts",
        level: "Component",
        parent: 0,
        child: Contracts,
        icon: NoteAdd,
        type: "CUS",
    },
    {
        id: 7,
        title: "ContractDetails",
        value: "ContractDetails",
        subcategories: [],
        route: "CONTDET",
        level: "Component",
        parent: 6,
        child: ContractDetails,
    },
    {
        id: 8,
        title: "Assignments",
        value: "Assignments",
        subcategories: [],
        route: "Assignments",
        level: "Component",
        parent: 0,
        child: Assignments,
        icon: Assignment,
        type: "CUS",
    },
    {
        id: 9,
        title: "Details",
        value: "Details",
        subcategories: [],
        route: "AssgnDetail",
        level: "Component",
        parent: 100,
        child: Details,
    },
    {
        id: 10,
        title: "Invoices",
        value: "Invoices",
        subcategories: [],
        route: "Invoices",
        level: "Component",
        parent: 0,
        child: Invoices,
        icon: Drafts,
        type: "CUS",
    },
    {
        id: 11,
        title: "InvoicesDetails",
        value: "InvoicesDetails",
        subcategories: [],
        route: "INVDET",
        level: "Component",
        parent: 6,
        child: InvoicesDetails,
    },
    {
        id: 12,
        title: "Contact",
        value: "Contact",
        subcategories: [],
        route: "Contact",
        level: "Component",
        parent: 0,
        child: Contact,
        icon: ContactsSharp,
        type: "VEN",
    },
    {
        id: 13,
        title: "Contracts",
        value: "Contracts",
        subcategories: [],
        route: "ContractsSV",
        level: "Component",
        parent: 0,
        child: ContractsSV,
        icon: Apartment,
        type: "VEN",
    },
    {
        id: 14,
        title: "ContractDetails",
        value: "ContractDetails",
        subcategories: [],
        route: "CONTDETSV",
        level: "Component",
        parent: 60,
        child: ContractDetailsSV,
    },
    {
        id: 15,
        title: "Assignments",
        value: "Assignments",
        subcategories: [],
        route: "AssignmentsSV",
        level: "Component",
        parent: 0,
        child: AssignmentsSV,
        icon: Assignment,
        type: "VEN",
    },
    {
        id: 16,
        title: "Details",
        value: "Details",
        subcategories: [],
        route: "AssgnDetailSV",
        level: "Component",
        parent: 100,
        child: AssignmentDetailsSV,
    },
    {
        id: 17,
        title: "Payable And Deduction",
        value: "PayableAging",
        subcategories: [],
        route: "PayableAging",
        level: "Component",
        parent: 0,
        child: PayableAging,
        icon: MonetizationOn,
        type: "VEN",
    },
    {
        id: 18,
        title: "PayableDetails",
        value: "PayableDetails",
        subcategories: [],
        route: "PayableDetails",
        level: "Component",
        parent: 101,
        child: PayableDetails,
    },
];

const LandingPageSchema = [
    {
        code: "dashboard",
        child: Dashboard,
    },
];

export default NavMenuSchema;
export { LandingPageSchema };
