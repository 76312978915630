const fulfillmentQuery = `
mutation(
    $entity:String
    $ou:Int
    $service:String
    $User:String
    $languageId:Int
    $supplierCode:String
    $suppliername:String
    $merrorid:String
){
    Fulfillment(
        entity:$entity
        ou:$ou
        service:$service
        User:$User
        languageId:$languageId
        supplierCode:$supplierCode
        suppliername:$suppliername
        merrorid:$merrorid
    ){
        Volumeinfo{
            purchased
            assigned
            lastUpdated
        }
        Loaninfo{
            openAssigned
            currentbalance
            owed
        }
        Deduct{
            workerscomp
            insurance
        }
    }
}
`;

export default fulfillmentQuery;
