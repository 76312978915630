import { Grid, Paper, Backdrop, CircularProgress } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import MuiDatatables from "Common/Mui-DataTables/MuiDatatables";
import { Redirect } from "react-router-dom";
import { DeductionSchema } from "./Schemas/DeductionSchema";
import useGetPosts from "ReactQuery/reactQuery";
import {
    deductionQuerySV,
    headerPayableQuerySV,
    payableQuerySV,
} from "ReactQuery/Query";
import { connect } from "react-redux";
import { makeStyles, Theme } from "@material-ui/core/styles";
import Alert from "Common/Alerts/Alerts";
import currencyFormatter from "Common/Utils/currencyFormatter";

const useStyles = makeStyles((theme: Theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
}));

interface BodyProps {
    currentContext: any;
    userName: any;
    selectedVendor: any;
    currency: any;
}

const Body = (props: BodyProps) => {
    const classes = useStyles();
    const { currentContext, userName, selectedVendor, currency } = props;
    const [header, setHeader] = useState<any>();
    const [deductionInfo, setdeductionInfo] = useState([]);
    const [payableInfo, setpayableInfo] = useState([]);
    const [assgnNoToggle, setAssgnNoToggle] = useState(false);
    const [popoverBlack, setPopoverBlack] = useState(false);
    const [assignmentSelected, setassignmentSelected] = useState();
    const [alert, setAlert] = useState(false);
    const [errr, seterr] = useState({
        code: 0,
        msg: "",
    });

    const handleErrorAlert = (code: number, message: string) => {
        Alert({
            statusCode: code,
            message: message,
            willClose: () => {
                setAlert(false);
                seterr({ code: 0, msg: "" });
            },
        });
    };

    const PayableSchema = [
        {
            name: "AccountNumber",
            Label: "Account Number",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Account Number</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return <b className="grey2 font-bolder">{value}</b>;
                },
            },
        },
        {
            name: "Subcontract",
            Label: "Subcontract",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Subcontract</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return <b className="grey2">{value}</b>;
                },
            },
        },
        {
            name: "Building",
            Label: "BuildingID",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Building Name</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: {
                        name: string;
                        id: string;
                    },
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return (
                        <b
                            className="blue"
                            onClick={() => handleClick()}
                            style={{ cursor: "pointer" }}
                        >
                            {value.name}
                        </b>
                    );
                },
            },
        },
        {
            name: "BuildingID",
            Label: "BuildingID",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Building ID</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return <b className="grey2">{value}</b>;
                },
            },
        },
        {
            name: "VendorAmount",
            Label: "VendorAmount",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Vendor Amount</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return (
                        <b
                            className="green"
                            style={{
                                display: "flex",
                                justifyContent: "flex-end",
                            }}
                        >
                            {currencyFormatter(value && value, currency)}
                        </b>
                    );
                },
            },
        },
        {
            name: "VendorNetAmount",
            Label: "VendorNetAmount",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Vendor Net Amount</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return (
                        <b
                            className="grey2 font-bolder"
                            style={{
                                display: "flex",
                                justifyContent: "flex-end",
                            }}
                        >
                            {currencyFormatter(
                                (value as any) && value,
                                currency,
                            )}
                        </b>
                    );
                },
            },
        },
        {
            name: "Percent",
            Label: "Percent",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Percentage</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return (
                        <b
                            className="grey"
                            style={{
                                display: "flex",
                                justifyContent: "flex-end",
                            }}
                        >
                            {value !== 0 && value ? `${value}%` : ""}
                        </b>
                    );
                },
            },
        },
        {
            name: "Description",
            Label: "Description",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Description</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return <b className="grey2">{value}</b>;
                },
            },
        },
        {
            name: "Adjustment",
            Label: "Adjustment",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Adjustment</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return <b className="grey2">{value}</b>;
                },
            },
        },
        {
            name: "SalesOrder",
            Label: "SalesOrder",
            options: {
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Sales Order</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return <b className="blue">{value}</b>;
                },
            },
        },
        {
            name: "Assignment",
            Label: "Assignment",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Assignment</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return (
                        <b
                            className="blue"
                            onClick={() => handleAssignmentNo(value)}
                            style={{ cursor: "pointer" }}
                        >
                            {value}
                        </b>
                    );
                },
            },
        },
        {
            name: "StartEndDate",
            Label: "StartEndDate",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Start-End Date</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return <b className="grey2">{value}</b>;
                },
            },
        },
        {
            name: "CreatedOn",
            Label: "CreatedOn",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Created On</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return <b className="grey2">{value}</b>;
                },
            },
        },
    ];

    const {
        data: payableData,
        error: payableDataDataError,
        isLoading: payableDataLoading,
    } = useGetPosts({
        name: `payableQuerySV_${selectedVendor?.companyCode}`,
        query: payableQuerySV,
        variables: {
            entity: "OW_Vendor_portal_getvendorpayables",
            ou: currentContext?.ouId,
            service: "",
            User: userName,
            languageId: currentContext?.langId,
            VendorCode: selectedVendor?.companyCode,
            Vendorname: "",
            merrorid: "",
        },
    });

    useEffect(() => {
        if (payableData?.PayableSV) {
            setpayableInfo(payableData?.PayableSV);
        }
    }, [payableData]);

    useEffect(() => {
        if (payableDataDataError && !payableDataLoading) {
            const data = JSON.parse(JSON.stringify(payableDataDataError));
            const errmsg = data?.response?.errors[0].message;
            seterr({
                code: 400,
                msg: errmsg,
            });
            setAlert(true);
        }
    }, [payableDataDataError, payableDataLoading]);

    const {
        data: deductionData,
        error: deductionDataError,
        isLoading: deductionDataLoading,
    } = useGetPosts({
        name: `deductionQuerySV_${selectedVendor?.companyCode}`,
        query: deductionQuerySV,
        variables: {
            entity: "OW_Vendor_portal_Deduction",
            ou: currentContext?.ouId,
            service: "",
            User: userName,
            languageId: currentContext?.langId,
            supplierCode: selectedVendor?.companyCode,
            suppliername: "",
            merrorid: "",
        },
    });

    useEffect(() => {
        if (deductionData?.deductionSV) {
            setdeductionInfo(deductionData?.deductionSV);
        }
    }, [deductionData]);

    useEffect(() => {
        if (deductionDataError && !deductionDataLoading) {
            const data = JSON.parse(JSON.stringify(deductionDataError));
            const errmsg = data?.response?.errors[0].message;
            seterr({
                code: 400,
                msg: errmsg,
            });
            setAlert(true);
        }
    }, [deductionDataError, deductionDataLoading]);

    const {
        data: PayableHeaderData,
        error: PayableHeaderDataError,
        isLoading: PayableHeaderDataLoading,
    } = useGetPosts({
        name: `headerPayableQuerySV_${selectedVendor?.companyCode}`,
        query: headerPayableQuerySV,
        variables: {
            entity: "OW_Vendor_portal_getpaydecstatus",
            ou: currentContext?.ouId,
            service: "",
            User: userName,
            languageId: currentContext?.langId,
            suppCode: selectedVendor?.companyCode,
            merrorid: "",
        },
    });

    useEffect(() => {
        if (PayableHeaderData?.headerPayableSV) {
            setHeader(PayableHeaderData?.headerPayableSV);
        }
    }, [PayableHeaderData]);

    useEffect(() => {
        if (PayableHeaderDataError && !PayableHeaderDataLoading) {
            const data = JSON.parse(JSON.stringify(PayableHeaderDataError));
            const errmsg = data?.response?.errors[0].message;
            seterr({
                code: 400,
                msg: errmsg,
            });
            setAlert(true);
        }
    }, [PayableHeaderDataError, PayableHeaderDataLoading]);

    const [active, setActive] = useState("PAYABLE");
    const handleActive = (index: string) => {
        setActive(index);
        console.log("index", index);
    };
    const [toggle, setToggle] = useState(false);
    if (toggle) {
        return (
            <Redirect
                to={{
                    pathname: "/app/PayableDetails",
                }}
            />
        );
    }
    if (assgnNoToggle) {
        return (
            <Redirect
                to={{
                    pathname: "/app/AssgnDetailSV",
                    state: {
                        assignmentSelected: assignmentSelected,
                    },
                }}
            />
        );
    }
    const handleClick = () => {
        setToggle((prevState) => !prevState);
    };

    const handleAssignmentNo = (val: any) => {
        setAssgnNoToggle((prevState) => !prevState);
        setassignmentSelected(val);
    };
    return (
        <>
            {alert ? handleErrorAlert(errr.code, errr.msg) : <></>}
            <Backdrop
                className={classes.backdrop}
                open={
                    payableDataLoading ||
                    deductionDataLoading ||
                    PayableHeaderDataLoading
                }
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Backdrop
                className={classes.backdrop}
                open={popoverBlack}
            ></Backdrop>
            <Grid container spacing={1} style={{ padding: "0 0 2%" }}>
                <Grid item xs={12} sm={4} md={3} style={{ paddingTop: "2%" }}>
                    <Paper elevation={2}>
                        <Grid
                            container
                            spacing={2}
                            style={{
                                padding: "5% 0% 0% 0%",
                            }}
                            justifyContent="space-around"
                        >
                            {header?.detail.map((element: any, index: any) => {
                                return (
                                    <Grid
                                        item
                                        xs={4}
                                        sm={4}
                                        className={
                                            active === element?.desc
                                                ? `${element?.color}_border_bottom pointer`
                                                : "pointer"
                                        }
                                        onClick={() =>
                                            handleActive(element?.desc)
                                        }
                                    >
                                        <Grid container spacing={1}>
                                            <Grid item xs={12} sm={12}>
                                                <span
                                                    // className={`${element?.color} font-biggest font-boldest`}
                                                    className={
                                                        active === element?.desc
                                                            ? `${element?.color} font-biggest font-boldest`
                                                            : `grey font-biggest font-boldest`
                                                    }
                                                >
                                                    {element?.count}
                                                </span>
                                            </Grid>
                                            <Grid item xs={12} sm={12}>
                                                <span
                                                    className={
                                                        active === element?.desc
                                                            ? `font-boldest font-smaller`
                                                            : "grey font-smallest"
                                                    }
                                                >
                                                    {element?.desc}
                                                </span>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                );
                            })}
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>

            {active === "PAYABLE" ? (
                <MuiDatatables
                    title={""}
                    header={PayableSchema}
                    detail={[payableInfo, setpayableInfo]}
                    // handleAddSideEffect={handleAddSideEffect}
                    // handleDeleteSideEffect={handleDeleteSideEffect}
                    initRow={false}
                />
            ) : (
                <MuiDatatables
                    title={""}
                    header={DeductionSchema}
                    detail={[deductionInfo, setdeductionInfo]}
                    // handleAddSideEffect={handleAddSideEffect}
                    // handleDeleteSideEffect={handleDeleteSideEffect}
                    initRow={false}
                />
            )}
        </>
    );
};

const mapStateToProps = (state: any) => {
    return {
        currentContext: state.context?.userDefaults,
        userName: state.context?.name,
        selectedVendor: state.selectedVendor,
        currency: state.currency,
    };
};

export default connect(mapStateToProps)(Body);
