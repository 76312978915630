import { Grid } from "@material-ui/core";
import "Common/Main.scss";
// import CheckCircleIcon from "@material-ui/icons/";



export const Servicedays= [
    {
        name: "ContractId",
        Label: "  ContractId",
        options: {
            filter: true,
            sort: false,
            customHeadLabelRender: () => (
                <span style={{ color: "white" }}>Contract ID</span>
            ),
            // setCellHeaderProps: () => {
            //     return { style: { borderRight: "1px solid #D5D5D5" } };
            // },
            // setCellProps: () => {
            //     return { style: { borderRight: "1px solid #D5D5D5" } };
            // },
            customBodyRender: (
                value: any,
                tableMeta: any,
                updateValue: any,
            ) => {
                console.log("table data", value);
                return <b className="blue" >{value}</b>;
            },
        },
    },
    {
        name:"Monday",
        Label: "Monday",
        options: {
            filter: true,
            sort: false,
            customHeadLabelRender: () => (
                <span style={{ color: "white" }}>Monday</span>
            ),
            // setCellHeaderProps: () => {
            //     return { style: { borderRight: "1px solid #D5D5D5" } };
            // },
            // setCellProps: () => {
            //     return { style: { borderRight: "1px solid #D5D5D5" } };
            // },
            customBodyRender: (
                value: any,
                tableMeta: any,
                updateValue: any,
            ) => {
                console.log("table data", value);
                return <b className="blue">{value}</b>;
            },
        },
    },
    {
        name:"Tuesday",
        Label: "Tuesday",
        options: {
            filter: true,
            sort: false,
            customHeadLabelRender: () => (
                <span style={{ color: "white" }}>Tuesday</span>
            ),
            // setCellHeaderProps: () => {
            //     return { style: { borderRight: "1px solid #D5D5D5" } };
            // },
            // setCellProps: () => {
            //     return { style: { borderRight: "1px solid #D5D5D5" } };
            // },
            customBodyRender: (
                value: any,
                tableMeta: any,
                updateValue: any,
            ) => {
                console.log("table data", value);
                return <b className="blue">{value}</b>;
            },
        },
    },
    {
        name:"Wednesday",
        Label: "Wednesday",
        options: {
            filter: true,
            sort: false,
            customHeadLabelRender: () => (
                <span style={{ color: "white" }}>Wednesday</span>
            ),
            // setCellHeaderProps: () => {
            //     return { style: { borderRight: "1px solid #D5D5D5" } };
            // },
            // setCellProps: () => {
            //     return { style: { borderRight: "1px solid #D5D5D5" } };
            // },
            customBodyRender: (
                value: any,
                tableMeta: any,
                updateValue: any,
            ) => {
                console.log("table data", value);
                return <b className="blue">{value}</b>;
            },
        },
    },
    {
        name:"Thrusday",
        Label: "Thrusday",
        options: {
            filter: true,
            sort: false,
            customHeadLabelRender: () => (
                <span style={{ color: "white" }}>Thrusday</span>
            ),
            // setCellHeaderProps: () => {
            //     return { style: { borderRight: "1px solid #D5D5D5" } };
            // },
            // setCellProps: () => {
            //     return { style: { borderRight: "1px solid #D5D5D5" } };
            // },
            customBodyRender: (
                value: any,
                tableMeta: any,
                updateValue: any,
            ) => {
                console.log("table data", value);
                return <b className="blue">{value}</b>;
            },
        },
    },
    {
        name:"Friday",
        Label: "Friday",
        options: {
            filter: true,
            sort: false,
            customHeadLabelRender: () => (
                <span style={{ color: "white" }}>Friday</span>
            ),
            // setCellHeaderProps: () => {
            //     return { style: { borderRight: "1px solid #D5D5D5" } };
            // },
            // setCellProps: () => {
            //     return { style: { borderRight: "1px solid #D5D5D5" } };
            // },
            customBodyRender: (
                value: any,
                tableMeta: any,
                updateValue: any,
            ) => {
                console.log("table data", value);
                return <b className="blue">{value}</b>;
            },
        },
    },
    {
        name:"Saturday",
        Label: "Saturday",
        options: {
            filter: true,
            sort: false,
            customHeadLabelRender: () => (
                <span style={{ color: "white" }}>Saturday</span>
            ),
            // setCellHeaderProps: () => {
            //     return { style: { borderRight: "1px solid #D5D5D5" } };
            // },
            // setCellProps: () => {
            //     return { style: { borderRight: "1px solid #D5D5D5" } };
            // },
            customBodyRender: (
                value: any,
                tableMeta: any,
                updateValue: any,
            ) => {
                console.log("table data", value);
                return <b className="blue">{value}</b>;
            },
        },
    },
    {
        name:"Sunday",
        Label: "Sunday",
        options: {
            filter: true,
            sort: false,
            customHeadLabelRender: () => (
                <span style={{ color: "white" }}>Sunday</span>
            ),
            // setCellHeaderProps: () => {
            //     return { style: { borderRight: "1px solid #D5D5D5" } };
            // },
            // setCellProps: () => {
            //     return { style: { borderRight: "1px solid #D5D5D5" } };
            // },
            customBodyRender: (
                value: any,
                tableMeta: any,
                updateValue: any,
            ) => {
                console.log("table data", value);
                return <b className="blue">{value}</b>;
            },
        },
    },

    
   
    
    
];
