import { STORE_CUSTOMER_DETAILS } from "../actionTypes";

const initialState = {
    data: {
        customerName: "",
        customerPhone: "",
        customerMail: "",
        customerCode: "",
    },
};

const storeSelectedCustomer = (prevState = initialState.data, action: any) => {
    switch (action.type) {
        case STORE_CUSTOMER_DETAILS:
            return action.payload;

        default:
            return prevState;
    }
};

export default storeSelectedCustomer;
