const contractQuerySV = `
query Query{
  contractSV {
    ContactID
    Building{
        name
        id
    }
    Contracts
    Revision
    RevisionStartEnd
    TotalAmount
    Type
    ServiceCode
    Description
    Po
    }
  }
  `;

export default contractQuerySV;
