import Application from "layout/Application";
import LoginPage from "layout/LoginPage";
import PostLogin from "layout/PostLogin";
import LogoutPage from "layout/LogoutPage";
import React, { Component } from "react";
import { Route, useLocation } from "react-router-dom";
// import {
//     ApolloClient,
//     ApolloLink,
//     ApolloProvider,
//     createHttpLink,
//     InMemoryCache,
// } from "@apollo/client";
// import { onError } from "@apollo/client/link/error";
// import { setContext } from "@apollo/client/link/context";
// import { createUploadLink } from "apollo-upload-client";
import "./App.scss";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import Configuration from "./Configuration";
// import MultipleSessions from "common/components/MultipleSessionsPage";

const queryClient = new QueryClient();

// const gqlHttpLink: ApolloLink = createHttpLink({
//     uri: `${Configuration.getConfiguration().getAppAPILocation()}/graphql`,
// });

// const gqlHttpLink_upload = createUploadLink({
//     uri: `${Configuration.getConfiguration().getAppAPILocation()}/graphql`,
//     //credentials: "include",
// });

// const authLink = setContext((_, { headers }) => {
//     // get the authentication token from local storage if it exists or from redis also
//     const token = localStorage.getItem("session");
//     const csrfToken = localStorage.csrf_token;
//     // return the headers to the context so httpLink can read them
//     return {
//         headers: {
//             ...headers,
//             authorization: token ? `${token}` : "",
//             "x-xsrf-token": csrfToken ? csrfToken : "",
//         },
//     };
// });

// const errorLink = onError(({ graphQLErrors, networkError }) => {
//     if (graphQLErrors) {
//         graphQLErrors.map((element) => {
//             const { extensions } = element;
//             if (extensions) console.log(JSON.stringify(extensions));
//         });

//         if (
//             graphQLErrors.some(
//                 ({ message, locations, path, extensions }) =>
//                     extensions?.code === "UNAUTHENTICATED",
//             )
//         ) {
//             window.location.replace(
//                 `${Configuration.getConfiguration().getWebAppLocation()}/MultipleSessions`,
//             );
//         }
//     }

//     if (networkError) console.log(`[Network error]: ${networkError}`);
// });

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};

const Routes = () => {
    let query = useQuery();
    return (
        <React.Fragment>
            <Route exact path="/" render={() => <LoginPage />} />
            <Route exact path="/login" render={() => <LoginPage />} />
            {/* <Route
                path="/MultipleSessions"
                render={() => <MultipleSessions />}
            />        */}
            <QueryClientProvider client={queryClient}>
                <Route
                    exact
                    path="/postlogin"
                    render={() => <PostLogin sId={query.get("sId")} />}
                />
                <Route path="/app/*" render={() => <Application />} />
                <ReactQueryDevtools initialIsOpen={false} />
            </QueryClientProvider>
            <Route path="/Logout" render={() => <LogoutPage />} />
        </React.Fragment>
    );
};

export default class App extends Component {
    render() {
        return (
            <div>
                <Routes />
            </div>
        );
    }
}
