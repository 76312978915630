import { Grid } from "@material-ui/core";
import "Common/Main.scss";

const ContactSchema = [
    {
        name: "AccNo",
        Label: "AccNo",
        options: {
            filter: true,
            sort: false,
            customHeadLabelRender: () => (
                <span style={{ color: "white" }}>Account No.</span>
            ),
            setCellHeaderProps: () => {
                return { style: { borderRight: "1px solid #D5D5D5" } };
            },
            setCellProps: () => {
                return {
                    style: {
                        borderRight: "1px solid #D5D5D5",
                        textAlign: "center",
                    },
                };
            },
            customBodyRender: (
                value: any,
                tableMeta: any,
                updateValue: any,
            ) => {
                console.log("table data", value);
                return <b className="grey2 font-bolder">{value}</b>;
            },
        },
    },
    {
        name: "CompName",
        Label: "CompName",
        type: "string",
        options: {
            filter: true,
            sort: false,
            customHeadLabelRender: () => (
                <span style={{ color: "white" }}>Company Name</span>
            ),
            setCellHeaderProps: () => {
                return { style: { borderRight: "1px solid #D5D5D5" } };
            },
            setCellProps: () => {
                return {
                    style: {
                        borderRight: "1px solid #D5D5D5",
                        textAlign: "center",
                    },
                };
            },
            customBodyRender: (
                value: any,
                tableMeta: any,
                updateValue: any,
            ) => {
                console.log("table data", value);
                return <b className="grey2 font-bolder">{value}</b>;
            },
        },
    },
    {
        name: "NameJobTitle",
        Label: "NameJobTitle",
        options: {
            filter: true,
            sort: false,
            customHeadLabelRender: () => (
                <span style={{ color: "white" }}>Name - Job Title</span>
            ),
            setCellHeaderProps: () => {
                return { style: { borderRight: "1px solid #D5D5D5" } };
            },
            setCellProps: () => {
                return {
                    style: {
                        borderRight: "1px solid #D5D5D5",
                        textAlign: "center",
                    },
                };
            },
            customBodyRender: (
                value: {
                    title: string;
                    name: string;
                },
                tableMeta: any,
                updateValue: any,
            ) => {
                console.log("table data", value);
                return (
                    <Grid container>
                        <Grid item xs={12}>
                            <b className="grey2 font-bolder">{value.title}</b>
                        </Grid>
                        <Grid item xs={12}>
                            <b className="grey">{value.name}</b>
                        </Grid>
                    </Grid>
                );
            },
        },
    },
    {
        name: "Address",
        Label: "Address",
        options: {
            filter: true,
            sort: false,
            customHeadLabelRender: () => (
                <span style={{ color: "white" }}>Address</span>
            ),
            setCellHeaderProps: () => {
                return { style: { borderRight: "1px solid #D5D5D5" } };
            },
            setCellProps: () => {
                return {
                    style: {
                        borderRight: "1px solid #D5D5D5",
                        textAlign: "center",
                    },
                };
            },
            customBodyRender: (
                value: {
                    name: string;
                    city: string;
                    state: string;
                    pincode: number;
                },
                tableMeta: any,
                updateValue: any,
            ) => {
                console.log("table data", value);
                return (
                    <Grid container>
                        <Grid item xs={12}>
                            <b className="grey2 font-bolder">{value.name}</b>
                        </Grid>
                        <Grid item xs={12}>
                            <b className="grey">
                                {value.city} | {value.state} | {value.pincode}
                            </b>
                        </Grid>
                    </Grid>
                );
            },
        },
    },
];

export default ContactSchema;
