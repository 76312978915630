import { Grid } from "@material-ui/core";
import "Common/Main.scss";
import currencyFormatter from "Common/Utils/currencyFormatter";

const BuildDetailsAssgnSchema = [
    {
        name: "Customer",
        Label: "Customer",
        options: {
            filter: true,
            sort: false,
            customHeadLabelRender: () => (
                <span style={{ color: "white" }}>Customer</span>
            ),
            setCellHeaderProps: () => {
                return { style: { borderRight: "1px solid #D5D5D5" } };
            },
            setCellProps: () => {
                return {
                    style: {
                        borderRight: "1px solid #D5D5D5",
                        textAlign: "center",
                    },
                };
            },
            customBodyRender: (
                value: any,
                tableMeta: any,
                updateValue: any,
            ) => {
                console.log("table data", value);
                return <b className="grey2 font-bolder">{value}</b>;
            },
        },
    },
    {
        name: "RevisionStartEnd",
        Label: "RevisionStartEnd",
        type: "string",
        options: {
            filter: true,
            sort: false,
            customHeadLabelRender: () => (
                <span style={{ color: "white" }}>Revision Start-End</span>
            ),
            setCellHeaderProps: () => {
                return { style: { borderRight: "1px solid #D5D5D5" } };
            },
            setCellProps: () => {
                return {
                    style: {
                        borderRight: "1px solid #D5D5D5",
                        textAlign: "center",
                    },
                };
            },
            customBodyRender: (
                value: any,
                tableMeta: any,
                updateValue: any,
            ) => {
                console.log("table data", value);
                return <b className="grey2 font-bolder">{value}</b>;
            },
        },
    },
    {
        name: "Vendor",
        Label: "Vendor",
        options: {
            filter: true,
            sort: false,
            customHeadLabelRender: () => (
                <span style={{ color: "white" }}>Vendor</span>
            ),
            setCellHeaderProps: () => {
                return { style: { borderRight: "1px solid #D5D5D5" } };
            },
            setCellProps: () => {
                return {
                    style: {
                        borderRight: "1px solid #D5D5D5",
                        textAlign: "center",
                    },
                };
            },
            customBodyRender: (
                value: any,
                tableMeta: any,
                updateValue: any,
            ) => {
                console.log("table data", value);
                return <b className="blue font-bolder">{value}</b>;
            },
        },
    },
    {
        name: "VendorAmt",
        Label: "VendorAmt",
        options: {
            filter: true,
            sort: false,
            customHeadLabelRender: () => (
                <span style={{ color: "white" }}>Vendor Amount</span>
            ),
            setCellHeaderProps: () => {
                return { style: { borderRight: "1px solid #D5D5D5" } };
            },
            setCellProps: () => {
                return {
                    style: {
                        borderRight: "1px solid #D5D5D5",
                        textAlign: "center",
                    },
                };
            },
            customBodyRender: (
                value: any,
                tableMeta: any,
                updateValue: any,
            ) => {
                console.log("table data", value);
                return (
                    <b
                        className="green font-bolder"
                        style={{
                            display: "flex",
                            justifyContent: "flex-end",
                        }}
                    >
                        {currencyFormatter(value, "USD")}
                    </b>
                );
            },
        },
    },
    {
        name: "Replaceable",
        Label: "Replaceable",
        options: {
            filter: true,
            sort: false,
            customHeadLabelRender: () => (
                <span style={{ color: "white" }}>Replaceable</span>
            ),
            setCellHeaderProps: () => {
                return { style: { borderRight: "1px solid #D5D5D5" } };
            },
            setCellProps: () => {
                return {
                    style: {
                        borderRight: "1px solid #D5D5D5",
                        textAlign: "center",
                    },
                };
            },
            customBodyRender: (
                value: any,
                tableMeta: any,
                updateValue: any,
            ) => {
                console.log("table data", value);
                return <b className="grey2 font-bolder">{value}</b>;
            },
        },
    },
    {
        name: "VendorNetAmount",
        Label: "VendorNetAmount",
        options: {
            filter: true,
            sort: false,
            customHeadLabelRender: () => (
                <span style={{ color: "white" }}>Vendor Net Amount</span>
            ),
            setCellHeaderProps: () => {
                return { style: { borderRight: "1px solid #D5D5D5" } };
            },
            setCellProps: () => {
                return {
                    style: {
                        borderRight: "1px solid #D5D5D5",
                        textAlign: "center",
                    },
                };
            },
            customBodyRender: (
                value: any,
                tableMeta: any,
                updateValue: any,
            ) => {
                console.log("table data", value);
                return (
                    <b
                        className="grey2 font-bolder"
                        style={{
                            display: "flex",
                            justifyContent: "flex-end",
                        }}
                    >
                        {currencyFormatter((value as any) && value, "USD")}
                    </b>
                );
            },
        },
    },
    {
        name: "VendorNetPercent",
        Label: "VendorNetPercent",
        options: {
            filter: true,
            sort: false,
            customHeadLabelRender: () => (
                <span style={{ color: "white" }}>Vendor Percent</span>
            ),
            setCellHeaderProps: () => {
                return { style: { borderRight: "1px solid #D5D5D5" } };
            },
            setCellProps: () => {
                return {
                    style: {
                        borderRight: "1px solid #D5D5D5",
                        textAlign: "center",
                    },
                };
            },
            customBodyRender: (
                value: any,
                tableMeta: any,
                updateValue: any,
            ) => {
                console.log("table data", value);
                return (
                    <b
                        className="grey"
                        style={{
                            display: "flex",
                            justifyContent: "flex-end",
                        }}
                    >
                        {`${(value as any) && value}%`}
                    </b>
                );
            },
        },
    },
    {
        name: "FirstMonthProrate",
        Label: "FirstMonthProrate",
        options: {
            filter: true,
            sort: false,
            customHeadLabelRender: () => (
                <span style={{ color: "white" }}>First Month's Prorate</span>
            ),
            setCellHeaderProps: () => {
                return { style: { borderRight: "1px solid #D5D5D5" } };
            },
            setCellProps: () => {
                return {
                    style: {
                        borderRight: "1px solid #D5D5D5",
                        textAlign: "center",
                    },
                };
            },
            customBodyRender: (
                value: any,
                tableMeta: any,
                updateValue: any,
            ) => {
                console.log("table data", value);
                return (
                    <b
                        className="grey2 font-bolder"
                        style={{
                            display: "flex",
                            justifyContent: "flex-end",
                        }}
                    >
                        {currencyFormatter(value, "USD")}
                    </b>
                );
            },
        },
    },
    {
        name: "LastMonthProrate",
        Label: "LastMonthProrate",
        options: {
            filter: true,
            sort: false,
            customHeadLabelRender: () => (
                <span style={{ color: "white" }}>Last Month's Prorate</span>
            ),
            setCellHeaderProps: () => {
                return { style: { borderRight: "1px solid #D5D5D5" } };
            },
            setCellProps: () => {
                return {
                    style: {
                        borderRight: "1px solid #D5D5D5",
                        textAlign: "center",
                    },
                };
            },
            customBodyRender: (
                value: any,
                tableMeta: any,
                updateValue: any,
            ) => {
                console.log("table data", value);
                return (
                    <b
                        className="grey2 font-bolder"
                        style={{
                            display: "flex",
                            justifyContent: "flex-end",
                        }}
                    >
                        {currencyFormatter(value, "USD")}
                    </b>
                );
            },
        },
    },
    {
        name: "CreatedOn",
        Label: "CreatedOn",
        options: {
            filter: true,
            sort: false,
            customHeadLabelRender: () => (
                <span style={{ color: "white" }}>Created On</span>
            ),
            setCellHeaderProps: () => {
                return { style: { borderRight: "1px solid #D5D5D5" } };
            },
            setCellProps: () => {
                return {
                    style: {
                        borderRight: "1px solid #D5D5D5",
                        textAlign: "center",
                    },
                };
            },
            customBodyRender: (
                value: any,
                tableMeta: any,
                updateValue: any,
            ) => {
                console.log("table data", value);
                return <b className="grey2 font-bolder">{value}</b>;
            },
        },
    },
];

export default BuildDetailsAssgnSchema;
