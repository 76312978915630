import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import "@sweetalert2/theme-material-ui/material-ui.css";
const AlertSwal = withReactContent(Swal);
interface AlertProps {
    statusCode: number;
    message: string;
    timer?: boolean;
    seconds?: number;
    willClose?: () => void;
}
const Alert = (props: AlertProps) => {
    const { statusCode, message: text } = props;
    const icon =
        statusCode === 200
            ? "success"
            : statusCode === 400
            ? "warning"
            : "error";
    const title =
        statusCode === 200
            ? "Success!"
            : statusCode === 400
            ? "Warning!"
            : "Error!";
    const renderedAlert = AlertSwal.fire({
        icon,
        title,
        text,
        timer: props.seconds,
        timerProgressBar: props.timer || true,
        willClose: props.willClose,
    });
    return <>{renderedAlert}</>;
};
export default Alert;