const Configurations: any = {
    development: {
        getBaseRoute: (): string => "/",
        getWebAppLocation: (): string => "http://localhost:3000",
        getAppAPILocation: (): string =>
            (window as any)["runtimeWebConfig"].REACT_APPAPI_URL,
        getWebAppSessionValidDuration: (): number =>
            (window as any)["runtimeWebConfig"].REACT_CHECK_VALID_SESSION,
    },
    production: {
        getBaseRoute: (): string => "/",
        getWebAppLocation: (): string => window.location.origin,
        getAppAPILocation: (): string =>
            (window as any)["runtimeWebConfig"].REACT_APPAPI_URL,
        getWebAppSessionValidDuration: (): number =>
            (window as any)["runtimeWebConfig"].REACT_CHECK_VALID_SESSION,
    },
};

interface IConfiguration {
    getWebAppLocation: () => string;
    getAppAPILocation: () => string;
    getWebAppSessionValidDuration: () => number;
    getenv: () => string;
}

/**
 * Configuration details for the Web application
 */
export default class Configuration implements IConfiguration {
    private config: any;
    environment: any;
    constructor(environment: any) {
        this.config = Configurations[environment];
        this.environment = environment;
    }

    getWebAppLocation = (): string => {
        return this.config.getWebAppLocation();
    };

    getAppAPILocation = (): string => {
        return this.config.getAppAPILocation();
    };

    getWebAppSessionValidDuration = (): number => {
        return this.config.getWebAppSessionValidDuration();
    };

    getenv = () => {
        return this.environment;
    };

    static getConfiguration = (): IConfiguration => {
        return _config;
    };
}

let _config: IConfiguration = new Configuration(process.env.NODE_ENV);
