const activerecurringQuerySV = `
query Query {
  activerecurringSV{
    Vendor
    ServiceCode
    Date
    }
  }
  `;

export default activerecurringQuerySV;
