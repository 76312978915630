import { Grid, Paper, Backdrop, CircularProgress } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import useGetPosts from "ReactQuery/reactQuery";
import { CustomerSearchQuery, VendorSearchQuery } from "ReactQuery/Query";
import TextFields from "Common/TextField/TextField";
import ButtonGroups from "Common/ButtonGroup/ButtonGroup";
import MUIButton from "Common/Button/Button";
import ButtonGroupAZ from "Common/ButtonGroup/ButtonGroupAZ";
import { connect } from "react-redux";
import { storeSelectedCustomer } from "stateManagement/actions/Common/customerDetails";
import { storeSelectedVendor } from "stateManagement/actions/Common/vendorDetails";
import BackdropLoading from "Common/Backdrop/Backdrop";
import Alert from "Common/Alerts/Alerts";
import DashBoardDetailsCard from "./DashBoardDetailsCard";
import DashBoardDetailsCardAZ from "./DashBoardDetailsCardAZ";
import { storePortal } from "stateManagement/actions/Common/storePortal";
import { Redirect } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        padding: "12px",
    },
    companyImg: {
        background: "rgba(0, 115, 230, 0.05)",
        padding: "3%",
    },
    company: {
        color: "#0073E6",
        textAlign: "left",
    },
    code: {
        color: "#33415C",
        fontWeight: 600,
    },
    search: {
        textAlign: "left",
        fontWeight: 600,
        color: "#33415C",
        marginBottom: "5%",
    },
    buttonGroup: {
        paddingLeft: "6%",
        paddingBottom: "5%",
        paddingTop: "5%",
    },
    textpadding: {
        padding: "3% 3%",
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
}));
interface BodyProps {
    currentContext: any;
    userName: any;
    portal: any;
    setPortal: any;
}

const Body = (props: BodyProps) => {
    const classes = useStyles();
    const { currentContext, userName, portal, setPortal } = props;
    console.log("current context ", currentContext);

    
    // useEffect(() => {

    // }, [currentContext]);

    const controlList = [
        {
            desc: "Customer",
            value: "Customer",
        },
        {
            desc: "Vendor",
            value: "Vendor",
        },
    ];
    const customerList = [
        {
            desc: "ALL",
            value: "All",
        },
        {
            desc: "A",
            value: "A",
        },
        {
            desc: "B",
            value: "B",
        },
        {
            desc: "C",
            value: "C",
        },
        {
            desc: "D",
            value: "D",
        },
        {
            desc: "E",
            value: "E",
        },
        {
            desc: "F",
            value: "F",
        },
        {
            desc: "G",
            value: "G",
        },
        {
            desc: "H",
            value: "H",
        },
        {
            desc: "I",
            value: "I",
        },
        {
            desc: "J",
            value: "J",
        },
        {
            desc: "K",
            value: "K",
        },
        {
            desc: "L",
            value: "L",
        },
        {
            desc: "M",
            value: "M",
        },
        {
            desc: "N",
            value: "N",
        },
        {
            desc: "O",
            value: "O",
        },
        {
            desc: "P",
            value: "P",
        },
        {
            desc: "Q",
            value: "Q",
        },
        {
            desc: "R",
            value: "R",
        },
        {
            desc: "S",
            value: "S",
        },
        {
            desc: "T",
            value: "T",
        },
        {
            desc: "U",
            value: "U",
        },
        {
            desc: "V",
            value: "V",
        },
        {
            desc: "W",
            value: "W",
        },
        {
            desc: "X",
            value: "X",
        },
        {
            desc: "Y",
            value: "Y",
        },
        {
            desc: "Z",
            value: "Z",
        },
    ];
    const [activeList, setActiveList] = useState(controlList[0].desc);
    const [searchactivelist, setsearchactivelist] = useState(
        customerList[0].value,
    );
    const [searchcustomer, setSearchCustomer] = useState(false);
    const [searchvendor, setSearchVendor] = useState(false);
    const [Customerdetails, setCustomerdetails] = useState({
        customerName: "",
        customerPhone: "",
        customerMail: "",
        customerCode: "",
    });
    const [vendordetails, setvendordetails] = useState({
        vendorName: "",
        vendorCode: "",
        vendorPhone: "",
        vendorMail: "",
    });
    const [popoverBlack, setPopoverBlack] = useState(false);
    const [alert, setAlert] = useState(false);
    const [errr, seterr] = useState({
        code: 0,
        msg: "",
    });

    const handleErrorAlert = (code: number, message: string) => {
        Alert({
            statusCode: code,
            message: message,
            willClose: () => {
                setAlert(false);
                seterr({ code: 0, msg: "" });
            },
        });
    };

    const handleDocList = (listType: string) => {
        console.log("listType", listType);
        setActiveList(listType);
    };
    const searchhandleDocList = (listType: string) => {
        console.log("listType", listType);
        setsearchactivelist(listType);
    };

    const [customer, setCustomer] = useState<any>([]);
    const [vendor, setVendor] = useState<any>([]);
    const [customerSearch, setCustomerSearch] = useState(false);
    const [vendorSearch, setvendorSearch] = useState(false);

    const handleChange = (e: React.ChangeEvent<{ value: unknown }>) => {
        const name = (e?.target as any).name;
        const value = (e?.target as any).value;
        console.log("EEE", name, value, e.target);
        setCustomerdetails((prevState: any) => ({
            ...prevState,
            [name]: value,
        }));
        setvendordetails((prevState: any) => ({ ...prevState, [name]: value }));
        // setCustomerSearch(true);
        // setvendorSearch(true);
    };

    useEffect(() => {
        setPortal("CMN");
    }, []);

    const {
        data: CustomerSearchData,
        error: CustomerSearchDataError,
        isLoading: CustomerSearchDataLoading,
    } = useGetPosts({
        name: `customersearch_${Customerdetails?.customerCode}_${Customerdetails?.customerName}_${Customerdetails?.customerPhone}_${Customerdetails?.customerMail}`,
        query: CustomerSearchQuery,
        variables: {
            entity: "OW_Cust_portal_cust_login",
            ou: currentContext?.ouId,
            service: "",
            User: userName, //"ramcouser",
            languageId: currentContext?.langId,
            customerName: Customerdetails?.customerName,
            customerCode: Customerdetails?.customerCode,
            mobileNumber: Customerdetails?.customerPhone,
            email: Customerdetails?.customerMail,
            merrorid: "0",
        },
        skip:
            customerSearch ||
            !Boolean(userName) ||
            !Boolean(currentContext?.ouId),
    });

    useEffect(() => {
        if (CustomerSearchData?.customerSearch) {
            setCustomer(CustomerSearchData?.customerSearch);
            setCustomerSearch(true);
        }
    }, [CustomerSearchData]);

    useEffect(() => {
        if (CustomerSearchDataError && !CustomerSearchDataLoading) {
            const data = JSON.parse(JSON.stringify(CustomerSearchDataError));
            const errmsg = data?.response?.errors[0].message;
            seterr({
                code: 400,
                msg: errmsg,
            });
            setAlert(true);
        }
    }, [CustomerSearchDataError, CustomerSearchDataLoading]);

    const handleCustomerSearch = () => {
        setSearchCustomer(true);
        setSearchVendor(false);
        setCustomerSearch(false);
        setvendorSearch(false);
    };

    const handleVendorSearch = () => {
        setSearchVendor(true);
        setSearchCustomer(false);
        setCustomerSearch(false);
        setvendorSearch(false);
    };

    const {
        data: VendorSearchData,
        error: VendorSearchDataError,
        isLoading: VendorSearchDataLoading,
    } = useGetPosts({
        name: `vendorsearch_${vendordetails?.vendorCode}_${vendordetails?.vendorName}_${vendordetails?.vendorPhone}_${vendordetails?.vendorMail}`,
        query: VendorSearchQuery,
        variables: {
            entity: `OW_vendor_portal_vendorlogin`,
            ou: currentContext?.ouId,
            service: "",
            User: userName,
            languageId: currentContext?.langId,
            vendorName: vendordetails?.vendorName,
            vendorCode: vendordetails?.vendorCode,
            mobileNumber: vendordetails?.vendorPhone,
            email: vendordetails?.vendorMail,
            merrorid: "0",
        },
        skip:
            vendorSearch ||
            !Boolean(userName) ||
            !Boolean(currentContext?.ouId),
    });

    useEffect(() => {
        if (VendorSearchData?.vendorSearch) {
            setVendor(VendorSearchData?.vendorSearch);
            setvendorSearch(true);
        }
    }, [VendorSearchData]);

    useEffect(() => {
        if (VendorSearchDataError && !VendorSearchDataLoading) {
            const data = JSON.parse(JSON.stringify(VendorSearchDataError));
            const errmsg = data?.response?.errors[0].message;
            seterr({
                code: 400,
                msg: errmsg,
            });
            setAlert(true);
        }
    }, [VendorSearchDataError, VendorSearchDataLoading]);

    if (
        currentContext?.roleName.toLowerCase() === "customerportal" ||
        currentContext?.roleName.toLowerCase() === "vendorportal" ||
        currentContext?.roleName.toLowerCase() === "custport" ||
        currentContext?.roleName.toLowerCase() === "franport"
    ) {
        return <Redirect to="/logout" />;
    }

    return (
        <>
            {alert ? handleErrorAlert(errr.code, errr.msg) : <></>}
            <Backdrop
                className={classes.backdrop}
                open={CustomerSearchDataLoading || VendorSearchDataLoading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Backdrop
                className={classes.backdrop}
                open={popoverBlack}
            ></Backdrop>
            <Grid container spacing={4} justifyContent="center">
                <BackdropLoading openStates={CustomerSearchDataLoading} />
                <Grid item xs={3}>
                    <Grid
                        className={classes.search}
                        style={{ marginBottom: "3%" }}
                    >
                        Search
                    </Grid>
                    <Paper elevation={1}>
                        <Grid xs={12}></Grid>
                        <Grid
                            item
                            sm={9}
                            xs={12}
                            className={classes.buttonGroup}
                        >
                            <ButtonGroups
                                controlList={controlList}
                                handleDocList={handleDocList}
                                gridSpacing={6}
                                externalActive={activeList}
                            />
                        </Grid>

                        {activeList === "Customer" ? (
                            <Grid item xs={12} className={classes.textpadding}>
                                <Grid
                                    item
                                    sm
                                    xs={12}
                                    className={classes.textpadding}
                                >
                                    {/* <Grid item xs sm={5}> */}
                                    <TextFields
                                        label={"Customer Name"}
                                        id={"customerName"}
                                        value={Customerdetails?.customerName}
                                        handleChange={handleChange}
                                        width={"100%"}
                                        multiline={false}
                                        rows={0}
                                    />
                                </Grid>

                                <Grid
                                    item
                                    sm
                                    xs={12}
                                    className={classes.textpadding}
                                >
                                    {/* <Grid item xs sm={5}> */}
                                    <TextFields
                                        label={"Customer Code"}
                                        id={"customerCode"}
                                        value={Customerdetails?.customerCode}
                                        handleChange={handleChange}
                                        width={"100%"}
                                        multiline={false}
                                        rows={0}
                                    />
                                </Grid>

                                <Grid
                                    item
                                    sm
                                    xs={12}
                                    className={classes.textpadding}
                                >
                                    {/* <Grid item xs sm={5}> */}
                                    <TextFields
                                        label={"Mobile Number"}
                                        id={"customerPhone"}
                                        value={Customerdetails?.customerPhone}
                                        handleChange={handleChange}
                                        width={"100%"}
                                        multiline={false}
                                        rows={0}
                                    />
                                </Grid>

                                <Grid
                                    item
                                    sm
                                    xs={12}
                                    className={classes.textpadding}
                                >
                                    {/* <Grid item xs sm={5}> */}
                                    <TextFields
                                        label={"E-Mail Id"}
                                        id={"customerMail"}
                                        value={Customerdetails?.customerMail}
                                        handleChange={handleChange}
                                        width={"100%"}
                                        multiline={false}
                                        rows={0}
                                    />
                                </Grid>

                                <Grid
                                    item
                                    sm={12}
                                    xs={12}
                                    className={classes.textpadding}
                                >
                                    {/* <Grid container alignItems="center"> */}
                                    <MUIButton
                                        label="Search"
                                        type="filled"
                                        font="font-small"
                                        onClick={handleCustomerSearch}
                                    />
                                    {/* </Grid> */}
                                </Grid>
                            </Grid>
                        ) : (
                            <Grid item xs={12} className={classes.textpadding}>
                                <Grid
                                    item
                                    sm
                                    xs={12}
                                    className={classes.textpadding}
                                >
                                    {/* <Grid item xs sm={5}> */}
                                    <TextFields
                                        label={"Vendor Name"}
                                        id={"vendorName"}
                                        value={vendordetails?.vendorName}
                                        handleChange={handleChange}
                                        width={"100%"}
                                        multiline={false}
                                        rows={0}
                                    />
                                </Grid>

                                <Grid
                                    item
                                    sm
                                    xs={12}
                                    className={classes.textpadding}
                                >
                                    {/* <Grid item xs sm={5}> */}
                                    <TextFields
                                        label={"Vendor Code"}
                                        id={"vendorCode"}
                                        value={vendordetails?.vendorCode}
                                        handleChange={handleChange}
                                        width={"100%"}
                                        multiline={false}
                                        rows={0}
                                    />
                                </Grid>

                                <Grid
                                    item
                                    sm
                                    xs={12}
                                    className={classes.textpadding}
                                >
                                    {/* <Grid item xs sm={5}> */}
                                    <TextFields
                                        label={"Mobile Number"}
                                        id={"vendorPhone"}
                                        value={vendordetails?.vendorPhone}
                                        handleChange={handleChange}
                                        width={"100%"}
                                        multiline={false}
                                        rows={0}
                                    />
                                </Grid>

                                <Grid
                                    item
                                    sm
                                    xs={12}
                                    className={classes.textpadding}
                                >
                                    {/* <Grid item xs sm={5}> */}
                                    <TextFields
                                        label={"E-Mail Id"}
                                        id={"vendorMail"}
                                        value={vendordetails?.vendorMail}
                                        handleChange={handleChange}
                                        width={"100%"}
                                        multiline={false}
                                        rows={0}
                                    />
                                </Grid>

                                <Grid
                                    item
                                    sm
                                    xs={12}
                                    className={classes.textpadding}
                                >
                                    <Grid container alignItems="center">
                                        <MUIButton
                                            label="Search"
                                            type="filled"
                                            font="font-small"
                                            onClick={handleVendorSearch}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}
                    </Paper>
                </Grid>
                {searchcustomer ? (
                    <Grid item xs={9}>
                        <Grid
                            xs={12}
                            style={{
                                textAlign: "left",
                                fontWeight: 600,
                                color: "#33415C",
                                marginBottom: "2%",
                            }}
                        >
                            Customer-Search Results({customer.length})
                        </Grid>
                        <Grid xs={12} style={{ marginBottom: "3%" }}>
                            <ButtonGroupAZ
                                controlList={customerList}
                                handleDocList={searchhandleDocList}
                                gridSpacing={12}
                                externalActive={searchactivelist}
                            />
                        </Grid>
                        <Grid xs={12}>
                            <Grid container>
                                <DashBoardDetailsCardAZ
                                    customer={customer}
                                    searchactivelist={searchactivelist}
                                    portalCode={"CUS"}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                ) : searchvendor ? (
                    <Grid item xs={9}>
                        <Grid
                            xs={12}
                            style={{
                                textAlign: "left",
                                fontWeight: 600,
                                color: "#33415C",
                                marginBottom: "2%",
                            }}
                        >
                            Vendor-Search Results({vendor.length})
                        </Grid>
                        <Grid xs={12} style={{ marginBottom: "3%" }}>
                            <ButtonGroupAZ
                                controlList={customerList}
                                handleDocList={searchhandleDocList}
                                gridSpacing={12}
                                externalActive={searchactivelist}
                            />
                        </Grid>
                        <Grid xs={12}>
                            <Grid container>
                                <DashBoardDetailsCardAZ
                                    customer={vendor}
                                    searchactivelist={searchactivelist}
                                    portalCode={"VEN"}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                ) : (
                    <Grid item xs={9}>
                        <Grid container spacing={5}>
                            <Grid item xs={6}>
                                <Grid className={classes.search}>
                                    Recent Customer Search
                                </Grid>
                                <DashBoardDetailsCard
                                    customer={customer}
                                    portalCode={"CUS"}
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <Grid className={classes.search}>
                                    Recent Vendor Search
                                </Grid>
                                <DashBoardDetailsCard
                                    customer={vendor}
                                    portalCode={"VEN"}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                )}
            </Grid>
        </>
    );
};

const mapStateToProps = (state: any) => {
    return {
        currentContext: state.context?.userDefaults,
        userName: state.context?.name,
        portal: state.portalData,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        setSelectedCustomer: (data: any) => {
            dispatch(storeSelectedCustomer(data));
        },
        setPortal: (data: any) => {
            dispatch(storePortal(data));
        },
        setSelectedVendor: (data: any) => {
            dispatch(storeSelectedVendor(data));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Body);
// export default Body;
