import "Common/Main.scss";
// import ButtonGroups from "Common/ButtonGroup/ButtonGroup";
import Body from "./Contacts/Body"


const Contacts= () => {
    return <Body/>
	
}

export default Contacts;