import {
    Grid,
    Paper,
    Backdrop,
    CircularProgress,
} from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import MUIButton from "Common/Button/Button";
import { connect } from "react-redux";
import useGetPosts from "ReactQuery/reactQuery";
import MuiDatatables from "Common/Mui-DataTables/MuiDatatables";
import { Redirect } from "react-router-dom";
import Dropdown from "Common/Dropdown/Dropdown";
import TextFields from "Common/TextField/TextField";
import CustomDatePickerBorder from "Common/DatePicker/DatePicker";
import {
    ContractsQuery,
    ContractStatusQuery,
    StatusComboQuery,
} from "ReactQuery/Query/index";
import dateFormatter from "Common/Utils/dateFormatter";
import Alert from "Common/Alerts/Alerts";
import currencyFormatter from "Common/Utils/currencyFormatter";

interface DashCards {
    desc: string;
    value: string;
    visible: boolean;
    frequent: boolean;
    detail: {
        desc: string;
        value: number;
        color: string;
    }[];
}

const useStyles = makeStyles((theme: Theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
}));

interface BodyProps {
    currentContext: any;
    userName: any;
    selectedCustomer: any;
    currency: any;
}

const Body = (props: BodyProps) => {
    const [header, setHeader] = useState<DashCards>();
    const classes = useStyles();
    const { currentContext, userName, selectedCustomer, currency } = props;
    const [contractDet, setContractDet] = useState<boolean>(false);
    const [statusList, setStatusList] = useState<any>([]);
    const [contractData, setContractData] = useState({
        fromDate: new Date(),
        toDate: new Date(),
        contractno: "",
        status: "",
    });

    const [active, setActive] = useState<string>("");

    const [showTable, setShowTable] = useState<boolean>(false);
    const [search, setSearch] = useState<boolean>(true);
    const [searchCount, setSearchCount] = useState(0);

    const [contractDetailsList, setContractDetailsList] = useState<any>([]);
    const [popoverBlack, setPopoverBlack] = useState(false);
    const [alert, setAlert] = useState(false);
    const [errr, seterr] = useState({
        code: 0,
        msg: "",
    });

    const [contractSelected, setcontractSelected] = useState();
    const [index, setIndex] = useState<any>(0);
    const [toggle, setToggle] = useState(false);

    const handleDate = (type: string, e: any) => {
        setShowTable(false);
        if (type === "toDate") {
            setContractData((prevstate: any) => ({
                ...prevstate,
                toDate: e,
            }));
        } else if (type === "fromDate") {
            setContractData((prevstate: any) => ({
                ...prevstate,
                fromDate: e,
            }));
        }
    };

    const handleContractClick = (val: any, index: any) => {
        setContractDet(true);
        setcontractSelected(val);
        setIndex(index);
    };

    const handleBuildingClick = (index: any) => {
        // redirect to build page
        setIndex(index);
        setToggle((prevState) => !prevState);
    };

    const {
        data: StatusComboData,
        error: StatusComboDataError,
        isLoading: StatusComboDataLoading,
    } = useGetPosts({
        name: `StatusComboQuery_${selectedCustomer?.companyCode}_Contract`,
        query: StatusComboQuery,
        variables: {
            entity: "ow_getcombo_status",
            ou: currentContext?.ouId,
            service: "",
            user: userName,
            langId: currentContext?.langId,
            screen: "Contract",
            tranType: "",
            merrorid: "",
        },
    });

    useEffect(() => {
        if (StatusComboData?.statusCombo) {
            console.log("StatusData", StatusComboData?.statusCombo);
            setStatusList(StatusComboData?.statusCombo);
            setContractData((prevState: any) => {
                return {
                    ...prevState,
                    status: StatusComboData?.statusCombo[0]?.value,
                };
            });
        }
    }, [StatusComboData]);

    useEffect(() => {
        if (StatusComboDataError && !StatusComboDataLoading) {
            const data = JSON.parse(JSON.stringify(StatusComboDataError));
            const errmsg = data?.response?.errors[0].message;
            seterr({
                code: 400,
                msg: errmsg,
            });
            setAlert(true);
        }
    }, [StatusComboDataError, StatusComboDataLoading]);

    const {
        data: ContractsData,
        error: ContractsDataError,
        isLoading: ContractsDataLoading,
    } = useGetPosts({
        name: `ContractsQuery_get_data_${contractData?.status}_${contractData?.contractno}_${contractData?.fromDate}_${contractData?.toDate}_${active}_${searchCount}_${selectedCustomer?.companyCode}`,
        query: ContractsQuery,
        variables: {
            entity: "OW_Cust_portal_cntct_dtls",
            ou: currentContext?.ouId,
            service: "",
            User: userName,
            languageId: currentContext?.langId,
            customerName: selectedCustomer?.companyName,
            customerCode: selectedCustomer?.companyCode,
            status: active === header?.detail[0]?.desc ? "AC" : contractData?.status,
            contractno: contractData?.contractno,
            fromdate: dateFormatter("sqlDate", contractData?.fromDate),
            todate: dateFormatter("sqlDate", contractData?.toDate),
            merrorid: "",
        },
        skip: active === header?.detail[0]?.desc ? false : !showTable
    });

    useEffect(() => {
        console.log("Contract Table", ContractsData?.contractdeatils);
        if (ContractsData?.contractdeatils) {
            setContractDetailsList(ContractsData?.contractdeatils);
        }
    }, [ContractsData]);

    useEffect(() => {
        if (ContractsDataError && !ContractsDataLoading) {
            const data = JSON.parse(JSON.stringify(ContractsDataError));
            const errmsg = data?.response?.errors[0].message;
            seterr({
                code: 400,
                msg: errmsg,
            });
            setAlert(true);
        }
    }, [ContractsDataError, ContractsDataLoading]);

    console.log("contractDetailsList", contractDetailsList);

    const {
        data: ContractHeaderData,
        error: ContractHeaderDataError,
        isLoading: ContractHeaderDataLoading,
    } = useGetPosts({
        name: `ContractHeaderQuery_get_data_${selectedCustomer?.companyCode}`,
        query: ContractStatusQuery, //ContractHeaderQuery,
        variables: {
            entity: "OW_Cust_portal_status",
            ou: currentContext?.ouId,
            service: "",
            User: userName,
            languageId: currentContext?.langId,
            customerCode: selectedCustomer?.companyCode, //selectedCustomer?.companyCode,//C1001178
            merrorid: "",
        },
    });

    useEffect(() => {
        console.log("Contract Header Data", ContractHeaderData);
        if (ContractHeaderData?.contractStatus) {
            setHeader(ContractHeaderData?.contractStatus);
            setActive(ContractHeaderData?.contractStatus?.detail[0]?.desc);
        }
    }, [ContractHeaderData]);

    useEffect(() => {
        if (ContractHeaderDataError && !ContractHeaderDataLoading) {
            const data = JSON.parse(JSON.stringify(ContractHeaderDataError));
            const errmsg = data?.response?.errors[0].message;
            seterr({
                code: 400,
                msg: errmsg,
            });
            setAlert(true);
        }
    }, [ContractHeaderDataError, ContractHeaderDataLoading]);

    const contractSchema = [
        {
            name: "Region",
            Label: "Region",
            type: "string",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Region</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return <b style={{ color: "#5C677D" }}>{value}</b>;
                },
            },
        },
        {
            name: "ContractID",
            Label: "ContractID",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Contract ID</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value, tableMeta.rowData);
                    return (
                        <b
                            style={{ color: "#0073E6", cursor: "pointer" }}
                            onClick={() =>
                                handleContractClick(value, tableMeta?.rowIndex)
                            }
                        >
                            {value}
                        </b>
                    );
                },
            },
        },
        {
            name: "BuildingDetails",
            Label: "BuildingDetails",
            type: "string",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Building Details</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,

                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return (
                        <b
                            style={{
                                color: "#0073E6",
                                cursor: "pointer",
                            }}
                            onClick={(value) =>
                                handleBuildingClick(tableMeta?.rowIndex)
                            }
                        >
                            {/* {value.line1} */}
                            {value}
                        </b>
                    );
                },
            },
        },
        {
            name: "BuildingAddress",
            Label: "BuildingAddress",
            type: "string",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Building Address</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return <b className="grey">{value}</b>;
                },
            },
        },
        {
            name: "AccountNumber",
            Label: "AccountNumber",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Account Number</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return <b style={{ color: "#5C677D" }}>{value}</b>;
                },
            },
        },
        {
            name: "BuildingAmount",
            Label: "BuildingAmount",
            type: "string",
            options: {
                filter: true,
                sort: false,
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Building Amount</span>
                ),
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return (
                        <b
                            style={{
                                color: "#00AE50",
                                display: "flex",
                                justifyContent: "flex-end",
                            }}
                        >
                            {currencyFormatter(value, currency)}
                        </b>
                    );
                },
            },
        },

        {
            name: "Contracts",
            Label: "Contracts",
            type: "number",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Contracts</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return <b style={{ color: "#5C677D" }}>{value}</b>;
                },
            },
        },
        {
            name: "Revision",
            Label: "Revision",
            type: "number",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Revision</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return <b style={{ color: "#5C677D" }}>{value}</b>;
                },
            },
        },

        {
            name: "RevisionStartEnd",
            Label: "RevisionStartEnd",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Revision Start - End</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return <b style={{ color: "#5C677D" }}>{value}</b>;
                },
            },
        },

        {
            name: "ActiveAssignment",
            Label: "ActiveAssignment",
            type: "number",

            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Active Assignment</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return <b style={{ color: "#5C677D" }}>{value}</b>;
                },
            },
        },
        {
            name: "Status",
            Label: "Status",
            options: {
                filter: true,
                sort: false,
                customHeadLabelRender: () => (
                    <span style={{ color: "white" }}>Status</span>
                ),
                setCellHeaderProps: () => {
                    return { style: { borderRight: "1px solid #D5D5D5" } };
                },
                setCellProps: () => {
                    return {
                        style: {
                            borderRight: "1px solid #D5D5D5",
                            textAlign: "center",
                        },
                    };
                },
                customBodyRender: (
                    value: any,
                    tableMeta: any,
                    updateValue: any,
                ) => {
                    console.log("table data", value);
                    return value === "Active" ? (
                        <>
                            <b className="Completed_dot"></b>
                            <b style={{ color: "#00AE50", padding: "3%" }}>
                                {value}
                            </b>
                        </>
                    ) : (
                        <>
                            <b className="Failed_dot"></b>
                            <b style={{ color: "#E01E37", padding: "3%" }}>
                                {value}
                            </b>
                        </>
                    );
                },
            },
        },
    ];

    const handleActive = (index: string) => {
        setActive(index);
        setShowTable(false);
        console.log("index", index);
    };

    const handleErrorAlert = (code: number, message: string) => {
        Alert({
            statusCode: code,
            message: message,
            willClose: () => {
                setAlert(false);
                seterr({ code: 0, msg: "" });
            },
        });
    };

    const handleChange = (e: any) => {
        setShowTable(false);
        setContractData((prevState) => ({
            ...prevState,
            contractno: e?.target?.value,
        }));
    };

    const handleChangeStatus = (e: any) => {
        setShowTable(false);
        setContractData((prevState) => ({
            ...prevState,
            status: e?.target?.value,
        }));
    };

    const handleSearch = () => {
        setShowTable(true);
        setSearch(false);
        setSearchCount((prevState) => prevState + 1);
    };

    if (contractDet) {
        return (
            <Redirect
                to={{
                    pathname: "/app/CONTDET",
                    state: {
                        contractSelected: contractSelected,
                        siteId: contractDetailsList[index]?.siteId,
                    },
                }}
            />
        );
    }

    if (toggle) {
        return (
            <Redirect
                to={{
                    pathname: "/app/BuildDetail",
                    state: {
                        siteId: contractDetailsList[index]?.siteId,
                    },
                }}
            />
        );
    }

    return (
        <>
            {alert ? handleErrorAlert(errr.code, errr.msg) : <></>}
            <Backdrop
                className={classes.backdrop}
                open={ContractsDataLoading || ContractHeaderDataLoading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Backdrop
                className={classes.backdrop}
                open={popoverBlack}
            ></Backdrop>
            <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} sm={12} style={{ paddingTop: "2%" }}>
                    {/* <Paper variant="outlined"> */}
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={12} sm={4} md={3}>
                            <Paper elevation={2}>
                                <Grid
                                    container
                                    spacing={2}
                                    style={{
                                        padding: "5% 0% 0% 0%",
                                    }}
                                    justifyContent="space-around"
                                >
                                    {header?.detail.map((element, index) => {
                                        return (
                                            <Grid
                                                item
                                                xs={4}
                                                sm={4}
                                                className={
                                                    active === element?.desc
                                                        ? `${element?.color}_border_bottom pointer`
                                                        : "pointer"
                                                }
                                                onClick={() =>
                                                    handleActive(element?.desc)
                                                }
                                            >
                                                <Grid container spacing={1}>
                                                    <Grid item xs={12} sm={12}>
                                                        <span
                                                            // className={`${element?.color} font-biggest font-boldest`}
                                                            className={
                                                                active ===
                                                                element?.desc
                                                                    ? `${element?.color} font-biggest font-boldest`
                                                                    : `grey font-biggest font-boldest`
                                                            }
                                                        >
                                                            {element?.value}
                                                        </span>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12}>
                                                        <span
                                                            className={
                                                                active ===
                                                                element?.desc
                                                                    ? `font-boldest font-smaller`
                                                                    : "grey font-smallest"
                                                            }
                                                        >
                                                            {element?.desc}
                                                        </span>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        );
                                    })}
                                </Grid>
                            </Paper>
                        </Grid>

                        {active === header?.detail[1]?.desc && (
                            <Grid item sm={12} md={8}>
                                <Paper elevation={2}>
                                    <Grid
                                        container
                                        spacing={2}
                                        style={{ padding: "2%" }}
                                    >
                                        <Grid item xs sm={10} style={{}}>
                                            <Grid
                                                container
                                                spacing={2}
                                                alignItems="center"
                                            >
                                                <Grid
                                                    item
                                                    sm
                                                    xs={12}
                                                    style={{}}
                                                >
                                                    {/* <Grid item xs sm={5}> */}
                                                    <TextFields
                                                        label={"Contract No."}
                                                        id={"contractno"}
                                                        value={
                                                            contractData?.contractno
                                                        }
                                                        handleChange={
                                                            handleChange
                                                        }
                                                        width={"100%"}
                                                        multiline={false}
                                                        rows={0}
                                                    />
                                                </Grid>
                                                <Grid
                                                    item
                                                    sm
                                                    xs={12}
                                                    style={{}}
                                                >
                                                    <Dropdown
                                                        label={"Status"}
                                                        id={"Status"}                                                        
                                                        value={
                                                            contractData?.status
                                                        }
                                                        list={statusList}
                                                        handleChange={
                                                            handleChangeStatus
                                                        }
                                                        width={"100%"}
                                                    />
                                                </Grid>
                                                <Grid item sm xs={2} style={{}}>
                                                    <CustomDatePickerBorder
                                                        label="From Date"
                                                        width="100%"
                                                        value={
                                                            contractData?.fromDate
                                                        }
                                                        onChange={(e) =>
                                                            handleDate(
                                                                "fromDate",
                                                                e,
                                                            )
                                                        }
                                                        format="MM/dd/yyyy"
                                                    />
                                                </Grid>
                                                <Grid item sm xs={2} style={{}}>
                                                    <CustomDatePickerBorder
                                                        label="To Date"
                                                        width="100%"
                                                        value={
                                                            contractData?.toDate
                                                        }
                                                        onChange={(e) =>
                                                            handleDate(
                                                                "toDate",
                                                                e,
                                                            )
                                                        }
                                                        format="MM/dd/yyyy"
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid item sm={2} xs={12}>
                                            <MUIButton
                                                label="Search"
                                                type="filled"
                                                font="font-small"
                                                onClick={handleSearch}
                                            />
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                        )}
                    </Grid>
                    {/* </Paper> */}
                </Grid>

                {active === header?.detail[0]?.desc && (
                    <Grid
                        item
                        xs={12}
                        style={{ marginRight: "2%", paddingTop: "2%" }}
                    >
                        <MuiDatatables
                            header={contractSchema}
                            // rowData={checklistDetailsList}
                            detail={[
                                contractDetailsList,
                                setContractDetailsList,
                            ]}
                            handleAddSideEffect={() => {}}
                            handleDeleteSideEffect={() => {}}
                            // handleSelectedSideEffect={handleSelectedSideEffect}
                            // initRow={true}
                        />
                    </Grid>
                )}

                {showTable && active === header?.detail[1]?.desc && (
                    <Grid
                        item
                        xs={12}
                        style={{ marginRight: "2%", paddingTop: "2%" }}
                    >
                        {" "}
                        {console.log("hello world")}
                        <MuiDatatables
                            header={contractSchema}
                            title={""}
                            detail={[
                                contractDetailsList,
                                setContractDetailsList,
                            ]}
                        />
                    </Grid>
                )}
            </Grid>
        </>
    );
};

const mapStateToProps = (state: any) => {
    return {
        currentContext: state.context?.userDefaults,
        userName: state.context?.name,
        selectedCustomer: state.selectedCustomer,
        currency: state.currency,
    };
};

export default connect(mapStateToProps)(Body);
