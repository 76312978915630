const PaymentsQuery = `
mutation(
    $entity: String
    $ou: Int
    $service: String
    $User: String
    $languageId: Int
    $invoiceNo: String
    $customerCode: String
    $customerName: String
    $siteId: String
    $buildName: String
    $contractNo: String
    $merrorid: String
) 
{
    GetPayments(
        entity: $entity
        ou: $ou
        service: $service
        User: $User
        languageId: $languageId
        invoiceNo: $invoiceNo
        customerCode: $customerCode
        customerName: $customerName
        siteId: $siteId
        buildName: $buildName
        contractNo: $contractNo
        merrorid: $merrorid
    ) 
    {
        VendorName
        VendorID
        ContractDetails{
            line1
            line2
            line3
        }
        Building
        BuildingID
        City
        PayStatus
        TransactionDetails
        VendorAmount
        Desc
        AdjustmentReason
        Date
        Approved
    }
}
`;

export default PaymentsQuery;
